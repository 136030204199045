<template>
  <main class="p-2 min-h-screen">
    <h4
      class="md:mt-3 md:block hidden md:text-base md:text-left text-xs text-headerText capitalize"
    >
      Payroll/ Edit Salary/{{ bonusorDed }}
    </h4>
    <div class="text-left mt-6 mb-3 md:block flex items-center justify-between">
      <easiBackButton></easiBackButton>
      <h4
        class="md:mt-3 md:hidden block md:text-sm md:text-left text-xs text-headerText capitalize"
      >
        Payroll/ Edit Salary/{{ bonusorDed }}
      </h4>
    </div>
    <div class="flex flex-row md:flex-col w-full md:w-52 md:items-start"></div>

    <div class="relative mx-auto w-full text-sm h-auto lg:w-8/12">
      <!-- <span @click="reloadPage" class="absolute top-4 right-4 cursor-pointer">
          <img src="@/assets/icons/close.svg" alt="" />
        </span> -->
      <span></span>
      <keep-alive>
        <EditBonus @completed="queryBonusOrDed" type="add" />
      </keep-alive>
    </div>
  </main>
</template>

<script setup>
import { ref, reactive, computed, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useDataStore } from "@/stores/data.js";
import { useRoute } from "vue-router";
import EditBonus from "@/components/Payroll/Edit/BonusAndDed/BonusAndDed.vue";

const store = useDataStore();
// const bonusOrDed = computed(() => store.getBonusOrDedById(route.params.id));
const { getAllEmployees, getBonusorDed, getBonusAndDeduction } =
  storeToRefs(store);
const { query, mutate } = store;
const route = useRoute();
const bonusorDed = computed(() => store.getBonusorDed);

const loading = ref(false);
const dataForBonusOrDed = computed(() => store.getBonusAndDeduction);

const bonusOrDedPayload = ref({
  paging: {
    limit: 1000,
    skip: 0,
    search: "",
  },
  type: bonusorDed.value,
});

// const employee = computed(() => store.getEmployeeById(route.params.id));
function getDataById(id) {
  const data = dataForBonusOrDed.value.filter((dat) => {
    return dat._id === id;
  });
  console.log(data);
  return data;
}
// const bData = getDataById(route.params.id);
//// View Components ////
const empName = () => {
  const bOrD = getDataById(route.params.id);
  return bOrD.length ? bOrD[0].name : "";
};

const display = ref(EditBonus);

const pages = reactive([
  {
    title: "Bonus",
    component: EditBonus,
    active: true,
    live: "view",
  },
]);
const queryBonusOrDed = async () => {
  loading.value = true;
  try {
    await query({
      endpoint: "ListBonusAndDeduction",
      payload: {
        input: bonusOrDedPayload.value,
      },
      service: "PAYROLL",
      storeKey: "listBonusAndDeduction",
    });
    // console.log(getBonusAndDeduction.value, "After query");
    loading.value = false;
  } catch (err) {
    loading.value = false;
    console.log(err);
  }
};
const switchView = (index) => {
  display.value = pages[index].component;
  pages.forEach((el) => {
    el.active = false;
  });
  pages[index].active = true;
};
onMounted(() => {
  queryBonusOrDed();
});
</script>

<style></style>
