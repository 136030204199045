<template>
  <div
    @click.self="showDropdow = true"
    class="relative border border-primary rounded-2xl p-2 sm:p-4 cursor-pointer flex items-center gap-2 bg-white w-fit"
  >
    {{ year }}

    <i @click="showDropdow = true" class="pi pi-angle-down"></i>

    <div
      v-show="showDropdow"
      class="z-50 absolute left-0 sm:-left-4 top-0 shadow-md bg-white w-full rounded-lg overflow-auto flex flex-col max-h-48 h-auto"
    >
      <div
        v-for="item in yearsArr"
        :key="item"
        @click="handleSelect(item)"
        :class="[
          year == item
            ? 'bg-primary text-highlight '
            : 'text-primary hover:bg-highlight ',
        ]"
        class="py-2 px-4 text-center font-medium last:border-none border-b"
      >
        {{ item }}
      </div>
    </div>
  </div>

  <div
    @click="showDropdow = false"
    :class="!showDropdow ? 'pointer-events-none' : ''"
    class="bg-transparent fixed inset-0"
  ></div>
</template>

<script setup>
import { computed, ref } from "vue";

const props = defineProps({
  startYear: {
    type: Number,
    default: 2021,
  },
  year: {
    type: Number,
    default: new Date().getFullYear(),
  },
});

const emit = defineEmits("update:year");
const showDropdow = ref(false);

const yearsArr = computed(() => {
  let arr = [];
  const currentYear = new Date().getFullYear();
  for (let year = props.startYear; year <= currentYear; year++) {
    arr.push(year);
  }

  arr = arr.sort((a, b) => b - a);
  return arr;
});

const handleSelect = (year) => {
  emit("update:year", year);
  showDropdow.value = false;
};
</script>

<style></style>
