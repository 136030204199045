<template>
  <main
    v-if="type === 'edit' || type === 'add' || edit === true"
    class="w-full"
  >
    <div
      :class="
        type === 'add'
          ? 'bg-none shadow-none md:w-full border-none mt-0 mb-0'
          : 'bg-white shadow-lg md:w-5/6  border border-boxBorder mt-10 mb-12'
      "
      class="w-full py-10 mx-auto rounded-2xl relative text-left"
    >
      <div :class="type === 'add' ? 'mb-0' : 'mb-8'">
        <h2
          class="ml-6 text-headerText font-bold md:text-xl text-lg md:text-2xl"
          :class="type === 'add' ? ' text-center' : 'text-left'"
        >
          {{ type === "add" ? "Add" : "Edit" }} Life Insurance
        </h2>
        <!-- <hr class="my-4 text-dividerColor" /> -->
        <span
          @click="$router.go(-1)"
          v-show="type === 'edit' || edit === true"
          class="absolute top-4 right-5 cursor-pointer"
        >
          <img src="@/assets/icons/close.svg" alt="" />
        </span>
      </div>
      <EditLifeInsurance type="edit" :data="data" />
    </div>
    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span
          >{{ bonusorDed }}
          {{ type === "edit" ? "updated" : "added" }} successfully</span
        >
      </template>
    </easiSuccess>

    <easiWarn v-if="deleteWarning" @close="deleteWarning = false">
      <template v-slot:message>
        <span>Are you sure you want to delete {{ bonusorDed }}?</span>
      </template>
      <template v-slot:action>
        <div class="flex flex-col items-center gap-4">
          <div class="flex justify-center items-center w-full gap-x-4 mt-6">
            <easiButton
              class="w-40 min-w-full"
              @click="deleteWarning = false"
              variant="outlined"
              >Cancel</easiButton
            >
            <easiButton
              class="w-40 min-w-full"
              :loading="loading"
              @click="deleteBonusOrDeduction"
              >Yes</easiButton
            >
          </div>
        </div>
      </template>
    </easiWarn>
  </main>
  <ViewLifeInsurance v-else @updateView="switchView($event)" />
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";
import { helperFunctions } from "@/composable/helperFunctions";
import lifeInsuranceBodies from "@/utils/lifeInsuranceBodies";

import ViewLifeInsurance from "@/components/Compliance/View/LifeInsurance.vue";
import EditLifeInsurance from "@/components/Compliance/Add/LifeInsurance.vue";
const emit = defineEmits(["completed"]);

const store = useDataStore();
const { getBonusorDed, getCompliance, getAllEmployees } = storeToRefs(store);

const allCompliance = computed(() =>
  getCompliance.value ? getCompliance.value.data : {}
);
const data = computed(() => allCompliance.value["lifeInsurance"]);

const bonusorDed = computed(() => store.getBonusorDed);
console.log(bonusorDed.value, "Check for NULL");
const nameOfType = ref("");
nameOfType.value = bonusorDed.value;
const { query, mutate } = store;
const { processDate } = helperFunctions;
const today = ref("");
today.value = processDate();

const allEmployees = computed(() => getAllEmployees.value.data);

const route = useRoute();
const router = useRouter();
const props = defineProps({
  type: {
    type: String,
    default: "view",
  },
});
const deleteWarning = ref(false);
const bonusOrDedPayload = ref({
  paging: {
    limit: 1000,
    skip: 0,
    search: "",
  },
  type: bonusorDed.value.length > 0 ? bonusorDed.value : "Bonus",
});

const updateSuccess = ref(false);
const loading = ref(false);
const edit = ref(false);
const percentage = ref(true);
const durationOption = ref([
  {
    label: "Weekly",
    value: "WEEKLY",
  },
  {
    label: "Monthly",
    value: "MONTHLY",
  },
  {
    label: "Annually",
    value: "ANNUALLY",
  },
  {
    label: "One-Time",
    value: "ONE_TIME",
  },
]);
const switchView = (e) => {
  edit.value = e;
};

props.type === "edit" ? (edit.value = true) : (edit.value = false);
const args = ref({
  type: "",
  name: "",
  employeeIds: [],
  amount: "0",
  percentage: 0,
  duration: "",
  endDate: "",
  status: "ACTIVE",

  lifeInsurance: {
    provider: "",
  },
});

const queryBonusOrDed = async () => {
  loading.value = true;
  try {
    await query({
      endpoint: "ListBonusAndDeduction",
      payload: {
        input: bonusOrDedPayload.value,
      },
      service: "PAYROLL",
      storeKey: "listBonusAndDeduction",
    });
    // console.log(getBonusAndDeduction.value, "After query");
    loading.value = false;
  } catch (err) {
    loading.value = false;
    console.log(err);
  }
};

async function createBonusOrDeduction() {
  loading.value = true;
  args.value.amount = Number(args.value.amount);
  args.value.percentage = Number(args.value.percentage);
  if (args.value.percentage < 0 || args.value.percentage > 100) {
    throw new Error("Percentage should be less than or equal to 100");
  }
  if (props.type === "add" && !route.params.id) {
    args.value.type = bonusorDed.value;

    try {
      let res = await mutate({
        endpoint: "CreateBonusOrDeduction",
        service: "PAYROLL",
        data: {
          input: args.value,
        },
      });
      loading.value = false;
      if (res._id) {
        await queryBonusOrDed();
        updateSuccess.value = true;
        emit("completed", false);

        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);
      }
    } catch (e) {
      loading.value = false;
      console.log(e);
    }
  } else {
    try {
      let res = await mutate({
        endpoint: "UpdateBonusOrDeduction",
        service: "PAYROLL",
        data: {
          updateBonusOrDeductionId: route.params.id,
          input: args.value,
        },
      });
      loading.value = false;
      if (res._id) {
        store.$patch({
          bonusorDed: args.value.type,
        });
        await queryBonusOrDed();
        updateSuccess.value = true;
        router.go(-1);
        // emit("completed", false);

        // setTimeout(() => {
        //   window.location.reload();
        //   router.go(-1);
        // }, 1000);
      }
    } catch (e) {
      loading.value = false;
      console.log(e);
    }
  }
}

async function deleteBonusOrDeduction() {
  try {
    let res = await mutate({
      endpoint: "DeleteBonusOrDeduction",
      service: "PAYROLL",
      data: {
        deleteBonusOrDeductionId: route.params.id,
      },
    });
    loading.value = false;
    if (res.success) {
      await queryBonusOrDed();
      updateSuccess.value = true;
      router.go(-1);

      // setTimeout(() => {
      //   router.go(-1);
      // }, 3000);
    }
  } catch (e) {
    loading.value = false;
    console.log(e);
  }
}
const createEmpList = () => {
  const list = allEmployees.value.map((emp) => {
    return {
      label: `${emp.profile.firstName} ${emp.profile.lastName}`,
      value: emp._id,
    };
  });
  return list;
};
queryBonusOrDed();
</script>

<style></style>
