<template>
  <div
    :class="onPrint ? 'grid-cols-4' : 'grid-cols-2 md:grid-cols-4'"
    class="rounded-2xl overflow-hidden bg-outlineGray p05 text-dark-900 mt-8 grid"
  >
    <div
      class="flex md:rounded-l-2xl flex-col items-start md:items-center p-4 bg-newDirtyGold"
    >
      <span class="text-md font-medium">Total Staff Paid</span>
      <span
        :class="
          loading
            ? 'animate-bounce'
            : '' + (onPrint ? ' text-xl ' : ' text-3xl ')
        "
        class="font-medium"
        >{{ loading ? "..." : data && data.employeesTotal }}</span
      >
    </div>
    <div
      class="flex flex-col items-start md:items-center p-4 bg-secondary-crepe"
    >
      <span class="text-md font-medium">Total Gross Salary</span>
      <span
        :class="
          loading
            ? 'animate-bounce'
            : '' + (onPrint ? ' text-xl ' : ' text-3xl ')
        "
        class="font-medium break-all"
        >{{ loading ? "..." : formatAmount(data && data.grossTotal) }}</span
      >
    </div>
    <div class="flex flex-col items-start md:items-center p-4 bg-mint">
      <span class="text-md">Total Net Salary</span>
      <span
        :class="
          loading
            ? 'animate-bounce'
            : '' + (onPrint ? ' text-xl ' : ' text-3xl ')
        "
        class="font-medium break-all"
        >{{ loading ? "..." : formatAmount(data && data.netTotal) }}</span
      >
    </div>
    <div
      class="flex flex-col md:rounded-r-2xl items-start md:items-center p-4 bg-secondary-meshed"
    >
      <span class="text-md">Total Deduction</span>
      <span
        :class="
          loading
            ? 'animate-bounce'
            : '' + (onPrint ? ' text-xl ' : ' text-3xl ')
        "
        class="font-medium break-all"
        >{{ loading ? "..." : formatAmount(data && data.deductionTotal) }}</span
      >
    </div>
  </div>
</template>

<script setup>
import { helperFunctions } from "@/composable/helperFunctions";
import { onMounted, ref } from "vue";

const { calculatePercentage, formatAmount } = helperFunctions;

const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  data: Object,

  onPrint: {
    type: Boolean,
    default: false,
  },
});
</script>

<style></style>
