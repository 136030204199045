<template>
  <div
    class="onprint bg-blueGray-100 md:ml-8 p-3 md:p-0 mx-auto md:mx-0 h-full overflow-x-hidden w-full"
  >
    <!-- {{ payrollDetailedReport }} -->
    <div
      class="flex flex-row-reverse justify-between md:items-start items-center md:flex-col"
    >
      <div class="w-full h-full mb-32">
        <main>
          <div
            @click="$router.go(-1)"
            class="no-printable cursor-pointer text-left md:mt-5 font-bold text-xl flex items-center gap-2 text-2xl"
          >
            <button
              class="text-left text-dark-800 font-medium focus:outline-none"
            >
              <i class="fas fa-angle-left mr-3 text-left cursor-pointer"></i>
            </button>
            <span class="text-base text-dark-800"> Back</span>
          </div>

          <div class="flex flex-col mt-4 md:mt-0 w-full gap-10">
            <div
              class="no-printable flex flex-col gap-4 w-full md:justify-start"
            >
              <div
                class="flex gap-3 text-hoverColor justify-between text-left w-full justify-self-start self-start md:gap-x-24"
              >
                <h3 class="font-bold text-3xl whitespace-nowrap text-primary">
                  Detailed
                  <span class="hidden md:inline"> Payroll </span>

                  Report
                </h3>

                <div
                  class="no-printable w-full flex justify-end md:justify-start items-center gap-2 md:gap-4"
                >
                  <easiButton
                    @click="generate"
                    variant="outlined"
                    :loading="isDownloading"
                    class="rounded-md md:justify-center flex items-center md:gap-2 w-full"
                    size="small"
                  >
                    <span class="hidden md:block"> Download</span>
                    <i :class="'pi pi-download'" />
                  </easiButton>
                  <easiButton
                    @click="shareLink = true"
                    variant="outlined"
                    class="rounded-md md:justify-center flex items-center gap-2 w-full"
                    size="small"
                  >
                    <span class="hidden md:block"> Share</span>
                    <i :class="'pi pi-share-alt'" />
                  </easiButton>
                  <easiButton
                    @click="printPage"
                    :loading="isPrinting"
                    variant="outlined"
                    class="rounded-md md:justify-center flex items-center gap-2 w-full"
                    size="small"
                  >
                    <span class="hidden md:block"> Print</span>
                    <i :class="'pi pi-print'" />
                  </easiButton>
                </div>
              </div>
              <p
                class="cursor-pointer text-sm text-left md:w-3/5 text-grey font-medium"
              >
                You must advise Eazipay of any discrepancies observed in this
                report within 15 days of receipt, otherwise it will be deemed
                correct. All products are subject to terms and conditions.
                <br />
                <span @click="openChat" class="text-primary font-medium">
                  Customer care</span
                >
              </p>
            </div>
            <vue3-simple-html2pdf
              ref="vue3SimpleHtml2pdf2"
              :options="pdfOptions"
              :filename="fileName"
            >
              <div
                :class="
                  !showAll
                    ? 'shadow-md md:shadow-lg md:mx-auto md:w-10/12 rounded rounded-2xl border border-boxBorder'
                    : ''
                "
                class="bg-white w-full px-4 py-4 md:p-8"
              >
                <div
                  :class="!showAll ? 'flex-col md:flex-row' : 'flex-row'"
                  class="flex gap-y-8 justify-between items-start"
                >
                  <div class="flex gap-12">
                    <div>
                      <img
                        v-if="
                          companyData &&
                          companyData.company &&
                          companyData.company.logo
                        "
                        :src="
                          companyData &&
                          companyData.company &&
                          companyData.company.logo
                        "
                        class="w-44"
                        alt=""
                      />
                      <img
                        v-else
                        src="@/assets/img/comp-logo.png"
                        class="w-44"
                        alt=""
                      />
                    </div>

                    <div class="flex flex-col gap-y-2">
                      <div class="flex items-center gap-x-4">
                        <img src="@/assets/img/newCompany.svg" alt="" />
                        <span
                          class="text-dark-800 font-normal text-sm capitalize"
                        >
                          {{
                            companyData &&
                            companyData.company &&
                            companyData.company.companyName
                          }}
                        </span>
                      </div>

                      <div class="flex items-center gap-x-4">
                        <img src="@/assets/img/newEmail.svg" alt="" />
                        <span
                          class="text-dark-800 font-normal text-sm lowercase"
                        >
                          {{
                            companyData && companyData.companyAdmin.email
                              ? companyData.companyAdmin.email
                              : adminDetails && adminDetails.email
                          }}
                        </span>
                      </div>

                      <div class="flex items-center gap-x-4">
                        <img src="@/assets/img/newLocation.svg" alt="" />
                        <span
                          class="text-dark-800 font-normal text-sm capitalize break-all"
                        >
                          {{
                            companyData &&
                            companyData.company &&
                            companyData.company.address
                          }}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                    :class="!showAll ? 'w-full md:w-auto' : 'w-64'"
                    class="flex flex-col bg-newLimeGreen border border-primary rounded-tr-5xl px-5 py-2 text-sm"
                  >
                    <!-- :class="
                  !showAll
                    ? 'flex-row md:flex-col items-center md:items-start'
                    : 'flex-col'
                " -->
                    <div class="flex items-center md:items-start gap-x-2 mr-6">
                      <span class="text-dark-800">Date Generated:</span>
                      <span class="text-dark-800 font-bold"
                        >{{ months[new Date().getMonth()] }}
                        {{ new Date().getDate().toString().padStart(2, "0") }},
                        {{ new Date().getFullYear() }}</span
                      >
                    </div>
                    <hr class="border border-dark-200 mt-1" />
                    <div class="flex gap-1 flex-row mt-1">
                      <span class="text-dark-800">Payroll:</span>
                      <span class="text-dark-800 font-bold break-all"
                        >myeazipaypayrollDetailedreport</span
                      >
                    </div>
                    <hr class="border border-dark-200 my-1" />
                    <!-- :class="
                    !showAll
                      ? 'flex-row md:flex-col items-center md:items-start'
                      : 'flex-col'
                  " -->
                    <div class="flex items-center md:items-start gap-x-2">
                      <div class="text-dark-800 my-auto">Requested By:</div>

                      <div class="flex items-center gap-x-2">
                        <div class="flex gap-x-2 items-center">
                          <div
                            :class="
                              !showAll
                                ? 'pt-1 pl-1 pr-1 md:p-2  md:h-9 md:w-9 w-7 h-7'
                                : 'p-2  h-9 w-9 '
                            "
                            class="bg-primary text-xs md:text-sm text-white text-center font-medium rounded-full"
                          >
                            {{
                              getAvatar({
                                profile: {
                                  firstName:
                                    companyData &&
                                    companyData.companyAdmin &&
                                    companyData.companyAdmin.firstName
                                      ? companyData.companyAdmin.firstName
                                      : adminDetails && adminDetails.firstName,
                                  lastName:
                                    companyData &&
                                    companyData.companyAdmin &&
                                    companyData.companyAdmin.lastName
                                      ? companyData.companyAdmin.lastName
                                      : adminDetails && adminDetails.lastName,
                                },
                              })
                            }}
                          </div>
                        </div>
                        <span class="text-dark-800 font-bold"
                          >{{
                            companyData &&
                            companyData.companyAdmin &&
                            companyData.companyAdmin.firstName
                              ? companyData.companyAdmin.firstName
                              : adminDetails && adminDetails.firstName
                          }}
                          {{
                            companyData &&
                            companyData.companyAdmin &&
                            companyData.companyAdmin.lastName
                              ? companyData.companyAdmin.lastName
                              : adminDetails && adminDetails.lastName
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  :class="
                    !showAll ? 'md:p-3 px-12 py-3 mt-7' : 'p-3 px-12  mt-7'
                  "
                  class="w-full bg-primary-hover text-sm font-medium text-center capitalize text-boxBorder"
                >
                  {{
                    companyData &&
                    companyData.company &&
                    companyData.company.companyName
                  }}
                  Payroll Report From
                  {{
                    $route.query.date &&
                    formatDateString($route.query.date.split("/")[0], "name")
                  }}
                  -
                  {{
                    $route.query.date &&
                    formatDateString($route.query.date.split("/")[1], "name")
                  }}
                </div>
                <div
                  :class="!showAll ? 'flex-col md:flex-row' : 'flex-row'"
                  class="flex items-center w-full"
                >
                  <div class="flex w-full flex-col items-center justify-center">
                    <div
                      class="bg-primary text-white flex flex-col gap-1 md:gap-3 w-full text-center text-2xl md:text-2xl py-4 md:py-3 px-3"
                    >
                      <span
                        class="uppercase text-white font-medium text-sm md:text-base"
                        >total gross pay</span
                      >
                      <span class="font-bold">{{
                        formatAmount(
                          payrollDetailedReport &&
                            payrollDetailedReport.totalGrossPay
                        )
                      }}</span>
                    </div>
                  </div>

                  <div class="flex w-full flex-col items-center justify-center">
                    <div
                      class="bg-deepGold text-white flex flex-col gap-1 md:gap-3 w-full text-center text-2xl md:text-2xl py-4 md:py-3 px-3"
                    >
                      <span
                        class="uppercase text-white font-medium text-sm md:text-base"
                        >total net pay</span
                      >
                      <span class="font-bold">
                        {{
                          formatAmount(
                            payrollDetailedReport &&
                              payrollDetailedReport.totalNet
                          )
                        }}</span
                      >
                    </div>
                  </div>
                </div>
                <div
                  class="w-full bg-blueHover flex items-center justify-center p-3 text-sm text-center text-dark-800 font-bold"
                >
                  <!-- <img
                    :class="!showAll ? 'md:block hidden' : ''"
                    src="@/assets/img/employees.png"
                    alt=""
                  /> -->
                  <span
                    :class="
                      !showAll
                        ? 'md:text-2xl font-black md:font-normal capitalize '
                        : 'text-2xl  font-normal capitalize'
                    "
                    >Total Number of successful payments:
                    {{
                      payrollDetailedReport &&
                      payrollDetailedReport.totalNumOfEmployees
                    }}</span
                  >
                  <!-- <img
                    :class="!showAll ? 'md:block hidden' : ''"
                    src="@/assets/img/employees.png"
                    alt=""
                  /> -->
                </div>
                <div
                  :class="
                    !showAll ? 'grid-cols-1 md:grid-cols-3' : 'grid-cols-3'
                  "
                  class="w-full grid py-3 text-sm text-center text-dark-800 font-bold"
                >
                  <div class="flex flex-col p-4 gap-2 bg-newAsh">
                    <span class="text-dark-900 font-medium"
                      >TOTAL STATUTORY DEDUCTIONS</span
                    >
                    <span
                      class="text-dark-900 text-center font-bold text-2xl"
                      >{{
                        formatAmount(
                          payrollDetailedReport &&
                            payrollDetailedReport.totalStatutoryDeductions
                        )
                      }}</span
                    >
                  </div>

                  <div
                    class="flex flex-col p-4 gap-2 bg-newAsh border-l border-newLightGray"
                  >
                    <span class="text-dark-900 font-medium"
                      >TOTAL DEDUCTIONS</span
                    >
                    <span
                      class="text-dark-900 text-center font-bold text-2xl"
                      >{{
                        formatAmount(
                          payrollDetailedReport &&
                            payrollDetailedReport.totalDeductions
                        )
                      }}</span
                    >
                  </div>

                  <div
                    class="flex flex-col p-4 gap-2 bg-newAsh border-l border-newLightGray"
                  >
                    <span class="text-dark-900 font-medium">TOTAL BONUS</span>
                    <span
                      class="text-dark-900 text-center font-bold text-2xl"
                      >{{
                        formatAmount(
                          payrollDetailedReport &&
                            payrollDetailedReport.totalBonuses
                        )
                      }}</span
                    >
                  </div>
                </div>

                <!--Table Starts -->

                <div v-if="!showAll" class="w-full mt-4">
                  <DetailedReportTable />
                </div>

                <div v-if="showAll" class="w-full mt-4">
                  <PrintDetailedReport />
                </div>
              </div>
            </vue3-simple-html2pdf>
            <div
              class="no-printable w-full flex justify-center items-center gap-2 md:gap-4"
            >
              <easiButton
                @click="generate"
                :loading="isDownloading"
                variant="outlined"
                class="rounded-md md:justify-center flex items-center md:gap-2 w-full"
                size="small"
              >
                <span class="hidden md:block"> Download</span>
                <i :class="'pi pi-download'" />
              </easiButton>
              <easiButton
                @click="shareLink = true"
                variant="outlined"
                class="rounded-md md:justify-center flex items-center gap-2 w-full"
                size="small"
              >
                <span class="hidden md:block"> Share</span>
                <i :class="'pi pi-share-alt'" />
              </easiButton>
              <easiButton
                @click="printPage"
                :loading="isPrinting"
                variant="outlined"
                class="rounded-md md:justify-center flex items-center gap-2 w-full"
                size="small"
              >
                <span class="hidden md:block"> Print</span>
                <i :class="'pi pi-print'" />
              </easiButton>
            </div>
          </div>
        </main>
      </div>
    </div>

    <easiModal v-if="selectDuration" @close="selectDuration = false">
      <template v-slot:header>
        <h1 class="text-dark-800 font-medium">Select Duration</h1>
      </template>
      <form
        @submit.prevent="sendOTP"
        class="flex flex-col items-center gap-9 w-full mt-3 px-5"
      >
        <span
          class="time-span-color px-3 py-2 text-sm rounded-md w-44 text-center text-grey font-bold"
          >From</span
        >
        <input
          class="focus:border-primary focus:ring-primary border-dark-200 p-16 bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border py-3 px-4 block appearance-none rounded-2xl mt-1"
          type="date"
          name="start"
          id="start"
          required
          v-model="startDate"
        />

        <span
          class="time-span-color px-3 py-2 text-sm rounded-md w-44 text-center text-grey font-bold"
          >To</span
        >

        <input
          class="focus:border-primary focus:ring-primary border-dark-200 p-16 bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border py-3 px-4 block appearance-none rounded-2xl mt-1"
          type="date"
          name="start"
          id="start"
          required
          :min="startDate"
        />

        <div class="w-full">
          <hr
            class="mt-2 mb-8 border-0.5 border-dividerColor text-dividerColor"
          />

          <easiButtonNew :block="true" class="w-full"
            >Generate Report</easiButtonNew
          >
        </div>
      </form>
    </easiModal>

    <easiModal v-if="enterOtp" @close="enterOtp = false" :isBorder="false">
      <easiContainer :hideCancel="true" class="w-full py-8 mx-auto text-center">
        <div class="mb-4">
          <h2 class="text-secondary font-bold text-2xl sm:text-3xl md:text-4xl">
            OTP Verification
          </h2>
          <span class="text-base md:text-md mt-1 font-medium block">
            Please input OTP sent to your email and phone number
          </span>
        </div>
        <form class="w-full" @submit.prevent="confirmOtp">
          <span class="mb-4 flex justify-around items-center">
            <easiOtp
              size="10"
              :token_length="6"
              :max-length="1"
              v-model="otpCode"
              @input="checkOTP"
              :error="errorRules.otpCode"
            />
          </span>
          <div
            class="text-dark-800 mb-4 text-base font-medium text-center flex items-center justify-center"
          >
            Did not receive OTP?
            <easiButtonNew
              type="button"
              @click="sendOTP"
              variant="text"
              color="secondary"
              class="text-base px-0"
              :loading="resendOtpLoading"
              >Resend</easiButtonNew
            >
          </div>
          <easiButtonNew block color="secondary" :loading="confirmOtpLoading">
            Submit
          </easiButtonNew>
        </form>
      </easiContainer>
    </easiModal>

    <easiModal v-if="shareLink" @close="shareLink = false">
      <template v-slot:header>
        <div class="flex items-center gap-x-4 justify-center">
          <img src="@/assets/icons/share-black.svg" alt="" />
          <h1 class="capitalize text-3xl font-medium text-headerText">Share</h1>
        </div>
      </template>

      <form
        @submit.prevent="shareReport"
        class="flex flex-col"
        autocomplete="on"
      >
        <div class="flex flex-col px-6 sm:px-12 lg:px-8 xl:px-10">
          <easiTextInput
            v-model="args2.email"
            placeholder="Input Email Address"
            type="email"
            name="email"
            class="mt-1 mb-5"
            required
            :error="errorRules2.email"
          />
        </div>
        <hr
          class="mt-3 md:mb-8 mb-4 border-0.5 border-dividerColor text-dividerColor"
        />
        <div class="px-10 w-full">
          <easiButtonNew
            :loading="loadingShare"
            :block="true"
            class="w-full mb-4 md:mb-5"
            >Send Mail</easiButtonNew
          >
        </div>
      </form>
    </easiModal>

    <easiLoading v-if="loadingProgress" @close="loadingProgress = false">
      <template v-slot:heading>
        <h1>Generating Report</h1>
      </template>

      <!-- <template v-slot:message>
        <div class="w-full rounded-xl">
          <vue3-progress-bar></vue3-progress-bar>
        </div>
      </template> -->
    </easiLoading>
    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span>Payroll Report has been shared successfully</span>
      </template>
    </easiSuccess>
  </div>
</template>

<script>
import { ref, reactive, onMounted, watch, computed } from "vue";
import { storeToRefs } from "pinia";
import { useDataStore } from "@/stores/data.js";
import { helperFunctions } from "@/composable/helperFunctions";
import { useToast } from "vue-toastification";
import BankDetail from "@/components/Employee/Edit/Benefits/BankDetails.vue";
import ReportTable from "@/components/Payroll/Report/ReportTable.vue";
import DetailedReportTable from "@/components/Payroll/Report/DetailedReportTable.vue";

import PrintFullReport from "@/components/Payroll/Report/FullReportPrint.vue";
import PrintDetailedReport from "@/components/Payroll/Report/DetailedReportPrint.vue";

export default {
  components: {
    ReportTable,
    PrintFullReport,
    DetailedReportTable,
    PrintDetailedReport,
  },
  setup() {
    const toast = useToast();

    const {
      formatAmount,
      calculatePercentage,
      formatDateString,
      downloadBase64,
    } = helperFunctions;

    const store = useDataStore();
    const { mutate, query } = useDataStore();
    const {
      listEmployees,
      listPayrollHistory,
      getAllEmployees,
      getPayrollDetailReport,
    } = storeToRefs(store);

    const payrollDetailedReport = computed(() => {
      if (store.getPayrollDetailReport && store.getPayrollDetailReport.length) {
        return store.getPayrollDetailReport[0];
      }
    });

    console.log("detsssailed", store.getPayrollDetailReport);

    const allEmployees = computed(() => store.getAllEmployees.data);
    const addBank = ref(false);

    const showPin = ref(false);
    const showOtp = ref(false);
    const showAll = ref(false);
    const isDownloading = ref(false);
    const detailed = ref(true);
    const isViewAnalysis = ref(true);

    const bankName = ref("");
    const selectDuration = ref(false);
    const adminDetails = computed(() => store.getCompanyAdmin.companyAdmin);

    const args = reactive({ pin: "" });
    const args2 = reactive({
      email: "",
      from: "",
      to: "",
      paymentType: ["salary", "deduction", "bonus", "statutory"],
    });
    const updateSuccess = ref(false);
    const otpReference = ref("");
    const enterOtp = ref(false);
    const otpCode = ref("");
    const errorRules = reactive({
      firstName: false,
      lastName: false,
      email: false,
      phoneNumber: false,
      companyName: false,
      pin: false,
      confirmPin: false,
      referralCode: false,
      otpCode: false,
    });
    const errorRules2 = reactive({
      from: false,
      to: false,
      email: false,
      download: false,
      paymentType: false,
    });

    const loading = ref(false);
    const otpLoading = ref(false);
    const resendOtpLoading = ref(false);
    const confirmOtpLoading = ref(false);
    const loadingProgress = ref(false);

    const startDate = ref("");
    let months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const argsArray = ref([]);
    const companyData = computed(() => store.getCompanyDetails);
    console.log(companyData);

    const bankTransfer = ref(true);
    const accountNumber = ref("0711456765");
    const link = ref(
      "9DIJxLyx3H85tP87hYF8eg/EAZIPAY-NEW-WEB-APP-PROJECT?node-id=6150%3EAZIPAY-NEW-WEB-Flutterwaveaccount7."
    );
    const topupOption = ref(false);
    const payStack = ref(false);
    const linkGenerated = ref(false);
    const shareLink = ref(false);
    const transferDetails = ref(false);
    const transferNotification = ref(false);
    function copyText(val) {
      var text = val;
      navigator.clipboard.writeText(text);
      toast.info("Copied!");
    }
    const payrollInterval = ref(["Weekly", "Monthly"]);
    function generatePaymentLink() {
      payStack.value = true;
    }

    function checkPin(otp_input) {
      args.pin = otp_input;
    }

    function addMore(arg) {
      const newObj = Object.assign({}, args);

      argsArray.value.push(newObj);

      store.$patch((state) => {
        state.profileArr.push(newObj);
      });
      toast.success("Details saved!");
    }
    function queryEmployees() {
      query({
        endpoint: "ListEmployees",
        payload: payload,
        service: "EMP",
        storeKey: "listEmployees",
      });
    }

    const payload = reactive({
      paging: {
        limit: 1000,
        skip: 0,
        search: "",
        sort: { firstName: "ASC" },
      },
      filter: [],
    });

    async function save() {
      addMore(args);
      Object.assign(args, resetObj(args, "args"));
    }

    const getAvatar = (employee) => {
      let name = "";

      if (employee) {
        console.log(employee);
        employee
          ? (name = `${
              employee && employee.profile && employee.profile.firstName
                ? employee.profile.firstName.charAt(0)
                : "N"
            }${
              employee && employee.profile && employee.profile.lastName
                ? employee.profile.lastName.charAt(0)
                : "/A"
            }`)
          : "";
      }

      return name ? name : "N/A";
    };
    const downloadThenPrint = async () => {
      const payload = {
        input: {
          from: "",
          paymentType: {
            salary: true,
            bonus: true,
            statutory: true,
            deduction: true,
          },
          to: "",
        },
        download: true,
      };
      const res = await query({
        endpoint: "SendDetailedPayrollReport",
        service: "PAYROLL",
        payload,
      });
      const newData = Buffer.from(res.data);
      const base64 = newData.toString("base64");
      openPdfInNewTab(base64);
    };
    function openPdfInNewTab(base64Pdf) {
      // Create a Blob from the base64 PDF string
      const pdfData = atob(base64Pdf);
      const arrayBuffer = new ArrayBuffer(pdfData.length);
      const uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < pdfData.length; i++) {
        uint8Array[i] = pdfData.charCodeAt(i);
      }
      const pdfBlob = new Blob([arrayBuffer], { type: "application/pdf" });

      // Generate a URL for the Blob
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Open the PDF in a new browser tab
      const newTab = window.open(pdfUrl, "_blank");

      // Trigger print dialog when new tab is loaded
      if (newTab) {
        newTab.onload = function () {
          newTab.print();
        };
      }
    }
    const printPage = async () => {
      try {
        isPrinting.value = true;
        await downloadThenPrint();
      } catch (e) {
        console.error(e);
      } finally {
        isPrinting.value = false;
      }

      // showAll.value = true;
      // setTimeout(() => {
      //   window.print();
      // }, 1000);
    };
    onafterprint = (event) => {
      showAll.value = false;
    };

    async function sendOTP() {
      //validate form field
      const payload = {
        channel: "EMAIL",
        token_length: 4,
        email: adminDetails.value.email,

        // type: "SIGNUP",
        phoneNumber: adminDetails.value.phoneNumber,

        firstName: adminDetails.value.firstName,
      };

      otpLoading.value = true;

      try {
        let res = await mutate({
          endpoint: "SendOTP",
          data: { input: payload },
          service: "AUTH",
        });

        if (res.success) {
          enterOtp.value = true;
          otpLoading.value = false;
          otpReference.value = res.data.reference;
          // toast.success("OTP")
          // window.localStorage.setItem("otpReference", res.data.reference);
        }
      } catch (e) {
        otpLoading.value = false;
      }
    }

    async function confirmOtp() {
      if (otpCode.value.trim() === "") {
        errorRules.otpCode = "Please enter the otp code sent to your email";
      } else {
        confirmOtpLoading.value = true;
        loadingProgress.value = true;

        const payload = {
          reference: otpReference.value,
          code: otpCode.value,
        };

        try {
          let res = await mutate({
            endpoint: "ConfirmOTP",
            data: { input: payload },
            service: "AUTH",
          });

          if (res.success) {
            //Clear error state
            errorRules.otpCode = false;
            loadingProgress.value = false;

            //Register user
            await processPayroll();
            confirmOtpLoading.value = false;
            enterOtp.value = false;
          }
        } catch (e) {
          loadingProgress.value = false;
          confirmOtpLoading.value = false;
          e.message.toLowerCase() == "record not found"
            ? (errorRules.otpCode = "OTP doesn't match. Please try again")
            : null;
        }
      }
    }

    const pdfOptions = reactive({
      margin: 10,
      image: {
        type: "jpeg",
        quality: 1,
      },
      html2canvas: { scale: 2 }, //scale of 3 makes it blank, only 2 works
      jsPDF: {
        unit: "mm",
        format: "a4",
        orientation: "p",
      },
    });

    const fileName = computed(() => {
      return `payroll report.pdf`;
    });
    async function shareReport() {
      if (args2.email.trim() !== "") {
        errorRules2.email = false;

        const payload = {
          input: {
            from: args2.from,
            paymentType: {
              salary: args2.paymentType.includes("salary") ? true : null,
              bonus: args2.paymentType.includes("bonus") ? true : null,
              statutory: args2.paymentType.includes("statutory") ? true : null,
              deduction: args2.paymentType.includes("deduction") ? true : null,
            },
            to: args2.to,
          },
          download: null,
          email: args2.email,
        };

        loadingShare.value = true;
        try {
          await query({
            endpoint: "SendDetailedPayrollReport",
            service: "PAYROLL",
            payload,
          });

          shareLink.value = false;
          loadingShare.value = false;
          updateSuccess.value = true;
          args2.email = "";
          setTimeout(() => {
            updateSuccess.value = false;
          }, 1000);
        } catch (e) {
          loadingShare.value = false;
          console.log(e);
        } finally {
          loadingShare.value = false;
        }
      } else {
        errorRules2.email = "Please enter a valid email address";
      }
    }
    async function generate() {
      // this.showAll = true;
      // setTimeout(() => {
      //   this.$refs.vue3SimpleHtml2pdf2.download();
      //   this.showAll = false;
      // }, 1000);

      try {
        isDownloading.value = true;
        const payload = {
          input: {
            from: "",
            paymentType: {
              salary: args2.paymentType.includes("salary") ? true : null,
              bonus: args2.paymentType.includes("bonus") ? true : null,
              statutory: args2.paymentType.includes("statutory") ? true : null,
              deduction: args2.paymentType.includes("deduction") ? true : null,
            },
            to: "",
          },
          download: true,
        };
        // old one SendPayrollReport
        const res = await query({
          endpoint: "SendDetailedPayrollReport",
          service: "PAYROLL",
          payload,
        });
        const newData = Buffer.from(res.data);
        const base64 = newData.toString("base64");
        downloadBase64(
          base64,
          `Detailed payroll report-${companyData.value.company.companyName},${
            (months[new Date().getMonth()],
            new Date().getDate().toString().padStart(2, "0"),
            new Date().getFullYear())
          }`
        );
      } catch (e) {
        console.error("Failed to download", e);
      } finally {
        isDownloading.value = false;
      }
    }
    const loadingShare = ref(false);
    const isPrinting = ref(false);

    function openChat() {
      // Trigger the Tawk.to chat
      if (typeof Tawk_API !== "undefined") {
        Tawk_API.toggle();
      }
    }

    return {
      openChat,
      pdfOptions,
      fileName,
      formatAmount,
      calculatePercentage,

      //Start Here
      payrollDetailedReport,

      store,
      mutate,
      query,

      addBank,
      loadingShare,

      showPin,
      showOtp,
      showAll,
      isDownloading,
      detailed,
      bankName,
      selectDuration,
      adminDetails,
      companyData,
      args,
      args2,
      generate,
      shareReport,
      updateSuccess,
      otpReference,
      enterOtp,
      otpCode,
      errorRules,
      errorRules2,

      loading,
      otpLoading,
      resendOtpLoading,
      confirmOtpLoading,
      loadingProgress,

      startDate,

      argsArray,
      companyData,

      bankTransfer,
      accountNumber,
      link,
      topupOption,
      payStack,
      linkGenerated,
      shareLink,
      transferDetails,
      transferNotification,
      queryEmployees,
      allEmployees,
      copyText,
      payrollInterval,
      generatePaymentLink,
      isViewAnalysis,
      listPayrollHistory,

      checkPin,
      formatDateString,
      months,

      addMore,

      save,

      getAvatar,
      printPage,
      onafterprint,
      sendOTP,
      confirmOtp,
      isPrinting,
    };
  },
  created() {
    this.queryEmployees();
  },
};
</script>

<style>
@media print {
  .no-printable {
    display: none !important;
  }
  .printable {
    display: block;
  }
}

.time-span-color {
  background: #f2f1f1;
}
</style>
