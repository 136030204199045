<template>
  <main
    class="w-full text-left bg-white rounded-2xl shadow-lg p-4 md:py-6 md:px-8"
  >
    <div class="">
      <h2 class="text-2xl font-bold rounded-t-2xl">
        Monthly Average Payroll (Department)
      </h2>
    </div>

    <div
      class="w-ful flex justify-start items-center text-xs gap-4 mt-8 mb-2 px-4 md:px-8"
    >
      <span
        v-for="(item, i) in payrollAnalysis &&
        payrollAnalysis.allDepartmentColours"
        :key="i"
      >
        <i :style="`color: ${item}`" class="fas fa-circle mr-1"></i>
        {{ i }}
      </span>
    </div>

    <div class="flex-auto">
      <div class="relative h-350-px">
        <canvas id="bar-chart-department"></canvas>
      </div>
    </div>

    <div v-if="!showAll" class="hidden lg:block my-6">
      <table class="table-auto w-full mx-auto">
        <thead>
          <tr>
            <td
              v-for="(item, i) in payrollAnalysis &&
              payrollAnalysis.allDepartmentColours"
              :key="i"
            >
              <span class="text-xs">
                <i :style="`color: ${item}`" class="fas fa-circle mr-1"></i>
                {{ i }}
              </span>
            </td>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(item, i) in payrollMonthly" :key="i">
            <td
              v-for="(data, index) in item.data"
              :key="index + 'data'"
              class="border-b border-yellow-200 p-1"
            >
              <div
                v-for="(head, index2) in payrollAnalysis &&
                payrollAnalysis.allDepartmentColours"
                :key="index2 + 'head'"
              >
                <div
                  v-if="data.department === index2"
                  class="flex items-center gap-2 mt-2 text-xs"
                >
                  <span
                    v-if="
                      data.department ==
                      Object.keys(
                        payrollAnalysis && payrollAnalysis.allDepartmentColours
                      )[0]
                    "
                    :style="`color: #8D8E8D`"
                    class="uppercase"
                    >{{ months[item.month] }}</span
                  >
                  <span class="">{{ formatAmount(data.count) }} </span>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </main>
</template>

<script setup>
import { ref, reactive, onMounted, watch, computed, nextTick } from "vue";
import { storeToRefs } from "pinia";
import { useDataStore } from "@/stores/data.js";
import { helperFunctions } from "@/composable/helperFunctions";
import { useToast } from "vue-toastification";

const { formatAmount, calculatePercentage, getAvatar } = helperFunctions;
const toast = useToast();
const store = useDataStore();
const { mutate, query } = useDataStore();

const payrollAnalysis = computed(() => store.getPayrollAnalysis);

const props = defineProps({
  showAll: {
    type: Boolean,
    default: false,
  },
});

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const getData = (arr) => {
  if (payrollAnalysis.value) {
    const dep = Object.keys(payrollAnalysis.value.allDepartmentColours).map(
      function (key) {
        return {
          department: key,
        };
      }
    );

    console.log(arr);
    return dep.map((el) => {
      const match = arr.find((ol) => {
        if (el.department == ol.department) {
          return el;
        }
      });

      return match
        ? match
        : {
            department: el.department,
            count: 0,
          };
    });
  }
};

const payrollMonthly = computed(() => {
  return (
    payrollAnalysis.value &&
    payrollAnalysis.value.monthlyPayrollByDepartment.map((el) => {
      return {
        month: el._id && el._id.month,
        data: getData(el.details),
      };
    })
  );
});

payrollMonthly.value = payrollMonthly.value.sort((a, b) => a.month - b.month);

onMounted(() => {
  runChart();
});

const config = computed(() => {
  const datasets = Object.keys(
    payrollAnalysis.value && payrollAnalysis.value.allDepartmentColours
  ).map((el, index) => {
    return {
      label: index,
      backgroundColor:
        payrollAnalysis.value && payrollAnalysis.value.allDepartmentColours[el],
      fill: false,
      data: payrollMonthly.value.map((i) => {
        let obj = "";
        i.data.forEach((ol) => {
          if (ol.department === el) {
            obj = parseFloat(ol.count / 1000000);
          }
        });

        return obj;
      }),
    };
  });

  console.log("DATA SETTTTTTT", datasets);

  return {
    type: "bar",
    data: {
      labels: months,
      datasets,
    },
    options: {
      maintainAspectRatio: false,
      responsive: true,
      title: {
        display: false,
        text: "Orders Chart",
      },
      tooltips: {
        mode: "index",
        intersect: false,
      },
      hover: {
        mode: "nearest",
        intersect: true,
      },
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            display: true,
            stacked: true,
            gridLines: {
              color: "#E0A409",
              zeroLineColor: "#E0A409",
            },
          },
        ],
        yAxes: [
          {
            display: true,
            stacked: true,
            gridLines: {
              color: "#E0A409",
              zeroLineColor: "#E0A409",
            },
            ticks: {
              // Include a dollar sign in the ticks
              callback: function (value, index, ticks) {
                return `${value}M`;
              },
            },
          },
        ],
      },
    },
  };
});

const runChart = () => {
  nextTick(function () {
    var ctx = document.getElementById("bar-chart-department").getContext("2d");
    // ctx.fillText(data[0].value + "%", width/2 - 20, width/2, 200);
    window.myDoughnut = new Chart(ctx, config.value);
  });
};

function log(arg) {
  console.log(JSON.parse(JSON.stringify(arg)));
}
</script>

<style></style>
