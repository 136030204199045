<template>
  <main class="w-full overflow-x-hidden">
    <div style="height: 35rem" class="w-full overflow-y-scroll">
      <div class="flex flex-col gap-6 w-full">
        <div
          class="border border-gray"
          v-for="(sub, k) in payrollDetailedReport"
          :key="k"
        >
          <div
            class="bg-primary w-full py-1 flex text-sm px-5 justify-between items-center text-white font-bold"
          >
            <span class="uppercase">
              {{ ` ${months[sub.month]}, ${sub.year} ` }}</span
            >
            <span> {{ sub.numOfEmployees }} EMPLOYEE(S)</span>
          </div>
          <div
            class="w-full grid grid-cols-2 md:grid-cols-5 justify-start border-b border-dark-800"
          >
            <div class="md:flex w-full col-span-2 md:col-span-3">
              <div
                class="bg-white text-xs text-grey flex flex-col gap-y-1 items-start upercase font-medium py-2 pl-2"
              >
                <!-- <span class="text-left text-xs"
                  >PROVIDERS / PAYMENT PARTNERS</span
                > -->
                <!-- <span
                  class="text-dark-800 font-medium text-sm w-full text-left"
                >
                  "ARM pension", "Ikeja Tax Office", "Reliance HMO", "Leadway
                  Assurance",</span
                > -->
              </div>
            </div>

            <div
              class="flex flex-col col-span-1 justify-start py-2 pl-2 gap-1 border-l border-r border-dark-300"
            >
              <span
                class="text-xs text-dark-800 text-left uppercase font-medium"
                >total gross pay</span
              >

              <span
                class="text-xs text-dark-900 text-left uppercase font-bold"
                >{{ formatAmount(sub.totalGross) }}</span
              >
            </div>

            <div class="flex flex-col col-span-1 justify-start py-2 pl-2 gap-1">
              <span
                class="text-xs text-dark-800 text-left uppercase font-medium"
                >total net pay</span
              >

              <span
                class="text-xs text-dark-900 text-left uppercase font-bold"
                >{{ formatAmount(sub.totalNet) }}</span
              >
            </div>
          </div>
          <div
            class="w-full grid grid-cols-2 md:grid-cols-5 justify-start border-none border-dark-800"
          >
            <div
              class="flex flex-col border-b border-black col-span-2 md:col-span-1 justify-start py-2 pl-2 gap-1"
            >
              <span
                class="text-xs text-dark-800 text-left uppercase font-medium"
                >total allowance</span
              >

              <span
                class="text-xs text-dark-900 text-left uppercase font-bold"
                >{{ formatAmount(sub.totalAllowances) }}</span
              >
            </div>
            <div
              v-for="(value, key) in sub.allowanceDetails"
              :key="key"
              class="flex flex-col col-span-1 justify-start py-2 pl-2 gap-1 border-l border-r border-b border-dark-600"
            >
              <span
                class="text-xs text-dark-800 text-left font-medium capitalize"
              >
                {{ value.name }}
              </span>

              <span
                class="text-xs text-dark-900 text-left uppercase font-bold"
                >{{ formatAmount(value.amount) }}</span
              >
            </div>
          </div>
          <div
            class="w-full grid grid-cols-2 md:grid-cols-5 justify-start border border-dark-800"
          >
            <div
              class="flex flex-col border-b border-black col-span-2 md:col-span-1 justify-start py-2 pl-2 gap-1"
            >
              <span
                class="text-xs text-dark-800 text-left uppercase font-medium"
                >total bonus </span
              >

              <span
                class="text-xs text-dark-900 text-left uppercase font-bold"
                >{{ formatAmount(sub.totalBonuses) }}</span
              >
            </div>
            <div
              v-for="(value, key) in sub.bonuses"
              :key="key"
              class="flex flex-col col-span-1 justify-start py-2 pl-2 gap-1 border-l border-r border-b border-dark-600"
            >
              <span
                class="text-xs text-dark-800 text-left font-medium capitalize"
              >
                {{ value.name }}
              </span>

              <span
                class="text-xs text-dark-900 text-left uppercase font-bold"
                >{{ formatAmount(value.amount) }}</span
              >
            </div>
          </div>
          <div
            class="w-full grid grid-cols-2 md:grid-cols-5 justify-start border-b border-dark-800"
          >
            <div
              class="flex flex-col col-span-2 md:col-span-1 justify-start py-2 pl-2 gap-1"
            >
              <span
                class="text-xs text-dark-800 text-left uppercase font-medium"
                >total other deductions</span
              >

              <span
                class="text-xs text-dark-900 text-left uppercase font-bold"
                >{{ formatAmount(sub.totalDeductions) }}</span
              >
            </div>

            <div
              v-for="deduction in sub.deductions"
              :key="deduction._id"
              class="flex flex-col col-span-1 justify-start py-2 pl-2 gap-1 border-l border-r border-dark-300"
            >
              <span
                class="text-xs text-dark-800 text-left capitalize font-medium"
                >{{ deduction.name }}
              </span>

              <span
                class="text-xs text-dark-900 text-left uppercase font-bold"
                >{{
                  deduction.amount
                    ? formatAmount(deduction.amount)
                    : formatAmount(
                        calculatePercentage(120900, deduction.percentage)
                      )
                }}</span
              >
            </div>
          </div>
          <div
            class="grid grid-cols-2 md:grid-cols-5 gap-0 w-full border-b border-dark-800"
          >
            <div
              class="w-full col-span-2 md:col-span-1 justify-center flex flex-col py-2 md:pl-4 pl-2 gap-1 border-r border-btnPri"
            >
              <span
                class="text-xs text-dark-800 text-left uppercase font-medium"
                >TOTAL STATUTOTRY DEDUCTIONS</span
              >

              <span
                class="text-xs text-dark-900 text-left uppercase font-bold"
                >{{ formatAmount(sub.totalStatutoryDeductions) }}</span
              >
            </div>

            <div
              class="w-full col-span-4 grid grid-cols-2 md:grid-cols-4 justify-start"
            >
              <div
                class="w-full flex flex-col col-span-1 md:col-span-2 py-2 pl-2 gap-1 border-r border-dark-300"
              >
                <span
                  class="text-xs text-dark-800 text-left uppercase font-medium"
                  >employer contribution</span
                >

                <span
                  class="text-xs text-dark-900 text-left uppercase font-bold"
                  >{{ sub.totalGrossPay }}</span
                >
              </div>
              <div
                class="w-full flex flex-col col-span-1 md:col-span-2 py-2 pl-2 gap-1"
              >
                <span
                  class="text-xs text-dark-800 text-left uppercase font-medium"
                  >employee contribution</span
                >

                <span
                  class="text-xs text-dark-900 text-left uppercase font-bold"
                  >{{ sub.totalGrossPay }}</span
                >
              </div>
              <div
                v-for="(value, key) in sub.totalStatutoryDetails"
                :key="key"
                class="w-auto text-grey text-xs flex flex-col gap-y-1 items-start upercase font-medium py-2 border border-b-0 border-dark-300"
                :class="even.includes(n) ? ' pl-2 pr-1' : ' pl-2 pr-1'"
              >
                <span class="text-left uppercase w-full">TOTAL {{ key }} </span>
                <span class="text-left uppercase text-dark-900 font-bold">{{
                  formatAmount(value)
                }}</span>
              </div>
            </div>
          </div>
          <div v-for="(emp, empIndex) in sub.employeeDetails" :key="empIndex">
            <div
              class="w-full bg-newLightGreen py-2 px-2 flex md:items-center justify-between mt-4 border-0.5 border-grey"
            >
              <p
                class="font-bold text-left text-dark-800 text-xs md:text-base capitalize"
              >
                {{ empIndex + 1 }}.
                {{
                  ` ${emp.profile.title} ${emp.profile.firstName} ${emp.profile.lastName}`
                }}
              </p>

              <p
                v-if="emp.salary.paidAt"
                class="font-bold text-dark-800 text-right text-xs md:text-base"
              >
                <span class="font-normal"> Date Paid:</span>
                {{
                  new Date(emp.salary.paidAt).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })
                }}
              </p>
            </div>

            <div class="grid grid-cols-2 md:grid-cols-5 w-full gap-0">
              <div class="col-span-2 md:col-span-4">
                <p
                  class="border border-grey text-dark-800 text-center uppercase text-xs font-medium bg-newAsh"
                >
                  salary
                </p>

                <div
                v-if="emp.salary.allowanceDetails"
                class="grid grid-cols-2 md:grid-cols-4">
                  <div
                    v-show="
                      ['basic', 'transport', 'housing'].every((elem) =>
                        Object.keys(emp.salary.allowanceDetails).includes(elem)
                      )
                    "
                    v-for="(value, key) in Object.fromEntries(
                      Object.entries(emp.salary.allowanceDetails).filter(
                        ([key]) =>
                          ['basic', 'transport', 'housing'].includes(key)
                      )
                    )"
                    :key="key"
                    class="flex flex-col border border-btnPri col-span-1 justify-start py-1 pl-2 gap-1"
                  >
                    <span
                      class="text-xs text-dark-800 text-left uppercase font-medium"
                    >
                      {{ key }}
                    </span>

                    <span
                      class="text-xs text-dark-900 text-left uppercase font-black"
                    >
                      {{ formatAmount(value.amount) }}
                    </span>
                  </div>

                  <div
                    class="flex flex-col border border-btnPri col-span-1 justify-start py-1 pl-2 gap-1"
                  >
                    <span
                      class="text-xs text-dark-800 text-left uppercase font-medium"
                    >
                      total</span
                    >

                    <span
                      class="text-xs text-dark-900 text-left uppercase font-black"
                    >
                      {{
                        formatAmount(
                          Object.values(
                            Object.fromEntries(
                              Object.entries(
                                emp.salary.allowanceDetails
                              ).filter(([key]) =>
                                ["basic", "transport", "housing"].includes(key)
                              )
                            )
                          ).reduce((accumulator, value) => {
                            return accumulator + value.amount;
                          }, 0)
                        )
                      }}
                    </span>
                  </div>
                </div>

                <p
                v-if="emp.salary.allowanceDetails"

                  class="border border-grey text-white text-center uppercase text-xs font-medium bg-successStatus"
                >
                  ADDITIONAL ALLOWANCES
                </p>

                <div
                v-if="emp.salary.allowanceDetails"
                
                class="grid grid-cols-2 md:grid-cols-4">
                  <div
                    v-show="
                      Object.fromEntries(
                        Object.entries(emp.salary.allowanceDetails).filter(
                          ([key]) =>
                            !['basic', 'transport', 'housing'].includes(key)
                        )
                      )
                    "
                    v-for="(value, key) in Object.fromEntries(
                      Object.entries(emp.salary.allowanceDetails).filter(
                        ([key]) =>
                          !['basic', 'transport', 'housing'].includes(key)
                      )
                    )"
                    :key="key"
                    class="flex flex-col border border-btnPri col-span-1 justify-start py-1 pl-2 gap-1"
                  >
                    <span
                      class="text-xs text-dark-800 text-left uppercase font-medium"
                    >
                      {{ key }}
                    </span>

                    <span
                      class="text-xs text-dark-900 text-left uppercase font-black"
                    >
                      {{ formatAmount(value.amount) }}
                    </span>
                  </div>

                  <div
                    class="flex flex-col border border-btnPri col-span-1 justify-start py-1 pl-2 gap-1"
                  >
                    <span
                      class="text-xs text-dark-800 text-left uppercase font-medium"
                    >
                      total</span
                    >

                    <span
                      class="text-xs text-dark-900 text-left uppercase font-black"
                    >
                      {{
                        formatAmount(
                          Object.values(
                            Object.fromEntries(
                              Object.entries(
                                emp.salary.allowanceDetails
                              ).filter(
                                ([key]) =>
                                  !["basic", "transport", "housing"].includes(
                                    key
                                  )
                              )
                            )
                          ).reduce((accumulator, value) => {
                            return accumulator + value.amount;
                          }, 0)
                        )
                      }}
                    </span>
                  </div>
                </div>

                <p
                  class="border border-grey text-white text-center uppercase text-xs font-medium bg-successStatus"
                >
                  bonus
                </p>

                <div v-if="emp.Bonus" class="grid grid-cols-2 md:grid-cols-4">
                  <div
                    v-for="(value, key) in emp.Bonus"
                    :key="key"
                    class="flex flex-col border border-btnPri col-span-1 justify-start py-1 pl-2 gap-1"
                  >
                    <span
                      class="text-xs text-dark-800 text-left uppercase font-medium"
                    >
                      {{ key }}</span
                    >

                    <span
                      class="text-xs text-dark-900 text-left uppercase font-black"
                      >{{ formatAmount(value.amount) }}</span
                    >
                  </div>

                  <div
                    class="flex flex-col border border-btnPri col-span-1 justify-start py-1 pl-2 gap-1"
                  >
                    <span
                      class="text-xs text-dark-800 text-left uppercase font-medium"
                    >
                      total</span
                    >

                    <span
                      class="text-xs text-dark-900 text-left uppercase font-black"
                    >
                      {{
                        formatAmount(
                          Object.values(
                            Object.fromEntries(Object.entries(emp.Bonus))
                          ).reduce((accumulator, value) => {
                            return accumulator + value.amount;
                          }, 0)
                        )
                      }}
                    </span>
                  </div>
                </div>
                <div v-else class="grid grid-cols-2 md:grid-cols-4">
                  <div
                    class="flex flex-col border border-btnPri col-span-1 justify-start py-1 pl-2 gap-1"
                  >
                    <span
                      class="text-xs text-dark-800 text-left uppercase font-medium"
                    >
                      total</span
                    >

                    <span
                      class="text-xs text-dark-900 text-left uppercase font-black"
                    >
                      0
                    </span>
                  </div>
                </div>
              </div>

              <div
                class="md:flex hidden flex-col bg-newAsh border border-grey col-span-1 justify-center py-2 pl-5 gap-1"
              >
                <span
                  class="text-sm text-dark-800 text-left uppercase font-medium"
                >
                  gross pay</span
                >

                <span
                  class="text-sm text-dark-900 text-left uppercase font-bold"
                  >{{ formatAmount(emp.salary.grossAmount) }}</span
                >
              </div>
            </div>

            <div class="grid grid-cols-2 md:grid-cols-5 w-full gap-0">
              <div class="col-span-2 md:col-span-4">
                <p
                  class="border border-grey text-white text-center uppercase text-xs font-medium bg-lightBorder"
                >
                  OTHER DEDUCTIONS
                </p>

                <div
                  v-if="emp.Deduction && emp.Deduction.length"
                  class="grid grid-cols-2 md:grid-cols-4"
                >
                  <div
                    v-for="(value, key) in emp.Deduction"
                    :key="key"
                    class="flex flex-col border border-btnPri col-span-1 justify-start py-1 pl-2 gap-1"
                  >
                    <span
                      class="text-xs text-dark-800 text-left uppercase font-medium"
                    >
                      {{ key }}</span
                    >

                    <span
                      class="text-xs text-dark-900 text-left uppercase font-black"
                      >{{ formatAmount(value.amount) }}</span
                    >
                  </div>

                  <div
                    class="flex flex-col border border-btnPri col-span-1 justify-start py-1 pl-2 gap-1"
                  >
                    <span
                      class="text-xs text-dark-800 text-left uppercase font-medium"
                    >
                      total</span
                    >

                    <span
                      class="text-xs text-dark-900 text-left uppercase font-black"
                    >
                      {{
                        formatAmount(
                          Object.values(
                            Object.fromEntries(Object.entries(emp.Deduction))
                          ).reduce((accumulator, value) => {
                            return accumulator + value.amount;
                          }, 0)
                        )
                      }}
                    </span>
                  </div>
                </div>
                <div v-else class="grid grid-cols-2 md:grid-cols-4">
                  <div
                    class="flex flex-col border border-btnPri col-span-1 justify-start py-1 pl-2 gap-1"
                  >
                    <span
                      class="text-xs text-dark-800 text-left uppercase font-medium"
                    >
                      total</span
                    >

                    <span
                      class="text-xs text-dark-900 text-left uppercase font-black"
                    >
                      0
                    </span>
                  </div>
                </div>

                <p
                  style="background-color: #e4572e"
                  class="border border-grey text-white text-center uppercase text-xs font-medium"
                >
                  STATUTORY DEDUCTIONS
                </p>

                <div
                  v-if="emp.statutory"
                  class="grid grid-cols-2 md:grid-cols-4"
                >
                  <div
                    v-for="(value, key) in emp.statutory"
                    :key="key"
                    class="flex flex-col border border-outlineGray col-span-1 justify-start py-1 pl-2 gap-1"
                  >
                    <span
                      class="text-xs text-dark-800 text-left uppercase font-medium"
                    >
                      {{ key }}</span
                    >

                    <span
                      class="text-xs text-dark-900 text-left uppercase font-black"
                      >{{
                        value ? formatAmount(value.amount) : formatAmount(0)
                      }}</span
                    >
                  </div>

                  <div
                    class="flex flex-col border border-outlineGray bg-white col-span-1 justify-start py-1 pl-2 gap-1"
                  >
                    <span
                      class="text-xs text-dark-800 text-left uppercase font-medium"
                    >
                      total</span
                    >

                    <span
                      class="text-xs text-dark-900 text-left uppercase font-black"
                    >
                      {{ formatAmount(calculateObjectToal(emp.statutory)) }}
                    </span>
                  </div>
                </div>
                <div v-else class="grid grid-cols-2 md:grid-cols-4">
                  <div
                    class="flex flex-col border border-btnPri col-span-1 justify-start py-1 pl-2 gap-1"
                  >
                    <span
                      class="text-xs text-dark-800 text-left uppercase font-medium"
                    >
                      total</span
                    >

                    <span
                      class="text-xs text-dark-900 text-left uppercase font-black"
                    >
                      0
                    </span>
                  </div>
                </div>
              </div>

              <div
                class="md:flex hidden flex-col bg-newAsh border border-grey col-span-1 justify-center py-2 pl-5 gap-1"
              >
                <span
                  class="text-sm text-dark-800 text-left uppercase font-medium"
                >
                  net pay</span
                >

                <span
                  class="text-sm text-dark-900 text-left uppercase font-bold"
                  >{{ formatAmount(emp.totalNet) }}</span
                >
              </div>
            </div>

            <div class="grid grid-cols-2 md:hidden">
              <div
                class="flex flex-col bg-newAsh border border-outlineGray col-span-1 justify-center py-2 pl-5 gap-1"
              >
                <span
                  class="text-xs text-dark-800 text-left uppercase font-medium"
                >
                  gross pay</span
                >

                <span
                  class="text-xs text-dark-900 text-left uppercase font-black"
                  >{{ formatAmount(emp.salary.grossAmount) }}</span
                >
              </div>
              <div
                class="flex flex-col bg-newAsh border border-outlineGray col-span-1 justify-center py-2 pl-5 gap-1"
              >
                <span
                  class="text-xs text-dark-800 text-left uppercase font-medium"
                >
                  net pay</span
                >

                <span
                  class="text-xs text-dark-900 text-left uppercase font-black"
                  >{{ formatAmount(emp.totalNet) }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <easiPagin
      v-if="data.length"
      :data="data"
      @page:update="updatePage"
      :currentPage="currentPage"
      :pageSize="pageSize"
      class="my-4"
    /> -->
  </main>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { useDataStore } from "@/stores/data.js";
import { useToast } from "vue-toastification";
import { helperFunctions } from "@/composable/helperFunctions";

const toast = useToast();
const store = useDataStore();
const { query, mutate } = store;
const { formatAmount, calculatePercentage } = helperFunctions;

const bonusDeduction = computed(() => store.getBonusAndDeduction);
const allowanceData = computed(() => store.listAllowance.data);

const props = defineProps({
  showFullData: {
    type: Boolean,
    default: false,
  },
});
const propD = ref(props.showFullData);
const bonusData = ref([]);
const deductionData = ref([]);
// watch(propD, (val, newVal) => {
//   console.log("cje");
//   visibleData.value = splitData.value;
//   console.log(visibleData.value);
// });
const even = [0, 2, 5, 7, 8, 10];
const odd = [1, 3, 4, 6, 9];
const headers = ref([
  "DATE",
  "TRANSACTION",
  "AMOUNT",
  "DESCRIPTION",
  "WALLET BALANCE",
]);

const currentPage = ref(0);
const pageSize = ref(3);
let visibleData = ref([]);
const months = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];
const payrollDetailedReport = computed(() => {
  if (store.getPayrollDetailReport && store.getPayrollDetailReport.length) {
    return store.getPayrollDetailReport[0].data;
  }
});
console.log("reportss is", payrollDetailedReport);
const bonusGridLength = ref(5);
const dedGridLength = ref(5);
const benArr = [
  "paye",
  "pension",
  "hmo",
  "lifeInsurance",
  "lifeAssurance",
  "nsitf",
  "itf",
  "nhf",
  "wht",
  "gratuity",
  "businessExpense",
];
const payload2 = ref({
  paging: {
    limit: 1000,
    skip: 0,
    search: null,
  },
});
async function queryAllowance() {
  await query({
    endpoint: "ListAllowance",
    payload: payload2.value,
    service: "PAYROLL",
    storeKey: "allowance",
  });
}
queryAllowance();
const queryBonus = async () => {
  try {
    await query({
      endpoint: "ListBonusAndDeduction",
      payload: {
        input: {
          paging: {
            limit: 1000,
            skip: 0,
            search: "",
          },
          type: "Bonus",
        },
      },
      service: "PAYROLL",
      storeKey: "listBonusAndDeduction",
    });
    bonusData.value = bonusDeduction.value;
    if (bonusData.value.length > 5)
      bonusGridLength.value = bonusData.value.length + 1;

    console.log(bonusData.value, "N");
  } catch (err) {
    console.log(err);
  }
};

const queryDeduction = async () => {
  try {
    await query({
      endpoint: "ListBonusAndDeduction",
      payload: {
        input: {
          paging: {
            limit: 1000,
            skip: 0,
            search: "",
          },
          type: "Deduction",
        },
      },
      service: "PAYROLL",
      storeKey: "listBonusAndDeduction",
    });
    deductionData.value = bonusDeduction.value;
    if (deductionData.value.length > 5)
      dedGridLength.value = deductionData.value.length + 1;
  } catch (err) {
    console.log(err);
  }
};
queryBonus();
queryDeduction();

const data = ref([
  {
    date: "January 02, 2021",
    employees: [1, 2, 3, 4, 5, 6, 7, 7, 554, 3],
    providers: [
      "ARM pension",
      "Ikeja Tax Office",
      "Reliance HMO",
      "Leadway Assurance",
    ],
    totalGrossPay: "N12,565,807.67",
    totalBonus: "N11,565,807.67",
    otherDeductions: "N13,565,807.67",
    totalNetPay: "N12,565,807.67",
    employeeContribution: "N12,565,807.67",
    employerContribution: "N12,565,807.67",
    paye: "N105,807.67",
    pension: "N105,807.67",
    hmo: "N105,807.67",
    lifeInsurance: "N105,807.67",
    lifeAssurance: "N105,807.67",
    nsitf: "N105,807.67",
    itf: "N105,807.67",
    nhf: "N105,807.67",
    wht: "N105,807.67",
    gratuity: "N105,807.67",
    businessExpense: "N105,807.67",
  },
  {
    date: "September 30, 2022",
    employees: [
      1, 2, 3, 4, 5, 6, 7, 7, 554, 3, 1, 2, 3, 4, 5, 5, 6, 6, 6, 5, 5, 5, 5, 5,
      4, 3,
    ],
    providers: [
      "ARM pension",
      "Ikeja Tax Office",
      "Reliance HMO",
      "Leadway Assurance",
    ],
    totalGrossPay: "N12,565,807.67",
    totalBonus: "N11,565,807.67",
    otherDeductions: "N13,565,807.67",
    totalNetPay: "N12,565,807.67",
    employeeContribution: "N12,565,807.67",
    employerContribution: "N12,565,807.67",
    paye: "N105,807.67",
    pension: "N105,807.67",
    hmo: "N105,807.67",
    lifeInsurance: "N105,807.67",
    lifeAssurance: "N105,807.67",
    nsitf: "N105,807.67",
    itf: "N105,807.67",
    nhf: "N105,807.67",
    wht: "N105,807.67",
    gratuity: "N105,807.67",
    businessExpense: "N105,807.67",
  },

  {
    date: "December 27, 2022",
    employees: [
      1, 2, 3, 4, 5, 6, 7, 7, 3, 4, 5, 5, 6, 6, 6, 5, 5, 5, 5, 5, 4, 3,
    ],
    providers: [
      "ARM pension",
      "Ikeja Tax Office",
      "Reliance HMO",
      "Leadway Assurance",
    ],
    totalGrossPay: "N12,565,807.67",
    totalBonus: "N11,565,807.67",
    otherDeductions: "N13,565,807.67",
    totalNetPay: "N12,565,807.67",
    employeeContribution: "N12,565,807.67",
    employerContribution: "N12,565,807.67",
    paye: "N105,807.67",
    pension: "N105,807.67",
    hmo: "N105,807.67",
    lifeInsurance: "N105,807.67",
    lifeAssurance: "N105,807.67",
    nsitf: "N105,807.67",
    itf: "N105,807.67",
    nhf: "N105,807.67",
    wht: "N105,807.67",
    gratuity: "N105,807.67",
    businessExpense: "N105,807.67",
  },
  {
    date: "September 30, 2022",
    employees: [
      1, 2, 3, 4, 5, 6, 7, 7, 554, 3, 1, 2, 3, 4, 5, 5, 6, 6, 6, 5, 5, 5, 5, 5,
      4, 3,
    ],
    providers: [
      "ARM pension",
      "Ikeja Tax Office",
      "Reliance HMO",
      "Leadway Assurance",
    ],
    totalGrossPay: "N12,565,807.67",
    totalBonus: "N11,565,807.67",
    otherDeductions: "N13,565,807.67",
    totalNetPay: "N12,565,807.67",
    employeeContribution: "N12,565,807.67",
    employerContribution: "N12,565,807.67",
    paye: "N105,807.67",
    pension: "N105,807.67",
    hmo: "N105,807.67",
    lifeInsurance: "N105,807.67",
    lifeAssurance: "N105,807.67",
    nsitf: "N105,807.67",
    itf: "N105,807.67",
    nhf: "N105,807.67",
    wht: "N105,807.67",
    gratuity: "N105,807.67",
    businessExpense: "N105,807.67",
  },
  {
    date: "September 30, 2022",
    employees: [
      1, 2, 3, 4, 5, 6, 7, 7, 554, 3, 1, 2, 3, 4, 5, 5, 6, 6, 6, 5, 5, 5, 5, 5,
      4, 3,
    ],
    providers: [
      "ARM pension",
      "Ikeja Tax Office",
      "Reliance HMO",
      "Leadway Assurance",
    ],
    totalGrossPay: "N12,565,807.67",
    totalBonus: "N11,565,807.67",
    otherDeductions: "N13,565,807.67",
    totalNetPay: "N12,565,807.67",
    employeeContribution: "N12,565,807.67",
    employerContribution: "N12,565,807.67",
    paye: "N105,807.67",
    pension: "N105,807.67",
    hmo: "N105,807.67",
    lifeInsurance: "N105,807.67",
    lifeAssurance: "N105,807.67",
    nsitf: "N105,807.67",
    itf: "N105,807.67",
    nhf: "N105,807.67",
    wht: "N105,807.67",
    gratuity: "N105,807.67",
    businessExpense: "N105,807.67",
  },
  {
    date: "September 30, 2022",
    employees: [
      1, 2, 3, 4, 5, 6, 7, 7, 554, 3, 1, 2, 3, 4, 5, 5, 6, 6, 6, 5, 5, 5, 5, 5,
      4, 3,
    ],
    providers: [
      "ARM pension",
      "Ikeja Tax Office",
      "Reliance HMO",
      "Leadway Assurance",
    ],
    totalGrossPay: "N12,565,807.67",
    totalBonus: "N11,565,807.67",
    otherDeductions: "N13,565,807.67",
    totalNetPay: "N12,565,807.67",
    employeeContribution: "N12,565,807.67",
    employerContribution: "N12,565,807.67",
    paye: "N105,807.67",
    pension: "N105,807.67",
    hmo: "N105,807.67",
    lifeInsurance: "N105,807.67",
    lifeAssurance: "N105,807.67",
    nsitf: "N105,807.67",
    itf: "N105,807.67",
    nhf: "N105,807.67",
    wht: "N105,807.67",
    gratuity: "N105,807.67",
    businessExpense: "N105,807.67",
  },
  {
    date: "September 30, 2022",
    employees: [
      1, 2, 3, 4, 5, 6, 7, 7, 554, 3, 1, 2, 3, 4, 5, 5, 6, 6, 6, 5, 5, 5, 5, 5,
      4, 3,
    ],
    providers: [
      "ARM pension",
      "Ikeja Tax Office",
      "Reliance HMO",
      "Leadway Assurance",
    ],
    totalGrossPay: "N12,565,807.67",
    totalBonus: "N11,565,807.67",
    otherDeductions: "N13,565,807.67",
    totalNetPay: "N12,565,807.67",
    employeeContribution: "N12,565,807.67",
    employerContribution: "N12,565,807.67",
    paye: "N105,807.67",
    pension: "N105,807.67",
    hmo: "N105,807.67",
    lifeInsurance: "N105,807.67",
    lifeAssurance: "N105,807.67",
    nsitf: "N105,807.67",
    itf: "N105,807.67",
    nhf: "N105,807.67",
    wht: "N105,807.67",
    gratuity: "N105,807.67",
    businessExpense: "N105,807.67",
  },
  {
    date: "September 30, 2022",
    employees: [
      1, 2, 3, 4, 5, 6, 7, 7, 554, 3, 1, 2, 3, 4, 5, 5, 6, 6, 6, 5, 5, 5, 5, 5,
      4, 3,
    ],
    providers: [
      "ARM pension",
      "Ikeja Tax Office",
      "Reliance HMO",
      "Leadway Assurance",
    ],
    totalGrossPay: "N12,565,807.67",
    totalBonus: "N11,565,807.67",
    otherDeductions: "N13,565,807.67",
    totalNetPay: "N12,565,807.67",
    employeeContribution: "N12,565,807.67",
    employerContribution: "N12,565,807.67",
    paye: "N105,807.67",
    pension: "N105,807.67",
    hmo: "N105,807.67",
    lifeInsurance: "N105,807.67",
    lifeAssurance: "N105,807.67",
    nsitf: "N105,807.67",
    itf: "N105,807.67",
    nhf: "N105,807.67",
    wht: "N105,807.67",
    gratuity: "N105,807.67",
    businessExpense: "N105,807.67",
  },
  {
    date: "September 30, 2022",
    employees: [
      1, 2, 3, 4, 5, 6, 7, 7, 554, 3, 1, 2, 3, 4, 5, 5, 6, 6, 6, 5, 5, 5, 5, 5,
      4, 3,
    ],
    providers: [
      "ARM pension",
      "Ikeja Tax Office",
      "Reliance HMO",
      "Leadway Assurance",
    ],
    totalGrossPay: "N12,565,807.67",
    totalBonus: "N11,565,807.67",
    otherDeductions: "N13,565,807.67",
    totalNetPay: "N12,565,807.67",
    employeeContribution: "N12,565,807.67",
    employerContribution: "N12,565,807.67",
    paye: "N105,807.67",
    pension: "N105,807.67",
    hmo: "N105,807.67",
    lifeInsurance: "N105,807.67",
    lifeAssurance: "N105,807.67",
    nsitf: "N105,807.67",
    itf: "N105,807.67",
    nhf: "N105,807.67",
    wht: "N105,807.67",
    gratuity: "N105,807.67",
    businessExpense: "N105,807.67",
  },
  {
    date: "September 30, 2022",
    employees: [
      1, 2, 3, 4, 5, 6, 7, 7, 554, 3, 1, 2, 3, 4, 5, 5, 6, 6, 6, 5, 5, 5, 5, 5,
      4, 3,
    ],
    providers: [
      "ARM pension",
      "Ikeja Tax Office",
      "Reliance HMO",
      "Leadway Assurance",
    ],
    totalGrossPay: "N12,565,807.67",
    totalBonus: "N11,565,807.67",
    otherDeductions: "N13,565,807.67",
    totalNetPay: "N12,565,807.67",
    employeeContribution: "N12,565,807.67",
    employerContribution: "N12,565,807.67",
    paye: "N105,807.67",
    pension: "N105,807.67",
    hmo: "N105,807.67",
    lifeInsurance: "N105,807.67",
    lifeAssurance: "N105,807.67",
    nsitf: "N105,807.67",
    itf: "N105,807.67",
    nhf: "N105,807.67",
    wht: "N105,807.67",
    gratuity: "N105,807.67",
    businessExpense: "N105,807.67",
  },
  {
    date: "September 30, 2022",
    employees: [
      1, 2, 3, 4, 5, 6, 7, 7, 554, 3, 1, 2, 3, 4, 5, 5, 6, 6, 6, 5, 5, 5, 5, 5,
      4, 3,
    ],
    providers: [
      "ARM pension",
      "Ikeja Tax Office",
      "Reliance HMO",
      "Leadway Assurance",
    ],
    totalGrossPay: "N12,565,807.67",
    totalBonus: "N11,565,807.67",
    otherDeductions: "N13,565,807.67",
    totalNetPay: "N12,565,807.67",
    employeeContribution: "N12,565,807.67",
    employerContribution: "N12,565,807.67",
    paye: "N105,807.67",
    pension: "N105,807.67",
    hmo: "N105,807.67",
    lifeInsurance: "N105,807.67",
    lifeAssurance: "N105,807.67",
    nsitf: "N105,807.67",
    itf: "N105,807.67",
    nhf: "N105,807.67",
    wht: "N105,807.67",
    gratuity: "N105,807.67",
    businessExpense: "N105,807.67",
  },
  {
    date: "September 30, 2022",
    employees: [
      1, 2, 3, 4, 5, 6, 7, 7, 554, 3, 1, 2, 3, 4, 5, 5, 6, 6, 6, 5, 5, 5, 5, 5,
      4, 3,
    ],
    providers: [
      "ARM pension",
      "Ikeja Tax Office",
      "Reliance HMO",
      "Leadway Assurance",
    ],
    totalGrossPay: "N12,565,807.67",
    totalBonus: "N11,565,807.67",
    otherDeductions: "N13,565,807.67",
    totalNetPay: "N12,565,807.67",
    employeeContribution: "N12,565,807.67",
    employerContribution: "N12,565,807.67",
    paye: "N105,807.67",
    pension: "N105,807.67",
    hmo: "N105,807.67",
    lifeInsurance: "N105,807.67",
    lifeAssurance: "N105,807.67",
    nsitf: "N105,807.67",
    itf: "N105,807.67",
    nhf: "N105,807.67",
    wht: "N105,807.67",
    gratuity: "N105,807.67",
    businessExpense: "N105,807.67",
  },
  {
    date: "September 30, 2022",
    employees: [
      1, 2, 3, 4, 5, 6, 7, 7, 554, 3, 1, 2, 3, 4, 5, 5, 6, 6, 6, 5, 5, 5, 5, 5,
      4, 3,
    ],
    providers: [
      "ARM pension",
      "Ikeja Tax Office",
      "Reliance HMO",
      "Leadway Assurance",
    ],
    totalGrossPay: "N12,565,807.67",
    totalBonus: "N11,565,807.67",
    otherDeductions: "N13,565,807.67",
    totalNetPay: "N12,565,807.67",
    employeeContribution: "N12,565,807.67",
    employerContribution: "N12,565,807.67",
    paye: "N105,807.67",
    pension: "N105,807.67",
    hmo: "N105,807.67",
    lifeInsurance: "N105,807.67",
    lifeAssurance: "N105,807.67",
    nsitf: "N105,807.67",
    itf: "N105,807.67",
    nhf: "N105,807.67",
    wht: "N105,807.67",
    gratuity: "N105,807.67",
    businessExpense: "N105,807.67",
  },
  {
    date: "September 30, 2022",
    employees: [
      1, 2, 3, 4, 5, 6, 7, 7, 554, 3, 1, 2, 3, 4, 5, 5, 6, 6, 6, 5, 5, 5, 5, 5,
      4, 3,
    ],
    providers: [
      "ARM pension",
      "Ikeja Tax Office",
      "Reliance HMO",
      "Leadway Assurance",
    ],
    totalGrossPay: "N12,565,807.67",
    totalBonus: "N11,565,807.67",
    otherDeductions: "N13,565,807.67",
    totalNetPay: "N12,565,807.67",
    employeeContribution: "N12,565,807.67",
    employerContribution: "N12,565,807.67",
    paye: "N105,807.67",
    pension: "N105,807.67",
    hmo: "N105,807.67",
    lifeInsurance: "N105,807.67",
    lifeAssurance: "N105,807.67",
    nsitf: "N105,807.67",
    itf: "N105,807.67",
    nhf: "N105,807.67",
    wht: "N105,807.67",
    gratuity: "N105,807.67",
    businessExpense: "N105,807.67",
  },
  {
    date: "September 30, 2022",
    employees: [
      1, 2, 3, 4, 5, 6, 7, 7, 554, 3, 1, 2, 3, 4, 5, 5, 6, 6, 6, 5, 5, 5, 5, 5,
      4, 3,
    ],
    providers: [
      "ARM pension",
      "Ikeja Tax Office",
      "Reliance HMO",
      "Leadway Assurance",
    ],
    totalGrossPay: "N12,565,807.67",
    totalBonus: "N11,565,807.67",
    otherDeductions: "N13,565,807.67",
    totalNetPay: "N12,565,807.67",
    employeeContribution: "N12,565,807.67",
    employerContribution: "N12,565,807.67",
    paye: "N105,807.67",
    pension: "N105,807.67",
    hmo: "N105,807.67",
    lifeInsurance: "N105,807.67",
    lifeAssurance: "N105,807.67",
    nsitf: "N105,807.67",
    itf: "N105,807.67",
    nhf: "N105,807.67",
    wht: "N105,807.67",
    gratuity: "N105,807.67",
    businessExpense: "N105,807.67",
  },
  {
    date: "September 30, 2022",
    employees: [
      1, 2, 3, 4, 5, 6, 7, 7, 554, 3, 1, 2, 3, 4, 5, 5, 6, 6, 6, 5, 5, 5, 5, 5,
      4, 3,
    ],
    providers: [
      "ARM pension",
      "Ikeja Tax Office",
      "Reliance HMO",
      "Leadway Assurance",
    ],
    totalGrossPay: "N12,565,807.67",
    totalBonus: "N11,565,807.67",
    otherDeductions: "N13,565,807.67",
    totalNetPay: "N12,565,807.67",
    employeeContribution: "N12,565,807.67",
    employerContribution: "N12,565,807.67",
    paye: "N105,807.67",
    pension: "N105,807.67",
    hmo: "N105,807.67",
    lifeInsurance: "N105,807.67",
    lifeAssurance: "N105,807.67",
    nsitf: "N105,807.67",
    itf: "N105,807.67",
    nhf: "N105,807.67",
    wht: "N105,807.67",
    gratuity: "N105,807.67",
    businessExpense: "N105,807.67",
  },
  {
    date: "September 30, 2022",
    employees: [
      1, 2, 3, 4, 5, 6, 7, 7, 554, 3, 1, 2, 3, 4, 5, 5, 6, 6, 6, 5, 5, 5, 5, 5,
      4, 3,
    ],
    providers: [
      "ARM pension",
      "Ikeja Tax Office",
      "Reliance HMO",
      "Leadway Assurance",
    ],
    totalGrossPay: "N12,565,807.67",
    totalBonus: "N11,565,807.67",
    otherDeductions: "N13,565,807.67",
    totalNetPay: "N12,565,807.67",
    employeeContribution: "N12,565,807.67",
    employerContribution: "N12,565,807.67",
    paye: "N105,807.67",
    pension: "N105,807.67",
    hmo: "N105,807.67",
    lifeInsurance: "N105,807.67",
    lifeAssurance: "N105,807.67",
    nsitf: "N105,807.67",
    itf: "N105,807.67",
    nhf: "N105,807.67",
    wht: "N105,807.67",
    gratuity: "N105,807.67",
    businessExpense: "N105,807.67",
  },
  {
    date: "September 30, 2022",
    employees: [
      1, 2, 3, 4, 5, 6, 7, 7, 554, 3, 1, 2, 3, 4, 5, 5, 6, 6, 6, 5, 5, 5, 5, 5,
      4, 3,
    ],
    providers: [
      "ARM pension",
      "Ikeja Tax Office",
      "Reliance HMO",
      "Leadway Assurance",
    ],
    totalGrossPay: "N12,565,807.67",
    totalBonus: "N11,565,807.67",
    otherDeductions: "N13,565,807.67",
    totalNetPay: "N12,565,807.67",
    employeeContribution: "N12,565,807.67",
    employerContribution: "N12,565,807.67",
    paye: "N105,807.67",
    pension: "N105,807.67",
    hmo: "N105,807.67",
    lifeInsurance: "N105,807.67",
    lifeAssurance: "N105,807.67",
    nsitf: "N105,807.67",
    itf: "N105,807.67",
    nhf: "N105,807.67",
    wht: "N105,807.67",
    gratuity: "N105,807.67",
    businessExpense: "N105,807.67",
  },
  {
    date: "September 30, 2022",
    employees: [
      1, 2, 3, 4, 5, 6, 7, 7, 554, 3, 1, 2, 3, 4, 5, 5, 6, 6, 6, 5, 5, 5, 5, 5,
      4, 3,
    ],
    providers: [
      "ARM pension",
      "Ikeja Tax Office",
      "Reliance HMO",
      "Leadway Assurance",
    ],
    totalGrossPay: "N12,565,807.67",
    totalBonus: "N11,565,807.67",
    otherDeductions: "N13,565,807.67",
    totalNetPay: "N12,565,807.67",
    employeeContribution: "N12,565,807.67",
    employerContribution: "N12,565,807.67",
    paye: "N105,807.67",
    pension: "N105,807.67",
    hmo: "N105,807.67",
    lifeInsurance: "N105,807.67",
    lifeAssurance: "N105,807.67",
    nsitf: "N105,807.67",
    itf: "N105,807.67",
    nhf: "N105,807.67",
    wht: "N105,807.67",
    gratuity: "N105,807.67",
    businessExpense: "N105,807.67",
  },
  {
    date: "September 30, 2022",
    employees: [
      1, 2, 3, 4, 5, 6, 7, 7, 554, 3, 1, 2, 3, 4, 5, 5, 6, 6, 6, 5, 5, 5, 5, 5,
      4, 3,
    ],
    providers: [
      "ARM pension",
      "Ikeja Tax Office",
      "Reliance HMO",
      "Leadway Assurance",
    ],
    totalGrossPay: "N12,565,807.67",
    totalBonus: "N11,565,807.67",
    otherDeductions: "N13,565,807.67",
    totalNetPay: "N12,565,807.67",
    employeeContribution: "N12,565,807.67",
    employerContribution: "N12,565,807.67",
    paye: "N105,807.67",
    pension: "N105,807.67",
    hmo: "N105,807.67",
    lifeInsurance: "N105,807.67",
    lifeAssurance: "N105,807.67",
    nsitf: "N105,807.67",
    itf: "N105,807.67",
    nhf: "N105,807.67",
    wht: "N105,807.67",
    gratuity: "N105,807.67",
    businessExpense: "N105,807.67",
  },
  {
    date: "September 30, 2022",
    employees: [
      1, 2, 3, 4, 5, 6, 7, 7, 554, 3, 1, 2, 3, 4, 5, 5, 6, 6, 6, 5, 5, 5, 5, 5,
      4, 3,
    ],
    providers: [
      "ARM pension",
      "Ikeja Tax Office",
      "Reliance HMO",
      "Leadway Assurance",
    ],
    totalGrossPay: "N12,565,807.67",
    totalBonus: "N11,565,807.67",
    otherDeductions: "N13,565,807.67",
    totalNetPay: "N12,565,807.67",
    employeeContribution: "N12,565,807.67",
    employerContribution: "N12,565,807.67",
    paye: "N105,807.67",
    pension: "N105,807.67",
    hmo: "N105,807.67",
    lifeInsurance: "N105,807.67",
    lifeAssurance: "N105,807.67",
    nsitf: "N105,807.67",
    itf: "N105,807.67",
    nhf: "N105,807.67",
    wht: "N105,807.67",
    gratuity: "N105,807.67",
    businessExpense: "N105,807.67",
  },
  {
    date: "September 30, 2022",
    employees: [
      1, 2, 3, 4, 5, 6, 7, 7, 554, 3, 1, 2, 3, 4, 5, 5, 6, 6, 6, 5, 5, 5, 5, 5,
      4, 3,
    ],
    providers: [
      "ARM pension",
      "Ikeja Tax Office",
      "Reliance HMO",
      "Leadway Assurance",
    ],
    totalGrossPay: "N12,565,807.67",
    totalBonus: "N11,565,807.67",
    otherDeductions: "N13,565,807.67",
    totalNetPay: "N12,565,807.67",
    employeeContribution: "N12,565,807.67",
    employerContribution: "N12,565,807.67",
    paye: "N105,807.67",
    pension: "N105,807.67",
    hmo: "N105,807.67",
    lifeInsurance: "N105,807.67",
    lifeAssurance: "N105,807.67",
    nsitf: "N105,807.67",
    itf: "N105,807.67",
    nhf: "N105,807.67",
    wht: "N105,807.67",
    gratuity: "N105,807.67",
    businessExpense: "N105,807.67",
  },
  {
    date: "September 30, 2022",
    employees: [
      1, 2, 3, 4, 5, 6, 7, 7, 554, 3, 1, 2, 3, 4, 5, 5, 6, 6, 6, 5, 5, 5, 5, 5,
      4, 3,
    ],
    providers: [
      "ARM pension",
      "Ikeja Tax Office",
      "Reliance HMO",
      "Leadway Assurance",
    ],
    totalGrossPay: "N12,565,807.67",
    totalBonus: "N11,565,807.67",
    otherDeductions: "N13,565,807.67",
    totalNetPay: "N12,565,807.67",
    employeeContribution: "N12,565,807.67",
    employerContribution: "N12,565,807.67",
    paye: "N105,807.67",
    pension: "N105,807.67",
    hmo: "N105,807.67",
    lifeInsurance: "N105,807.67",
    lifeAssurance: "N105,807.67",
    nsitf: "N105,807.67",
    itf: "N105,807.67",
    nhf: "N105,807.67",
    wht: "N105,807.67",
    gratuity: "N105,807.67",
    businessExpense: "N105,807.67",
  },
  {
    date: "September 30, 2022",
    employees: [
      1, 2, 3, 4, 5, 6, 7, 7, 554, 3, 1, 2, 3, 4, 5, 5, 6, 6, 6, 5, 5, 5, 5, 5,
      4, 3,
    ],
    providers: [
      "ARM pension",
      "Ikeja Tax Office",
      "Reliance HMO",
      "Leadway Assurance",
    ],
    totalGrossPay: "N12,565,807.67",
    totalBonus: "N11,565,807.67",
    otherDeductions: "N13,565,807.67",
    totalNetPay: "N12,565,807.67",
    employeeContribution: "N12,565,807.67",
    employerContribution: "N12,565,807.67",
    paye: "N105,807.67",
    pension: "N105,807.67",
    hmo: "N105,807.67",
    lifeInsurance: "N105,807.67",
    lifeAssurance: "N105,807.67",
    nsitf: "N105,807.67",
    itf: "N105,807.67",
    nhf: "N105,807.67",
    wht: "N105,807.67",
    gratuity: "N105,807.67",
    businessExpense: "N105,807.67",
  },
  {
    date: "September 30, 2022",
    employees: [
      1, 2, 3, 4, 5, 6, 7, 7, 554, 3, 1, 2, 3, 4, 5, 5, 6, 6, 6, 5, 5, 5, 5, 5,
      4, 3,
    ],
    providers: [
      "ARM pension",
      "Ikeja Tax Office",
      "Reliance HMO",
      "Leadway Assurance",
    ],
    totalGrossPay: "N12,565,807.67",
    totalBonus: "N11,565,807.67",
    otherDeductions: "N13,565,807.67",
    totalNetPay: "N12,565,807.67",
    employeeContribution: "N12,565,807.67",
    employerContribution: "N12,565,807.67",
    paye: "N105,807.67",
    pension: "N105,807.67",
    hmo: "N105,807.67",
    lifeInsurance: "N105,807.67",
    lifeAssurance: "N105,807.67",
    nsitf: "N105,807.67",
    itf: "N105,807.67",
    nhf: "N105,807.67",
    wht: "N105,807.67",
    gratuity: "N105,807.67",
    businessExpense: "N105,807.67",
  },
  {
    date: "September 30, 2022",
    employees: [
      1, 2, 3, 4, 5, 6, 7, 7, 554, 3, 1, 2, 3, 4, 5, 5, 6, 6, 6, 5, 5, 5, 5, 5,
      4, 3,
    ],
    providers: [
      "ARM pension",
      "Ikeja Tax Office",
      "Reliance HMO",
      "Leadway Assurance",
    ],
    totalGrossPay: "N12,565,807.67",
    totalBonus: "N11,565,807.67",
    otherDeductions: "N13,565,807.67",
    totalNetPay: "N12,565,807.67",
    employeeContribution: "N12,565,807.67",
    employerContribution: "N12,565,807.67",
    paye: "N105,807.67",
    pension: "N105,807.67",
    hmo: "N105,807.67",
    lifeInsurance: "N105,807.67",
    lifeAssurance: "N105,807.67",
    nsitf: "N105,807.67",
    itf: "N105,807.67",
    nhf: "N105,807.67",
    wht: "N105,807.67",
    gratuity: "N105,807.67",
    businessExpense: "N105,807.67",
  },
  {
    date: "September 30, 2022",
    employees: [
      1, 2, 3, 4, 5, 6, 7, 7, 554, 3, 1, 2, 3, 4, 5, 5, 6, 6, 6, 5, 5, 5, 5, 5,
      4, 3,
    ],
    providers: [
      "ARM pension",
      "Ikeja Tax Office",
      "Reliance HMO",
      "Leadway Assurance",
    ],
    totalGrossPay: "N12,565,807.67",
    totalBonus: "N11,565,807.67",
    otherDeductions: "N13,565,807.67",
    totalNetPay: "N12,565,807.67",
    employeeContribution: "N12,565,807.67",
    employerContribution: "N12,565,807.67",
    paye: "N105,807.67",
    pension: "N105,807.67",
    hmo: "N105,807.67",
    lifeInsurance: "N105,807.67",
    lifeAssurance: "N105,807.67",
    nsitf: "N105,807.67",
    itf: "N105,807.67",
    nhf: "N105,807.67",
    wht: "N105,807.67",
    gratuity: "N105,807.67",
    businessExpense: "N105,807.67",
  },
  {
    date: "September 30, 2022",
    employees: [
      1, 2, 3, 4, 5, 6, 7, 7, 554, 3, 1, 2, 3, 4, 5, 5, 6, 6, 6, 5, 5, 5, 5, 5,
      4, 3,
    ],
    providers: [
      "ARM pension",
      "Ikeja Tax Office",
      "Reliance HMO",
      "Leadway Assurance",
    ],
    totalGrossPay: "N12,565,807.67",
    totalBonus: "N11,565,807.67",
    otherDeductions: "N13,565,807.67",
    totalNetPay: "N12,565,807.67",
    employeeContribution: "N12,565,807.67",
    employerContribution: "N12,565,807.67",
    paye: "N105,807.67",
    pension: "N105,807.67",
    hmo: "N105,807.67",
    lifeInsurance: "N105,807.67",
    lifeAssurance: "N105,807.67",
    nsitf: "N105,807.67",
    itf: "N105,807.67",
    nhf: "N105,807.67",
    wht: "N105,807.67",
    gratuity: "N105,807.67",
    businessExpense: "N105,807.67",
  },
  {
    date: "September 30, 2022",
    employees: [
      1, 2, 3, 4, 5, 6, 7, 7, 554, 3, 1, 2, 3, 4, 5, 5, 6, 6, 6, 5, 5, 5, 5, 5,
      4, 3,
    ],
    providers: [
      "ARM pension",
      "Ikeja Tax Office",
      "Reliance HMO",
      "Leadway Assurance",
    ],
    totalGrossPay: "N12,565,807.67",
    totalBonus: "N11,565,807.67",
    otherDeductions: "N13,565,807.67",
    totalNetPay: "N12,565,807.67",
    employeeContribution: "N12,565,807.67",
    employerContribution: "N12,565,807.67",
    paye: "N105,807.67",
    pension: "N105,807.67",
    hmo: "N105,807.67",
    lifeInsurance: "N105,807.67",
    lifeAssurance: "N105,807.67",
    nsitf: "N105,807.67",
    itf: "N105,807.67",
    nhf: "N105,807.67",
    wht: "N105,807.67",
    gratuity: "N105,807.67",
    businessExpense: "N105,807.67",
  },
]);
const splitData = ref([]);
function calculateObjectToal(obj) {
  const keys = Object.keys(obj);
  let total = 0;
  for (let index = 0; index < keys.length; index++) {
    const key = keys[index];
    if (!obj[key]) {
      continue;
    }
    const value = obj[key];
    total += value.amount;
  }
  return total;
}
function splitToChunk() {
  const chunkSize = 18;
  let all = [];
  for (let i = 0; i < data.value.length; i += chunkSize) {
    const chunk = data.value.slice(i, i + chunkSize);
    all.push(chunk);

    // do whatever
  }
  splitData.value = all;
}
splitToChunk();

const updatePage = (pageNumber) => {
  currentPage.value = pageNumber;
  updateVisibleData();
};
const updateVisibleData = () => {
  let datas = data.value;
  visibleData.value = datas.slice(
    currentPage.value * pageSize.value,
    currentPage.value * pageSize.value + pageSize.value
  );

  // if we have 0 visible todos, go back a page
  if (visibleData.value.length == 0 && currentPage.value > 0) {
    updatePage(currentPage.value - 1);
  }
};
updateVisibleData();
</script>

<style>
.debit {
  background: rgba(228, 87, 46, 0.15);
}
</style>
