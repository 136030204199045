<template>
  <main>
    <h4
      class="md:mt-3 md:block hidden md:text-sm md:text-left text-xs text-headerText"
    >
      Edit Salary / {{ empName() }}
    </h4>
    <div class="flex justify-between items-center text-left mt-6 mb-9 md:mb-3">
      <easiBackButton></easiBackButton>
      <h4
        class="md:mt-3 block md:hidden md:text-sm md:text-left text-xs text-headerText"
      >
        Edit Salary / {{ empName() }}
      </h4>
    </div>
    <div class="flex flex-row md:flex-col w-full md:w-52 md:items-start"></div>

    <div class="relative mx-auto w-full text-sm h-auto lg:w-8/12">
      <!-- <span @click="reloadPage" class="absolute top-4 right-4 cursor-pointer">
        <img src="@/assets/icons/close.svg" alt="" />
      </span> -->
      <span></span>
      <keep-alive>
        <component :is="display" />
      </keep-alive>
    </div>
  </main>
</template>

<script setup>
import { ref, reactive, computed, shallowRef } from "vue";
import { storeToRefs } from "pinia";
import { useDataStore } from "@/stores/data.js";
import { useRoute } from "vue-router";

//// View Components ////
import EditBenefits from "@/components/Payroll/Edit/Benefits/Benefits.vue";

const store = useDataStore();
const { getAllEmployees } = storeToRefs(store);
const route = useRoute();

const employee = computed(() => store.getEmployeeById(route.params.id));

function empName() {
  const fName =
    employee.value && employee.value.profile
      ? employee.value.profile.firstName
      : "";
  const lName =
    employee.value && employee.value.profile
      ? employee.value.profile.lastName
      : "";
  const fullName = `${fName} ${lName}`;
  return fullName;
}

const display = shallowRef(EditBenefits);

const pages = reactive([
  {
    title: "Job History",
    component: EditBenefits,
    active: true,
    live: "view",
  },
]);

const reloadPage = () => {
  // store.$patch({
  //   pageIndex: currentPage,
  // });
  window.location.reload();
};

const switchView = (index) => {
  display.value = pages[index].component;
  pages.forEach((el) => {
    el.active = false;
  });
  pages[index].active = true;
};
</script>

<style></style>
