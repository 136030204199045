<template>
  <main class="w-full min-h-screen px-2 sm:px-4 py-4">
    <div
      class="flex flex-row-reverse items-center justify-between sm:flex-col sm:items-start mb-2 sm:mb-0"
    >
      <h4
        class="md:mt-3 md:text-sm md:text-left text-xs font-medium text-dark-600 sm:text-headerText capitalize sm:uppercase"
      >
        Payroll/Bonus and Deduction
      </h4>
      <div class="text-left sm:mt-4 mb-2 sm:mb-0">
        <easiBackButton></easiBackButton>
      </div>
    </div>

    <div
      class="flex items-center text-center w-full md:w-1/2 mx-auto border border-outlineGray rounded-xl tracking-wide justify-center md:mb-- mb-0 gap-x-2 p-2 text-sm font-medium cursor-pointer bg-white"
    >
      <span
        @click="toggleAdd"
        class="w-full text-sm rounded-lg md:px-5 md:py-2 px-3 py-2"
        :class="bonus ? 'bg-primary shadow-2xl text-white' : 'bg-newGrey'"
        >Bonus</span
      >
      <span
        @click="toggleAdd"
        class="w-full text-sm rounded-lg md:px-5 md:py-2 px-3 py-2"
        :class="!bonus ? 'bg-primary shadow-2xl text-white' : 'bg-newGrey'"
        >Deduction</span
      >
    </div>

    <div v-if="loading" class="w-full">
      <easiRoundLoader />
    </div>

    <div v-if="!isSearch && !bonusorDedData.length" class="mt-16 mb-4">
      <h3 class="my-6 text-2xl text-center md:text-3xl text-dark-800 font-bold">
        You have not added any {{ item }} yet.
      </h3>
      <div
        class="container w-full mx-auto flex items-center justify-center gap-4 flex-wrap"
      >
        <!-- <easiButton
          @click="showAdd = true"
          class="font-medium"
          variant="outline-secondary"
        >
          Add New {{ bonus === true ? "Bonus" : "Deduction" }}
        </easiButton> -->
        <easiButton
          @click="$router.push({ name: 'AddBonus' })"
          class="font-medium"
          variant="outline-secondary"
        >
          Add New {{ bonus === true ? "Bonus" : "Deduction" }}
        </easiButton>
        <img src="@/assets/icons/no_employee.svg" alt="" />
      </div>
    </div>

    <div
      v-else
      class="bg-white border rounded-2xl pt-8 pl-8 pr-4 border-outlineGray my-6 md:my-10 w-full"
    >
      <div
        class="flex pr-6 md:pr-8 md:justify-between md:flex-row flex-col gap-y-6 md:gap-y-0 w-full"
      >
        <div
          class="flex justify-between flex-wrap md:items-center gap-x-2 md:gap-x-7 mt-2 w-full grow-0 gap-y-4 md:gap-y-2"
        >
          <!-- /////// Search Component ///// -->
          <!-- <div class="md:w-7/12">
            <BonusSearch
              :placeholder="
                bonus === true ? 'Search Bonus' : 'Search Deduction'
              "
              :type="item"
              v-model="searchText"
              @search="searchFN"
            />
          </div> -->

          <!-- /////// Filter Component Start /////// -->
          <!-- <div class="md:ml-3">
            <BonusFilter ref="bonusFilterRef" @update="filterFN" />
          </div> -->
          <!-- /////// Filter Component Ends /////// -->
          <p class="text-grey font-bold text-xl">
            All
            {{ bonus === true ? "Bonuses" : "Deductions" }}
          </p>
          <div class="flex gap-5">
            <!-- <easiButton
              @click="showAdd = true"
              variant="primary"
              class="flex items-center gap-3 py-4 md:px-10 text-xs md:text-base"
            >
              <img
                class="hidden md:block"
                src="@/assets/icons/newPlus.svg"
                alt=""
              />
              <span class="text-xs font-medium md:text:base">
                Add New {{ bonus === true ? "Bonus" : "Deduction" }}
              </span>
            </easiButton> -->
            <div
              class="text-primary bg-lighterGreen rounded-full cursor-pointer px-7 py-3 flex gap-3"
              @click="
                $router.push(
                  `/payroll/salary/bonus-and-deductions/history?type=${
                    bonus === true ? 'Bonus' : 'Deduction'
                  }`
                )
              "
            >
              <img src="@/assets/img/clock.svg" alt="" />
              <p class="flex gap-1">
                <span class="hidden sm:block"> View </span>
                history
              </p>
            </div>
            <easiButton
              @click="$router.push({ name: 'AddBonus' })"
              variant="primary"
              class="flex items-center gap-3 py-4 md:px-10 text-xs md:text-base"
            >
              <img
                class="hidden md:block"
                src="@/assets/icons/newPlus.svg"
                alt=""
              />
              <span class="text-xs font-medium md:text:base">
                Add New {{ bonus === true ? "Bonus" : "Deduction" }}
              </span>
            </easiButton>
          </div>
        </div>
      </div>

      <easiActiveFilter
        class="my-4"
        @close="removeFilter"
        :filters="activeFilters"
      />
      <div
        v-if="mostRecentBonusorDedData"
        class="border rounded-2xl border-outlineGray mr-6 md:mr-8 p-4 bg-landingGrey"
      >
        <div class="flex justify-between">
          <p class="text-grey font-medium text-lg">Recent Payment</p>
          <div
            class="flex items-center gap-2 cursor-pointer"
            @click="
            $router.push(
              `/payroll/salary/bonus-and-deductions/history?type=${
                bonus === true ? 'Bonus' : 'Deduction'
              }`
            )
          "
          >
            <p>See all</p>
            <img src="@/assets/img/seeAll.svg" alt="" />
          </div>
        </div>
        <div class="grid px-2 mt-4 py-2 text-xs grid-cols-4 bg-white">
          <p class="capitalize">
            {{ mostRecentBonusorDedData.employeeName }}
          </p>
          <p>
            {{ formatAmount(mostRecentBonusorDedData.amountPaid) }}
          </p>
          <p>{{ mostRecentBonusorDedData.bankName }}</p>
          <p>{{ mostRecentBonusorDedData.statusOfPayment }}</p>
        </div>
      </div>
      <div class="-ml-4 w-full h-full md:mt-8">
        <div v-if="loading" class="w-full">
          <easiLoader />
        </div>
        <easiBonusTable
          :data="dataForBonusOrDed"
          :amountValues="amountValues"
          :frozenFields="[]"
          :highlight="['bonusName', 'deductionName']"
          :useForOption="`${item.toLowerCase()}Name`"
          :header="headers()"
          :readonly="true"
          :routable="true"
          :is-search="isSearch"
          @completed="queryBonusOrDed"
        />
      </div>
    </div>

    <!-- <div v-if="!bonus" class="md:block hidden -ml-4 md:mt-8">
      <easiBonusTable
        :data="deductionData"
        :namesForAllowances="allowanceNames"
        :amountValues="amountValues"
        :frozenFields="['deductionName']"
        :highlight="['deductionName']"
        :useForOption="'deductionName'"
        :header="headers(deductionData)"
        :useSelect="['duration']"
        :readonly="true"
        :routable="true"
      />
    </div> -->

    <!-- 
    <easiPagin
      v-if="payrollData.length && bonus"
      :data="payrollData"
      @page:update="updatePage"
      :currentPage="currentPage"
      :pageSize="pageSize"
      class=""
    /> -->

    <easiLoading v-if="loadingProgress" @close="loadingProgress = false">
      <template v-slot:heading>
        <h1>Analyzing Data</h1>
      </template>

      <template v-slot:message>
        <div class="w-full rounded-xl">
          <vue3-progress-bar></vue3-progress-bar>
        </div>
      </template>
    </easiLoading>
    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span>Updated successfully</span>
      </template>
    </easiSuccess>

    <easiModal :isBorder="false" v-if="showAdd" @close="showAdd = false">
      <EditBonus @completed="queryBonusOrDed" :type="'add'" />
    </easiModal>
  </main>
</template>

<script setup>
import { ref, reactive, onMounted, computed, watch } from "vue";
import { storeToRefs } from "pinia";
import { useToast } from "vue-toastification";

import { useDataStore } from "@/stores/data.js";
import EditBonus from "@/components/Payroll/Edit/BonusAndDed/BonusAndDed.vue";
import BonusFilter from "@/components/Filters/bonusFilter.vue";
import BonusSearch from "@/components/Search/bonusSearch.vue";
import { helperFunctions } from "@/composable/helperFunctions";

const { formatAmount } = helperFunctions;
const store = useDataStore();
const { query, mutate } = store;
const {
  listEmployees,
  getAllEmployees,
  getBonusAndDeduction,
  getMostRecentBonusOrDeduction,
  getBonusorDed,
} = storeToRefs(store);
const toast = useToast();

// import BonusAndDed from "@/components/Payroll/View/BonusAndDed/BonusAndDed";
const showAdd = ref(false);
const queryOption = computed(() => store.getBonusorDed);

const { formatDateString } = helperFunctions;

const dataForBonusOrDed = ref([]);
const bonusorDedData = computed(() => getBonusAndDeduction.value);
const mostRecentBonusorDedData = computed(
  () =>
    getMostRecentBonusOrDeduction.value &&
    getMostRecentBonusOrDeduction.value.bonusOrDeductionPayments &&
    getMostRecentBonusOrDeduction.value.bonusOrDeductionPayments.length &&
    getMostRecentBonusOrDeduction.value.bonusOrDeductionPayments[0]
);
const allEmployees = computed(() => getAllEmployees.value.data);
let selectedEmployees = ref([]);
const item = ref("");

const loading = ref(false);
const searchText = ref("");
let isSearch = ref(false);
const filterOptions = ref({
  sort: { firstName: "ASC" },
  filter: [],
});
const bonusFilterRef = ref(null);
const activeFilters = ref([]);

const currentPage = ref(0);
const pageSize = ref(15);
let visibleData = ref([]);

const payload = ref({
  paging: {
    limit: 1000,
    skip: 0,
    search: "",
    sort: {},
  },
  filter: [],
});

const bonusOrDedPayload = ref({
  paging: {
    limit: 1000,
    skip: 0,
    search: "",
  },
  sort: {},
  filter: [],
  type: queryOption.value.length > 0 ? queryOption.value : "Bonus",
});

watch(searchText, async (val) => {
  if (val.trim() === "") {
    bonusOrDedPayload.value.paging.search = "";
    await queryBonusOrDed();
    isSearch.value = false;
  }
});
const getMostRecentBonusOrDed = async () => {
  const payload = {
    input: {
      type: bonus.value === true ? "Bonus" : "Deduction",
      paging: {
        limit: 1,
        skip: 0,
        search: null,
      },
    },
  };
  try {
    const res = await query({
      endpoint: "GetBonusAndDeductionHistory",
      payload,
      service: "PAYROLL",
      storeKey: "bonusOrDeductionHistory",
    });

    // console.log(getBonusAndDeduction.value, "After query");
  } catch (err) {
    console.log(err);
  }
};
const queryBonusOrDed = async () => {
  loading.value = true;
  showAdd.value = false;
  try {
    const res = await query({
      endpoint: "ListBonusAndDeduction",
      payload: {
        input: bonusOrDedPayload.value,
      },
      service: "PAYROLL",
      storeKey: "listBonusAndDeduction",
    });

    // console.log(getBonusAndDeduction.value, "After query");

    dataForBonusOrDed.value = extractData();
    await getMostRecentBonusOrDed();
  } catch (err) {
    loading.value = false;
    console.log(err);
  } finally {
    loading.value = false;
  }
};
const queryEmployees = async () => {
  loading.value = true;
  try {
    await query({
      endpoint: "ListEmployees",
      payload: payload.value,
      service: "EMP",
      storeKey: "listEmployees",
    });

    loading.value = false;
  } catch (err) {
    loading.value = false;
    console.log(err);
  } finally {
    loading.value = false;
  }
};
// queryBonusOrDed();
queryEmployees();
const amountValues = ["amount", "totalAmount"];

const extractData = () => {
  if (bonusorDedData.value && Array.isArray(bonusorDedData.value)) {
    const data = bonusorDedData.value.map((d) => {
      return {
        id: d._id,
        [`${d.type.toLowerCase()}Name`]: d.name,
        totalAmount: d.totalAmount,
        // percentage: d.percentage,
        // duration: d.duration,
        employee: d.employees.length,
        dateCreated: formatDateString(d.createdAt),
        // endDate: d.endDate,
        employeeIds: getEmployeeIds(d),
        status: d.status ? d.status : "N/A",
      };
    });

    function getEmployeeIds(arg) {
      return arg ? arg.employees.map((el) => el._id) : [];
    }
    return data;
  }
};
dataForBonusOrDed.value = extractData();

const headers = () => {
  if (dataForBonusOrDed.value && dataForBonusOrDed.value.length > 0) {
    let headArr = [];
    const head = Object.keys(dataForBonusOrDed.value[0]);
    for (let i of head) {
      headArr.push({
        field: i,
        header: i,
      });
    }

    return headArr;
  }
};

const loadingProgress = ref(false);
const updateSuccess = ref(false);

const bonus = ref(true);
async function toggleAdd() {
  searchText.value = "";
  bonusOrDedPayload.value.paging.search = "";

  bonus.value = !bonus.value;
  item.value = bonus.value === true ? "Bonus" : "Deduction";
  isSearch.value = false;
  bonusOrDedPayload.value.type = item.value;
  bonusOrDedPayload.value.filter = [];
  activeFilters.value = [];
  bonusOrDedPayload.value.sort = {};
  await queryBonusOrDed();
  dataForBonusOrDed.value = extractData();

  store.$patch({
    bonusorDed: item.value,
  });
}

const searchFN = async (suggested) => {
  // console.log(suggested, searchText.value);
  searchText.value = suggested ? suggested : searchText.value;
  isSearch.value = true;
  bonusOrDedPayload.value.paging.search = searchText.value;
  await queryBonusOrDed();
};

const filterFN = ({ filter, active }) => {
  bonusOrDedPayload.value.sort = {};
  isSearch.value = true;
  filterOptions.value = filter;

  bonusOrDedPayload.value.sort = filterOptions.value.sort;
  bonusOrDedPayload.value.filter = filterOptions.value.filter;

  queryBonusOrDed();
  activeFilters.value = active;
};

const removeFilter = (arg) => {
  if (bonusFilterRef.value && bonusFilterRef.value.removeFilter) {
    bonusFilterRef.value.removeFilter(arg);
  }
};

function updateSelectedEmployees(arg) {
  selectedEmployees.value = [];
  selectedEmployees.value = arg;
}

function empty() {
  getAllEmployees.value.data = [];
}
// Get rid of the proxy when console.log
function log(arg) {
  console.log(JSON.parse(JSON.stringify(arg)));
}

const updatePage = (pageNumber) => {
  currentPage.value = pageNumber;
  updateVisibleData();
};
const updateVisibleData = () => {
  let datas = dataForBonusOrDed.value;
  visibleData.value =
    datas &&
    datas.slice(
      currentPage.value * pageSize.value,
      currentPage.value * pageSize.value + pageSize.value
    );

  // if we have 0 visible todos, go back a page
  if (
    visibleData.value &&
    visibleData.value.length == 0 &&
    currentPage.value > 0
  ) {
    updatePage(currentPage.value - 1);
  }
};

onMounted(async () => {
  item.value = queryOption.value;
  bonus.value = queryOption.value === "Bonus" ? true : false;
  await queryEmployees();
  await queryBonusOrDed();
  updateVisibleData();
  dataForBonusOrDed.value = extractData();
  // visibleData.value = employees;
});
</script>

<style></style>
