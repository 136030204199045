<template>
  <main class="w-full">
    <div style="height: 35rem" class="w-full overflow-y-scroll">
      <div class="flex flex-col gap-6 w-full">
        <div
          class="border border-gray"
          v-for="(sub, k) in visibleData"
          :key="k"
        >
          <div
            class="bg-primary w-full py-1 flex text-sm px-5 justify-between items-center text-white font-bold"
          >
            <div class="flex items-center gap-1">
              <span> {{ months[sub.month] }}</span
              >, <span> {{ sub.year }}</span
              >|
              <span> {{ sub.timeline }}</span>
            </div>

            <div>{{ sub.numOfEmployees }} EMPLOYEE(S)</div>
          </div>

          <div v class="w-full grid grid-cols-2 md:grid-cols-5 justify-start">
            <div class="flex flex-col col-span-1 justify-start py-2 pl-2 gap-1">
              <span
                class="text-xs text-dark-800 text-left uppercase font-medium"
                >total gross pay</span
              >

              <span
                class="text-xs text-dark-900 text-left uppercase font-bold"
                >{{ formatAmount(sub.totalGross) }}</span
              >
            </div>
            <div
              class="flex flex-col col-span-1 justify-start py-2 pl-2 gap-1 border-l border-r border-outlineGray"
            >
              <span
                class="text-xs text-dark-800 text-left uppercase font-medium"
                >TOTAL NET PAY</span
              >

              <span
                class="text-xs text-dark-900 text-left uppercase font-bold"
                >{{ formatAmount(sub.totalNet) }}</span
              >
            </div>

            <!-- <div class="md:flex w-full md:col-span-4 border-l border-btnPri">
              <div
                class="bg-white text-xs text-grey flex flex-col gap-y-1 items-start upercase font-medium py-2 pl-1"
              >
                <span class="text-left">PROVIDERS / PAYMENT PARTNERS</span>
                <span
                  class="text-dark-800 font-medium md:w-72 md:pr-5 text-left"
                  >{{ sub.providers.join() }}</span
                >
              </div>

              <div
                class="bg-paleGreen text-xs text-dark-800 md:flex hidden flex-col gap-y-1 items-start upercase font-medium py-2 pl-2 w-full"
              >
                <span class="text-xs text-left">ADDITIONAL ALLOWANCES</span>
                <span class="text-dark-800 font-medium text-left">{{
                  sub.totalBonus
                }}</span>
              </div>
              <div
                class="bg-paleGreen text-xs text-dark-800 md:flex hidden flex-col gap-y-1 items-start upercase font-medium py-2 pl-2 w-full"
              >
                <span class="text-xs text-left">TOTAL BONUS</span>
                <span class="text-dark-800 font-medium text-left">{{
                  sub.totalBonus
                }}</span>
              </div>

              <div
                class="bg-gold text-xs text-dark-800 md:flex hidden flex-col gap-y-1 items-start upercase font-medium py-2 pl-2 w-full"
              >
                <span class="text-xs text-left">OTHER DEDUCTIONS</span>
                <span class="text-dark-800 font-medium text-left">{{
                  sub.otherDeductions
                }}</span>
              </div>
            </div> -->

            <div
              class="flex flex-col text-xs text-dark-800 text-left gap-y-1 items-start upercase font-medium py-2 pl-2 w-full"
            >
              <span class="text-xs text-left">ADDITIONAL ALLOWANCES</span>
              <span class="text-dark-800 font-medium text-left">{{
                formatAmount(sub.totalAllowances)
              }}</span>
            </div>
            <div
              class="flex flex-col text-xs text-dark-800 text-left gap-y-1 items-start upercase font-medium py-2 pl-2 w-full border-l border-r border-outlineGray"
            >
              <span class="text-xs text-left">TOTAL BONUS</span>
              <span class="text-dark-800 font-medium text-left">{{
                formatAmount(sub.totalBonuses)
              }}</span>
            </div>

            <div
              class="flex flex-col text-xs text-dark-800 text-left gap-y-1 items-start upercase font-medium py-2 pl-2 w-full"
            >
              <span class="text-xs text-left">OTHER DEDUCTIONS</span>
              <span class="text-dark-800 font-medium text-left">{{
                formatAmount(sub.totalDeductions)
              }}</span>
            </div>
          </div>
          <div
            v-if="sub.totalStatutoryDetails"
            class="bg-newLightGreen w-full text-sm text-dark-900 flex flex-col gap-y-1 items-start upercase font-medium py-3 pl-2"
          >
            <span class="w-72 text-left">STATUTORY DEDUCTIONS</span>
          </div>
          <div
            v-if="sub.totalStatutoryDetails"
            class="w-full grid md:grid-cols-5 grid-cols-2 border-t border-btnPri col-span-2 md:col-span-4"
          >
            <div
              v-for="(ben, n, index) in sub.totalStatutoryDetails"
              :key="ben"
              class="w-auto text-grey text-xs flex flex-col gap-y-1 items-start upercase font-medium py-2 border border-outlineGray"
              :class="index % 2 ? ' pl-2 pr-1' : ' pl-2 pr-1'"
            >
              <span class="text-left uppercase w-full">TOTAL {{ n }}</span>
              <span class="text-left uppercase text-dark-900 font-bold">{{
                formatAmount(ben)
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- <table
        v-for="(dat, i) in visibleData"
        :key="i"
        class="w-full md:hidden table mb-0"
      >
        <tbody class="md:relative">
          <tr
            v-for="(sub, k) in dat"
            :key="k"
            class="hover:bg-easigrey group bg-white cursor-pointer relative my-4"
          >
            <td
              :class="
                sub.transaction === 'debit'
                  ? 'border border-secondary font-medium   text-xs  bg-white group-hover:bg-indigo-100 group-hover:border-indigo-300 sticky capitalize'
                  : 'border border-success font-medium  text-xs  bg-white group-hover:bg-indigo-100 group-hover:border-indigo-300 sticky capitalize'
              "
              class="flex flex-col my-3 font-medium text-left text-dark-900 text-xs bg-white group-hover:bg-indigo-100 group-hover:border-indigo-300"
            >
              <div
                :class="
                  sub.transaction === 'debit'
                    ? 'border-b border-secondary'
                    : 'border-b border-success'
                "
                class="text-center text-dark-800 font-medium py-2"
              >
                {{ sub.date }}
              </div>
              <div
                class="flex justify-between items-stretch place-items-stretch justify-items-stretch"
              >
                <div
                  :class="
                    sub.transaction === 'debit'
                      ? ' border-secondary'
                      : ' border-success'
                  "
                  class="w-full text-dark-900 font-medium py-1 px-3"
                >
                  {{ sub.description }}
                </div>
                <div
                  :class="
                    sub.transaction === 'debit' ? 'debit' : 'bg-successBg'
                  "
                  class="w-full text-dark-900 font-black py-1 px-3 self-stretch flex flex-row items-center"
                >
                  {{ sub.amount }}
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table> -->
    </div>
    <easiPagin
      v-if="visibleData && visibleData.length"
      :data="visibleData"
      @page:update="updatePage"
      :currentPage="currentPage"
      :pageSize="pageSize"
      class="my-4"
    />
  </main>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { storeToRefs, mapWritableState } from "pinia";
import { useDataStore } from "@/stores/data.js";
import { helperFunctions } from "@/composable/helperFunctions";
import { format } from "path";

const store = useDataStore();
const { log, formatAmount } = helperFunctions;

const props = defineProps({
  showFullData: {
    type: Boolean,
    default: false,
  },
});

const propD = ref(props.showFullData);

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const currentPage = ref(0);
const pageSize = ref(5);
let visibleData = ref([]);
const data = computed(() => {
  if (store.getPayrollReport && store.getPayrollReport.length) {
    return store.getPayrollReport[0];
  }
});

const updatePage = (pageNumber) => {
  currentPage.value = pageNumber;
  updateVisibleData();
};

const updateVisibleData = () => {
  if (data.value) {
    let datas = data.value.data.sort(
      (a, b) => new Date(b.year) - new Date(a.year)
    );
    visibleData.value = datas.slice(
      currentPage.value * pageSize.value,
      currentPage.value * pageSize.value + pageSize.value
    );

    // if we have 0 visible todos, go back a page
    if (visibleData.value.length == 0 && currentPage.value > 0) {
      updatePage(currentPage.value - 1);
    }
  }
};
updateVisibleData();
</script>

<style>
.debit {
  background: rgba(228, 87, 46, 0.15);
}
</style>
