<template>
  <div class="relative p-fluid" style="height: auto">
    <div class="card mx-4 h-full" :class="editInProgress ? 'z-20 ' : ''">
      <DataTable
        :value="data"
        v-model:selection="selectedEmployees"
        dataKey="employeeId"
        :resizableColumns="true"
        columnResizeMode="expand"
        showGridlines
        :paginator="false"
        :rows="20"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 20, 50]"
        :scrollable="true"
        scrollHeight="600px"
        :loading="!data.length"
        scrollDirection="both"
        editMode="cell"
        @cell-edit-complete="onCellEditComplete"
        class="editable-cells-table p-datatable-sm"
        responsiveLayout="scroll"
        @rowSelect="onRowSelect"
        @rowUnselect="onRowUnselect"
      >
        <Column
          :frozen="true"
          selectionMode="multiple"
          :headerStyle="headStyle"
          bodyStyle="width: 2.5em;border: 1px solid #E7E8E7"
        ></Column>

        <Column
          v-for="col of header"
          :field="col.field"
          :header="label(col.header)"
          :key="col.field"
          :frozen="freeze(col)"
          class="text-dark-900"
          style="
            width: 140px;
            border: 1px solid #e7e8e7;
            font-size: 14px;
            color: #231f1f;
          "
          :style="[
            highlightField(col.field),
            col.field === 'staffLevelId' ||
            col.field === 'employeeId' ||
            col.field === 'picture' ||
            col.field === 'prorate'
              ? 'display: none'
              : '',
          ]"
          :headerStyle="[
            col.header === header[header.length - 2].field
              ? lastHeaderStyle
              : style,
            col.header === active ? activeHeader : style,
          ]"
        >
          <template
            v-if="useSelect.includes(col.field)"
            #editor="{ data, field }"
          >
            <Dropdown
              v-model="data[field]"
              :options="returnOptions(field)"
              optionLabel="label"
              optionValue="value"
              placeholder="Select"
              @change="updateMultipleCells($event, data, field)"
            >
              <template #option="slotProps">
                <span>{{ slotProps.option.label }}</span>
              </template>
            </Dropdown>
          </template>

          <template
            v-else-if="!useSelect.includes(col.field) && readonly === false"
            #editor="{ data, field }"
          >
            <InputText
              @focus="editInProgress = true"
              v-model="data[field]"
              :ref="field"
              @keyup="updateMultipleCells($event, data, field)"
              autofocus
            />
          </template>

          <template v-if="col.field === 'firstName'" #body="slotProps">
            <div class="flex items-center gap-2">
              <div
                v-if="slotProps.data.picture"
                class="border w-9 h-9 rounded-full overflow-hidden"
              >
                <img
                  class="w-full object-cover"
                  :src="slotProps.data.picture"
                />
              </div>
              <div
                v-else
                :style="`background-color:${getAvatar(slotProps.data).color}`"
                class="p-2 h-9 w-9 text-sm text-white font-medium rounded-full mr-2"
              >
                {{ getAvatar(slotProps.data).initialLetters }}
              </div>
            </div>
            <div
              :class="[
                error(slotProps.data, col.field),
                bankError(slotProps.data, col.field),
              ]"
            >
              {{ slotProps.data[col.field] }}
            </div>
          </template>
          <template v-else-if="col.field === 'grossPay'" #body="slotProps">
            <div class="flex items-center gap-2"></div>
            <div
              :class="[
                error(slotProps.data, col.field),
                bankError(slotProps.data, col.field),
              ]"
            >
              {{ formatAmount(slotProps.data[col.field]) }}
            </div>
          </template>

          <template
            v-else-if="col.field === 'department' || col.field === 'branch'"
            #body="slotProps"
          >
            <div class="flex items-center gap-2"></div>
            <div
              :class="[
                error(slotProps.data, col.field),
                bankError(slotProps.data, col.field),
              ]"
              class="capitalize"
            >
              {{
                getDepartmentOrBranchName(slotProps.data[col.field], col.field)
              }}
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
    <div
      @click="editInProgress = false"
      :class="!editInProgress ? 'pointer-events-none' : ''"
      class="bg-transparent z-50 fixed inset-0"
    ></div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import { helperFunctions } from "@/composable/helperFunctions";

export default {
  setup() {
    const { formatAmount } = helperFunctions;
    return { formatAmount };
  },
  emits: ["data"],
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: [],
    },
    header: {
      type: Array,
      default: [],
    },
    mapData: {
      type: Array,
      default: [],
    },
    frozenFields: {
      type: Array,
      default: [],
    },
    highlight: {
      type: Array,
      default: [],
    },
    useSelect: {
      type: Array,
      default: [],
    },
    mandatory: {
      type: Array,
      default: [],
    },
  },

  data() {
    return {
      selectedEmployees: null,
      editingRows: [],
      columns: null,
      holdData: [],
      active: "",
      editInProgress: false,
    };
  },

  computed: {
    style() {
      return "padding-top: 0.75rem; padding-bottom: 0.75rem; line-height: 16px; font-weight: 700; letter-spacing: 0.025em; text-align: center;  text-transform: uppercase; border-width: 1px; border-top-width: 1px; background: rgba(240, 247, 235, 0.5);;margin-bottom: 10px;color: #515251; border: 1px solid #E7E8E7; font-size: 12px; box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);";
    },
    activeHeader() {
      return "padding-top: 0.75rem; padding-bottom: 0.75rem; background-color: #11453B; line-height: 16px; font-weight: 700; letter-spacing: 0.025em; text-align: center;  text-transform: uppercase; border-width: 2px; border-top-width: 0; background: #E7E8E7;margin-bottom: 10px;color: #ffffff; font-size: 14px;";
    },
    headStyle() {
      if (this.hideAllowances === false) {
        return "padding-top: 0.75rem; padding-bottom: 0.75rem; background-color: rgba(240, 247, 235, 0.5);  border-width: 2px; border-top-width: 0; margin-bottom: 10px;border-top-left-radius: 1rem;border-bottom-left-radius: 0rem; border: 1px solid #E7E8E7; font-size: 12px; text-align: center; width:3em";
      }
      return "width: 3em;border: 1px solid #E7E8E7; background-color: rgba(240, 247, 235, 0.5);  border-width: 2px; border-top-left-radius: 1rem;border-bottom-left-radius: 0rem; border: 1px solid #E7E8E7; font-size: 12px; text-align: left;margin-bottom: 10px;";
    },
    lastHeaderStyle() {
      return "padding-top: 0.75rem; padding-bottom: 0.75rem; background-color: rgba(240, 247, 235, 0.5); line-height: 16px; font-weight: 700; letter-spacing: 0.025em; text-align: center;  text-transform: capitalize; border-width: 2px; border-top-width: 0; background: rgba(240, 247, 235, 0.5);;margin-bottom: 10px;border-top-right-radius: 1rem;border-bottom-right-radius: 0rem;color: #515251; font-size: 14px; ";
    },
    label() {
      return (str) =>
        str
          .split(".")
          .pop()
          .replace(/([A-Z])/g, " $1");
    },
    freeze() {
      return (field) => {
        if (this.frozenFields.includes(field.field)) {
          return true;
        }
        return false;
      };
    },
    highlightField() {
      return (field) => {
        if (this.highlight.includes(field)) {
          // return "background: rgba(240, 247, 235, 1); box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1); box-shadow: 8px 0px 8px rgba(0, 0, 0, 0.2);";
          return "background: rgba(240, 247, 235, 1); ";
        }
        return "background: #FCFCFC;";
      };
    },
    // mandatoryField() {
    //   return (field) => {
    //     if (this.mandatory.includes(field)) {
    //       return "border: 1px solid #FFF0EC";
    //     }
    //   };
    // },
  },

  methods: {
    getDepartmentOrBranchName(id, field) {
      if (this.mapData && this.mapData.length && id) {
        if (
          field === "department" &&
          this.mapData[0] &&
          this.mapData[0].length
        ) {
          const arr = this.mapData[0].filter((el) => el.value === id);

          return arr[0].label;
        }

        if (field === "branch" && this.mapData[1] && this.mapData[1].length) {
          const arr = this.mapData[1].filter((el) => el.value === id);

          return arr[0].label;
        }
      } else {
        return "N/A";
      }
    },
    returnOptions(fieldType) {
      if (fieldType === "department") {
        return this.mapData[0];
      } else if (fieldType === "branch") {
        return this.mapData[1];
      } else {
        return this.mapData[2];
      }
    },
    updateMultipleCells(event, obj, objkey) {
      console.log("1", event, "2", obj, "3", objkey);
      this.active = objkey;
      for (let i = 0; i < this.holdData.length; i++) {
        if (event.target) {
          this.holdData[i][objkey] = event.target.value;
          obj[objkey] = event.target.value;
        } else {
          this.holdData[i][objkey] = event.value;
          obj[objkey] = event.value;
        }
      }
    },
    initials(name) {
      const arr = name.split(" ");
      const str = `${arr[0].charAt(0).toUpperCase()}${arr[1]
        .charAt(0)
        .toUpperCase()}`;
      return str;
    },
    getColor(index) {
      let n = (index * 0xfffff * 1000000).toString(16);
      return "#" + n.slice(0, 6);
    },

    getAvatar(employee) {
      let name = `${employee.firstName} ${employee.lastName}`;
      let initialLetters = this.initials(name);

      let color;
      let alphabet = "abcdefghijklmnopqrstuvwxyz".split("");
      const initialOne = String(
        employee && employee.firstName && employee.firstName.charAt(0)
      );
      const initialTwo = String(
        employee && employee.lastName && employee.lastName.charAt(0)
      );
      const num =
        Number(alphabet.indexOf(initialOne.toLowerCase())) +
        Number(alphabet.indexOf(initialTwo.toLowerCase()));
      color = this.getColor(num);
      // Get color

      return {
        color,
        initialLetters,
      };
    },
    // getAvatar(employee) {
    //   let name = `${employee.firstName} ${employee.lastName}`;
    //   let initialLetters = this.initials(name);
    //   let color;
    //   // Get color
    //   const colors = [
    //     "#D32F2F",
    //     "#189F00",
    //     "#0A244F",
    //     "#E4572E",
    //     "#01AA8C",
    //     "#184EA8",
    //     "#F39C36",
    //     "#4f46e5",
    //   ];

    //   const randomNumber = Math.floor(Math.random() * 5);
    //   color = colors[randomNumber + 1];

    //   return {
    //     color,
    //     initialLetters,
    //   };
    // },
    onRowSelect(event) {
      this.holdData.push(event.data);
    },
    onRowUnselect(event) {
      this.holdData = this.holdData.filter((emp) => {
        return emp.employeeId !== event.data.employeeId;
      });
    },
    bankError(data, field) {
      if (data.bankError) {
        console.log(data.bankError, "RE");

        const bankError = data.bankError;
        if (
          field === "bankName" ||
          field === "bankCode" ||
          field === "accountNumber"
        ) {
          return [
            {
              "border border-error-table w-full h-full text-error-table p-2":
                bankError.length > 0,
            },
          ];
        }
      }
    },
    error(data, field) {
      if (data.reason) {
        console.log(data.reason, "RE");

        const reason = data.reason;

        return [
          {
            "border border-error-table w-full h-full text-error-table p-2":
              reason.includes(field),
          },
        ];
      }
    },
    madatoryClass(data, field) {
      console.log("DA", data);
      return [
        {
          "border border-secondary w-full h-full text-secondary":
            data[field].length === 0,
        },
      ];
    },
    onCellEditComplete(event) {
      this.editInProgress = false;
      let { data, newValue, field } = event;
      console.log(newValue, "NEWS");

      switch (field) {
        case "quantity":
        case "price":
          if (this.isPositiveInteger(newValue)) data[field] = newValue;
          else event.preventDefault();
          break;

        default:
          if (newValue.trim().length > 0) data[field] = newValue;
          else event.preventDefault();
          break;
      }
    },
    isPositiveInteger(val) {
      let str = String(val);
      str = str.trim();
      if (!str) {
        return false;
      }
      str = str.replace(/^0+/, "") || "0";
      var n = Math.floor(Number(str));
      return n !== Infinity && String(n) === str && n >= 0;
    },
    onRowEditSave(event) {
      let { newData, index } = event;

      this.products2[index] = newData;
    },
    getStatusLabel(status) {
      switch (status) {
        case "INSTOCK":
          return "In Stock";

        case "LOWSTOCK":
          return "Low Stock";

        case "OUTOFSTOCK":
          return "Out of Stock";

        default:
          return "NA";
      }
    },
  },
};
</script>

<style scoped></style>
