<template>
  <main class="w-full px-4 min-h-screen">
    <h4
      class="md:mt-3 hidden md:block md:text-sm md:text-left text-xs text-headerText"
    >
      Payroll / Edit Salary
    </h4>
    <div class="md:block hidden text-left my-6">
      <easiBackButton></easiBackButton>
    </div>

    <div
      v-if="!isSearch && allEmployees && !allEmployees.length"
      class="mt-16 mb-4"
    >
      <easiEmptyState
        text=" You have not added any employee yet."
      ></easiEmptyState>
    </div>

    <div v-else class="mt-6 md:mt-10 w-full mx-auto">
      <div
        class="flex md:flex-row flex-col md:justify-between justify-center mx-auto w-full md:gap-y-0 gap-y-5 md:items-center"
      >
        <div
          class="flex flex-wrap md:flex-nowrap md:items-center mt-4 w-full md:w-full justify-between md:justify-center md:mx-2 grow-0 gap-x-5"
        >
          <!-- /////// Search Component ///// -->
          <div class="w-9/12 sm:w-10/12 md:w-full">
            <easiSearch
              useNewclass
              placeholder="Search employee by name"
              v-model="searchText"
              @search="searchFN"
            />
          </div>

          <!-- /////// Filter Component Start /////// -->
          <div class="w-32">
            <SalaryFilter ref="viewSalaryFilterRef" @update="filterFN" />
          </div>
          <div class="hidden md:block">
            <easiButton
              @click="goToBulkEdit"
              size="small"
              color="primary"
              class="flex w-28 md:w-auto items-center justify-center md:justify-between gap-2 md:gap-3 py-3 px-4 md:py-4"
            >
              <span
                class="text-xs md:text-base text-center md:text-left whitespace-nowrap"
                >Bulk Edit</span
              >
            </easiButton>
          </div>
          <!-- /////// Filter Component Ends /////// -->
        </div>
      </div>

      <easiActiveFilter
        class="w-full my-4 md:mx-5"
        @close="removeFilter"
        :filters="activeFilters"
      />
      <div
        v-if="allEmployees && allEmployees.length"
        class="md:mt-9 md:mx-5 flex justify-between sm:justify-start mb-5 md:mb-0 gap-x-3"
      >
        <div
          @click="openProrateModal"
          class="border text-primary border-primary rounded-full flex items-center p-2 gap-x-2 cursor-pointer"
        >
          <img class="hidden md:block" src="@/assets/icons/newProrate.svg" />

          <span class="text-xs md:text-base"> Prorate Salary </span>
        </div>

        <div
          class="border text-primary border-primary rounded-full flex items-center p-2 gap-x-2 cursor-pointer"
          @click="$router.push({ name: 'BonusAndDeduction' })"
        >
          <img class="hidden md:block" src="@/assets/icons/newBonus.svg" />

          <span class="text-xs md:text-base"> Bonus/Deduction </span>
        </div>
        <div class="block md:hidden">
          <easiButton
            @click="goToBulkEdit"
            size="small"
            color="primary"
            class="flex w-28 md:w-auto items-center justify-center md:justify-between gap-2 md:gap-3 py-3 px-4 md:py-4"
          >
            <span
              class="text-xs md:text-base text-center md:text-left whitespace-nowrap"
              >Bulk Edit</span
            >
          </easiButton>
        </div>
        <!-- <div
        @click="generate"
        class="border text-primary border-primary rounded-full flex items-center p-2 space-x-2 cursor-pointer"
      >
      <img src="@/assets/icons/newEdit.svg" />

        <span> Edit Salary </span>
      </div> -->
      </div>
    </div>

    <div v-if="allEmployees && allEmployees.length" class="">
      <easiPayrollTable
        :routable="true"
        :data="transformedData"
        :namesForAllowances="allowanceNames"
        :amountValues="amountValues"
        :frozenFields="['firstName', 'lastName']"
        :highlight="['firstName', 'lastName']"
        :header="headers"
        :useSelect="['department', 'employmentType', 'branch']"
        :hideAllowances="false"
        @selected="updateSelectedEmployees($event)"
      />
    </div>

    <div
      v-if="isSearch && !allEmployees.length"
      class="container mx-auto items-center justify-center w-full sm:w-11/12 md:w-10/12 my-6 grid grid-cols-2 gap-2 mt-12"
    >
      <div class="flex flex-col text-left col-span-2 md:col-span-1">
        <h1 class="text-secondary text-5xl md:text-7xl font-semibold mb-4">
          Oops!
        </h1>
        <div class="text-sm md:text-lg">
          Sorry we could not find a result for your search
        </div>
        <p class="text-xs md:text-sm mt-2">
          Check your spelling or try a different name. Make sure you search by
          <span class="text-secondary font-medium">Name</span>,
          <span class="text-secondary font-medium">Employee ID</span> or
          <span class="text-secondary font-medium">Department</span>
        </p>
      </div>
      <div class="col-span-2 md:col-span-1">
        <img src="@/assets/icons/search_null.svg" alt="" />
      </div>
    </div>

    <!-- <easiPagin
      v-if="payrollData.length"
      :data="payrollData"
      @page:update="updatePage"
      :currentPage="currentPage"
      :pageSize="pageSize"
      class=""
    /> -->

    <easiModal v-if="prorate === true" @close="prorate = false">
      <template v-slot:header>
        <h1 class="capitalize font-bold md:font-medium text-headerText">
          Prorate Salary
        </h1>
      </template>

      <form
        v-show="!prorated"
        @submit.prevent="prorateSalary"
        class="my-5 flex flex-col w-full"
      >
        <div class="flex flex-col items-start px-8">
          <label
            for="startDate"
            class="text-dark-800 text-left text-xs mt-2 font-medium"
            >Select Start Date
          </label>
          <input
            placeholder="Select Start Date"
            required
            v-model="dates.startDate"
            type="date"
            name="startDate"
            class="mt-1 mb-5 px-8 rounded-2xl w-full border border-dark-200"
          />
        </div>
        <hr
          class="mt-2 mb-4 border-0.5 border-dividerColor text-dividerColor"
        />
        <div class="flex flex-col items-start px-8">
          <label
            for="startDate"
            class="text-dark-800 text-left text-xs mt-2 font-medium"
            >Select End Date
          </label>
          <input
            placeholder="Select Start Date"
            required
            :min="dates.startDate"
            v-model="dates.endDate"
            type="date"
            name="startDate"
            class="mt-1 mb-5 px-8 rounded-2xl w-full border border-dark-200"
          />
        </div>

        <div class="flex flex-col w-full items-start px-8">
          <label
            for="weekendWork"
            class="text-dark-800 text-left text-xs mt-2 font-medium"
            >Weekend Work
          </label>
          <easiSelectInput2
            required
            class="mt-1 mb-2 w-full text-left"
            @update="prorateArg.weekendDay = $event"
            :value="prorateArg.weekendDay"
            :options="prorateOption"
          />
        </div>

        <div class="flex flex-col items-start px-8">
          <label
            for="holidays"
            class="text-dark-800 text-left text-xs mt-2 font-medium"
            >Number of Holidays
          </label>
          <easiSelectInput2
            required
            class="mt-1 mb-2 w-full text-left"
            @update="prorateArg.holidays = $event"
            :value="prorateArg.holidays"
            :options="holidaysArray"
            :grid="true"
          />
        </div>

        <div
          class="px-8 mt-4 w-full flex items-center md:flex-row flex-col md:w-full gap-4 justify-center"
        >
          <easiButton
            v-show="
              selectedEmployees &&
              selectedEmployees[0] &&
              selectedEmployees[0].prorate &&
              selectedEmployees[0].prorate.proratedSalary
            "
            variant="outlined"
            class="w-56 hidden"
            :loading="deleteProration"
            @click="deleteProrate(selectedEmployees[0])"
            type="button"
            >Cancel proration</easiButton
          >
          <easiButton class="w-56" :loading="prorateLoading">Submit</easiButton>
        </div>
      </form>

      <div v-show="prorated" class="flex w-full items-center flex-col gap-y-1">
        <span class="text-dark-800 text-sm font-medium">Duration:</span>
        <span class="text-secondary font-medium">{{
          `${formatDateString(dates.startDate)} - ${formatDateString(
            dates.endDate
          )}`
        }}</span>

        <span class="text-dark-800 text-sm font-medium">Amount:</span>
        <span class="text-primary text-2xl font-medium">{{
          proratedSalary
        }}</span>

        <easiButton
          :block="true"
          @click="
            () => {
              prorate = false;
            }
          "
          class="my-6 w-full"
          >Submit</easiButton
        >
      </div>
    </easiModal>

    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span>Salary prorated successfully</span>
      </template>
    </easiSuccess>
  </main>
</template>

<script setup>
import { ref, reactive, onMounted, watch, computed } from "vue";
import { storeToRefs } from "pinia";
import { useDataStore } from "@/stores/data.js";
import { helperFunctions } from "@/composable/helperFunctions";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toastification";

import SalaryFilter from "@/components/Filters/SalaryFilter.vue";

const store = useDataStore();
const { query, mutate } = store;
const { getAllEmployees, checkLoading, listAllowance } = storeToRefs(store);
const { calculatePercentage, formatAmount, formatDateString } = helperFunctions;
const router = useRouter();

const toast = useToast();

const loading = ref(false);
const searchText = ref("");
let isSearch = ref(false);
const filterOptions = ref({
  sort: { firstName: "ASC" },
  filter: [],
});
const activeFilters = ref([]);
const viewSalaryFilterRef = ref(null);

const allEmployees = computed(() =>
  getAllEmployees.value ? getAllEmployees.value.data : []
);
let selectedEmployees = ref([]);
const amountValues = [
  "paye",
  "pension",
  "hmo",
  "lifeInsurance",
  "lifeAssurance",
  "wht",
  "nsitf",
  "itf",
  "gratuity",
  "nhf",
  "businessExpense",
  "grossPay",
  "netPay",
];
const currentPage = ref(0);
const pageSize = ref(15);
let visibleData = ref([]);

const payload = ref({
  paging: {
    limit: 1000,
    skip: 0,
    search: "",
    sort: {
      firstName: "ASC",
    },
  },
  filter: [],
});

watch(searchText, async (val) => {
  if (val.trim() === "") {
    payload.value.paging.search = "";
    await queryEmployees();
    isSearch.value = false;
  }
});
const allAllowanceData = computed(() => store.listAllowance);

function transform(arr) {
  const newArr = arr.map((obj) => {
    let data = {
      // id: obj.job.userName,
      employeeId: obj._id,
      firstName: obj.profile.firstName,
      lastName: obj.profile.lastName,
      picture: obj.profile.pfp,

      grossPay: obj.staffLevel ? Number(obj.staffLevel.grossAmount) : 0,
      netPay:
        obj.staffLevel &&
        obj.prorate &&
        obj.prorate.staffLevel &&
        Number(obj.prorate.staffLevel.netAmount) > 0
          ? Number(obj.prorate.staffLevel.netAmount)
          : obj.staffLevel
          ? Number(obj.staffLevel.netAmount)
          : 0,
      accountName:
        obj.payment && obj.payment.accountName
          ? obj.payment.accountName
          : "N/A",
      accountNumber:
        obj.payment && obj.payment.accountNumber
          ? obj.payment.accountNumber
          : "N/A",
      bankName:
        obj.payment && obj.payment.bankName ? obj.payment.bankName : "N/A",
      //   department: obj.job.departmentId,
      //   employmentType: obj.job.employmentType,
      //   branch: obj.job.branchId,
      usePercentage: obj.staffLevel && obj.staffLevel.usePercentageForAllowance,
      staffLevelId: obj.staffLevel && obj.staffLevel._id,
      // deductions: obj.staffLevel && obj.staffLevel.benefits,
      allowanceDetails: obj.staffLevel && obj.staffLevel.allowanceDetails,
      prorate: obj.prorate && obj.prorate.proratedSalary ? obj.prorate : {},

      // itf: obj.staffLevel.itf ? obj.staffLevel.itf.amount : 0,
      nhf: obj.staffLevel.nhf ? obj.staffLevel.nhf.amount : 0,
      hmo: obj.staffLevel.hmo ? obj.staffLevel.hmo.amount : 0,
      employeePension: obj.staffLevel.pension
        ? obj.staffLevel.pension.employeePensionContribution
        : 0,
      employerPension: obj.staffLevel.pension
        ? obj.staffLevel.pension.employerPensionContribution
        : 0,
      paye: obj.staffLevel.paye ? obj.staffLevel.paye.amount : 0,
      lifeAssurance: obj.staffLevel.lifeAssurance,
      //   ? obj.staffLevel.lifeAssurance.amount
      //   : 0,
      // lifeInsurance: obj.staffLevel.lifeInsurance
      //   ? obj.staffLevel.lifeInsurance.amount
      //   : 0,
      // wht: obj.staffLevel.wht ? obj.staffLevel.wht.amount : 0,
      // nsitf: obj.staffLevel.nsitf ? obj.staffLevel.nsitf.amount : 0,
      // gratuity: obj.staffLevel.gratuity ? obj.staffLevel.gratuity.amount : 0,
      // businessExpense: obj.staffLevel.businessExpense
      //   ? obj.staffLevel.businessExpense.amount
      //   : 0,
    };

    obj.bonusOrDeductions.forEach((item) => {
      data[`${item.name.toLowerCase()}${item.type}`] = item.percentage
        ? formatAmount(calculatePercentage(data.grossPay, item.percentage))
        : formatAmount(item.amount);
    });
    return data;
  });

  return newArr;
}
const allowanceNames = ref([]);
function getAllowanceNameById(id) {
  let allName = "";
  if (
    allAllowanceData.value &&
    allAllowanceData.value.data &&
    allAllowanceData.value.data.length
  ) {
    for (let data of allAllowanceData.value.data) {
      // allowanceNames.value.push(data.name);
      if (data._id === id) {
        allName = data.name;
        return allName;
      }
    }
  }
  return allName;
}
function getAllowanceNames() {
  const names =
    allAllowanceData.value &&
    allAllowanceData.value.data &&
    allAllowanceData.value.data.map((n) => n.name);
  return names;
}
allowanceNames.value = getAllowanceNames();

function createCustomAllowanceDetails() {
  const employees = allEmployees.value;
  for (let emp of employees) {
    for (let aw of allAllowanceData.value.data) {
      if (
        emp.staffLevel &&
        emp.staffLevel.allowanceDetails.allowanceId !== aw._id
      ) {
        emp.staffLevel.allowanceDetails.push({
          _id: null,
          allowanceId: aw._id,
          amount: 0,
          percentage: 0,
        });
      }
    }
  }
  console.log(employees, "HEY");
}
// createCustomAllowanceDetails();

function extractAllowance(arr) {
  const allowance = arr.map((obj) => {
    const details =
      obj.staffLevel &&
      obj.staffLevel.allowanceDetails.map((ad) => {
        return {
          amount: ad.amount,
          percentage: ad.percentage,
          usePercentage: obj.staffLevel.usePercentageForAllowance,
          name: getAllowanceNameById(ad.allowanceId),
        };
      });

    return details;
  });

  return allowance;
}
const allowance = ref([]);
allowance.value = extractAllowance(allEmployees.value);

function finalVal() {
  const fna =
    allowance.value &&
    allowance.value.map((item, index) => {
      const info =
        item &&
        item.reduce(
          (acc, cur) => ({ ...acc, [cur.name]: cur.percentage || cur.amount }),
          {}
        );
      return info;
    });
  return fna;
}
const datum = ref([]);
datum.value = finalVal();
const transformedData = ref([]);
transformedData.value = transform(allEmployees.value);

const headers = computed(() => {
  if (payrollData.value.length > 0) {
    let headArr = [];
    const head = Object.keys(payrollData.value[0]);
    for (let i of head) {
      headArr.push({
        field: i,
        header: i,
      });
    }
    return headArr;
  }
});

const searchFN = async (suggested) => {
  // console.log(suggested, searchText.value);
  searchText.value = suggested ? suggested : searchText.value;
  isSearch.value = true;
  payload.value.paging.search = searchText.value;
  await queryEmployees();
};

const filterFN = async ({ filter, active }) => {
  isSearch.value = true;
  filterOptions.value = filter;

  payload.value.paging.sort = filterOptions.value.sort;
  payload.value.filter = filterOptions.value.filter;

  console.log("Payload");
  log(payload.value);
  await queryEmployees();

  activeFilters.value = active;
};

const removeFilter = (arg) => {
  console.log(viewSalaryFilterRef.value);
  if (viewSalaryFilterRef.value && viewSalaryFilterRef.value.removeFilter) {
    viewSalaryFilterRef.value.removeFilter(arg);
  }
};

const queryEmployees = async () => {
  loading.value = true;
  try {
    await query({
      endpoint: "ListEmployees",
      payload: payload.value,
      service: "EMP",
      storeKey: "listEmployees",
    });

    loading.value = false;
    console.log("Employees");
    log(getAllEmployees.value.data);
  } catch (err) {
    loading.value = false;
    console.log(err);
  }
};

function updateSelectedEmployees(arg) {
  selectedEmployees.value = [];
  selectedEmployees.value = arg;
}

function empty() {
  getAllEmployees.value.data = [];
  console.log(getAllEmployees.value.data);
}
// Get rid of the proxy when console.log
function log(arg) {
  console.log(JSON.parse(JSON.stringify(arg)));
}
function cross() {
  const employee = transformedData.value;
  let newTransformed = [];
  if (allowanceNames.value) {
    const fresh = employee.map((emp, index) => {
      const fin = datum.value.map((du, i) => {
        if (index === i) {
          const merged = Object.assign({}, emp, du);
          newTransformed.push(merged);
          return merged;
        }
      });
    });
    for (let all of newTransformed) {
      for (let k of allowanceNames.value) {
        if (!all[k]) {
          all[k] = 0;
        }
      }
    }
    console.log(newTransformed, "NEW");
  }
  return newTransformed;
}
const payrollData = ref([]);
payrollData.value = cross();
function goToBulkEdit() {
  store.$patch({
    payrollData: payrollData.value,
    allowanceNames: allowanceNames.value,
  });
  router.push({ name: "SalaryBulkEdit" });
}
const updatePage = (pageNumber) => {
  currentPage.value = pageNumber;
  updateVisibleData();
};
const updateVisibleData = () => {
  let datas = transformedData.value;
  visibleData.value = datas.slice(
    currentPage.value * pageSize.value,
    currentPage.value * pageSize.value + pageSize.value
  );

  // if we have 0 visible todos, go back a page
  if (visibleData.value.length == 0 && currentPage.value > 0) {
    updatePage(currentPage.value - 1);
  }
};
watch(allEmployees, (val) => {
  transformedData.value = transform(val);
  // console.log("UPDATED", transformedData.value);

  allowance.value = extractAllowance(val);
  datum.value = finalVal();
  payrollData.value = cross();

  // updateVisibleData();
});

onMounted(async () => {
  await queryEmployees();
  // updateVisibleData();
});

//Prorate Logic
const updateSuccess = ref(false);
const dates = ref({
  startDate: "",
  endDate: "",
});
const prorateArg = ref({
  employeeId: "",
  month: 0,
  year: 0,
  days: 0,
  weekendDay: "BOTH",
  holidays: 0,
});

const today = ref("");
const holidaysArray = ref([]);
const prorate = ref(false);
const prorateLoading = ref(false);
const prorated = ref(false);
const proratedSalary = ref(0);
const prorateOption = ref([
  {
    label: "NONE",
    value: "NONE",
  },
  {
    label: "Saturday",
    value: "SATURDAY",
  },
  {
    label: "Sunday",
    value: "SUNDAY",
  },
  {
    label: "Saturday and Sunday",
    value: "BOTH",
  },
]);

function openProrateModal() {
  selectedEmployees.value.length != 1
    ? toast.error("You can only prorate one (1) employee's salary at a time.")
    : (prorate.value = true);
}
function getNumOfDays(start, end) {
  const startDate = new Date(start);
  const endDate = new Date(end);

  // Calculate the time difference in milliseconds
  const timeDiff = endDate - startDate;

  // Convert milliseconds to days
  const daysDiff = timeDiff / (1000 * 60 * 60 * 24);
  return daysDiff;
}

function calculateProration() {
  prorateArg.value.employeeId = selectedEmployees.value[0].employeeId;
  let { startDate, endDate } = dates.value;
  console.log(startDate, endDate);
  const dateArr = startDate.split("-");
  const start = Number(startDate.slice(8));
  const end = Number(endDate.slice(8));
  const month = Number(startDate.slice(5, 7)) - 1;
  const year = Number(startDate.slice(0, 4));
  // const days = Math.abs(start - end);
  const days = getNumOfDays(startDate, endDate);

  prorateArg.value.month = month;
  prorateArg.value.year = year;
  prorateArg.value.days = days;
  const input = {
    month,
    days,
    year,
    holidays: prorateArg.value.holidays,
    holidays: prorateArg.value.holidays,
    weekendDay: prorateArg.value.weekendDay,
  };
}

async function prorateSalary() {
  calculateProration();

  try {
    prorateLoading.value = true;
    let res = await mutate({
      endpoint: "Prorate",
      data: {
        input: prorateArg.value,
      },
      service: "PAYROLL",
    });
    console.log(res);
    if (res && res._id) {
      proratedSalary.value = formatAmount(res.proratedSalary);
      prorated.value = true;
      prorateLoading.value = false;
      await queryEmployees();
      updateSuccess.value = true;

      setTimeout(() => {
        updateSuccess.value = false;
        window.location.reload();
      }, 1000);
    }
  } catch (e) {
    prorateLoading.value = false;
    console.log(e);
  } finally {
    prorateLoading.value = false;
  }
}

const deleteProration = ref(false);
async function deleteProrate(data) {
  const { employeeId, prorate } = data;

  try {
    deleteProration.value = true;
    let res = await mutate({
      endpoint: "DeleteProrate",
      data: {
        input: {
          employeeIds: [employeeId],
          year: prorate.year,
          month: prorate.month,
        },
      },
      service: "PAYROLL",
    });
    console.log(res);
    if (res) {
      await queryEmployees();
      prorate.value = false;
    }
  } catch (e) {
    deleteProration.value = false;
    console.log(e);
  } finally {
    deleteProration.value = false;
  }
}

function populateHolidayArray() {
  for (let i = 1; i <= 31; i++) {
    holidaysArray.value.push({
      label: i < 10 ? `0${i}` : `${i}`,
      value: i,
    });
  }
}
populateHolidayArray();
</script>

<style></style>
