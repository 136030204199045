<template>
  <main class="mx-1 md:p-4 md:px-10">
    <h4
      class="mt-3 md:text-sm md:text-left text-right text-xs font-medium text-dark-800"
    >
      Compliance / Add Compliance
    </h4>
    <div class="md:block hidden text-left my-6">
      <easiBackButton></easiBackButton>
    </div>

    <div
      class="text-center md:text-left md:mt-9 font-medium items-center md:items-start mt-6 m text-sm flex flex-col gap-x-9"
    >
      <p class="text-dark-800 font-bold text-lg md:text-xl">Add Compliance</p>
    </div>

    <div
      class="md:w-1/2 w-full py-3 md:mx-auto bg-white rounded-xl mt-5 md:mt-10 mb-12 relative text-left border border-boxBorder shadow-lg"
    >
      <h1 class="text-center mt-4 text-dark-800 text-lg md:text-2xl font-bold">
        Please Select Compliance Type
      </h1>

      <form
        @submit.prevent="openModal = true"
        class="w-full mt-8 mb-12 px-4 md:px-12"
      >
        <label class="text-grey font-medium text-sm" for="compliiance"
          >Select Compliance</label
        >
        <easiSelectInput
          class="capitalize"
          @update="activateModal($event)"
          :value="activeModal"
          :options="[
            'PAYE',
            'pension',
            'HMO',
            // 'life Insurance',
            'life Assurance',
            'NSITF',
            'ITF',
            'NHF',
            'WHT',
            // 'gratuity',
            // 'business Expense',
          ]"
        >
        </easiSelectInput>

        <easiButton :block="true" class="w-full mt-9">Submit</easiButton>
      </form>
    </div>

    <easiModal :compliance="true" v-if="openModal" @close="closeAll">
      <template v-slot:header
        ><h1 class="font-bold text-lg md:text-xl uppercase">
          {{ activeModal }}
        </h1></template
      >

      <PayeComponent
        @registered="closeAll"
        type="add"
        v-if="activeModal === 'PAYE'"
      />
      <HmoComponent
        @registered="closeAll"
        type="add"
        v-if="activeModal === 'HMO'"
      />
      <PensionComponent
        @registered="closeAll"
        type="add"
        v-if="activeModal === 'pension'"
      />
      <NhfComponent
        @registered="closeAll"
        type="add"
        v-if="activeModal === 'NHF'"
      />
      <ItfComponent
        @registered="closeAll"
        type="add"
        v-if="activeModal === 'ITF'"
      />
      <NsitfComponent
        @registered="closeAll"
        type="add"
        v-if="activeModal === 'NSITF'"
      />
      <LifeInsuranceComponent
        @registered="closeAll"
        type="add"
        v-if="activeModal === 'life Insurance'"
      />
      <LifeAssuranceComponent
        @registered="closeAll"
        type="add"
        v-if="activeModal === 'life Assurance'"
      />
      <WhtComponent
        @registered="closeAll"
        type="add"
        v-if="activeModal === 'WHT'"
      />
      <BusinessExpenseComponent
        @registered="closeAll"
        type="add"
        v-if="activeModal === 'business Expense'"
      />
      <GratuityComponent
        @registered="closeAll"
        type="add"
        v-if="activeModal === 'gratuity'"
      />
    </easiModal>
  </main>
</template>

<script setup>
import { useRoute } from "vue-router";
import { ref, reactive, nextTick, watch, computed } from "vue";
import { useToast } from "vue-toastification";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";
import PayeComponent from "@/components/Compliance/Add/Paye.vue";
import HmoComponent from "@/components/Compliance/Add/Hmo.vue";
import PensionComponent from "@/components/Compliance/Add/Pension.vue";
import NhfComponent from "@/components/Compliance/Add/Nhf.vue";
import ItfComponent from "@/components/Compliance/Add/Itf.vue";
import WhtComponent from "@/components/Compliance/Add/Wht.vue";
import BusinessExpenseComponent from "@/components/Compliance/Add/BusinessExpense.vue";
import GratuityComponent from "@/components/Compliance/Add/Gratuity.vue";
import NsitfComponent from "@/components/Compliance/Add/Nsitf.vue";
import LifeInsuranceComponent from "@/components/Compliance/Add/LifeInsurance.vue";
import LifeAssuranceComponent from "@/components/Compliance/Add/LifeAssurance.vue";

const store = useDataStore();
const { query, mutate } = store;
const { listEmployees, getAllEmployees } = storeToRefs(store);
const toast = useToast();
const { resetObj } = helperFunctions;

const route = useRoute();

const compliance = ref("");
const activeModal = ref("");
const openModal = ref(false);
function activateModal(e) {
  activeModal.value = e;
}
function closeAll() {
  openModal.value = false;
  activeModal.value = "";
}
</script>

<style></style>
