<template>
  <main
    class="w-full text-left bg-white rounded-2xl shadow-lg p-4 md:py-6 md:px-8"
  >
    <div class="">
      <h2 class="text-2xl font-bold rounded-t-2xl">
        Monthly Average Payroll (Gender)
      </h2>
    </div>

    <div
      class="w-ful flex justify-end items-center text-xs gap-4 md:gap-8 mt-8 mb-2 px-4 md:px-8"
    >
      <span>
        <i :style="`color: ${male.colour}`" class="fas fa-circle mr-2"></i>
        Male
      </span>
      <span>
        <i :style="`color: ${female.colour}}`" class="fas fa-circle mr-2"></i>
        Female 
      </span>
    </div>

    <div class="flex-auto">
      <div class="relative h-350-px">
        <canvas id="bar-chart-gender"></canvas>
      </div>
    </div>

    <div class="my-6 flex items-center flex-wrap text-xs">
      <div
        v-for="(item, i) in payrollMonthly"
        :key="i"
        class="flex items-center p-2"
      >
        <div
          v-if="item.show"
          class="transform -rotate-90 border-b-2 border-blue-100 text-xs uppercase"
        >
          {{ months[item.month] }}
        </div>
        <div v-if="item.show" class="flex flex-col">
          <span>M - {{ formatAmount(item.M) }}</span>
          <span>F - {{ formatAmount(item.F) }}</span>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import { ref, reactive, onMounted, watch, computed, nextTick } from "vue";
import { storeToRefs } from "pinia";
import { useDataStore } from "@/stores/data.js";
import { helperFunctions } from "@/composable/helperFunctions";
import { useToast } from "vue-toastification";

const { formatAmount, calculatePercentage, getAvatar } = helperFunctions;
const toast = useToast();
const store = useDataStore();
const { mutate, query } = useDataStore();

const payrollAnalysis = computed(() => store.getPayrollAnalysis);

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];


const male = computed(() => {
  return (
    payrollAnalysis.value &&
    payrollAnalysis.value.byGender.find((el) => el._id === "MALE")
  );
});
const female = computed(() => {
  return (
    payrollAnalysis.value &&
    payrollAnalysis.value.byGender.find((el) => el._id === "FEMALE")
  );
});

const getItemByGender = (arr, opt) => {
  return arr.find((el) => {
    if (el.gender === opt) {
      return el;
    }
  });
};

const payrollMonthly = computed(() => {
  const arr =
    payrollAnalysis.value &&
    payrollAnalysis.value.monthlyPayrollByGender.map((el) => {
      return {
        month: el._id && el._id.month,
        F: getItemByGender(el.details, "FEMALE")
          ? getItemByGender(el.details, "FEMALE").count
          : 0,
        M: getItemByGender(el.details, "MALE")
          ? getItemByGender(el.details, "MALE").count
          : 0,
      };
    });

  return months.map((ol, index) => {
    const matchedMonth = arr.find((el) => {
      if (index === parseInt(el.month)) {
        el.show = true;
        return el;
      }
    });

    return matchedMonth
      ? matchedMonth
      : {
          month: index,
          F: 0,
          M: 0,
          show: false,
        };
  });
});

onMounted(() => {
  runChart();
});

const config = computed(() => {
  return {
    type: "bar",
    data: {
      labels: months,
      datasets: [
        {
          label: "Male",
          backgroundColor: "#023C98",
          data:
            payrollMonthly.value &&
            payrollMonthly.value.map((el) => {
              return el.M;
            }),
          fill: false,
        },
        {
          label: "Femalee",
          fill: false,
          backgroundColor: "#E4572E",

          data:
            payrollMonthly.value &&
            payrollMonthly.value.map((el) => {
              return el.F;
            }),
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      responsive: true,
      title: {
        display: false,
        text: "Orders Chart",
      },
      tooltips: {
        mode: "index",
        intersect: false,
      },
      hover: {
        mode: "nearest",
        intersect: true,
      },
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            display: true,
            stacked: true,
            gridLines: {
              color: "#E0A409",
              zeroLineColor: "#E0A409",
            },
          },
        ],
        yAxes: [
          {
            display: true,

            gridLines: {
              color: "#E0A409",
              zeroLineColor: "#E0A409",
            },
            ticks: {
              // Include a dollar sign in the ticks
              callback: function (value, index, ticks) {
                return `${value}M`;
              },
            },
          },
        ],
      },
    },
  };
});

console.log("AVERAGE GENDER", config.value);
const runChart = () => {
  nextTick(function () {
    var ctx = document.getElementById("bar-chart-gender").getContext("2d");
    // ctx.fillText(data[0].value + "%", width/2 - 20, width/2, 200);
    window.myDoughnut = new Chart(ctx, config.value);
  });
};
</script>

<style></style>
