export const prorateNet = {
  methods: {
    truncateAmount(amount) {
      try {
        // Truncate finalAmount to 2 decimal places without rounding up
        if (amount === undefined || amount === null) {
          return 0;
        }
        const truncatedAmount = Math.trunc(amount * 100) / 100;
        return truncatedAmount;
      } catch (error) {
        console.error("error:", error);
        return 0;
      }
    },
    prorateNet(input) {
      let { month, year, days, weekendDay, holidays, netAmount } = input;

      if (month > 11) {
        //use MIKE
        throw new Error("Month must be between 0 and 11");
      }

      let workingDays = this.subtractWeekendsAndExtraDaysFromWorkingDays(
        month,
        year,
        holidays,
        weekendDay
      );

      let dailySalary = netAmount / workingDays;
      let proratedSalary = dailySalary * days;

      return proratedSalary;
    },
    calculateDaysinMonth(month, year) {
      let date = new Date(year, month, 1);
      let days = 0;
      while (date.getMonth() === month) {
        days++;
        date.setDate(date.getDate() + 1);
      }
      return days;
    },
    calculateWeekends(month, year) {
      let weekends =
        this.calculateSaturdays(month, year) +
        this.calculateSundays(month, year);
      return weekends;
    },
    calculateWorkingDays(month, year) {
      let workingDays =
        this.calculateDaysinMonth(month, year) -
        this.calculateWeekends(month, year);
      return workingDays;
    },
    calculateSundays(month, year) {
      let sundays = 0;
      let date = new Date(year, month, 1);
      while (date.getMonth() === month) {
        if (date.getDay() === 0) {
          sundays++;
        }
        date.setDate(date.getDate() + 1);
      }
      return sundays;
    },
    calculateSaturdays(month, year) {
      let saturdays = 0;
      let date = new Date(year, month, 1);
      while (date.getMonth() === month) {
        if (date.getDay() === 6) {
          saturdays++;
        }
        date.setDate(date.getDate() + 1);
      }
      return saturdays;
    },
    subtractWeekendsAndExtraDaysFromWorkingDays(
      month,
      year,
      holidays,
      weekendDay
    ) {
      let workingDays = this.calculateWorkingDays(month, year);
      console.log(workingDays);
      let AdditionalWeekends = 0;
      if (weekendDay === "SATURDAY") {
        AdditionalWeekends = this.calculateSaturdays(month, year);
      } else if (weekendDay === "SUNDAY") {
        AdditionalWeekends = this.calculateSundays(month, year);
      } else if (weekendDay === "BOTH") {
        AdditionalWeekends = this.calculateWeekends(month, year);
      }
      let newWorkingDays = workingDays + AdditionalWeekends - holidays;
      return newWorkingDays;
    },
  },
};
