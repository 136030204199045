<template>
  <main>
    <div class="relative">
      <div
        @click="toggleFilter = true"
        class="border border-primary rounded-2xl p-4 cursor-pointer flex items-center gap-2 bg-white w-fit"
        :class="bonus ? 'rounded-l-none' : ''"
      >
        <img
          :class="bonus ? 'md:hidden' : 'flex'"
          class="w-6 md:w-4"
          src="@/assets/icons/newFilter.svg"
          alt=""
        />
        <span class="text-primary font-medium hidden md:flex items-center gap-3"
          >{{ activeFilter || "Filter" }}
          <i v-show="bonus" class="fas fa-angle-right"></i
        ></span>
      </div>
      <div
        v-if="toggleFilter"
        class="absolute h-auto w-52 right-0 top-16 shadow-lg bg-white flex flex-col text-sm py-2"
        style="z-index: 2"
      >
        <div
          v-for="option in filterOptions"
          :key="option.name"
          @click="filterFunc(option)"
        >
          <div
            class="flex items-center gap-3 cursor-pointer px-4 py-2 hover:bg-mint"
          >
            <span>
              <img
                v-if="option.active"
                class="w-4"
                src="../../assets/icons/check3-active.svg"
                alt=""
              />
              <img
                v-else
                class="w-4"
                src="../../assets/icons/check3-null.svg"
                alt=""
              />
            </span>
            <span>{{ option.name }}</span>
          </div>
        </div>

        <div
          @mouseover="customFilter.active = true"
          class="relative group flex px-4 py-2 cursor-pointer hover:bg-mint"
        >
          Custom Date Range
          <div
            v-show="customFilter.active"
            :class="dropdownStyle"
            class="w-48 group-hover:text-dark-800 absolute -left-48 flex flex-col rounded-b-xl bg-white shadow-lg"
          >
            <div class="flex flex-col gap-2 pt-4">
              <div class="flex flex-col items-start px-4">
                <label for="startDate">Start Date</label>
                <input
                  v-model="customFilter.startDate"
                  class="focus:border-primary focus:ring-primary border-dark-200 bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border rounded-sm py-2 px-4 block appearance-none rounded-lg my-1"
                  type="month"
                  name="received"
                  id="received"
                />
              </div>
              <div class="flex flex-col items-start px-4">
                <label for="endDate">End Date</label>
                <input
                  v-model="customFilter.endDate"
                  class="focus:border-primary focus:ring-primary border-dark-200 bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border rounded-sm py-2 px-4 block appearance-none rounded-lg my-1"
                  type="month"
                  name="received"
                  id="received"
                />
              </div>
              <button
                @click="customFunction"
                class="text-white text-sm font-medium focus:outline-none bg-primary rounded-b-xl w-full p-2 mt-2"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      @click="toggleFilter = false"
      :class="!toggleFilter ? 'pointer-events-none' : ''"
      class="bg-transparent fixed inset-0"
    ></div>
  </main>
</template>

<script>
import { ref, reactive } from "vue";

export default {
  props: {
    bonus: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["update"],

  data() {
    return {
      dropdownStyle: "top-0",
    };
  },

  setup(props, { emit }) {
    const payload = reactive({
      endMonth: null,
      startMonth: null,
      year: null,
      endYear: null,
      isSearch: true,
    });

    let months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    let currentYear = new Date().getFullYear();
    const activeFilter = ref("");
    const toggleFilter = ref(false);

    const filterOptions = reactive([
      {
        name: "All",
        value: "all",
        active: true,
      },
      {
        name: "Last Month",
        value: "last",
        active: false,
      },
      {
        name: "3 Months",
        value: "3",
        active: false,
      },
      {
        name: "6 Months",
        value: "6",
        active: false,
      },
      {
        name: "Last Year",
        value: "year",
        active: false,
      },
    ]);

    const customFilter = reactive({
      startDate: null,
      endDate: null,
      active: false,
    });

    // Functions
    const filterFunc = (arg, remove = false) => {
      const date = new Date();

      toggleActive();
      resetPayload();
      payload.isSearch = true;
      if (arg.value === "all") {
        resetPayload();
        payload.isSearch = false; //this is turn off filter and search
      } else if (arg.value === "last") {
        payload.endMonth = date.getMonth() - 1;
        payload.startMonth = date.getMonth() - 1;
        payload.year = date.getFullYear();
        payload.endYear = date.getFullYear();
      } else if (arg.value === "3") {
        payload.endMonth = date.getMonth();
        payload.startMonth = date.getMonth() - 2;
        payload.year = date.getFullYear();
        payload.endYear = date.getFullYear();
      } else if (arg.value === "6") {
        payload.endMonth = date.getMonth();
        payload.startMonth = date.getMonth() - 5;
        payload.year = date.getFullYear();
        payload.endYear = date.getFullYear();
      } else if (arg.value === "year") {
        payload.endMonth = 11;
        payload.startMonth = 0;
        payload.year = date.getFullYear() - 1;
        payload.endYear = date.getFullYear() - 1;
      }

      //Set Active
      //Set the date
      //Call the endpoint

      function toggleActive() {
        //Uncheck all
        filterOptions.forEach((el) => {
          el.active = false;
        });

        //Check the selected
        const index = filterOptions.map((el) => el.value).indexOf(arg.value);
        filterOptions[index].active = !filterOptions[index].active;

        return filterOptions[index].active;
      }

      emit("update", {
        filter: payload,
        active: remove ? [] : arg.name !== "All" ? [arg.name] : [],
      });
      toggleFilter.value = false; //Close Filter
      console.log("Filter");
      console.log(payload);
    };

    function resetPayload() {
      payload.endMonth = null;
      payload.startMonth = null;
      payload.year = null;
      payload.endYear = null;
    }

    function customFunction() {
      const obj = {
        startMonth: parseInt(customFilter.startDate.split("-")[1] - 1),
        year: parseInt(customFilter.startDate.split("-")[0]),
        endMonth: parseInt(customFilter.endDate.split("-")[1] - 1),
        endYear: parseInt(customFilter.endDate.split("-")[0]),
        isSearch: true,
      };

      customFilter.active = false;

      emit("update", { filter: obj, all: false });
      toggleFilter.value = false; //Close Filter
      console.log("Filter");
      console.log(obj);
    }

    const removeFilter = (arg) => {
      filterFunc(filterOptions[0], true);
      filterOptions.forEach((el) => {
        el.active = false;
      });
      activeFilter.value = "";
    };

    return {
      toggleFilter,
      activeFilter,
      customFilter,
      customFunction,
      filterOptions,
      filterFunc,
      removeFilter,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.checkDropdownPosition);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.checkDropdownPosition);
  },

  methods: {
    checkDropdownPosition() {
      let dropdown = this.$el;
      let windowHeight = window.innerHeight;
      let dropdownRect = dropdown.getBoundingClientRect();
      let dropdownHeight = dropdownRect.height;
      let dropdownTop = dropdownRect.top;
      let dropdownBottom = dropdownRect.bottom;

      // console.log("BOTTOM", dropdownBottom);
      // console.log("Window Hieght", windowHeight);

      if (dropdownTop < 0) {
        this.dropdownStyle = "top-0";
      } else if (dropdownBottom > 300 && dropdownBottom < 400) {
        this.dropdownStyle = "-bottom-2";
      } else {
        this.dropdownStyle = "top-0";
      }
    },
  },
};
</script>

<style></style>
