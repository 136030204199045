<template>
  <main v-if="pendingLaunch" class="px-2 sm:px-4 py-1 md:min-h-screen">
    <ComingSoon :details="launchDetails"></ComingSoon>
  </main>
  <main v-else class="w-full">
    <div
      class="flex mt-4 flex-row-reverse items-center justify-between sm:flex-col sm:items-start mb-6 sm:mb-0"
    >
      <h4
        class="md:mt-3 md:text-sm md:text-left text-xs font-medium text-dark-600 sm:text-headerText capitalize sm:uppercase"
      >
        Payroll / Payroll Report
      </h4>
      <div class="text-left sm:mt-4">
        <easiBackButton></easiBackButton>
      </div>
    </div>
    <div
      class="onprint bg-blueGray-100 md:ml-8 p-3 md:p-0 mx-auto md:mx-0 h-full overflow-x-hidden w-full min-h-screen"
    >
      <div
        class="flex flex-row-reverse justify-between md:items-start items-center md:flex-col"
      >
        <div
          v-if="
            listPayrollHistory.dataByYear &&
            !listPayrollHistory.dataByYear.length
          "
          class="no-printable mt-16 mb-4 w-full"
        >
          <div v-if="loading" class="pt-4 w-full">
            <easiLoader />
          </div>
          <div v-else>
            <img
              src="@/assets/img/payroll-empty.png"
              class="w-8/12 md:w-96 mx-auto"
              alt=""
            />
            <h2 class="text-xl -mt-2 text-center md:text-2xl font-medium">
              Still empty! You have not run any payroll yet
            </h2>
            <div class="flex justify-center">
              <easiButton
                @click="$router.push({ name: 'PaySalary' })"
                class="w-8/12 sm:w-60 mt-5"
              >
                Run Payroll
              </easiButton>
            </div>
          </div>
        </div>

        <div class="w-full h-full mb-32" v-else>
          <div v-if="!isViewReport">
            <div class="flex flex-wrap my-10">
              <div class="text-left font-bold text-dark-800 px-4">
                <h2 class="text-2xl capitalize">Generate Payroll Report</h2>
                <p class="text-base mt-2 font-medium">
                  For data security reasons, you will be required to input an
                  OTP or set up 2FA to access your payroll report.
                </p>
              </div>
            </div>

            <div class="block md:hidden w-90 mx-auto">
              <img src="@/assets/img/empty-report.png" alt="" />
            </div>
            <div
              class="gradient relative rounded-2xl md:rounded-l-2xl md:-mr-16 p-4 md:p-6 px-8 flex items-center mt-10 md:mt-20"
            >
              <div class="w-full md:w-min">
                <!-- <easiButton
                @click="selectDuration = true"
                variant="outlined"
                color="white"
                block
                class="font-bold text-lg md:text-xl"
                >Generate Report</easiButton
              > -->
                <div
                  class="w-full border border-white px-10 py-3 font-bold text-3xl text-white whitespace-nowrap rounded-lg cursor-pointer"
                  @click="selectDuration = true"
                >
                  Generate Report
                </div>

                <div class="hidden md:block absolute w-90 md:left-80 -top-24">
                  <img src="@/assets/img/empty-report.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <main v-else class="mt-5">
            <!-- <div
            @click="$router.go(-1)"
            class="no-printable cursor-pointer text-left md:mt-5 font-bold text-xl flex items-center gap-2 text-2xl"
          >
            <button
              class="text-left text-dark-800 font-medium focus:outline-none"
            >
              <i class="fas fa-angle-left mr-3 text-left cursor-pointer"></i>
            </button>
            <span class="text-base text-dark-800"> Back</span>
          </div> -->

            <div class="flex flex-col mt-4 md:mt-0 w-full gap-10">
              <div
                class="no-printable flex flex-col gap-4 w-full md:justify-start"
              >
                <div
                  class="flex flex-row text-dark-800 justify-between text-left w-full justify-self-start self-start md:gap-x-24"
                >
                  <h3
                    class="font-black text-3xl whitespace-nowrap hidden md:block text-grey"
                  >
                    Payroll Report
                  </h3>

                  <h3 class="font-black text-2xl w-full md:hidden text-grey">
                    Report
                  </h3>

                  <div
                    class="no-printable w-full flex justify-end md:justify-start items-center gap-2 md:gap-4"
                  >
                    <easiButton
                      @click="generate"
                      :loading="isDownloading"
                      variant="outlined"
                      class="rounded-md md:justify-center flex items-center md:gap-2 w-full"
                      size="small"
                    >
                      <span class="hidden md:block"> Download</span>
                      <i :class="'pi pi-download'" />
                    </easiButton>
                    <easiButton
                      @click="shareLink = true"
                      variant="outlined"
                      class="rounded-md md:justify-center flex items-center gap-2 w-full"
                      size="small"
                    >
                      <span class="hidden md:block"> Share</span>
                      <i :class="'pi pi-share-alt'" />
                    </easiButton>
                    <easiButton
                      @click="printPage"
                      :loading="isPrinting"
                      variant="outlined"
                      class="rounded-md md:justify-center flex items-center gap-2 w-full"
                      size="small"
                    >
                      <span class="hidden md:block"> Print</span>
                      <i :class="'pi pi-print'" />
                    </easiButton>
                  </div>
                  <easiButton
                    @click="queryDetailPayrollReport"
                    class="rounded-sm hidden md:block mr-24"
                    :loading="loadingDetailReport"
                  >
                    <span class="hidden md:block"> View Detailed Report</span>
                  </easiButton>
                </div>
                <p
                  class="cursor-pointer text-sm text-left md:w-3/5 text-grey font-medium"
                >
                  You must advise Eazipay of any discrepancies observed in this
                  report within 15 days of receipt, otherwise it will be deemed
                  correct. All products are subject to terms and conditions.
                  <br />
                  <span
                    @click="openChat"
                    class="text-info md:text-primary font-medium"
                  >
                    Customer care</span
                  >
                </p>
                <!-- <div class="w-full">
                <easiButton
                  @click="queryDetailPayrollReport"
                  class="rounded-sm w-full mr-24"
                  :loading="loadingDetailReport"
                >
                  <span class="block"> View Detailed Report</span>
                </easiButton>
              </div> -->
              </div>
              <vue3-simple-html2pdf
                ref="vue3SimpleHtml2pdf"
                :options="pdfOptions"
                :filename="fileName"
              >
                <div
                  :class="
                    !showAll
                      ? 'shadow-md md:shadow-lg md:mx-auto md:w-11/12  rounded-3xl border border-boxBorder'
                      : ''
                  "
                  class="bg-white w-full px-4 py-4 md:p-8"
                >
                  <div
                    :class="!showAll ? 'flex-col md:flex-row' : 'flex-row'"
                    class="flex gap-y-8 justify-between items-start"
                  >
                    <div class="flex gap-12">
                      <div>
                        <img
                          v-if="
                            companyData &&
                            companyData.company &&
                            companyData.company.logo
                          "
                          :src="
                            companyData &&
                            companyData.company &&
                            companyData.company.logo
                          "
                          class="w-44"
                          alt=""
                        />
                        <img
                          v-else
                          src="@/assets/img/comp-logo.png"
                          class="w-44"
                          alt=""
                        />
                      </div>

                      <div class="flex flex-col gap-y-2">
                        <div class="flex items-center gap-x-4">
                          <img src="@/assets/img/newCompany.svg" alt="" />
                          <span
                            class="text-dark-800 font-normal text-sm capitalize"
                          >
                            {{
                              companyData &&
                              companyData.company &&
                              companyData.company.companyName
                            }}
                          </span>
                        </div>

                        <div class="flex items-center gap-x-4">
                          <img src="@/assets/img/newEmail.svg" alt="" />
                          <span
                            class="text-dark-800 font-normal text-sm lowercase"
                          >
                            {{
                              companyData && companyData.companyAdmin.email
                                ? companyData.companyAdmin.email
                                : adminDetails && adminDetails.email
                            }}
                          </span>
                        </div>

                        <div class="flex items-center gap-x-4">
                          <img src="@/assets/img/newLocation.svg" alt="" />
                          <span
                            class="text-dark-800 font-normal text-sm capitalize break-all"
                          >
                            {{
                              companyData &&
                              companyData.company &&
                              companyData.company.address
                            }}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div
                      :class="!showAll ? 'w-full md:w-auto' : 'w-64'"
                      class="flex flex-col bg-newLimeGreen border border-primary rounded-tr-5xl px-5 py-2 text-sm"
                    >
                      <div
                        class="flex items-center md:items-start gap-x-2 mr-6"
                      >
                        <span class="text-dark-800">Date Generated:</span>
                        <span class="text-dark-800 font-bold"
                          >{{ months[new Date().getMonth()] }}
                          {{
                            new Date().getDate().toString().padStart(2, "0")
                          }}, {{ new Date().getFullYear() }}</span
                        >
                      </div>
                      <hr class="border border-dark-200 mt-1" />
                      <div class="flex gap-1 flex-row mt-1">
                        <span class="text-dark-800">Payroll:</span>
                        <span class="text-dark-800 font-bold"
                          >myeazipaypayrollreport</span
                        >
                      </div>
                      <hr class="border border-dark-200 my-1" />
                      <div class="flex items-center md:items-start gap-x-2">
                        <div class="text-dark-800 my-auto">Requested By:</div>

                        <div class="flex items-center gap-x-2">
                          <div class="flex gap-x-2 items-center">
                            <div
                              :class="
                                !showAll
                                  ? 'pt-1 pl-1 pr-1 md:p-2  md:h-9 md:w-9 w-7 h-7'
                                  : 'p-2  h-9 w-9 '
                              "
                              class="bg-primary text-xs md:text-sm text-white text-center font-medium rounded-full"
                            >
                              {{
                                getAvatar({
                                  profile: {
                                    firstName:
                                      companyData &&
                                      companyData.companyAdmin &&
                                      companyData.companyAdmin.firstName
                                        ? companyData.companyAdmin.firstName
                                        : adminDetails &&
                                          adminDetails.firstName,
                                    lastName:
                                      companyData &&
                                      companyData.companyAdmin &&
                                      companyData.companyAdmin.lastName
                                        ? companyData.companyAdmin.lastName
                                        : adminDetails && adminDetails.lastName,
                                  },
                                })
                              }}
                            </div>
                          </div>
                          <span class="text-dark-800 font-bold"
                            >{{
                              companyData &&
                              companyData.companyAdmin &&
                              companyData.companyAdmin.firstName
                                ? companyData.companyAdmin.firstName
                                : adminDetails && adminDetails.firstName
                            }}
                            {{
                              companyData &&
                              companyData.companyAdmin &&
                              companyData.companyAdmin.lastName
                                ? companyData.companyAdmin.lastName
                                : adminDetails && adminDetails.lastName
                            }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    :class="
                      !showAll ? 'md:p-3 px-12 py-3 mt-7' : 'p-3 px-12  mt-7'
                    "
                    class="w-full bg-primary-hover text-sm font-medium text-center capitalize text-boxBorder"
                  >
                    {{
                      companyData &&
                      companyData.companyAdmin &&
                      companyData.company.companyName
                    }}
                    Payroll Report From
                    {{
                      $route.query.date &&
                      getDate($route.query.date.split("/")[0])
                    }}
                    -
                    {{
                      $route.query.date &&
                      getDate($route.query.date.split("/")[1])
                    }}
                  </div>
                  <div
                    :class="!showAll ? 'flex-col md:flex-row' : 'flex-row'"
                    class="flex items-center w-full"
                  >
                    <div
                      class="flex w-full flex-col items-center justify-center"
                    >
                      <div
                        class="bg-primary text-white flex flex-col gap-1 md:gap-3 w-full text-center text-2xl md:text-2xl py-4 md:py-3 px-3"
                      >
                        <span
                          class="uppercase text-white font-medium text-sm md:text-base"
                          >total gross pay</span
                        >
                        <span class="font-bold">{{
                          formatAmount(
                            payrollReport && payrollReport.totalGrossPay
                          )
                        }}</span>
                      </div>
                    </div>

                    <div
                      class="flex w-full flex-col items-center justify-center"
                    >
                      <div
                        class="bg-deepGold text-white flex flex-col gap-1 md:gap-3 w-full text-center text-2xl md:text-2xl py-4 md:py-3 px-3"
                      >
                        <span
                          class="uppercase text-white font-medium text-sm md:text-base"
                          >total net pay</span
                        >
                        <span class="font-bold">
                          {{
                            formatAmount(
                              payrollReport && payrollReport.totalNet
                            )
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    class="w-full bg-blueHover flex items-center justify-center p-3 text-sm text-center text-dark-800 font-bold"
                  >
                    <!-- <img
                    :class="!showAll ? 'md:block hidden' : ''"
                    src="@/assets/img/employees.png"
                    alt=""
                  /> -->
                    <span
                      :class="
                        !showAll
                          ? 'md:text-2xl font-black md:font-normal capitalize'
                          : 'text-2xl  font-normal capitalize'
                      "
                    >
                      Total number of successful payments
                      {{
                        payrollReport && payrollReport.totalNumOfEmployees
                      }}</span
                    >
                    <!-- <img
                    :class="!showAll ? 'md:block hidden' : ''"
                    src="@/assets/img/employees.png"
                    alt=""
                  /> -->
                  </div>
                  <div
                    :class="
                      !showAll ? 'grid-cols-1 md:grid-cols-3' : 'grid-cols-3'
                    "
                    class="w-full grid py-3 text-sm text-center text-dark-800 font-bold"
                  >
                    <div class="flex flex-col p-4 gap-2 bg-newAsh">
                      <span class="text-dark-900 font-medium"
                        >TOTAL STATUTORY DEDUCTIONS</span
                      >
                      <span
                        class="text-dark-900 text-center font-bold text-2xl"
                        >{{
                          formatAmount(
                            payrollReport &&
                              payrollReport.totalStatutoryDeductions
                          )
                        }}</span
                      >
                    </div>

                    <div
                      class="flex flex-col p-4 gap-2 bg-newAsh border-l border-newLightGray"
                    >
                      <span class="text-dark-900 font-medium"
                        >TOTAL DEDUCTIONS</span
                      >
                      <span
                        class="text-dark-900 text-center font-bold text-2xl"
                        >{{
                          formatAmount(
                            payrollReport && payrollReport.totalDeductions
                          )
                        }}</span
                      >
                    </div>

                    <div
                      class="flex flex-col p-4 gap-2 bg-newAsh border-l border-newLightGray"
                    >
                      <span class="text-dark-900 font-medium">TOTAL BONUS</span>
                      <span
                        class="text-dark-900 text-center font-bold text-2xl"
                        >{{
                          formatAmount(
                            payrollReport && payrollReport.totalBonuses
                          )
                        }}</span
                      >
                    </div>
                  </div>

                  <!--Table Starts -->
                  <div v-if="!showAll" class="w-full mt-4">
                    <ReportTable />
                  </div>

                  <div v-else class="w-full mt-4">
                    <PrintFullReport />
                  </div>
                </div>
              </vue3-simple-html2pdf>
            </div>
          </main>
        </div>
      </div>

      <easiModal v-if="selectDuration" @close="selectDuration = false">
        <template v-slot:header>
          <h1 class="text-dark-800 font-medium text-3xl">
            Please Select Duration
          </h1>
        </template>
        <form
          @submit.prevent="sendOTP"
          class="flex flex-col items-center gap-9 w-full mt-3 px-5"
        >
          <span
            class="bg-primary px-3 py-2 text-sm rounded-md w-44 text-center text-white font-bold"
            >From</span
          >

          <input
            class="focus:border-primary focus:ring-primary border-dark-200 p-16 bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border rounded-sm py-3 px-4 block appearance-none rounded-2xl mt-1"
            type="date"
            name="start"
            id="start"
            required
            v-model="args.from"
          />

          <span
            class="bg-primary px-3 py-2 text-sm rounded-md w-44 text-center text-white font-bold"
            >To</span
          >

          <input
            class="focus:border-primary focus:ring-primary border-dark-200 p-16 bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border rounded-sm py-3 px-4 block appearance-none rounded-2xl mt-1"
            type="date"
            name="start"
            id="start"
            required
            :max="today"
            v-model="args.to"
          />

          <div class="w-full">
            <hr class="mb-4 border-0.5 border-dividerColor text-dividerColor" />
            <div class="text-left mb-8">
              <label
                for="whtPaymentType"
                class="text-dark-800 text-sm font-medium"
                >Payment Type(s)
              </label>
              <easiMultiSelectInput
                :showOptions="false"
                required
                class="mt-1 mb-3"
                placeholder="Payment type"
                @update="args.paymentTypes = $event"
                :value="args.paymentTypes"
                :options="[
                  {
                    label: 'Net Salary',
                    value: 'salary',
                  },
                  {
                    label: 'Bonus',
                    value: 'bonus',
                  },
                  {
                    label: 'Statutory Deductions',
                    value: 'statutory',
                  },
                  {
                    label: 'Deductions',
                    value: 'deduction',
                  },
                ]"
              />
            </div>

            <easiButton block :loading="otpLoading" color="primary" class="">
              Generate Report
            </easiButton>
          </div>
        </form>
      </easiModal>

      <easiModal v-if="enterOtp" @close="enterOtp = false" :isBorder="false">
        <easiContainer
          :hideCancel="true"
          class="w-full py-8 mx-auto text-center"
        >
          <div class="mb-4">
            <h2 class="font-bold text-2xl sm:text-3xl md:text-4xl text-grey">
              OTP Verification
            </h2>
            <span class="text-base md:text-md mt-1 font-medium block">
              Please input OTP sent to your email and phone number
            </span>
          </div>
          <form class="w-full" @submit.prevent="confirmOtp">
            <span class="mb-4 flex justify-around items-center">
              <easiOtp
                size="10"
                :token_length="6"
                :max-length="1"
                v-model="otpCode"
                @input="checkOTP"
                :error="errorRules.otpCode"
              />
            </span>
            <div
              class="text-dark-800 mb-4 text-base font-medium text-center flex items-center justify-center"
            >
              Did not receive OTP?
              <easiButton
                @click="sendOTP"
                variant="text"
                type="button"
                color="grey"
                class="text-base px-0 font-bold -ml-4"
                :loading="otpLoading"
                >Resend</easiButton
              >
            </div>
            <easiButton
              type="submit"
              block
              color="primary"
              :loading="confirmOtpLoading"
            >
              Submit
            </easiButton>
          </form>
        </easiContainer>
      </easiModal>

      <easiModal v-if="shareLink" @close="shareLink = false">
        <template v-slot:header>
          <div class="flex items-center gap-x-4 justify-center">
            <img src="@/assets/icons/share-black.svg" alt="" />
            <h1 class="capitalize text-3xl font-medium text-headerText">
              Share
            </h1>
          </div>
        </template>

        <form
          @submit.prevent="shareReport"
          class="flex flex-col"
          autocomplete="on"
        >
          <div class="flex flex-col px-6 sm:px-12 lg:px-8 xl:px-10">
            <easiTextInput
              v-model="args.email"
              placeholder="Input Email Address"
              type="email"
              name="email"
              class="mt-1 mb-5"
              required
              :error="errorRules.email"
            />
          </div>
          <hr
            class="mt-3 md:mb-8 mb-4 border-0.5 border-dividerColor text-dividerColor"
          />
          <div class="px-10 w-full">
            <easiButton
              :loading="loadingShare"
              :block="true"
              class="w-full mb-4 md:mb-5"
              >Send Mail</easiButton
            >
          </div>
        </form>
      </easiModal>

      <easiLoading v-if="loadingProgress" @close="loadingProgress = false">
        <template v-slot:heading>
          <h1>Generating Report</h1>
        </template>

        <!-- <template v-slot:message>
        <div class="w-full rounded-xl">
          <vue3-progress-bar></vue3-progress-bar>
        </div>
      </template> -->
      </easiLoading>
      <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
        <template v-slot:message>
          <span>Payroll Report has been shared successfully</span>
        </template>
      </easiSuccess>
    </div>
  </main>
</template>

<script>
import { ref, reactive, onMounted, watch, computed, onUnmounted } from "vue";
import { storeToRefs } from "pinia";
import { useDataStore } from "@/stores/data.js";
import { helperFunctions } from "@/composable/helperFunctions";
import { useToast } from "vue-toastification";

import ComingSoon from "@/components/Payroll/ComingSoon.vue";

import BankDetail from "@/components/Employee/Edit/Benefits/BankDetails.vue";
import ReportTable from "@/components/Payroll/Report/ReportTable.vue";
import DetailedReportTable from "@/components/Payroll/Report/DetailedReportTable.vue";

import PrintFullReport from "@/components/Payroll/Report/FullReportPrint.vue";
import PrintDetailedReport from "@/components/Payroll/Report/DetailedReportPrint.vue";
import { def } from "@vue/shared";
import { useRoute, useRouter } from "vue-router";

export default {
  components: {
    ReportTable,
    PrintFullReport,
    DetailedReportTable,
    PrintDetailedReport,
    ComingSoon,
  },
  setup() {
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();

    const {
      formatAmount,
      calculatePercentage,
      processDate,
      formatDateString,
      downloadBase64,
    } = helperFunctions;

    const store = useDataStore();
    const { mutate, query } = useDataStore();
    const { listPayrollHistory } = storeToRefs(store);

    const payrollReport = computed(() => {
      if (store.getPayrollReport && store.getPayrollReport.length) {
        return store.getPayrollReport[0];
      }
    });

    console.log("PAYROLL REPORT", payrollReport.value);

    const pendingLaunch = ref(true);
    const launchDetails = ref({
      header: "Payroll report coming soon!",
      list: [
        "Comprehensive report of your payroll data",
        "Accurate compliance breakdown",
        "Monthly evaluation of bonuses & deductions",
        "Available for print and download.",
      ],
    });

    const showPin = ref(false);
    const showOtp = ref(false);
    const showAll = ref(false);
    const detailed = ref(false);
    const isViewReport = ref(false);

    const selectDuration = ref(false);
    const adminDetails = computed(() => store.getCompanyAdmin.companyAdmin);

    const args = reactive({
      from: "",
      to: "",
      paymentType: ["salary", "deduction", "bonus", "statutory"],
    });

    const updateSuccess = ref(false);
    const otpReference = ref("");
    const enterOtp = ref(false);
    const otpCode = ref("");
    const errorRules = reactive({
      from: false,
      to: false,
      email: false,
      download: false,
      paymentType: false,
    });

    const loading = ref(false);
    const otpLoading = ref(false);
    const resendOtpLoading = ref(false);
    const confirmOtpLoading = ref(false);
    const loadingProgress = ref(false);
    const loadingShare = ref(false);
    const loadingDetailReport = ref(false);
    const isDownloading = ref(false);
    const isPrinting = ref(false);

    const today = ref("");
    today.value = processDate();

    const companyData = computed(() => store.getCompanyDetails);

    const shareLink = ref(false);

    let months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    onMounted(() => {
      // queryPayrollReport();
      if (route.query.date) {
        isViewReport.value = true;
      }
    });

    const getAvatar = (employee) => {
      let name = "";

      if (employee) {
        console.log(employee);
        employee
          ? (name = `${
              employee && employee.profile && employee.profile.firstName
                ? employee.profile.firstName.charAt(0)
                : "N"
            }${
              employee && employee.profile && employee.profile.lastName
                ? employee.profile.lastName.charAt(0)
                : "/A"
            }`)
          : "";
      }

      return name ? name : "N/A";
    };

    const downloadThenPrint = async () => {
      const payload = {
        input: {
          from: "",
          paymentType: {
            salary: true,
            bonus: true,
            statutory: true,
            deduction: true,
          },
          to: "",
        },
        download: true,
      };
      const res = await query({
        endpoint: "SendPayrollReport",
        service: "PAYROLL",
        payload,
      });
      const newData = Buffer.from(res.data);
      const base64 = newData.toString("base64");
      openPdfInNewTab(base64);
    };
    function openPdfInNewTab(base64Pdf) {
      // Create a Blob from the base64 PDF string
      const pdfData = atob(base64Pdf);
      const arrayBuffer = new ArrayBuffer(pdfData.length);
      const uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < pdfData.length; i++) {
        uint8Array[i] = pdfData.charCodeAt(i);
      }
      const pdfBlob = new Blob([arrayBuffer], { type: "application/pdf" });

      // Generate a URL for the Blob
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Open the PDF in a new browser tab
      const newTab = window.open(pdfUrl, "_blank");

      // Trigger print dialog when new tab is loaded
      if (newTab) {
        newTab.onload = function () {
          newTab.print();
        };
      }
    }
    const printPage = async () => {
      try {
        isPrinting.value = true;
        await downloadThenPrint();
      } catch (e) {
        console.error(e);
      } finally {
        isPrinting.value = false;
      }

      // showAll.value = true;
      // setTimeout(() => {
      //   window.print();
      // }, 1000);
    };
    onafterprint = (event) => {
      showAll.value = false;
    };

    function checkOTP(code) {
      otpCode.value = code;
    }
    async function generate() {
      // this.showAll = true;
      // setTimeout(() => {
      //   this.$refs.vue3SimpleHtml2pdf.download();
      //   this.showAll = false;
      // }, 1000);
      try {
        isDownloading.value = true;
        const payload = {
          input: {
            from: "",
            paymentType: {
              salary: true,
              bonus: true,
              statutory: true,
              deduction: true,
            },
            to: "",
          },
          download: true,
        };
        const res = await query({
          endpoint: "SendPayrollReport",
          service: "PAYROLL",
          payload,
        });
        const newData = Buffer.from(res.data);
        const base64 = newData.toString("base64");
        downloadBase64(
          base64,
          `Payroll report-${companyData.value.company.companyName},${
            (months[new Date().getMonth()],
            new Date().getDate().toString().padStart(2, "0"),
            new Date().getFullYear())
          }`
        );
      } catch (e) {
        console.error("Failed to download", e);
      } finally {
        isDownloading.value = false;
      }
    }
    async function shareReport() {
      if (args.email.trim() !== "") {
        errorRules.email = false;

        const payload = {
          input: {
            from: args.from,
            paymentType: {
              salary: args.paymentType.includes("salary") ? true : null,
              bonus: args.paymentType.includes("bonus") ? true : null,
              statutory: args.paymentType.includes("statutory") ? true : null,
              deduction: args.paymentType.includes("deduction") ? true : null,
            },
            to: args.to,
          },
          download: null,
          email: args.email,
        };

        loadingShare.value = true;
        try {
          await query({
            endpoint: "SendPayrollReport",
            service: "PAYROLL",
            payload,
          });

          shareLink.value = false;
          loadingShare.value = false;
          updateSuccess.value = true;

          setTimeout(() => {
            updateSuccess.value = false;
          }, 1000);
        } catch (e) {
          loadingShare.value = false;
          console.log(e);
        }
      } else {
        errorRules.email = "Please enter a valid email address";
      }
    }

    async function queryDetailPayrollReport() {
      const selectDate = route.query.date.split("/");
      const paymentType = JSON.parse(
        window.localStorage.getItem("paymentType")
      );

      console.log("PAYMENT TYPE", paymentType);

      const payload = {
        input: {
          from: selectDate[0],
          paymentType: {
            salary: paymentType.includes("salary") ? true : null,
            bonus: paymentType.includes("bonus") ? true : null,
            statutory: paymentType.includes("statutory") ? true : null,
            deduction: paymentType.includes("deduction") ? true : null,
          },
          to: selectDate[1],
        },
      };

      console.log("PAYLOAD", payload);

      loadingProgress.value = true;

      try {
        await query({
          endpoint: "GetDetailedPayrollReport",
          service: "PAYROLL",
          storeKey: "payrollDetailReport",
          payload,
        });

        loadingProgress.value = false;

        router.push({
          name: "DetailedPayrollReport",
          query: {
            date: `${route.query.date && route.query.date.split("/")[0]}/${
              route.query.date && route.query.date.split("/")[1]
            }`,
          },
        });
      } catch (e) {
        loadingProgress.value = false;
        console.log(e);
      }
    }

    async function queryPayrollReport() {
      const payload = {
        input: {
          from: args.from,
          paymentType: {
            salary: args.paymentType.includes("salary") ? true : null,
            bonus: args.paymentType.includes("bonus") ? true : null,
            statutory: args.paymentType.includes("statutory") ? true : null,
            deduction: args.paymentType.includes("deduction") ? true : null,
          },
          to: args.to,
        },
        otpRef: window.localStorage.getItem("otpReference"),
      };

      window.localStorage.setItem(
        "paymentType",
        JSON.stringify(args.paymentType)
      );

      console.log(JSON.parse(window.localStorage.getItem("paymentType")));
      loadingProgress.value = true;

      try {
        await query({
          endpoint: "GetPayrollReport",
          service: "PAYROLL",
          storeKey: "payrollReport",
          payload,
        });

        delete payload.otpRef;

        await query({
          endpoint: "GetDetailedPayrollReport",
          service: "PAYROLL",
          storeKey: "payrollDetailReport",
          payload,
        });

        enterOtp.value = false;
        loadingProgress.value = false;
        isViewReport.value = true;

        console.log("ARGS DATE", args.from, args.to);
        router.replace({ query: { date: `${args.from}/${args.to}` } });
      } catch (e) {
        loadingProgress.value = false;
        console.log(e);
      }
    }

    async function sendOTP() {
      const payload = {
        channel: "EMAIL",
        type: "PAYROLL_REPORT",
        value: adminDetails.value.email,
      };

      otpLoading.value = true;

      try {
        let res = await mutate({
          endpoint: "SendOTP",
          data: { input: payload },
          service: "AUTH",
        });

        if (res.success) {
          selectDuration.value = false;
          enterOtp.value = true;
          otpLoading.value = false;
          otpReference.value = res.data.reference;
          window.localStorage.setItem("otpReference", res.data.reference);
          toast.success(res.message);
        } else {
          otpLoading.value = false;
        }
      } catch (e) {
        otpLoading.value = false;
      }
    }

    async function confirmOtp() {
      if (otpCode.value.trim() === "") {
        errorRules.otpCode = "Please enter the otp code sent to your email";
      } else {
        confirmOtpLoading.value = true;

        const payload = {
          reference: window.localStorage.getItem("otpReference"),
          code: otpCode.value,
          value: adminDetails.value.email,
        };

        try {
          let res = await mutate({
            endpoint: "ConfirmOTP",
            data: { input: payload },
            service: "AUTH",
          });

          console.log(res);
          if (res.success) {
            //Clear error state
            errorRules.otpCode = false;

            //Register user
            confirmOtpLoading.value = false;
            enterOtp.value = false;
            await queryPayrollReport();
          } else {
            errorRules.otpCode = res.message;
            toast.error(res.message);
            confirmOtpLoading.value = false;
          }
        } catch (e) {
          console.log(e);
          confirmOtpLoading.value = false;
          e.message.toLowerCase() == "record not found"
            ? (errorRules.otpCode = "OTP doesn't match. Please try again")
            : null;
        }
      }
    }

    const pdfOptions = reactive({
      margin: 10,
      image: {
        type: "jpeg",
        quality: 1,
      },
      html2canvas: { scale: 3 },
      jsPDF: {
        unit: "mm",
        format: "letter",
        orientation: "p",
      },
    });

    const fileName = computed(() => {
      return `payroll report.pdf`;
    });

    const getDate = (arg) => {
      const d = new Date(arg);
      return `${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
    };
    function openChat() {
      // Trigger the Tawk.to chat
      if (typeof Tawk_API !== "undefined") {
        Tawk_API.toggle();
      }
    }

    return {
      openChat,
      pdfOptions,
      fileName,
      formatAmount,
      calculatePercentage,

      //Start Here

      pendingLaunch,
      launchDetails,

      store,
      mutate,
      query,
      getDate,

      showPin,
      showOtp,
      showAll,
      detailed,
      selectDuration,
      adminDetails,
      args,
      isDownloading,
      isPrinting,
      generate,
      updateSuccess,
      otpReference,
      enterOtp,
      otpCode,
      errorRules,

      loading,
      otpLoading,
      resendOtpLoading,
      confirmOtpLoading,
      loadingProgress,
      loadingDetailReport,

      queryPayrollReport,
      queryDetailPayrollReport,

      companyData,
      payrollReport,

      shareLink,

      isViewReport,
      listPayrollHistory,

      checkOTP,
      today,
      months,
      formatDateString,

      shareReport,
      loadingShare,

      getAvatar,
      printPage,
      onafterprint,
      sendOTP,
      confirmOtp,
    };
  },

  created() {
    // this.queryEmployees();
  },
};
</script>

<style>
@media print {
  .no-printable {
    display: none !important;
  }
  .printable {
    display: block;
  }
}
</style>
