<template>
  <main class="w-full">
    <div class="w-full">
      <div class="flex flex-col gap-6 w-full">
        <div
          class="border border-gray"
          v-for="(sub, k) in visibleData"
          :key="k"
        >
          <div
            class="bg-primary w-full py-1 flex text-sm px-5 justify-between items-center text-white font-bold"
          >
            <div class="flex items-center gap-1">
              <span> {{ months[sub.month] }}</span
              >, <span> {{ sub.year }}</span
              >|
              <span> {{ sub.timeline }}</span>
            </div>

            <div>{{ sub.numOfEmployees }} EMPLOYEE(S)</div>
          </div>

          <div class="w-full grid grid-cols-5 justify-start">
            <div class="flex flex-col col-span-1 justify-start py-2 pl-2 gap-1">
              <span
                class="text-xs text-dark-800 text-left uppercase font-medium"
                >total gross pay</span
              >

              <span
                class="text-xs text-dark-900 text-left uppercase font-bold"
                >{{ formatAmount(sub.totalGross) }}</span
              >
            </div>
            <div
              class="flex flex-col col-span-1 justify-start py-2 pl-2 gap-1 border-l border-r border-outlineGray"
            >
              <span
                class="text-xs text-dark-800 text-left uppercase font-medium"
                >total net pay</span
              >

              <span
                class="text-xs text-dark-900 text-left uppercase font-bold"
                >{{ formatAmount(sub.totalNet) }}</span
              >
            </div>
            <div class="flex flex-col col-span-1 justify-start py-2 pl-2 gap-1">
              <span
                class="text-xs text-dark-800 text-left uppercase font-medium"
                >additional allowance</span
              >

              <span
                class="text-xs text-dark-900 text-left uppercase font-bold"
                >{{ formatAmount(sub.totalAllowances) }}</span
              >
            </div>
            <div
              class="flex flex-col col-span-1 justify-start py-2 pl-2 gap-1 border-l border-r border-outlineGray"
            >
              <span
                class="text-xs text-dark-800 text-left uppercase font-medium"
                >total bonus</span
              >

              <span class="text-xs text-dark-900 text-left uppercase font-bold">
                {{ formatAmount(sub.totalBonuses) }}</span
              >
            </div>

            <div class="flex flex-col col-span-1 justify-start py-2 pl-2 gap-1">
              <span
                class="text-xs text-dark-800 text-left uppercase font-medium"
                >other deductions</span
              >

              <span
                class="text-xs text-dark-900 text-left uppercase font-bold"
                >{{ formatAmount(sub.totalDeductions) }}</span
              >
            </div>
          </div>

          <div
            v-if="sub.totalStatutoryDetails"
            class="bg-newLightGreen w-full text-sm text-dark-900 flex flex-col gap-y-1 items-start upercase font-medium py-3 pl-2"
          >
            <span class="w-72 text-left">STATUTORY DEDUCTIONS</span>
          </div>
          <div
            v-if="sub.totalStatutoryDetails"
            class="w-full grid grid-cols-5 border-t border-btnPri col-span-4"
          >
            <div
              v-for="(ben, n, index) in sub.totalStatutoryDetails"
              :key="ben"
              class="w-auto col-span-1 text-grey text-xs flex flex-col gap-y-1 items-start upercase font-medium py-2 border border-outlineGray"
              :class="index % 2 ? ' pl-2 pr-1' : ' pl-2 pr-1'"
            >
              <span class="text-left uppercase w-full">TOTAL {{ n }}</span>
              <span class="text-left uppercase text-dark-900 font-bold">{{
                formatAmount(ben)
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <easiPagin
      v-if="splitData.length"
      :data="splitData"
      @page:update="updatePage"
      :currentPage="currentPage"
      :pageSize="pageSize"
      class="my-4"
    /> -->
  </main>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { storeToRefs, mapWritableState } from "pinia";
import { useDataStore } from "@/stores/data.js";
import { helperFunctions } from "@/composable/helperFunctions";
import { format } from "path";

const store = useDataStore();
const { log, formatAmount } = helperFunctions;

const props = defineProps({
  showFullData: {
    type: Boolean,
    default: false,
  },
});
const propD = ref(props.showFullData);
// watch(propD, (val, newVal) => {
//   console.log("cje");
//   visibleData.value = data.value.data;
//   console.log(visibleData.value);
// });

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const currentPage = ref(0);
const pageSize = ref(1);
let visibleData = ref([]);
const data = computed(() => {
  if (store.getPayrollReport && store.getPayrollReport.length) {
    return store.getPayrollReport[0];
  }
});

const updatePage = (pageNumber) => {
  currentPage.value = pageNumber;
  updateVisibleData();
};
const updateVisibleData = () => {
  if (data.value) {
    // let datas = data.value.data.sort(
    //   (a, b) => new Date(b.year) - new Date(a.year)
    // );
    // visibleData.value = datas.slice(
    //   currentPage.value * pageSize.value,
    //   currentPage.value * pageSize.value + pageSize.value
    // );

    // // if we have 0 visible todos, go back a page
    // if (visibleData.value.length == 0 && currentPage.value > 0) {
    //   updatePage(currentPage.value - 1);
    // }
    visibleData.value = data.value.data;
  }
};
updateVisibleData();
</script>

<style>
.debit {
  background: rgba(228, 87, 46, 0.15);
}
</style>
