<template>
  <main
    v-if="type === 'edit' || type === 'add' || edit === true"
    class="w-full"
  >
    <div
      class="flex items-center w-full md:w-11/12 w-full mb-8 md:mx-auto border border-outlineGray rounded-xl tracking-wide justify-center md:mt-4 mt-4 gap-x-2 p-2 text-sm font-medium cursor-pointer bg-white"
    >
      <span
        @click="toggleAdd"
        :class="
          singleAdd
            ? 'bg-primary shadow-2xl text-white '
            : 'bg-newGrey md:shadow-none shadow-2xl'
        "
        class="w-full text-sm text-center rounded-lg md:px-5 md:py-2 px-3 py-2"
        >Single Add</span
      >
      <span
        @click="toggleAdd"
        :class="
          !singleAdd
            ? 'bg-primary shadow-2xl text-white'
            : 'bg-newGrey md:shadow-none shadow-2xl'
        "
        class="w-full text-sm text-center rounded-lg md:px-5 md:py-2 px-3 py-2"
        >Bulk Add</span
      >
    </div>
    <div
      :class="
        type === 'add'
          ? 'bg-none bg-white shadow-2xl md:w-full rounded-xl py-1 w-full border-outlineGray mt-0 mb-0'
          : 'bg-white shadow-2xl rounded-xl  md:w-full   border-outlineGray py-10  mb-12'
      "
      class="w-full md:mx-auto md:rounded-2xl relative border text-left"
    >
      <div :class="type === 'add' ? 'mb-0' : 'mb-8'">
        <h2
          class="mt-6 ml-6 text-headerText font-bold text-xl sm:text-lg md:text-xl"
          :class="type === 'add' ? ' text-center' : 'text-left'"
        >
          {{ type === "add" ? "Add" : "Edit" }} {{ nameOfType }}
        </h2>
        <hr class="my-4 mx-4 text-dividerColor" />
        <span
          @click="$router.go(-1)"
          v-show="type === 'edit' || edit === true"
          class="absolute top-4 right-5 cursor-pointer"
        >
          <img src="@/assets/icons/close.svg" alt="" />
        </span>
      </div>

      <form
        v-if="singleAdd"
        @submit.prevent="createBonusOrDeduction"
        class="flex flex-col"
        autocomplete="on"
      >
        <div
          class="flex items-center justify-between px-3 md:px-12 lg:px-8 xl:px-10"
        >
          <div class="w-full flex items-center gap-6">
            <span
              v-show="!details"
              @click="details = true"
              class="cursor-pointer"
            >
              <svg
                width="9"
                height="17"
                viewBox="0 0 9 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 15.5L1 8.5L8 1.5"
                  stroke="#8D8E8D"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span class="text-grey font-medium text-sm w-full md:text-xl">
              {{
                details ? `Enter ${nameOfType} details` : "Add Beneficiaries"
              }}</span
            >
          </div>

          <div v-show="!details" class="w-full flex items-center justify-end">
            <easiButton
              type="button"
              @click="$router.go(-1)"
              class="hidden md:block"
              color="primary"
              variant="text"
              >Cancel</easiButton
            >
            <easiButton type="submit" color="primary"
              ><span>Save</span></easiButton
            >
          </div>
        </div>
        <div v-if="details" class="w-full">
          <div class="flex flex-col px-3 md:px-12 lg:px-8 xl:px-10">
            <!-- <label for="bonusName" class="text-gray text-sm font-medium"
            >{{ nameOfType }} Name</label
          > -->
            <easiTextInput
              :placeholder="`${nameOfType} Name`"
              :label="`${nameOfType} Name`"
              type="text"
              name="bonusName"
              class="mt-1 mb-5"
              required
              v-model="args.name"
              :error="errorRules.name"
            />
          </div>

          <div class="flex flex-col px-3 mt-7 md:px-12 lg:px-8 xl:px-10">
            <easiSelectInput2
              :options="[
                { label: 'Amount', value: false },
                { label: 'Percentage', value: true },
              ]"
              :value="percentage"
              @update="($event) => (percentage = $event)"
              :placeholder="`${nameOfType} type`"
            ></easiSelectInput2>
          </div>

          <!-- <div class="flex flex-col items-center mt-5 gap-4">
          <h3 class="font-bold text-gray text-base">
            Please Select {{ nameOfType }} Type
          </h3>

          <form class="flex gap-4" action="">
            <div>
              <label class="text-gray font-normal text-sm mr-2" for="amount"
                >Amount</label
              >
              <input
                type="radio"
                name="percentage"
                id="percentage"
                :value="false"
                v-model="percentage"
                :checked="percentage === false"
              />
            </div>

            <div>
              <label class="text-gray font-normal text-sm mx-2" for="percentage"
                >Percentage of Total Salary</label
              >
              <input
                type="radio"
                name="percentage"
                id="percentage"
                :value="true"
                v-model="percentage"
                :checked="percentage === true"
              />
            </div>
          </form>
        </div> -->
          <div class="flex flex-col px-3 md:px-12 lg:px-8 xl:px-10 mt-2">
            <easiTextInput
              step="any"
              :label="
                percentage ? 'Enter percentage for all' : 'Enter amount for all'
              "
              :placeholder="
                percentage ? 'Enter percentage for all' : 'Enter amount for all'
              "
              :format="percentage ? false : true"
              type="text"
              name="percentage"
              class="mt-1 mb-2"
              required
              v-model="args.amount"
            />

            <p
              v-show="percentage === true && Number(args.amount) > 100"
              class="text-error text-xs capitalize"
            >
              percentage value can't be more than 100
            </p>
          </div>

          <div
            class="w-full flex flex-col flex-col-reverse md:flex-row items-center justify-end px-3 md:px-12 lg:px-8 xl:px-10 mt-6 mb-8"
          >
            <easiButton
              type="button"
              @click="$router.go(-1)"
              color="primary"
              variant="text"
              >Cancel</easiButton
            >
            <easiButton
              type="button"
              @click="proceedToAdd"
              color="primary"
              variant="outlined"
              ><span> Proceed to add beneficaries</span
              ><span class="m-3"> <i class="fas fa-arrow-right"></i> </span
            ></easiButton>
          </div>

          <!-- <div
          class="flex items-center gap-3 flex-row px-3 md:px-12 lg:px-8 xl:px-10 mt-4"
        >
          <input type="checkbox" name="apply" id="" v-model="applyToAll" />
          <label class="text-sm" for="apply">Apply to all</label>
        </div> -->
          <!-- <div
          v-if="false"
          class="flex flex-col px-3 md:px-12 lg:px-8 xl:px-10 mt-4"
        >
          <easiSelectInput2
            placeholder="Select duration"
            label="Select duration"
            name="duration"
            class="mt-1 mb-1"
            required
            @update="args.duration = $event"
            :value="args.duration"
            :options="durationOption"
          />
        </div> -->

          <!-- <div
          v-if="false"
          class="flex flex-col px-3 md:px-12 lg:px-8 xl:px-10 mt-2"
        >
          <label for="duration" class="text-gray text-sm font-medium"
            >Select End Date</label
          >
          <div class="relative">
            <input
              v-model="args.endDate"
              class="focus:border-primary focus:ring-primary border-dark-200 border-dark-200 p-16 bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border rounded-sm py-3 px-4 block appearance-none rounded-2xl mt-1 mb-3"
              type="date"
              name="received"
              id="received"
              :min="today"
            />
          </div>
        </div> -->
        </div>

        <div
          v-else
          class="flex flex-col px-3 py-4 md:px-8 md:mx-4 h-96 overflow-y-scroll rounded-2xl lg:px-8 xl:px-6 mt-4"
        >
          <easiEmployeeBonus
            label="Add Employee"
            @update="args.employeeIds = $event"
            :value="args.employeeIds"
            :options="createEmpList()"
            class="mt-1 mb-1"
            :hideList="true"
          />

          <div class="grid md:grid-cols-2 grid-cols-2 gap-y-4 md:gap-x-48 mt-2">
            <h3 class="text-newGray text-sm capitalize font-medium">NAME</h3>

            <h3 class="text-newGray text-sm text-right capitalize font-medium">
              {{ percentage ? "PERCENTAGE" : "AMOUNT" }}
            </h3>

            <div
              v-if="!args.employeeIds.length"
              class="md:col-span-3 col-span-2 bg-white flex flex-col gap-5 p-4 justify-center rounded-xl"
            >
              <img :src="empty" class="w-20 self-center" alt="" />

              <span class="text-center w-full text-sm"
                >You have no employees added yet!</span
              >
            </div>
            <div
              v-for="id in args.employeeIds"
              :key="id"
              class="grid grid-cols-3 md:grid-cols-4 gap-x-3 col-span-3 border border-newAsh rounded-xl"
              style="background-color: #f5f5f5"
              @mouseenter="hovered = id"
              @mouseleave="hovered = ''"
            >
              <div
                v-if="getEmployeeById(id) && getEmployeeById(id).profile"
                class="flex items-center gap-3"
              >
                <div
                  v-if="getEmployeeById(id).profile.pfp"
                  class="border w-8 h-8 rounded-full overflow-hidden"
                >
                  <img
                    class="w-full h-full object-cover"
                    :src="
                      getEmployeeById(id) && getEmployeeById(id).profile.pfp
                    "
                    :alt="
                      getEmployeeById(id) &&
                      getEmployeeById(id).profile.firstName +
                        ' ' +
                        getEmployeeById(id) &&
                      getEmployeeById(id).profile.lastName
                    "
                  />
                </div>
                <div
                  class="ml-2 bg-successBg uppercase p-2 w-9 h-9 text-sm flex items-center justify-center text-white font-medium rounded-full"
                >
                  {{ getAvatar(getEmployeeById(id)).name }}
                </div>
                <span class="text-sm text-dark-800 capitalize font-medium"
                  >{{
                    getEmployeeById(id) &&
                    getEmployeeById(id).profile &&
                    getEmployeeById(id).profile.firstName &&
                    getEmployeeById(id).profile.firstName
                  }}
                  {{
                    getEmployeeById(id) &&
                    getEmployeeById(id).profile &&
                    getEmployeeById(id).profile.lastName &&
                    getEmployeeById(id).profile.lastName
                  }}</span
                >
              </div>

              <div
                class="justify-self-end md:mr-8 mr-2 -mt-3 mb-2 col-span-2 md:col-span-3 flex items-center gap-4"
              >
                <div class="flex flex-col w-32 md:w-auto gap-2">
                  <easiTextInput
                    v-model="bonOrDedObj[id].amount"
                    :format="percentage ? false : true"
                    class="bg-white rounded-lg m-0 text-sm border-none text-left focus:outline-none p-2"
                  />
                  <p
                    v-show="
                      bonOrDedObj &&
                      bonOrDedObj[id] &&
                      percentage === true &&
                      Number(bonOrDedObj[id].amount) > 100
                    "
                    class="text-error my-1 text-xs capitalize"
                  >
                    percentage value can't be more than 100
                  </p>
                </div>
                <div
                  @click="update(id)"
                  :class="hovered === id ? 'flex' : 'invisible'"
                  class="cursor-pointer bg-newAsh justify-center w-4 h-4 md:w-12 md:h-12 rounded-full mt-5"
                >
                  <img
                    class="w-5"
                    src="@/assets/icons/delete-beneficiary.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div
          v-show="type === 'edit' || edit === true"
          class="px-4 md:px-0 w-full my-6 flex md:flex-row flex-col md:justify-center md:justify-items-center md:place-items-center gap-5 md:items-center"
        >
          <div class="w-full md:w-5/12">
            <easiButton
              @click="$router.go(-1)"
              type="button"
              variant="outlined"
              class="bg-white w-full md:w-40"
              :block="true"
            >
              <span class="text-sm text-dark-800"> Cancel</span>
            </easiButton>
          </div>
          <div class="w-full md:w-5/12">
            <easiButton :loading="loading" :block="true" class="w-full">
              <span class="text-sm"> Update </span>
            </easiButton>
          </div>
        </div> -->

        <p
          @click="deleteWarning = true"
          v-show="type === 'edit' || edit === true"
          class="cursor-pointer text-center text-base font-medium text-dark-800 my-2"
        >
          Delete {{ bonusorDed }}
        </p>
        <!-- <div
          v-show="type === 'add'"
          class="max-w-full min-w-full px-4 px-0 relative md:px-0 my-3 flex justify-center w-full gap-5 items-center"
        >
          <button
            type="submit"
            class="focus:outline-none w-full mx-2 md:mx-0 md:w-11/12 font-medium capitalize text-sm mt-2 py-3 px-4 md:mx-auto justify-center bg-newGreen rounded-full text-white"
          >
            Submit
          </button>
    
        </div> -->
      </form>

      <div v-else class="flex items-center flex-col gap-3 w-full">
        <span class="text-sm font-medium"
          >Download {{ nameOfType }} Sheet Template</span
        >
        <div
          :class="templateLoading ? 'pointer-events-none' : ''"
          @click="download(nameOfType)"
          class="w-auto text-center flex items-center rounded-full border border-secondary py-2 px-4 cursor-pointer"
        >
          <img src="@/assets/icons/arrow-tray.svg" class="w-5 mr-3" />
          <span class="text-secondary text-sm text-center font-medium">
            {{ templateLoading ? "Downloading..." : "Download Template" }}</span
          >
        </div>

        <div
          @click="$refs.input.click()"
          class="w-11/12 rounded-xl cursor-pointer mt-6 border border-dashed border-grey flex flex-col gap-3 p-4 items-center mx-auto"
          style="background-color: #f5f5f5"
        >
          <img src="@/assets/icons/arrow-up-tray.svg" class="w-6 mr-3" />
          <span class="text-primary font-bold">Choose file</span>
          <span class="text-grey font-bold mt-2"
            >Upload your filled {{ nameOfType }} sheet</span
          >

          <div v-if="uploadFile != null" class="w-full">
            <fileLoader />
          </div>

          <span>{{ message.length ? message : "" }}</span>
          <span v-if="fileName">{{ fileName }} {{ fileSize }}</span>

          <input
            v-show="hide === false"
            ref="input"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            @change="previewFiles($event)"
            type="file"
            class="custom-file-input"
            required
          />
        </div>

        <div class="w-11/12 my-4 px-auto">
          <easiButton
            @click="previewBonusOrDed"
            :loading="loading"
            :disabled="uploadFile == null"
            block
            >Preview {{ nameOfType }} Sheet</easiButton
          >
        </div>
      </div>
    </div>

    <easiWarn v-if="deleteWarning" @close="deleteWarning = false">
      <template v-slot:message>
        <span>Are you sure you want to delete {{ bonusorDed }}?</span>
      </template>
      <template v-slot:action>
        <div class="flex flex-col items-center gap-4">
          <div class="flex justify-center items-center w-full gap-x-4 mt-6">
            <easiButton
              class="w-40 min-w-full"
              @click="deleteWarning = false"
              variant="outlined"
              >Cancel</easiButton
            >
            <easiButton
              class="w-40 min-w-full"
              :loading="loading"
              @click="deleteBonusOrDeduction"
              >Yes</easiButton
            >
          </div>
        </div>
      </template>
    </easiWarn>
    <easiRoundLoader v-if="loading" />

    <easiEligible v-if="ineligible" @close="ineligible = false">
      <template v-slot:message>
        <span class="text-center mb-5"
          >Deduction cannot be charged to the following employees as their
          salaries are lower than the deduction amount:</span
        >

        <div
          v-for="(emp, index) in ineligibleEmployees"
          :key="emp._id"
          class="flex items-center gap-x-2 w-full my-3"
        >
          <span>{{ index + 1 }}.</span>
          <span
            >{{ `${emp.profile.firstName} ${emp.profile.lastName}` }} - Salary:
            {{ formatAmount(emp.staffLevel.netAmount) }}</span
          >
        </div>
      </template>

      <template v-slot:action>
        <div class="px-4 sm:px-10 lg:px-6 xl:px-8">
          <button
            @click="ineligible = false"
            class="focus:outline-none text-sm mt-2 py-3 w-full px-4 md:mx-auto justify-center bg-primary rounded-full text-white"
          >
            Back
          </button>
        </div></template
      >
    </easiEligible>

    <easiPreview v-if="previewModal" @close="previewModal = false">
      <template v-slot:header>
        <h1>{{ nameOfType }} Preview</h1>
      </template>

      <template v-slot:body>
        <div class="w-full">
          <easiTable
            :data="jsonData"
            :header="uploadHeaders"
            :useSelect="['bankCode']"
            :frozenFields="['sr', 'firstName', 'lastName']"
            :highlight="['firstName', 'lastName']"
            :mandatory="['firstName', 'lastName']"
            :amountValues="['grossAmount', 'grossPay', 'amount']"
          ></easiTable>
        </div>

        <div class="flex flex-col justify-center gap-y-4 mt-4 mb-3 w-full">
          <div class="self-center flex justify-center w-full text-base">
            <easiButton
              :loading="loading"
              :disabled="loading"
              class="md:w-72 w-96"
              @click="createBulkBonusOrDed"
              >Submit</easiButton
            >
          </div>
        </div>
      </template>
    </easiPreview>

    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span
          >{{ bonusorDed }}
          {{ type === "edit" ? "updated" : "added" }} successfully</span
        >
      </template>
    </easiSuccess>
  </main>
  <ViewBonus v-else @updateView="switchView($event)" />
</template>

<script setup>
import { ref, onMounted, computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";
import { helperFunctions } from "@/composable/helperFunctions";
import { useToast } from "vue-toastification";
import empty from "@/assets/icons/empty-state.svg";
import fileLoader from "@/components/global/FileLoader.vue";
import * as XLSX from "xlsx/xlsx.mjs";
import axios from "axios";
import { AUTH_CONTEXT } from "@/api/api";

import ViewBonus from "@/components/Payroll/View/BonusAndDed/BonusAndDed.vue";
const emit = defineEmits(["completed"]);
const toast = useToast();
const store = useDataStore();
const { getBonusorDed, getBonusOrDedById, getAllEmployees } =
  storeToRefs(store);
const bonusorDed = computed(() => store.getBonusorDed);
const hovered = ref("");
const nameOfType = ref("");
nameOfType.value = bonusorDed.value;
const { query, mutate } = store;
const { processDate, formatAmount, getAvatar, downloadExcelFromBase64 } =
  helperFunctions;
const today = ref("");
const ineligible = ref(false);
const previewModal = ref(false);
today.value = processDate();

const allEmployees = computed(() =>
  getAllEmployees.value ? getAllEmployees.value.data : []
);
const uploadHeaders = computed(() => {
  let headArr = [];
  if (jsonData.value && jsonData.value.length) {
    const head = Object.keys(jsonData.value[0]);
    for (let i of head) {
      headArr.push({
        field: i,
        header: i,
      });
    }
  }

  return headArr;
});
const route = useRoute();
const router = useRouter();
const props = defineProps({
  type: {
    type: String,
    default: "view",
  },
});
const deleteWarning = ref(false);
const details = ref(true);
const bonusOrdDedId = ref("");
const bonOrDedObj = ref({});
// const employeeIds = ref({});
const payload = ref({
  paging: {
    limit: 1000,
    skip: 0,
    search: "",
    sort: { firstName: "ASC" },
  },
  filter: [],
});
const bonusOrDedPayload = ref({
  paging: {
    limit: 1000,
    skip: 0,
    search: "",
  },
  type: bonusorDed.value.length > 0 ? bonusorDed.value : "Bonus",
});

const applyToAll = ref(false);
const updateSuccess = ref(false);
const loading = ref(false);
const templateLoading = ref(false);
const edit = ref(false);
const hide = ref(true);
const percentage = ref(true);
const singleAdd = ref(true);
const uploadFile = ref(null);
const input = ref(null);
const jsonData = ref([]);
const message = ref("");
const fileName = ref("");
const fileSize = ref("");

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

function previewFiles(e) {
  try {
    jsonData.value = [];
    uploadFile.value = null;
    var files = e.target.files;
    let f = files[0];

    uploadFile.value = f;
    const allowedTypes = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];

    if (!allowedTypes.includes(f.type)) {
      message.value = "File type is wrong!!";
      throw new Error(message.value);
    }
    if (f.size > 1000000) {
      message.value = "Too large, max size allowed is 1MB";
      throw new Error(message.value);
    }
    const { name, size } = f;
    fileName.value = name;
    fileSize.value = formatBytes(size);
    var reader = new FileReader();
    reader.onloadend = (e) => {
      var data = new Uint8Array(e.target.result);
      var workbook = XLSX.read(data, { type: "array" });
      let sheetName = workbook.SheetNames[0];
      /* DO SOMETHING WITH workbook HERE */
      let worksheet = workbook.Sheets[sheetName];
      let jsondata = XLSX.utils.sheet_to_json(worksheet, { defval: "" });
      jsondata.forEach(function (data) {
        jsonData.value.push(data);
      });
      // jsonData = jsondata;
    };
    reader.readAsArrayBuffer(f);
  } catch (e) {
    console.log(e);
  }
}

function toggleAdd() {
  singleAdd.value = !singleAdd.value;
}

const durationOption = ref([
  {
    label: "Weekly",
    value: "WEEKLY",
  },
  {
    label: "Monthly",
    value: "MONTHLY",
  },
  {
    label: "Annually",
    value: "ANNUALLY",
  },
  {
    label: "One-Time",
    value: "ONE_TIME",
  },
]);
const switchView = (e) => {
  edit.value = e;
};

props.type === "edit" ? (edit.value = true) : (edit.value = false);
const args = ref({
  type: "",
  name: "",
  employeeIds: [],
  amount: "0",
  percentage: 0,
  duration: "",
  endDate: "",
  status: "ACTIVE",
});

// watch(percentage, () => {
//   if (props.type === "add") {
//     args.value.amount = "0";
//     args.value.percentage = 0;
//   }
// });

const queryBonusOrDed = async () => {
  // loading.value = true;
  try {
    await query({
      endpoint: "ListBonusAndDeduction",
      payload: {
        input: bonusOrDedPayload.value,
      },
      service: "PAYROLL",
      storeKey: "listBonusAndDeduction",
    });
    // console.log(getBonusAndDeduction.value, "After query");
    loading.value = false;
  } catch (err) {
    loading.value = false;
    console.log(err);
  }
};

function removeEmployeesWithLesserSalaryThanDeduction(employeeIds) {
  const data =
    allEmployees.value &&
    allEmployees.value
      .filter((emp) => employeeIds.includes(emp._id))
      .filter(
        (e) =>
          Number(
            e.staffLevel && e.staffLevel.netAmount ? e.staffLevel.netAmount : 0
          ) < Number(args.value.amount)
      )
      .filter((em) => em != undefined && em != null);
  return data;
}
const ineligibleEmployees = ref([]);

const returnPayload = () => {
  let payload = [];

  for (let emp of args.value.employeeIds) {
    if (bonOrDedObj.value[emp] && bonOrDedObj.value[emp].amount) {
      const employee = {
        //  providerPin: null,
        // duration: "MONTHLY",
        // endDate: "2029-05-20",
        status: "ACTIVE",
        // bonusAndDeductionsId: "64b9375108e0b06598fe754e",
        percentage: percentage.value
          ? Number(bonOrDedObj.value[emp].amount)
          : 0,
        amount: percentage.value ? 0 : Number(bonOrDedObj.value[emp].amount),
        employeeId: emp,
      };

      if (route.params.id && bonOrDedObj.value[emp].employeeBnDId) {
        employee.id = bonOrDedObj.value[emp].employeeBnDId;
      }

      payload.push(employee);
    }
  }
  return payload;
};

// function download(type) {
//   let host = window.location.protocol + "//" + window.location.host;
//   axios({
//     url: `${host}/uploads/Employee.xlsx`,
//     method: "GET",
//     responseType: "blob",
//   }).then((response) => {
//     var fileURL = window.URL.createObjectURL(new Blob([response.data]));
//     var fileLink = document.createElement("a");
//     fileLink.href = fileURL;
//     fileLink.setAttribute("download", `${type}.xlsx`);
//     document.body.appendChild(fileLink);
//     fileLink.click();
//   });
// }

async function download(type) {
  try {
    templateLoading.value = true;

    let res = await query({
      endpoint: "DownloadXLTemplate",
      payload: { input: { type: "addBonusOrDeduction" } },
      service: "PAYROLL",
    });

    if (res) {
      downloadExcelFromBase64(res, type);
      templateLoading.value = false;
    } else {
      toast.error("Download failed");
      templateLoading.value = false;
    }
  } catch (err) {
    toast.error(err.message);
    console.log(err);
  } finally {
    templateLoading.value = false;
  }
}

function checkIfDeductionAmountExceedSalary(data) {
  data.find((el) => {
    const emp = getEmployeeById(el.employeeId);
    if (el.amount > emp.staffLevel.netAmount) {
      const fName = `${emp.profile.firstName} ${emp.profile.lastName}`;
      const message = `The following Employee's Salary is lesser than their deduction : ${fName}`;
      toast.error(message);
      throw new Error(message);
    }
  });
}

function checkIfPercentageExceed100(data) {
  const PercentageExceed100 = data.some((el) => el.percentage > 100);
  if (PercentageExceed100) {
    toast.error(`Percentage should be less than or equal to 100`);
    throw new Error("Percentage should be less than or equal to 100");
  }
}

async function createBonusOrDeduction() {
  const data = returnPayload();

  try {
    checkIfPercentageExceed100(data);
    if (bonusorDed.value == "Deduction") {
      checkIfDeductionAmountExceedSalary(data);
    }

    loading.value = true;

    if (props.type === "add" && !route.params.id) {
      let res = await mutate({
        endpoint: "CreateBonusOrDeduction",
        service: "PAYROLL",
        data: {
          input: {
            name: args.value.name,
            type: bonusorDed.value,
          },
        },
      });

      if (res.success) {
        // await updateBonusOrDeductionName();
        await AddEmployeesToBonusOrDeduction(res.data._id, data);
        setTimeout(() => {
          router.go(-1);
        }, 500);
      } else {
        toast.error(res.message);
      }
    } else {
      await updateBonusOrDeductionName();
      const existing = data.filter(
        (pld) => pld["id"] || typeof pld["id"] === "string"
      );
      const newData = data.filter(
        (pld) => pld["id"] == null || typeof pld["id"] == "undefined"
      );

      if (newData && newData.length) {
        await AddEmployeesToBonusOrDeduction(route.params.id, newData);
      }
      let res = await mutate({
        endpoint: "UpdateEmployeeBonusOrDeductionBulkV3",
        service: "PAYROLL",
        data: {
          bonusAndDeductionsId: route.params.id,
          input: existing,
        },
      });
      loading.value = false;
      if (res.success) {
        store.$patch({
          bonusorDed: args.value.type,
        });
        await queryBonusOrDed();
        updateSuccess.value = true;
        router.go(-1);
        // emit("completed", false);

        // setTimeout(() => {
        //   router.go(-1);
        // }, 500);
      } else {
        toast.error(res.message);
      }
    }
  } catch (e) {
    loading.value = false;
    console.log(e);
  } finally {
    loading.value = false;
  }
}

async function AddEmployeesToBonusOrDeduction(id, employees) {
  // const employees = returnPayload();
  try {
    let res = await mutate({
      endpoint: "AddManyEmployeesToBonusOrDeductionV3",
      service: "PAYROLL",
      data: {
        employees,
        bonusAndDeductionsId: id,
      },
    });

    if (res.success) {
      await queryBonusOrDed();

      updateSuccess.value = true;
      emit("completed", false);
      loading.value = false;
    } else {
      toast.error(res.message);
    }
  } catch (error) {
    loading.value = false;
    console.log(e);
  }
}

const bulkData = ref([]);
async function createBulkBonusOrDed() {
  // const employees = returnPayload();
  loading.value = true;
  try {
    let res = await mutate({
      endpoint: "CreateBnDAndAddEmp",
      service: "PAYROLL",
      data: {
        input: bulkData.value,
      },
    });

    if (res.success) {
      await queryBonusOrDed();
      previewModal.value = false;

      updateSuccess.value = true;
      emit("completed", true);
      loading.value = false;
    } else {
      toast.error(res.message);
    }
  } catch (error) {
    loading.value = false;
    console.log(e);
  } finally {
    loading.value = false;
  }
}
const bonusorDedData = computed(() => store.getBonusOrDedById(route.params.id));
async function updateBonusOrDeductionName() {
  const name = bonusorDedData.value.name.toLocaleLowerCase();
  if (args.value.name.trim().toLocaleLowerCase() !== name) {
    console.log("New Name", args.value.name);

    try {
      let res = await mutate({
        endpoint: "UpdateBonusOrDeduction",
        service: "PAYROLL",
        data: {
          updateBonusOrDeductionId: route.params.id,
          input: {
            type: bonusorDed.value,
            name: args.value.name,
          },
        },
      });

      console.log(res.message);
    } catch (error) {
      console.log(error.message);
    }
  }
}

async function deleteBonusOrDeduction() {
  try {
    let res = await mutate({
      endpoint: "DeleteBonusOrDeduction",
      service: "PAYROLL",
      data: {
        deleteBonusOrDeductionId: route.params.id,
      },
    });
    loading.value = false;
    if (res.success) {
      await queryBonusOrDed();
      updateSuccess.value = true;
      router.go(-1);

      // setTimeout(() => {
      //   router.go(-1);
      // }, 3000);
    }
  } catch (e) {
    loading.value = false;
    console.log(e);
  }
}

// async function previewBonusOrDed() {
//   loading.value = true;
//   try {
//     let res = await mutate({
//       endpoint: "ViewEmployeeBnD",
//       service: "PAYROLL",
//       data: {
//         file: uploadFile.value,
//       },
//     });
//     console.log(res, "rrr");

//     // if (res.success) {
//     //   await queryBonusOrDed();
//     //   updateSuccess.value = true;
//     //   router.go(-1);

//     // }
//   } catch (e) {
//     loading.value = false;
//     console.log(e);
//   }
// }

async function previewBonusOrDed() {
  loading.value = true;
  const formData = new FormData();
  const operation = "ViewEmployeeBnD";
  // formData.append(
  //   "operations",
  //   `{
  //         "query": "mutation ${operation}($file:Upload!) { ${operation}(file: $file){message data success} }"
  //       }`
  // );
  // formData.append("map", `{"0": ["variables.file"]}`);
  // formData.append("0", uploadFile.value, `${nameOfType.value}.xlsx`);

  formData.append(
    "operations",
    `{
            "query": "mutation ${operation}($file:Upload!) { ${operation}(file: $file){message data} }"
          }`
  );
  formData.append("map", `{"0": ["variables.file"]}`);
  formData.append("0", uploadFile.value);

  try {
    const response = await axios.post(
      "https://payrollservice-d5ffb79ba43a9141.onporter.run/graphql",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: await AUTH_CONTEXT(),
        },
      }
    );
    jsonData.value = response.data.data.ViewEmployeeBnD.data.dataFromExcel.map(
      (dat) => {
        return {
          sn: dat.SN,
          employeeId: dat["Employee ID"],
          firstName: dat["First Name"],
          lastName: dat["Last Name"],
          [`${bonusorDed.value}Name`]: dat.Name,
          amount: dat.Amount,
          percentage: dat.Percentage,
          uid: dat.UID,
        };
      }
    );
    bulkData.value = jsonData.value.map((jdata) => {
      return {
        type: nameOfType.value,
        percentage: jdata.percentage,
        employeeId: jdata.uid,
        amount: jdata.amount,
        bndName: jdata[`${nameOfType.value}Name`],
      };
    });
    previewModal.value = true;
    // console.log(response.data.data, "upload res");
  } catch (err) {
    console.log(err);
  } finally {
    loading.value = false;
  }
}

const createEmpList = () => {
  const list =
    allEmployees.value &&
    allEmployees.value.map((emp) => {
      return {
        label: `${emp.profile.firstName} ${emp.profile.lastName}`,
        value: emp._id,
      };
    });
  return list;
};
queryBonusOrDed();

async function queryEmployees() {
  loading.value = true;
  try {
    let res = await query({
      endpoint: "ListEmployees",
      payload: payload.value,
      service: "EMP",
      storeKey: "listEmployees",
    });
    // employeeIds.value = res.data.map((e) => e._id);
    if (res && res.data) {
      let result = {};
      result = res.data.reduce(function (r, a) {
        r[a._id] = r[a._id] || {};
        r[a._id]["employeeId"] = a._id;
        r[a._id]["status"] = "ACTIVE";
        r[a._id]["percentage"] = 0;
        r[a._id]["amount"] = 0;
        // r[a._id]["bonusAndDeductionsId"] = bonusOrdDedId.value;

        return r;
      }, {});
      bonOrDedObj.value = result || {};
    }
  } catch (e) {
    console.log(e);
  } finally {
    loading.value = false;
  }
}
// queryEmployees();
const watchAmount = computed(() => args.value.amount);

watch([applyToAll, watchAmount], () => {
  const keys = Object.keys(bonOrDedObj.value);

  if (applyToAll.value == true) {
    keys.map((k) => {
      bonOrDedObj.value[k].amount = args.value.amount;
    });
  } else {
    if (!route.params.id) {
      keys.map((k) => {
        bonOrDedObj.value[k].amount = 0;
      });
    }
  }

  console.log("BONUSORDEDUCTION", bonOrDedObj.value);
});

function getEmployeeById(id) {
  let employee = {};
  if (allEmployees.value && allEmployees.value.length) {
    employee = allEmployees.value.find((emp) => emp._id === id);
  }
  return employee || {};
}
const removedData = ref([]);

async function deleteEmployeeBonusOrDed(bndId) {
  loading.value = true;
  try {
    let res = await mutate({
      endpoint: "DeleteEmployeeBonusOrDeductionV3",
      service: "PAYROLL",
      data: {
        deleteEmployeeBonusOrDeductionV3Id: bndId,
      },
    });
    loading.value = false;
    if (res.success) {
      toast.success(res.message);
      await queryBonusOrDed();
      // updateSuccess.value = true;
      // router.go(-1);

      // setTimeout(() => {
      //   router.go(-1);
      // }, 3000);
    }
  } catch (e) {
    loading.value = false;
    console.log(e);
  }
}
async function update(param) {
  if (param) {
    {
      if (args.value.employeeIds.includes(param)) {
        if (
          route.params.id &&
          bonusorDedData.value &&
          bonusorDedData.value.employees &&
          bonusorDedData.value.employees.find((emp) => emp._id === param)
        ) {
          const { employeeBnDId = null } = bonusorDedData.value.employees.find(
            (bd) => bd._id === param
          );
          if (employeeBnDId) {
            await deleteEmployeeBonusOrDed(employeeBnDId);
          }
        }
        let index = args.value.employeeIds.indexOf(param);
        if (index > -1) {
          args.value.employeeIds.splice(index, 1);
        }
        removedData.value.push(param);
      } else {
        args.value.employeeIds.push(param);
        let index = removedData.value.indexOf(param);
        if (index > -1) {
          removedData.value.splice(index, 1);
        }
        nextTick();
      }
    }
  }
}
const errorRules = ref({ name: "" });
function proceedToAdd() {
  if (args.value.name.length == 0) {
    errorRules.value.name = "Name is required";
    return;
  }
  if (args.value.amount && Number(args.value.amount) > 0) {
    applyToAll.value = true;
  }
  details.value = false;
}

onMounted(async () => {
  await queryEmployees();
  // queryBonusOrDed();
  if (route.params.id) {
    const bonusorDedData = computed(() =>
      store.getBonusOrDedById(route.params.id)
    );
    console.log(bonusorDed.value, "TYPE", bonusorDedData.value, "DATA");
    const data = bonusorDedData.value;
    percentage.value = isPercentage();
    args.value.name = data.name;
    args.value.percentage = 0;
    args.value.amount = 0;
    // args.value.duration = data.duration;
    args.value.type = data.type;
    // args.value.endDate = data.endDate.slice(0, 10);
    args.value.employeeIds = getEmployeeIds();

    // for (let d of data.employeeIds) {
    //   args.value.employeeIds.push(d);
    // }

    const employees = data.employees;

    let result = {};
    result = employees.reduce(function (r, a) {
      r[a._id] = r[a._id] || {};
      r[a._id]["employeeId"] = a._id;
      r[a._id]["status"] = a.status;
      r[a._id]["percentage"] = a.percentage;
      r[a._id]["amount"] = a.percentage
        ? a.percentage
        : a.amount
        ? a.amount
        : 0;
      r[a._id]["employeeBnDId"] = a.employeeBnDId;
      // r[a._id]["bonusAndDeductionsId"] = bonusOrdDedId.value;

      return r;
    }, {});
    for (const key in bonOrDedObj.value) {
      if (result[key]) {
        bonOrDedObj.value[key] = result[key];
      }
    }
    // bonOrDedObj.value = result || {};

    function getEmployeeIds() {
      return data ? data.employees.map((el) => el._id) : [];
    }

    function isPercentage() {
      return !!data.employees.find((el) => el.percentage > 0);
    }
  }
  // else {
  //   await queryEmployees();
  // }
});
</script>

<style></style>
