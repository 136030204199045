<template>
  <main class="min-h-screen">
    <div class="flex w-full items-center justify-center">
      <div
        @click="payrollDetails = true"
        :class="
          payrollDetails
            ? 'bg-primary bg-secondary text-white '
            : 'border border-gray'
        "
        class="cursor-pointer rounded-full w-6 h-6 text-center flex items-center font-bold justify-center text-xs"
      >
        1
      </div>
      <hr class="w-24 my-1 border-2 text-dividerColor" />

      <div
        @click="toggleToSalaryScreen"
        :class="
          !payrollDetails
            ? 'bg-primary bg-secondary text-white '
            : 'border border-gray'
        "
        class="cursor-pointer rounded-full w-6 h-6 text-center flex items-center font-bold justify-center text-xs"
      >
        2
      </div>
    </div>
    <div
      v-show="payrollDetails"
      class="md:w-1/2 w-full py-10 mx-auto bg-white rounded-2xl mt-10 mb-12 relative text-left border border-boxBorder shadow-lg"
    >
      <div class="mb-8 text-center">
        <h2
          class="text-center text-headerText font-bold md:font-medium text-2xl md:text-3xl"
        >
          Input Payroll Details
        </h2>
        <!-- <span class="text-xs text-error text-center"
          >Please fill mandatory fields (*)</span
        > -->
        <hr class="my-4 border-0.5 border-dividerColor text-dividerColor" />
      </div>
      <form
        @submit.prevent="queryPayroll"
        autocomplete="on"
        class="flex flex-col px-3 lg:px-8 xl:px-10"
      >
        <easiSelectInput
          required
          label="Select Year"
          class="mt-1 mb-5"
          @update="args.year = $event"
          :value="args.year"
          :options="years"
          :searchable="true"
        />

        <easiSelectInput
          required
          label="Select Timeline"
          class="mt-1 mb-5"
          @update="args.timeline = $event"
          :value="args.timeline"
          :options="['DAILY', 'WEEKLY', 'BIWEEKLY', 'MONTHLY']"
          :searchable="true"
        />

        <easiSelectInput
          required
          label="Select Month"
          class="mt-1 mb-5"
          @update="payrollMonth = $event"
          :value="payrollMonth"
          :options="months"
          :searchable="true"
        />

        <easiSelectInput2
          v-if="args.timeline === 'BIWEEKLY'"
          required
          placeholder="Select Half"
          class="mt-1 mb-3"
          @update="biWeeklyHalf = $event"
          :value="biWeeklyHalf"
          :options="[
            { label: 'FIRST HALF', value: 'FIRSTHALF' },
            { label: 'SECOND HALF', value: 'SECONDHALF' },
          ]"
          :searchable="true"
        />

        <easiMultiSelectInput
          required
          label="Specify Payment"
          class="mt-1 mb-3"
          @update="paymentOption = $event"
          :value="paymentOption"
          :options="paymentOptions"
          :searchable="true"
          :error="optionError"
        />

        <bonusSelection
          v-if="paymentOption.includes('bonus')"
          required
          label="Bonus"
          class="mb-5"
          @update="bonuses = $event"
          :value="bonuses"
          :options="bonusOptions"
          :searchable="true"
        />

        <bonusSelection
          v-if="paymentOption.includes('deduction')"
          required
          label="Deduction"
          class="mt-1 mb-5"
          @update="deductions = $event"
          :value="deductions"
          :options="deductionOptions"
          :searchable="true"
        />

        <bonusSelection
          v-if="paymentOption.includes('statutoryDeductions')"
          required
          label="Statutory Deductions"
          class="mt-1 mb-5"
          @update="args.statutories = $event"
          :value="args.statutories"
          :options="statutoryOptions"
          :searchable="true"
        />
        <div class="w-full mt-3 items-center md:justify-center md:w-full">
          <easiButton :loading="loading" block class="w-full"
            >Preview Payroll</easiButton
          >
        </div>
      </form>
    </div>

    <div
      v-show="!payrollDetails"
      class="md:w-5/12 w-full py-10 md:mx-auto bg-white rounded-2xl mt-10 mb-12 relative text-left border border-boxBorder shadow-lg"
    >
      <div class="w-full">
        <p
          class="text-center font-bold text-2xl pb-4 mb-4 border-b-2 border-outlineGray text-grey"
        >
          Run Payroll
        </p>
        <div
          class="relative bg-mint mt-6 grid grid-cols-2 gap-4 justify-between text-dark-800 w-11/12 md:w-10/12 h-auto px-4 py-4 rounded rounded-3xl border mx-auto border-dark-300"
        >
          <span class="font-medium md:text-base w-full">Payroll Load </span>
          <span class="md:text-base w-full text-right">{{
            Object.keys(totalPayrollAmount).length &&
            typeof totalPayrollAmount.total === "number"
              ? formatAmount(
                  totalPayrollAmount && Number(totalPayrollAmount.total)
                )
              : formatAmount(0)
          }}</span>

          <span class="relative font-medium md:text-base w-full"
            >Transaction fee
            <span class="inline-block absolute ml-2 top-0.5">
              <img
                @click="setHoveredType('sub')"
                @mouseenter="setHoveredType('sub')"
                @mouseleave="clearHoveredType"
                class="cursor-pointer"
                src="@/assets/icons/payroll-info.svg"
                alt=""
            /></span>
          </span>
          <span class="md:text-base w-full text-right">{{
            Object.keys(previewTotals).length &&
            typeof previewTotals.transactionFee === "number"
              ? formatAmount(
                  previewTotals && Number(previewTotals.transactionFee)
                )
              : formatAmount(0)
          }}</span>
          <div
            v-if="isHovered('sub')"
            class="absolute z-20 top-20 mt-2 w-11/12 left-4 bg-white shadow-2xl rounded-xl flex gap-2 p-4 flex-col mb-2 text-left"
          >
            <h3 class="font-bold text-base text-dark-800">Transaction fee</h3>

            <p class="text-xs text-dark-200 leading-5">
              Transaction fee is charged for all your employees and is inclusive
              of VAT.
            </p>
          </div>

          <span class="font-medium md:text-base w-full">Subscription fee </span>
          <span class="md:text-base w-full text-right">{{
            previewTotals &&
            previewTotals.subscriptionData &&
            typeof previewTotals.subscriptionData.baseSub === "number"
              ? formatAmount(Number(previewTotals.subscriptionData.baseSub))
              : formatAmount(0)
          }}</span>

          <span class="font-medium md:text-base w-full"
            >Compliance charges</span
          >
          <span class="md:text-base w-full text-right">{{
            previewTotals &&
            previewTotals.subscriptionData &&
            previewTotals.subscriptionData.statCharges &&
            previewTotals.subscriptionData.statCharges.statTotal &&
            typeof previewTotals.subscriptionData.statCharges.statTotal ===
              "number"
              ? formatAmount(
                  Number(previewTotals.subscriptionData.statCharges.statTotal)
                )
              : formatAmount(0)
          }}</span>

          <hr class="border-dashed border-newGray col-span-2" />

          <p class="font-bold text-primary-deep md:text-2xl">Total</p>
          <p class="font-bold text-primary-deep md:text-2xl text-right">
            {{ formatAmount(previewTotals.sumTotal) }}
          </p>
        </div>

        <div class="flex items-center mt-7 mx-auto w-10/12 justify-between">
          <div class="flex flex-col gap-1">
            <span class="text-dark-200 text-sm">Wallet Balance</span>
            <span class="text-lg font-bold">{{
              balance.balance ? formatAmount(balance.balance) : formatAmount(0)
            }}</span>
          </div>

          <div
            @click="sendSlip = !sendSlip"
            class="flex items-center cursor-pointer justify-end gap-x-1 mt-4 mb-10"
          >
            <button class="-mb-10 focus:outline-none bg-none">
              <img
                v-show="sendSlip"
                src="@/assets/icons/black-check-checked.svg"
                alt=""
              />
            </button>
            <button class="-mb-10 focus:outline-none bg-none">
              <img
                v-show="!sendSlip"
                src="@/assets/icons/black-check.svg"
                alt=""
              />
            </button>
            <!-- 
            <input
              :checked="sendSlip"
              v-model="sendSlip"
              type="checkbox"
              name=""
              id=""
            /> -->
            <span class="text-gray text-base font-bold -mb-10"
              >Send Payslip(s)
            </span>
          </div>
        </div>
        <p
          v-show="!creditBalance"
          class="text-xs text-center text-secondary font-medium my-3 mx-auto md:w-10/12 mt-8 w-11/12"
        >
          Insufficient balance, please top up and proceed.
        </p>
        <div class="mx-auto md:w-10/12 mt-8 w-11/12">
          <easiButton
            v-if="creditBalance"
            :loading="otpLoading"
            @click="sendOTP"
            :block="true"
            >Pay From Wallet</easiButton
          >

          <easiButton v-else :block="true" @click="topupOption = true">
            Top Up
          </easiButton>
        </div>
      </div>

      <form
        @submit.prevent="submitBvn"
        class="hidden flex flex-col w-full px-4 md:px-5 gap-3 items-start"
      >
        <span class="text-dark-800 text-sm"
          >Kindly input your BVN to further secure your account from
          fraud.</span
        >
        <div class="w-full flex flex-col gap-y-8">
          <easiTextInput
            required
            class="max-w-full w-full min-w-full"
            type="number"
            v-model="bvnNumber"
            placeholder="Enter your BVN"
          ></easiTextInput>
          <easiButton
            :block="true"
            :loading="loading"
            color="primary"
            type="submit"
            class="md:min-w-full md:max-w-full md:w-auto w-full focus:outline-none text-sm py-3 bg-primary rounded-lg text-white"
          >
            Continue
          </easiButton>
        </div>
      </form>
    </div>

    <easiModal :payroll="true" v-if="topupOption" @close="topupOption = false">
      <template v-slot:header>
        <h1 class="capitalize font-medium text-headerText">Topup Wallet</h1>
      </template>
      <div class="px-5">
        <topUp
          @done="queryWalletBalance"
          :adminEmail="
            companyData &&
            companyData.companyAdmin &&
            companyData.companyAdmin.email
          "
        />
      </div>

      <!-- <div class="flex flex-col items-center gap-y-4">
        <p class="text-gray">Please select topup option</p>

        <div
          class="flex md:flex-row flex-col md:items-center md:justify-center gap-5 w-full px-6"
        >
          <div
            @click="bankTransfer = true"
            :class="
              bankTransfer
                ? 'shadow shadow-xl wallet border-4  border-gray'
                : 'bg-white border-2 border-gray'
            "
            class="cursor-pointer flex flex-col items-center md:justify-center w-full gap-1 relative md:w-auto pt-2 px-4 md:pb-5 pb-8 rounded rounded-2xl"
          >
            <img
              v-show="bankTransfer"
              class="self-end justify-self-start place-self-start"
              src="../../../assets/icons/checked.svg"
              alt=""
            />
            <div
              v-show="!bankTransfer"
              class="w-4 h-4 rounded-full border-2 border-gray bg-white self-end justify-self-start place-self-start"
            ></div>
            <span
              :class="
                !bankTransfer ? 'font-medium text-gray' : 'font-bold text-white'
              "
              class="text-sm mt-2"
              >Bank Transfer</span
            >
            <img
              v-show="bankTransfer"
              src="../../../assets/icons/arrow.svg"
              alt=""
            />
            <img
              v-show="!bankTransfer"
              src="../../../assets/icons/arrow-inactive.svg"
              alt=""
            />
          </div>

          <div
            @click="bankTransfer = false"
            :class="
              !bankTransfer
                ? 'shadow shadow-xl wallet border-4  border-gray'
                : 'bg-white border-2 border-gray'
            "
            class="flex flex-col cursor-pointer items-center justify-center gap-1 relative w-full md:w-auto pt-2 px-6 pb-5 rounded rounded-2xl"
          >
            <img
              v-show="!bankTransfer"
              class="self-end justify-self-start place-self-start"
              src="../../../assets/icons/checked.svg"
              alt=""
            />
            <div
              v-show="bankTransfer"
              class="w-4 h-4 rounded-full border float-right border-gray bg-white self-end justify-self-start place-self-start"
            ></div>
            <span
              :class="
                bankTransfer
                  ? 'md:font-medium md:text-gray font-bold text-dark-800'
                  : 'font-bold text-white'
              "
              class="text-sm mt-2"
              >Paystack</span
            >
            <img
              v-show="!bankTransfer"
              class="invisible"
              src="../../../assets/icons/arrow.svg"
              alt=""
            />
            <img
              v-show="bankTransfer"
              class="invisible"
              src="../../../assets/icons/arrow-inactive.svg"
              alt=""
            />
          </div>
        </div>
      </div>
      <hr class="mt-8 mb-4 border-0.5 border-dividerColor text-dividerColor" />
      <div
        class="flex md:flex-row flex-col flex-col-reverse gap-6 px-6 md:px-0 w-full my-6 md:justify-center md:items-center"
      >
        <span
          @click="topupOption = false"
          class="w-full md:w-40 cursor-pointer text-center bg-white rounded-lg text-dark-800 font-medium border border-secondary px-3 text-sm py-3"
          >Cancel</span
        >
        <easiButton
          block
          @click="
            bankTransfer
              ? (topupOption = false)((transferDetails = true))
              : (topupOption = false)((payStack = true))
          "
          class="w-full md:w-40 focus:outline-none text-sm py-3 px-4 bg-secondary rounded-lg text-white"
        >
          Submit
        </easiButton>
      </div> -->
    </easiModal>

    <easiModal v-if="transferDetails" @close="transferDetails = false">
      <template v-slot:header>
        <h1 class="capitalize font-medium text-headerText">
          pay via bank transfer
        </h1>
      </template>

      <div class="flex flex-col items-start px-10 gap-5">
        <div class="flex flex-col items-start">
          <span class="text-gray text-sm">Bank Name:</span>
          <span class="text-dark-800 font-medium text-base">{{
            companyAccount.bankName
          }}</span>
        </div>

        <div class="flex flex-col items-start">
          <span class="text-gray text-sm">Account Name:</span>
          <span class="text-dark-800 font-medium text-base">{{
            companyAccount.accountName
          }}</span>
        </div>
        <div class="flex gap-x-6">
          <div class="flex flex-col items-start">
            <span class="text-gray text-sm">Account Number:</span>

            <span id="copy" class="text-dark-800 font-medium text-base">{{
              companyAccount.accountNumber
            }}</span>
          </div>
          <div
            @click="copyText(companyAccount.accountNumber)"
            class="bg-white rounded rounded-xl px-4 py-2 cursor-pointer flex items-center gap-3"
          >
            <img src="../../../assets/icons/copy.svg" alt="" />
            <span class="text-dark-800 font-bold inline-block text-sm"
              >Copy</span
            >
          </div>
        </div>
      </div>
      <hr class="mt-8 mb-4 border-0.5 border-dividerColor text-dividerColor" />
      <div
        class="flex flex-col flex-col-reverse px-6 md:px-0 md:flex-row gap-6 w-full my-6 md:justify-center md:items-center"
      >
        <span
          @click="transferDetails = false"
          class="w-full md:w-40 cursor-pointer text-center bg-white rounded-lg text-dark-800 font-medium border border-secondary px-3 text-sm py-3"
          >Cancel</span
        >
        <easiButton
          @click="
            () => {
              transferDetails = false;
              transferNotification = true;
            }
          "
          class="w-full md:w-auto focus:outline-none text-sm py-3 px-4 bg-secondary rounded-lg text-white"
        >
          I Have Made The Transfer
        </easiButton>
      </div>
    </easiModal>

    <easiModal
      :isBorder="false"
      v-if="transferNotification"
      @close="transferNotification = false"
    >
      <div class="flex flex-col items-center justify-center px-10 gap-8">
        <span class="text-xl text-center mt-9 font-medium text-dark-800"
          >Wallet will be updated as <br />
          soon as the money is <br />
          received.</span
        >
        <img src="../../../assets/icons/wallet-icon.svg" alt="" />
      </div>

      <hr class="mt-8 mb-4 border-0.5 border-dividerColor text-dividerColor" />
      <div class="flex gap-x-6 w-full my-6 justify-center items-center">
        <easiButton
          @click="transferNotification = false"
          class="w-80 md:w-40 focus:outline-none text-sm py-3 px-4 bg-secondary rounded-lg text-white"
        >
          <span class="text-sm"> Back to Run Payroll </span>
        </easiButton>
      </div>
    </easiModal>

    <easiModal v-if="enterOtp" @close="enterOtp = false" :isBorder="false">
      <easiContainer
        :hideCancel="true"
        class="w-full py-8 px-5 md:px-0 mx-auto text-center"
      >
        <div class="mb-4">
          <h2
            class="text-primary-deep font-bold text-2xl sm:text-3xl md:text-4xl"
          >
            OTP Verification
          </h2>
          <span class="text-base md:text-md mt-1 font-medium block">
            Please input OTP sent to your email
          </span>
        </div>
        <form class="w-full" @submit.prevent="confirmOtp">
          <span class="mb-2 flex justify-around items-center">
            <easiOtp
              size="10"
              :token_length="6"
              :max-length="1"
              v-model="otpCode"
              @input="checkOTP"
              :error="errorRules.otpCode"
            />
          </span>
          <easiTextInput
            v-if="
              companyData &&
              companyData.twoFactorAuth &&
              companyData.twoFactorAuth.authType
            "
            v-model="securityCode"
            class="mx-3 mt-8 mb-10"
            :placeholder="codeLabel"
            type="text"
            :error="twoFaError"
            required
          />
          <div
            class="text-secondary justify-center text-sm flex items-center gap-1"
          >
            <div
              class="w-9 h-9 rounded-xl text-sm flex justify-center items-center bg-background"
            >
              {{ minutes }}
            </div>
            :
            <div
              class="w-9 h-9 rounded-xl text-sm flex justify-center items-center bg-background"
            >
              {{ seconds }}
            </div>
          </div>
          <div
            class="text-dark-800 text-sm gap-0 font-medium text-center flex items-center justify-center"
          >
            <span class="-mr-4"> Did not receive OTP?</span>
            <easiButton
              type="button"
              @click="sendOTP('EMAIL')"
              variant="text"
              class="text-xs font-bold px-0"
              :class="
                disabledBtn
                  ? 'cursor-not-allowed text-secondary-disabled'
                  : 'text-secondary'
              "
              :loading="otpLoading"
              :disabled="disabledBtn"
              >Resend</easiButton
            >
          </div>

          <div
            class="text-dark-800 mb-4 text-xs font-medium text-center flex items-center justify-center"
          >
            <easiButton
              type="button"
              @click="sendOTP('SMS')"
              variant="text"
              class="text-xs font-bold px-0"
              :class="
                disabledBtn
                  ? 'cursor-not-allowed text-secondary-disabled'
                  : 'text-secondary'
              "
              :disabled="disabledBtn"
              :loading="resendOtpLoading"
              >Send to phone number instead</easiButton
            >
          </div>
          <easiButton
            :disabled="otpCode && otpCode.length != 6"
            block
            :loading="confirmOtpLoading"
          >
            Pay
          </easiButton>
        </form>
      </easiContainer>
    </easiModal>

    <easiModal v-if="payStack" @close="payStack = false">
      <template v-slot:header>
        <div class="flex flex-col items-center justify-center6 gap-2">
          <h1 class="capitalize font-medium text-headerText">pay via</h1>

          <div class="bg-white w-11/12 rounded rounded-xl p-5">
            <img src="../../../assets/icons/paystack.svg" alt="" />
          </div>
        </div>
      </template>

      <div v-show="!linkGenerated" class="flex flex-col items-center">
        <form class="flex gap-4" action="">
          <div>
            <label class="text-dark-800 font-normal text-sm mr-2" for="amount"
              >One Time Payment</label
            >
            <input
              type="radio"
              name="percentage"
              id="percentage"
              :value="false"
              v-model="recurrentPayment"
              :checked="!recurrentPayment"
            />
          </div>

          <div>
            <label
              class="text-dark-800 font-normal text-sm mx-2"
              for="percentage"
              >Recurrent Payment</label
            >
            <input
              type="radio"
              name="percentage"
              id="percentage"
              :value="true"
              v-model="recurrentPayment"
              :checked="recurrentPayment"
            />
          </div>
        </form>
      </div>

      <span
        v-show="linkGenerated"
        @click="copyText(link)"
        class="text-hoverColor font-medium text-sm text-center"
        >{{ link }}</span
      >

      <div
        v-show="linkGenerated"
        class="flex mt-2 gap-x-5 justify-center items-center"
      >
        <div
          @click="copyText(link)"
          class="bg-white rounded rounded-xl px-4 py-2 cursor-pointer flex items-center gap-3"
        >
          <img src="../../../assets/icons/copy.svg" alt="" />
          <span class="text-dark-800 font-bold inline-block text-sm">Copy</span>
        </div>
        <div
          @click="shareLink = true"
          class="bg-white rounded rounded-xl px-4 py-2 cursor-pointer flex items-center gap-3"
        >
          <img src="../../../assets/icons/share.svg" alt="" />
          <span class="text-dark-800 font-bold inline-block text-sm"
            >Share</span
          >
        </div>
      </div>

      <hr class="mt-5 mb-7 border-0.5 border-dividerColor text-dividerColor" />
      <div
        v-show="linkGenerated"
        class="flex flex-col md:flex-row px-6 md:px-0 gap-6 w-full my-6 md:justify-center flex-col-reverse md:items-center"
      >
        <span
          @click="
            () => {
              payStack = false;
              linkGenerated = false;
            }
          "
          class="w-full md:w-40 cursor-pointer text-center bg-white rounded-lg text-dark-800 font-medium border border-secondary px-3 text-sm py-3"
          >Cancel</span
        >
        <easiButton
          @click="
            () => {
              payStack = false;
              linkGenerated = false;
              transferNotification = true;
            }
          "
          class="w-full md:w-auto focus:outline-none text-sm py-3 px-4 bg-secondary rounded-lg text-white"
        >
          <span class="text-sm"> I Have Made The Transfer</span>
        </easiButton>
      </div>

      <form
        @submit.prevent="initPaystack"
        v-show="!linkGenerated"
        class="flex flex-col w-full"
        autocomplete="on"
      >
        <div class="flex flex-col px-3 md:px-12 lg:px-8 xl:px-10">
          <label
            for="paymentName"
            class="text-left text-dark-800 text-sm font-medium"
            >Payment Name</label
          >
          <easiTextInput
            placeholder="Payment Name"
            type="text"
            name="paymentName"
            class="mt-1 mb-5"
          />

          <!-- <label
            for="description"
            class="text-dark-800 text-left text-sm font-medium"
            >Description
          </label> -->
          <easiTextArea
            class="focus:border-primary focus:ring-primary border-dark-200 p-16 bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border py-3 px-4 block appearance-none rounded-2xl mt-1 mb-6"
            name="description"
            id="description"
            cols="30"
            rows="5"
            placeholder="Payment Description"
          ></easiTextArea>

          <label
            for="amount"
            class="text-left text-dark-800 text-sm font-medium"
            >Amount</label
          >
          <easiTextInput
            placeholder="Input Amount"
            type="text"
            :format="true"
            name="amount"
            v-model="paystackArgs.amount"
            class="mt-1 mb-5"
            required
          />
          <label
            v-show="recurrentPayment"
            for="Interval"
            class="text-dark-800 text-left text-sm font-medium"
            >Select Interval</label
          >

          <easiSelectInput
            v-if="recurrentPayment === true"
            required
            class="mt-1 mb-5 text-left"
            @update="interval = $event"
            :value="interval"
            :options="payrollInterval"
            :searchable="true"
            :autoHeight="true"
          />
        </div>
        <div
          class="w-11/12 my-3 md:justify-center md:items-center px-4 md:px-0"
        >
          <easiButton
            :block="true"
            class="md:w-auto focus:outline-none text-sm py-3 bg-secondary rounded-lg text-white"
          >
            Generate Payment Link
          </easiButton>
        </div>
      </form>
    </easiModal>

    <easiModal v-if="shareLink" @close="shareLink = false">
      <template v-slot:header>
        <div class="flex items-center gap-x-4 justify-center">
          <img src="@/assets/icons/share-black.svg" alt="" />
          <h1 class="capitalize text-3xl font-medium text-headerText">Share</h1>
        </div>
      </template>

      <form @submit.prevent="sendEmail" class="flex flex-col" autocomplete="on">
        <div class="flex flex-col px-3 md:px-12 lg:px-8 xl:px-10">
          <label for="email" class="text-left text-dark-800 text-sm font-medium"
            >Email Address</label
          >
          <easiTextInput
            v-model="emailAddress"
            placeholder="Input Email Address"
            type="email"
            name="email"
            class="mt-1 mb-5"
            required
          />
        </div>
        <hr
          class="mt-3 md:mb-8 mb-4 border-0.5 border-dividerColor text-dividerColor"
        />
        <div class="px-10 w-full">
          <easiButtonNew
            :loading="loading"
            :block="true"
            class="w-full mb-4 md:mb-5"
            >Send Mail</easiButtonNew
          >
        </div>
      </form>
    </easiModal>

    <easiPreview v-if="previewModal" @close="previewModal = false">
      <template v-slot:header>
        <h1 class="text-3xl text-black">
          {{ getMonthByIndex(args.month) || "N/A" }}, {{ args.year }} PAYROLL
        </h1>
        <!-- <p
          v-if="bankInvalid"
          class="text-error-table font-bold text-base mt-7 mb-2"
        >
          Invalid bank details found
        </p>
        <p
          v-if="errorFound"
          class="text-error-table font-bold text-base mt-7 mb-2"
        >
          The highlted section(s) need to be corrected. Please correct and
          submit
        </p> -->
      </template>

      <template v-slot:body>
        <div
          style="background-color: #d9ebcd"
          class="rounded-xl w-full flex flex-col mb-4 justify-around p-4"
        >
          <div
            class="grid grid-cols-2 text-center items-center w-full md:w-auto max-w-full border border-outlineGray rounded-xl tracking-wide md:justify-center self-center gap-x-2 p-3 text-sm font-medium cursor-pointer bg-white"
          >
            <span
              class="w-full font-bold md:font-medium text-dark-800 text-lg md:text-base bg-white rounded-lg md:px-5 text-left px-3"
              >Total payroll load</span
            >
            <span
              class="w-full text-base font-bold bg-primary shadow-2xl text-white rounded-lg md:px-5 md:py-2 px-3 py-2"
              >{{ formatAmount(totalPayrollAmount.total) }}</span
            >
          </div>

          <div
            v-if="
              previewTotals &&
              Object.keys(previewTotals) &&
              Object.keys(previewTotals).length
            "
            class="flex gap-x-5 gap-y-5 flex-wrap justify-start mt-8 gap-3 mt-3 items-center"
          >
            <div
              v-for="(figure, i) in Object.keys(previewTotals)"
              :key="i"
              class="flex flex-col bg-white gap-2 rounded-2xl p-3 items-center"
              :class="
                ['subscriptionData', 'sumTotal', 'transactionFee'].includes(
                  figure
                )
                  ? 'hidden'
                  : undefined
              "
            >
              <span
                :class="
                  ['nhf', 'nsitf', 'itf', 'hmo', 'wht'].includes(figure)
                    ? 'uppercase'
                    : 'capitalize'
                "
                class="w-full font-medium text-newGray text-lg rounded-lg text-left"
                >{{ figure.replace(/([A-Z])/g, " $1") }}</span
              >
              <div
                style="min-width: 12rem; max-width: fit-content"
                class="grid text-left text-dark-800 font-bold items-center rounded-xl tracking-wide justify-start self-center gap-x-2 text-xl cursor-pointer"
              >
                {{ formatAmount(previewTotals[figure] || 0) }}
              </div>
            </div>

            <div
              class="flex flex-col bg-white gap-2 rounded-2xl p-3 items-center"
            >
              <span
                class="capitalize w-full font-medium text-newGray text-lg rounded-lg text-left"
                >Total compliance charges</span
              >
              <div
                style="min-width: 12rem; max-width: fit-content"
                class="grid text-left text-dark-800 font-bold items-center rounded-xl tracking-wide justify-start self-center gap-x-2 text-xl cursor-pointer"
              >
                {{
                  previewTotals &&
                  previewTotals.subscriptionData &&
                  previewTotals.subscriptionData.statCharges &&
                  previewTotals.subscriptionData.statCharges.statTotal &&
                  typeof previewTotals.subscriptionData.statCharges
                    .statTotal === "number"
                    ? formatAmount(
                        Number(
                          previewTotals.subscriptionData.statCharges.statTotal
                        )
                      )
                    : formatAmount(0)
                }}
              </div>
            </div>

            <div
              class="flex flex-col bg-white gap-2 rounded-2xl p-3 items-center"
            >
              <span
                class="capitalize w-full font-medium text-newGray text-lg rounded-lg text-left"
                >Total Transaction fee</span
              >
              <div
                style="min-width: 12rem; max-width: fit-content"
                class="grid text-left text-dark-800 font-bold items-center rounded-xl tracking-wide justify-start self-center gap-x-2 text-xl cursor-pointer"
              >
                {{
                  Object.keys(previewTotals).length &&
                  typeof previewTotals.transactionFee === "number"
                    ? formatAmount(
                        previewTotals && Number(previewTotals.transactionFee)
                      )
                    : formatAmount(0)
                }}
              </div>
            </div>

            <div
              class="flex flex-col bg-white gap-2 rounded-2xl p-3 items-center"
            >
              <span
                class="capitalize w-full font-medium text-newGray text-lg rounded-lg text-left"
                >Total Subscription fee</span
              >
              <div
                style="min-width: 12rem; max-width: fit-content"
                class="grid text-left text-dark-800 font-bold items-center rounded-xl tracking-wide justify-start self-center gap-x-2 text-xl cursor-pointer"
              >
                {{
                  previewTotals &&
                  previewTotals.subscriptionData &&
                  typeof previewTotals.subscriptionData.baseSub === "number"
                    ? formatAmount(
                        Number(previewTotals.subscriptionData.baseSub)
                      )
                    : formatAmount(0)
                }}
              </div>
            </div>
          </div>
        </div>
        <hr class="my-4 border-0.5 border-dividerColor text-dividerColor" />
        <div v-if="tableData.length" class="mt-2 h-full w-full">
          <easiTable
            :data="tableData"
            :header="configureHeaders"
            :useSelect="['Bank Code']"
            :frozenFields="['firstName', 'lastName']"
            :highlight="['firstName', 'lastName']"
            :mandatory="['firstName', 'lastName', 'Phone Number', 'Email']"
            :readonly="true"
          ></easiTable>
        </div>

        <div
          class="flex justify-center flex-col items-center gap-y-2 mt-10 md:mt-10 mb-3"
        >
          <div
            class="w-full flex md:flex-row flex-col gap-4 justify-center md:w-full text-xs"
          >
            <easiButton
              @click="downloadPayroll"
              variant="outlined"
              class="w-full md:w-80"
            >
              <svg
                class="inline-block mr-3 -mt-1"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 21H18"
                  stroke="#11453B"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12 3L12 17"
                  stroke="#11453B"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M17 12L12 17L7 12"
                  stroke="#8D8E8D"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span>Download Payroll</span>
            </easiButton>
            <easiButton @click="proceedToRun" class="w-full md:w-80">
              Run Payroll</easiButton
            >
          </div>
        </div>
      </template>
    </easiPreview>

    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span class="capitalize">{{ successMessage }}</span>
      </template>

      <template v-slot:action>
        <easiButton @click="returnToPayroll">Back to Salary List</easiButton>
      </template>
    </easiSuccess>

    <easiEligible
      v-if="ineligible"
      headerMessage="BVN Verification in Progress..."
      @close="ineligible = false"
    >
      <template v-slot:message>
        <!-- <span class="text-center mb-4">
          <strong>BVN Verification in Progress...</strong>
        </span> -->

        <span class="leading-5 text-left"
          >To speed up this process, kindly send the photo of your valid ID, a
          passport image, and your proof of residential address (e.g utility
          bill) to compliance@myeazipay.com.</span
        >
      </template>

      <template v-slot:action>
        <div class="px-4 sm:px-10 lg:px-6 xl:px-8">
          <button
            @click="ineligible = false"
            class="focus:outline-none text-sm mt-2 py-3 w-full px-4 md:mx-auto justify-center bg-primary rounded-full text-white"
          >
            Back To Payroll
          </button>
        </div></template
      >
    </easiEligible>

    <easiEligible
      v-if="misMatchExists"
      headerMessage="Payroll Mismatch & Duplicates"
      @close="misMatchExists = false"
      :detailed="true"
    >
      <template v-slot:message>
        <div class="flex flex-col items-center">
          <p class="text-dark-800 text-sm leading-5 text-center font-medium">
            We noticed some account number duplicates and account name mismatch
            with
            <span class="text-secondary capitalize">
              {{ numberToWords(errorArray.length) }}
              ({{ errorArray.length }})</span
            >
            of your employees. Kindly review to see if they are correct or not.
            If you proceed, the duplicates won’t be included in this payroll and
            will be categorised as unpaid until you correct them under edit
            salaries.
          </p>

          <div
            class="w-full rounded-tl-2xl rounded-tr-2xl h-80 overflow-x-hidden mt-5"
          >
            <table class="w-full">
              <thead>
                <tr>
                  <th
                    v-for="head in ['S/N', 'first name', 'last name', 'Error']"
                    :key="head"
                    class="bg-primary-deep font-medium capitalize text-white"
                    :class="[
                      head !== 'Error'
                        ? 'border-r border-outlineGray'
                        : undefined,
                      head === 'S/N' ? 'px-1 py-3' : 'p-1 md:p-3',
                    ]"
                  >
                    {{ head }}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(item, i) in errorArray" :key="item.sr">
                  <td
                    class="capitalize font-medium w-12 border-2 p-1 md:p-3 border-outlineGray text-dark-800"
                  >
                    {{ i + 1 }}
                  </td>

                  <td
                    class="capitalize font-medium w-1/4 border-l-2 border-b-2 p-1 md:p-3 border-outlineGray text-dark-800"
                  >
                    {{ item.firstName }}
                  </td>
                  <td
                    class="capitalize font-medium w-1/4 border-l-2 border-b-2 p-1 md:p-3 border-outlineGray text-dark-800"
                  >
                    {{ item.lastName }}
                  </td>
                  <td
                    class="flex flex-col items-center gap-2 capitalize font-medium w-full border-l-2 border-b-2 p-1 md:p-3 border-outlineGray text-dark-800"
                  >
                    <span v-show="item.accountMismatch">
                      Account name mismatch
                      <span v-show="item.duplicateAccount">,</span>
                    </span>
                    <span v-show="item.duplicateAccount">
                      Account number duplicate
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>

      <template v-slot:action>
        <div class="px-4 sm:px-10 flex items-center gap-3 lg:px-6 xl:px-8">
          <button
            @click="misMatchExists = false"
            class="focus:outline-none text-sm mt-2 py-3 w-full px-4 md:mx-auto justify-center border border-primary rounded-full text-primary"
          >
            Cancel
          </button>

          <button
            @click="proceedToRun"
            class="focus:outline-none text-sm mt-2 py-3 w-full px-4 md:mx-auto justify-center bg-primary rounded-full text-white"
          >
            <span> Proceed</span>
          </button>
        </div></template
      >
    </easiEligible>
    <easiRoundLoader v-if="loading" />
  </main>
</template>

<script setup>
import { ref, reactive, onMounted, watch, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import topUp from "@/components/Wallet/Topup.vue";

import { storeToRefs } from "pinia";
import { useDataStore } from "@/stores/data.js";
import { helperFunctions } from "@/composable/helperFunctions";
import { useToast } from "vue-toastification";
// import datums from "@/views/admin/employee/data.JSON";
const toast = useToast();

const emit = defineEmits(["completed"]);
const router = useRouter();
const route = useRoute();

const props = defineProps({
  employees: {
    type: Array,
    default: [],
  },
  allowances: {
    type: Array,
    default: [],
  },
});

const { formatAmount, calculatePercentage, downloadExcelFromBase64 } =
  helperFunctions;

const store = useDataStore();
const { query, mutate } = store;
const bonusDeduction = computed(() => store.getBonusAndDeduction);
const previewDataForPayroll = computed(() => store.getPayrollPreview);
// const subCharge = computed(() => store.getSubscriptionCharge);
const totalPayrollAmount = computed(() =>
  store.getTotalPayroll ? store.getTotalPayroll : { total: 0 }
);
const fullCompanyData = computed(() => store.getCompanyDetails);
// const companyData = computed(() => store.getCompanyAdmin);

const companyAccount = computed(() => store.getCompanyAccountDetails);
const balance = computed(() => store.getWalletBalance);
const payStackRes = computed(() => store.getPayStackResponse);
const successMessage = ref("");

const twoFaError = ref("");
const securityCode = ref("");
const securityQuestions = computed(
  () => store.getActiveSecurityQuestion && store.getActiveSecurityQuestion
);

const minutes = ref(2);
const seconds = ref("00");
const timer = ref(120);
const disabledBtn = ref(false);
const misMatchExists = ref(false);

const hoveredType = ref("");
function setHoveredType(type) {
  hoveredType.value = type;
}
function clearHoveredType() {
  hoveredType.value = null;
}
function isHovered(type) {
  return hoveredType.value === type;
}
function startTimer() {
  disabledBtn.value = true;
  const interval = setInterval(function () {
    minutes.value = parseInt(timer.value / 60, 10);
    seconds.value = parseInt(timer.value % 60, 10);

    minutes.value = minutes.value < 10 ? "0" + minutes.value : minutes.value;
    seconds.value = seconds.value < 10 ? "0" + seconds.value : seconds.value;

    if (--timer.value < 0) {
      timer.value = 120;
      disabledBtn.value = false;
      clearInterval(interval);
    }
  }, 1000);
}

const companyData = computed(() =>
  store.getCompanyAdmin ? store.getCompanyAdmin : {}
);
// console.log(companyData.value, "ADMIN");

const amountToBePaid = ref(0);
const biWeeklyHalf = ref("");
// const creditBalance = computed(() => {
//   return Number(balance.value ? balance.value.balance : 0) >=
//     amountToBePaid.value && typeof amountToBePaid.value === "number"
//     ? amountToBePaid.value
//     : 0;
// });
const creditBalance = computed(() => {
  const amount =
    amountToBePaid.value && typeof amountToBePaid.value === "number"
      ? amountToBePaid.value
      : 0;

  return Number(balance.value ? balance.value.balance : 0) >= amount;
});
const queryActiveSecurityQuestion = async () => {
  try {
    await query({
      endpoint: "ListSecurityQuestions",
      service: "SETTINGS",
      storeKey: "userSecurityQuestions",
    });
    console.log(securityQuestions.value, "SQUEST");
  } catch (e) {
    console.log(e.message);
  }
};
onMounted(async () => {
  await queryActiveSecurityQuestion();
});

const codeLabel = computed(() => {
  if (
    companyData.value &&
    companyData.value.twoFactorAuth &&
    companyData.value.twoFactorAuth.authType
  ) {
    const opts = {
      // SecurityQuestions:
      //   securityQuestions.value && securityQuestions.value.length
      //     ? securityQuestions.value[0].question.question
      //     : "",
      securityquestions: "Enter the answer to your secret question",

      sms: "Enter 2FA OTP",
      authenticator: "Open your authenticator app and enter code",
      backupcode: "Enter backup code",
    };
    console.log(opts, companyData.value.twoFactorAuth.authType);
    return opts[companyData.value.twoFactorAuth.authType.toLowerCase()] || "";
  }
  return "";
});

const queryCompany = async () => {
  try {
    await query({
      endpoint: "FetchCompaniesByAdminId",
      service: "SETTINGS",
      storeKey: "companyDetails",
    });
  } catch (e) {
    console.log(e);
  }
};

async function queryAllowance() {
  try {
    await query({
      endpoint: "ListAllowance",
      payload: {
        paging: {
          limit: 1000,
          skip: 0,
          search: null,
        },
      },
      service: "PAYROLL",
      storeKey: "allowance",
    });
  } catch (e) {
    console.log(e);
  }
}

async function queryEmployees() {
  await query({
    endpoint: "ListEmployees",
    payload: {
      paging: {
        limit: 1000,
        skip: 0,
        search: "",
        sort: { firstName: "ASC" },
      },
      filter: [],
    },
    service: "EMP",
    storeKey: "listEmployees",
  });
}

queryCompany();
queryEmployees();
queryAllowance();

onMounted(async () => {
  await queryCompany();
  await queryEmployees();
  await queryAllowance();
});

const paystackArgs = ref({
  amount: 0,
  email: "",
});
const payrollMonth = ref("");

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth();
payrollMonth.value = getMonthByIndex(Number(currentMonth));

const updateSuccess = ref(false);
const bonusData = ref([]);
const deductionData = ref([]);
const bonusOptions = ref([]);
const deductionOptions = ref([]);
const bonuses = ref([]);
const deductions = ref([]);
const insufficientBal = ref(true);
const bankTransfer = ref(true);
const topupOption = ref(false);
const headerKey = ref([]);
const transferDetails = ref(false);
const transferNotification = ref(false);
const payStack = ref(false);
const linkGenerated = ref(false);
const shareLink = ref(false);
const loading = ref(false);
const otpLoading = ref(false);
const resendOtpLoading = ref(false);
const confirmOtpLoading = ref(false);

const enterOtp = ref(false);
const otpCode = ref("");

const otpReference = ref("");
const configureHeaders = computed(() => {
  let headArr = [];
  // const head = Object.keys(tableData.value[0]);
  const head = headerKey.value;
  for (let i of head) {
    headArr.push({
      field: i,
      header: i,
    });
  }
  return headArr;
});

const link = ref("");

const previewModal = ref(false);
const payrollDetails = ref(true);
const years = ref([]);
const paymentOption = ref([]);
const interval = ref("");
const tableData = ref([]);
const bonusIds = ref([]);
const deductionIds = ref([]);
const errorRules = reactive({
  firstName: false,
  lastName: false,
  email: false,
  phoneNumber: false,
  companyName: false,
  pin: false,
  confirmPin: false,
  referralCode: false,
  otpCode: false,
});

const toggleToSalaryScreen = () => {
  payrollDetails.value = !payrollDetails.value;
};
const args = ref({
  employeeIds: [],
  month: currentMonth,
  year: currentYear,
  biweekly: "FIRSTHALF",
  bonuses: [],
  deductions: [],
  timeline: "MONTHLY",
  statutories: [],
  paymentOptions: {
    salary: false,
    bonus: false,
    statutory: false,
    deduction: false,
  },
});
const recurrentPayment = ref(false);
const accountNumber = ref("0711456765");

const paymentOptions = ref([
  {
    label: "Net Salary",
    value: "netSalary",
  },
  {
    label: "Bonus",
    value: "bonus",
  },
  {
    label: "Statutory Deductions",
    value: "statutoryDeductions",
  },
  // {
  //   label: "Deductions",
  //   value: "deduction",
  // },
]);

function copyText(val) {
  var text = val;
  navigator.clipboard.writeText(text);
  toast.info("Copied!");
}
const months = ref([
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]);
function getIndexByMonth(month) {
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  // return months[index];
  return months.indexOf(month);
}

function getMonthByIndex(index) {
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return months[index];
}
const payrollInterval = ref(["Weekly", "Monthly"]);
const statutoryOptions = ref([
  {
    label: "PAYE",
    value: "PAYE",
  },
  {
    label: "Pension",
    value: "Pension",
  },
  {
    label: "NHF",
    value: "NHF",
  },
  {
    label: "NSITF",
    value: "NSITF",
  },
  {
    label: "ITF",
    value: "ITF",
  },
  {
    label: "WHT",
    value: "WHT",
  },
  {
    label: "HMO",
    value: "HMO",
  },
  {
    label: "Life Insurance",
    value: "Group_Life_Insurance",
  },
  {
    label: "Life Assurance",
    value: "Employee_Life_Assurance",
  },
  {
    label: "Business Expense",
    value: "Business_Expense",
  },
  {
    label: "Gratuity",
    value: "Gratuity",
  },
]);

for (let i = currentYear + 5; i >= 1990; i--) {
  years.value.push(String(i));
}

const mapIdToName = () => {
  if (bonuses.value.length) {
    const bon = bonusData.value.map((bo) => {
      if (bonuses.value.includes(bo._id) && !isExpired(bo.endDate)) {
        return bo._id;
      }
    });
    args.value.bonuses = bon.filter((item) => item != null);
  }

  const dedu = deductionData.value.map((ded) => {
    if (!isExpired(ded.endDate)) {
      return ded._id;
    }
  });
  args.value.deductions = dedu.filter((item) => item != null); //Isn't being used on the backend anymore as deductions are automatically used by default.

  // if (deductions.value.length) {
  //   const dedu = deductionData.value.map((ded) => {
  //     if (deductions.value.includes(ded._id) && !isExpired(ded.endDate)) {
  //       return ded.name;
  //     }
  //   });
  //   args.value.deductions = dedu.filter((item) => item != null);
  // }
  if (paymentOption.value.includes("bonus") && !args.value.bonuses.length) {
    toast.error("No Bonuses Selected for Bonus Payment");

    return false;
  }
  // if (
  //   paymentOption.value.includes("deduction") &&
  //   !args.value.deductions.length
  // ) {
  //   toast.error("No Deductions Selected for Deduction Processing");

  //   return false;
  // }
  if (
    paymentOption.value.includes("statutoryDeductions") &&
    !args.value.statutories.length
  ) {
    toast.error("No Statutory Deductions Selected");

    return false;
  }

  return true;
};
const capitalize = (str) => {
  const str2 = str.charAt(0).toUpperCase() + str.slice(1);
  return str2;
};

function checkOTP(otp_input) {
  otpCode.value = otp_input;
}
const ineligible = ref(false);

async function sendOTP(via) {
  // if (
  //   fullCompanyData.value &&
  //   fullCompanyData.value.bvn &&
  //   fullCompanyData.value.bvnVerified == false
  // ) {
  //   ineligible.value = true;
  //   return;
  // }

  let admin = "";

  if (via && typeof via === "string" && via === "SMS") {
    admin =
      companyData.value && companyData.value.companyAdmin
        ? companyData.value.companyAdmin.email
        : fullCompanyData.value.companyAdmin.email; //changed from phoneNumber to email but keeping the code in case it's later changed to phone number
  } else {
    admin =
      companyData.value && companyData.value.companyAdmin
        ? companyData.value.companyAdmin.email
        : fullCompanyData.value.companyAdmin.email;
  }

  //validate form field
  const payload = {
    channel: via && typeof via === "string" ? via : "EMAIL",

    value: admin,

    type: "RUN_PAYROLL",
  };

  // otpLoading.value = true;
  // resendOtpLoading.value = true;
  via && typeof via === "string" && via === "SMS"
    ? (resendOtpLoading.value = true)
    : (otpLoading.value = true);

  try {
    let res = await mutate({
      endpoint: "SendOTP",
      data: { input: payload },
      service: "AUTH",
    });

    if (res.success) {
      startTimer();

      enterOtp.value = true;
      otpLoading.value = false;
      otpReference.value = res.data.reference;
      // toast.success("OTP")
      // window.localStorage.setItem("otpReference", res.data.reference);
    } else {
      toast.error(res.message);
    }
  } catch (e) {
    otpLoading.value = false;
    resendOtpLoading.value = false;
  } finally {
    otpLoading.value = false;
    resendOtpLoading.value = false;
  }
}

async function confirmOtp() {
  const admin =
    companyData.value && companyData.value.companyAdmin
      ? companyData.value.companyAdmin.email
      : fullCompanyData.value.companyAdmin.email;

  if (otpCode.value.trim() === "") {
    errorRules.otpCode = "Please enter the otp code sent to your email";
  } else {
    confirmOtpLoading.value = true;

    const payload = {
      reference: otpReference.value,
      code: otpCode.value,
      value: admin,
    };

    try {
      let res = await mutate({
        endpoint: "ConfirmOTP",
        data: { input: payload },
        service: "AUTH",
      });

      if (res.success) {
        //Clear error state
        errorRules.otpCode = false;
        //Register user
        await processPayroll();

        // confirmOtpLoading.value = false;
        // enterOtp.value = false;
      } else {
        errorRules.otpCode = res.message;
      }
    } catch (e) {
      // confirmOtpLoading.value = false;
      e.message.toLowerCase() == "record not found"
        ? (errorRules.otpCode = "OTP doesn't match. Please try again")
        : null;
    } finally {
      confirmOtpLoading.value = false;
    }
  }
}

// async function resendOTP() {
//   const userDetails = JSON.parse(window.localStorage.getItem("userDetails"));

//   // log(args);
//   // log("user details", userDetails);

//   const payload = {
//     channel: "EMAIL",
//     token_length: 4,
//     email: userDetails.email,
//     type: "SIGNUP",
//     phoneNumber: processNumber(userDetails.phoneNumber),
//     firstName: userDetails.firstName,
//   };

//   try {
//     let res = await mutate({
//       endpoint: "SendOTP",
//       data: { input: payload },
//       service: "AUTH",
//     });

//     if (res.success) {
//       loading.btnOtp = false;
//       toast.success("OTP sent successful. Please check email");
//       window.localStorage.setItem("otpReference", res.data.reference);
//     }
//   } catch (e) {
//     loading.btnOtp = false;
//   }
// }

// const bringToTopLevel = (arr) => {
//   const updatedDataArray = arr.map((dataObject) => {
//     const allowanceDetailsKeys = Object.keys(dataObject.allowanceDetails);
//     const bonusKeys = Object.keys(dataObject.bonus);
//     const deductionKeys = Object.keys(dataObject.deduction);

//     allowanceDetailsKeys.forEach(
//       (key) => (dataObject[key] = dataObject.allowanceDetails[key])
//     );
//     bonusKeys.forEach((key) => (dataObject[key] = dataObject.bonus[key]));
//     deductionKeys.forEach(
//       (key) => (dataObject[key] = dataObject.deduction[key])
//     );

//     return dataObject;
//   });
//   return updatedDataArray;
// };
// const mergeObjectsWithNullCheck = (target, ...sources) => {
//   sources.forEach((source) => {
//     Object.keys(source).forEach((key) => {
//       if (source[key] !== null && source[key] !== undefined) {
//         if (!(key in target)) {
//           target[key] = source[key];
//         } else {
//           // Handle key conflict by renaming the key from the source object
//           const newKey = `${key}_`;
//           target[newKey] = source[key];
//         }
//       }
//     });
//   });
// };
const mergeObjectsWithNullCheck = (target, prefix, ...sources) => {
  sources.forEach((source) => {
    Object.keys(source).forEach((key) => {
      if (source[key] !== null && source[key] !== undefined) {
        const newKey = `${key} ${prefix}`;
        target[newKey] = source[key];
      }
    });
  });
};

const bringToTopLevel = (arr) => {
  const updatedDataArray = arr.map((dataObject) => {
    const newObject = {};
    mergeObjectsWithNullCheck(
      newObject,
      "allowance",
      dataObject.allowanceDetails
    );
    mergeObjectsWithNullCheck(newObject, "bonus", dataObject.bonus);
    mergeObjectsWithNullCheck(newObject, "deduction", dataObject.deduction);
    // delete newObject.allowanceDetails;
    // delete newObject.bonus;
    // delete newObject.deduction;

    return { ...dataObject, ...newObject };
  });
  return updatedDataArray;
};

const convertResData = (data) => {
  const newData = data.map((d) => {
    return {
      id: d.profile.employeeId,
      firstName: d.profile.firstName,
      lastName: d.profile.lastName,
      bankName:
        d.paymentDetails && d.paymentDetails.bankName
          ? d.paymentDetails.bankName
          : "N/A",
      accountNo:
        d.paymentDetails && d.paymentDetails.accountNumber
          ? d.paymentDetails.accountNumber
          : "N/A",
      grossAmount: d.salary && d.salary.grossAmount ? d.salary.grossAmount : 0,
      netAmount: d.salary && d.salary.netAmount ? d.salary.netAmount : 0,
      transactionFee: d.transactionFee || 0,
      allowanceDetails: d.salary ? d.salary.allowanceDetails : {},
      bonus: d.Bonus ? d.Bonus : {},
      deduction: d.Deduction ? d.Deduction : {},

      itf: d.statutory && d.statutory.itf ? d.statutory.itf.amount : 0,
      nhf: d.statutory && d.statutory.nhf ? d.statutory.nhf.amount : 0,
      hmo: d.statutory && d.statutory.hmo ? d.statutory.hmo.amount : 0,
      pension:
        d.statutory && d.statutory.pension ? d.statutory.pension.amount : 0,
      paye: d.statutory && d.statutory.paye ? d.statutory.paye.amount : 0,
      lifeAssurance:
        d.statutory && d.statutory.lifeAssurance
          ? d.statutory.lifeAssurance.amount
          : 0,
      lifeInsurance:
        d.statutory && d.statutory.lifeInsurance
          ? d.statutory.lifeInsurance.amount
          : 0,
      wht: d.statutory && d.statutory.wht ? d.statutory.wht.amount : 0,
      nsitf: d.statutory && d.statutory.nsitf ? d.statutory.nsitf.amount : 0,
      gratuity:
        d.statutory && d.statutory.gratuity ? d.statutory.gratuity.amount : 0,
      businessExpense:
        d.statutory && d.statutory.businessExpense
          ? d.statutory.businessExpense.amount
          : 0,
    };
  });
  let final = bringToTopLevel(newData);
  // let final = newData.map((dt) => {
  //   const da = props.allowances.map((a) => {
  //     if (typeof dt === "object") {
  //       dt[a] = dt["allowanceDetails"][a];
  //     }
  //     return dt;
  //   });
  //   return da[0];
  // });
  // if (bonusIds.value && bonusIds.value.length) {
  //   final = newData.map((dt) => {
  //     const da = bonusIds.value.map((a) => {
  //       if (typeof dt === "object") {
  //         dt[a] = dt["bonus"][a];
  //       }
  //       return dt;
  //     });
  //     return da[0];
  //   });
  // }
  // if (deductionIds.value && deductionIds.value.length) {
  //   final = newData.map((dt) => {
  //     const da = deductionIds.value.map((a) => {
  //       if (typeof dt === "object") {
  //         dt[a] = dt["deduction"][a];
  //       }
  //       return dt;
  //     });
  //     return da[0];
  //   });
  // }

  return final;
};
const balanceInWallet = ref(0);
onMounted(() => {
  balanceInWallet.value = balance.value.balance;
});
const queryWalletBalance = async () => {
  // loading.value = true;
  try {
    const id = "";

    let res = await query({
      endpoint: "ViewWalletBalance",
      payload: {
        input: {
          companyId: id,
        },
      },
      service: "PAYROLL",
      storeKey: "walletBalance",
    });

    topupOption.value = false;

    return res.balance;
  } catch (err) {
    console.log(err);
  } finally {
    // loading.value = false;
  }
};
const startQueryingBalance = async () => {
  try {
    const initialBalance = balanceInWallet.value;

    while (true) {
      const newBalance = await queryWalletBalance();

      if (newBalance !== initialBalance) {
        balanceInWallet.value = newBalance;
        break;
      }

      await new Promise((resolve) => setTimeout(resolve, 1000)); // Delay between queries
    }
  } catch (err) {
    console.error(err);
  }
};
function transform(arr) {
  // const benArr = [
  //   "paye",
  //   "pension",
  //   "hmo",
  //   "lifeInsurance",
  //   "lifeAssurance",
  //   "nsitf",
  //   "itf",
  //   "nhf",
  //   "wht",
  //   "gratuity",
  //   "businessExpense",
  // ];
  const staticKeys = [
    "firstName",
    "lastName",
    "bankName",
    "accountNo",
    "grossAmount",
    "netAmount",
    "transactionFee",
  ];
  let keys = [];
  const selectedDeds = matchStatutoryDeds(args.value.statutories);

  if (args.value.paymentOptions.statutory) {
    for (let i of selectedDeds) {
      keys.push(i);
    }
  }
  if (args.value.paymentOptions.bonus) {
    for (let i of bonusIds.value) {
      if (args.value.bonuses.includes(i)) {
        const bonObject = bonusData.value.find((b) => b._id === i);
        keys.push(`${bonObject.name} bonus`);
      }
    }
  }
  // if (args.value.paymentOptions.deduction) {
  //   for (let i of deductionIds.value) {
  //     if (args.value.deductions.includes(i)) {
  //       keys.push(`${i} deduction`);
  //     }
  //   }
  // }
  if (
    deductionIds.value &&
    deductionIds.value.length &&
    args.value.paymentOptions.salary
  ) {
    for (let i of deductionIds.value) {
      if (args.value.deductions.includes(i)) {
        const dedObject = deductionData.value.find((d) => d._id === i);
        keys.push(`${dedObject.name} deduction`);
        // keys.push(`${i} deduction`);
      }
    }
  }
  if (args.value.paymentOptions.salary) {
    // keys.splice(2, 0, "netAmount");
    // keys.splice(2, 0, "grossAmount");
    for (let i of props.allowances) {
      keys.splice(4, 0, `${i} allowance`);
    }
  }

  const dynamicKeys = keys.filter((el) => el != null);
  headerKey.value = [...staticKeys, ...dynamicKeys];

  const newArr = arr.map((emp) => {
    const data = headerKey.value
      .filter((key) => {
        // for (let k of keys) {
        //   if (!Object.keys(emp).includes(k)) {
        //     console.log(k);
        //     emp[k] = null;
        //   }
        // }

        // return keys.includes(key);
        emp[key] ? emp[key] : "";
        return emp;
      })
      .reduce((obj, key) => {
        if (typeof emp[key] === "object") {
          return Object.assign(obj, {
            [key]: emp[key]
              ? emp[key].amount
                ? formatAmount(
                    typeof emp[key].amount === "number"
                      ? Number(emp[key].amount)
                      : 0
                  )
                : formatAmount(
                    Number(
                      calculatePercentage(emp.grossAmount, emp[key].percentage)
                    )
                  )
              : "N/A",
          });
        }
        if (typeof emp[key] === "number") {
          return Object.assign(obj, {
            [key]: emp[key] ? formatAmount(Number(emp[key])) : "N/A",
          });
        }
        return Object.assign(obj, {
          [key]: emp[key] ? emp[key] : "N/A",
        });
      }, {});

    return data;
  });

  return newArr;
}
const dateFromStore = computed(() =>
  store.getPayrollDate && store.getPayrollDate.length
    ? store.getPayrollDate
    : null
);
onMounted(() => {
  if (route.params.id || dateFromStore.value) {
    const payrollDate = route.params.id
      ? route.params.id.split("-")
      : dateFromStore.value.split("-");

    args.value.year = Number(payrollDate[1]);
    payrollMonth.value = getMonthByIndex(Number(payrollDate[0]));
    console.log(payrollMonth.value, "dfsv");
  }
});
const optionError = ref("");
const errorArray = ref([]);

function proceedToRun() {
  if (errorArray.value.length && misMatchExists.value == false) {
    misMatchExists.value = true;
    return;
  }
  misMatchExists.value = false;
  payrollDetails.value = false;
  previewModal.value = false;
}
function extractMismatchAndDuplicates(obj) {
  if (obj) {
    const duplicatesAndMismatches = obj
      .filter((data) => data.accountMismatch === true || data.duplicateAccount)
      .map((item) => {
        return {
          accountNumber: item.paymentDetails.accountNumber,
          bankName: item.paymentDetails.bankName,
          employeeId: item.profile.employeeId,
          firstName: item.profile.firstName,
          lastName: item.profile.lastName,
          returnedName: item.returnedName,
          accountMismatch: item.accountMismatch,
          duplicateAccount: item.duplicateAccount,
        };
      });
    return duplicatesAndMismatches || [];
  }
  return [];
}
const queryPayroll = async () => {
  if (!paymentOption || !paymentOption.value.length) {
    optionError.value = "Please specify what you want to pay";
    return;
  }
  const checkBon = mapIdToName();
  if (!checkBon) {
    return;
  }
  loading.value = true;

  args.value.month = getIndexByMonth(payrollMonth.value);
  if (args.value.timeline === "BIWEEKLY") {
    args.value["biweekly"] = biWeeklyHalf.value;
  } else {
    delete args.value["biweekly"];
  }
  args.value.employeeIds = props.employees.map((emp) => emp.employeeId);
  args.value.year = Number(args.value.year);
  paymentOption.value.includes("netSalary")
    ? (args.value.paymentOptions.salary = true)
    : (args.value.paymentOptions.salary = false);
  paymentOption.value.includes("bonus")
    ? (args.value.paymentOptions.bonus = true)
    : (args.value.paymentOptions.bonus = false);

  // args.value.deductions && args.value.deductions.length
  //   ? (args.value.paymentOptions.deduction = true)
  //   : (args.value.paymentOptions.deduction = false);
  // paymentOption.value.includes("deduction")
  //   ? (args.value.paymentOptions.deduction = true)
  //   : (args.value.paymentOptions.deduction = false);
  paymentOption.value.includes("statutoryDeductions")
    ? (args.value.paymentOptions.statutory = true)
    : (args.value.paymentOptions.statutory = false);

  try {
    let res = await query({
      endpoint: "PreviewPayroll",
      payload: {
        input: args.value,
      },
      service: "PAYROLL",
      storeKey: "previewPayroll",
    });
    if (res) {
      const resData = convertResData(previewDataForPayroll.value);
      tableData.value = transform(resData);
      await queryCalculatePayments(args.value);
      errorArray.value = extractMismatchAndDuplicates(
        previewDataForPayroll.value
      );
      if (tableData.value.length) {
        previewModal.value = true;
      }
    }
  } catch (err) {
    loading.value = false;
    toast.error(err.message);
    console.log(err);
  } finally {
    loading.value = false;
  }
};
const previewTotals = ref({});
// const subscriptionFee = ref(0);
// const fetchSubFee = async (obj) => {
//   const arg = {
//     year: obj.year,
//     statutories: obj.statutories,
//     paymentOptions: obj.paymentOptions,
//     month: obj.month,
//     employeeIds: obj.employeeIds,
//   };
//   try {
//     let res = await query({
//       endpoint: "GetSubscriptionCharges",
//       payload: {
//         input: arg,
//       },
//       service: "PAYROLL",
//       storeKey: "subscriptionCharge",
//     });
//     subscriptionFee.value = (res && res.data && res.data.total) || 0;
//   } catch (e) {
//     console.log(e);
//   }
// };
const queryCalculatePayments = async (arg) => {
  loading.value = true;

  try {
    let res = await query({
      endpoint: "CalculatePayments",
      payload: {
        input: arg,
      },
      service: "PAYROLL",
      storeKey: "totalPayroll",
    });
    amountToBePaid.value =
      res && typeof res.sumTotal === "number" ? Number(res.sumTotal) : 0;
    if (res && typeof res === "object") {
      const { statutoryDetails, ...rest } = res;
      const newData = { ...rest, ...statutoryDetails };

      previewTotals.value = Object.fromEntries(
        Object.entries(newData).filter(
          ([key, value]) => value !== null && key !== "__typename"
        )
      );
    }

    loading.value = false;
  } catch (err) {
    loading.value = false;
    console.log(err);
  } finally {
    loading.value = false;
  }
};

const downloadPayroll = async () => {
  loading.value = true;

  try {
    let res = await query({
      endpoint: "DownloadPayrollPreview",
      payload: {
        input: args.value,
      },
      service: "PAYROLL",
      // storeKey: "totalPayroll",
    });
    console.log(res);
    const fileName = `Payroll Sheet - ${getMonthByIndex(args.value.month)}, ${
      args.value.year
    }`;
    downloadExcelFromBase64(res.data.base64, fileName);

    loading.value = false;
  } catch (err) {
    loading.value = false;
    console.log(err);
  } finally {
    loading.value = false;
  }
};
function matchStatutoryDeds(arr) {
  const data = arr.map((item) => {
    if (item === "PAYE") return "paye";
    if (item === "Pension") return "pension";
    if (item === "NHF") return "nhf";
    if (item === "NSITF") return "nsitf";
    if (item === "ITF") return "itf";
    if (item === "WHT") return "wht";
    if (item === "Group_Life_Insurance") return "lifeInsurance";
    if (item === "Employee_Life_Assurance") return "lifeAssurance";
    if (item === "HMO") return "hmo";
    if (item === "Business_Expense") return "businessExpense";
    if (item === "Gratuity") return "gratuity";
  });
  return data;
}

function isExpired(expiryDateStr) {
  if (expiryDateStr || !expiryDateStr) {
    return false;
  }
  const expiryDate = new Date(expiryDateStr);
  const currentDate = new Date();
  return expiryDate < currentDate;
}

const queryBonus = async () => {
  try {
    await query({
      endpoint: "ListBonusAndDeduction",
      payload: {
        input: {
          paging: {
            limit: 1000,
            skip: 0,
            search: "",
          },
          type: "Bonus",
        },
      },
      service: "PAYROLL",
      storeKey: "listBonusAndDeduction",
    });
    bonusData.value = bonusDeduction.value;

    const data = bonusData.value.map((bd) => {
      bonusIds.value.push(bd._id);
      if (!isExpired(bd.endDate)) {
        return {
          label: bd.name,
          value: bd._id,
        };
      }
    });
    bonusOptions.value = data.filter((el) => typeof el !== "undefined");
  } catch (err) {
    console.log(err);
  }
};

const queryDeduction = async () => {
  try {
    await query({
      endpoint: "ListBonusAndDeduction",
      payload: {
        input: {
          paging: {
            limit: 1000,
            skip: 0,
            search: "",
          },
          type: "Deduction",
        },
      },
      service: "PAYROLL",
      storeKey: "listBonusAndDeduction",
    });
    deductionData.value = bonusDeduction.value;
    const data = deductionData.value.map((bd) => {
      deductionIds.value.push(bd._id);
      if (!isExpired(bd.endDate)) {
        return {
          label: bd.name,
          value: bd._id,
        };
      }
    });
    deductionOptions.value = data.filter((el) => typeof el !== "undefined");
  } catch (err) {
    console.log(err);
  }
};
const payload = ref({
  input: {},
  twoFa: {},
});
const sendSlip = ref(true);

const sendPayslipFN = async () => {
  // loading.value = true;

  const payload = {
    // companyId: window.localStorage.getItem("companyId"),
    employeeIds: [...args.value.employeeIds],
    month: args.value.month,
    year: args.value.year,
    timeline: args.value.timeline,
  };

  try {
    const res = await mutate({
      endpoint: "SendPaySlipEmails",
      data: {
        input: payload,
      },
      service: "PAYROLL",
    });

    if (res.success) {
      console.log(res);
    } else {
      toast.error(res.message);
    }
  } catch (err) {
    console.log(err);
  } finally {
    loading.value = false;
  }
};

const processPayroll = async () => {
  const twoFa = companyData.value;
  payload.value["input"] = args.value;

  // let payload = {
  //   input: args.value,
  // };

  try {
    loading.value = true;
    if (twoFa.twoFactorAuth !== null && twoFa.twoFactorAuth.authType !== null) {
      if (!securityCode.value.length) {
        twoFaError.value = "2FA is required";
        return;
      }
      if (twoFa.twoFactorAuth.authType.toLowerCase() === "securityquestions") {
        payload.value.twoFa["securityQuestions"] = {
          questions: [
            {
              question: twoFa.twoFactorObj.securityQuestions[0].question,
              answer: securityCode.value,
            },
          ],
        };
      }
      if (twoFa.twoFactorAuth.authType.toLowerCase() === "sms") {
        payload.value.twoFa["sms"] = {
          otpRef: securityCode.value,
        };
      }

      if (twoFa.twoFactorAuth.authType.toLowerCase() === "authenticator") {
        payload.value.twoFa["authenticator"] = {
          code: securityCode.value,
        };
      }

      if (twoFa.twoFactorAuth.authType.toLowerCase() === "backupcode") {
        payload.value.twoFa["backupCode"] = {
          code: securityCode.value,
        };
      }
      payload.value.twoFa["authType"] = twoFa.twoFactorAuth.authType;
    } else {
      delete payload.value["twoFa"];
    }
    // if (
    //   fullCompanyData.value &&
    //   exceptions.includes(fullCompanyData.value._id)
    // ) {
    //   await sendPayloadEmail(payload.value.input);
    //   successMessage.value = "Payment Initiated";
    //   updateSuccess.value = true;
    //   await queryWalletBalance();
    //   loading.value = false;
    //   enterOtp.value = false;
    //   return;
    // }
    let res = await mutate({
      endpoint: "ProcessPayroll",
      data: payload.value,
      service: "PAYROLL",
    });

    if (
      res.toLowerCase() === "payroll has been processed" ||
      res.toLowerCase() === "payment initiated"
    ) {
      //visit later for proper response handling

      successMessage.value = res;
      updateSuccess.value = true;

      if (sendSlip.value) {
        await sendPayslipFN();
      }

      await queryWalletBalance();
      loading.value = false;
      enterOtp.value = false;
    } else {
      toast.error(res);
      loading.value = false;
    }
    console.log(res, "PROCESS PAYROll");
  } catch (e) {
    loading.value = false;
    toast.error(e.message);
    console.log(e);
  }
};

async function queryCompanyAccountDetails() {
  try {
    await query({
      endpoint: "ViewCompanyVirtualAccount",
      service: "AUTH",
      storeKey: "companyAccountDetails",
    });
  } catch (e) {
    console.log(e);
  }
}

const returnToPayroll = () => {
  updateSuccess.value = false;
  emit("completed", false);
};

async function queryBonusOrDed() {
  try {
    await this.query({
      endpoint: "ListBonusAndDeduction",
      payload: {
        input: {
          paging: {
            limit: 1000,
            skip: 0,
            search: "",
          },
          type: "Bonus",
        },
      },
      service: "PAYROLL",
      storeKey: "listBonusAndDeduction",
    });
  } catch (err) {
    console.log(err);
  }
}

const initPaystack = async () => {
  paystackArgs.value.amount = Number(paystackArgs.value.amount);
  loading.value = true;

  try {
    let res = await mutate({
      endpoint: "PaystackPaymentInit",
      data: { input: paystackArgs.value },
      service: "PAYROLL",
    });

    if (res.authorizationUrl.length) {
      link.value = res.authorizationUrl;
      await verifyPaystack(res.reference);
    }
  } catch (e) {
    loading.value = false;
    console.log(e);
  }
};

async function verifyPaystack(paymentReference) {
  try {
    await query({
      endpoint: "VerifyPaystackPayment",
      service: "PAYROLL",
      payload: { input: { paymentReference } },
      storeKey: "payStackResponse",
    });
    if (payStackRes.value.paymentSuccessful) {
      paystackArgs.value.amount = 0;
      bankTransfer.value = true;
      // payStack.value = false;
      linkGenerated.value = true;
      loading.value = false;
    }
  } catch (e) {
    console.log(e);
  }
}

const emailAddress = ref("");
const mailArgs = ref({
  HTMLPart: null,
  To: [
    {
      Email: "",
    },
  ],
  Subject: "",
});

async function sendEmail() {
  mailArgs.value.HTMLPart = link.value;

  mailArgs.value.Subject = `Paystack payment link`;
  mailArgs.value.To[0].Email = emailAddress.value;
  loading.value = true;

  try {
    let res = await mutate({
      endpoint: "SendEmail",
      data: { input: mailArgs.value },
      service: "PAYROLL",
    });
    if (res.success) {
      loading.value = false;
      shareLink.value = false;
    } else {
      loading.value = false;
      toast.error(res.message);
    }
  } catch (err) {
    loading.value = false;
    console.log(err);
  }
}

// async function sendPayloadEmail(input) {
//   mailArgs.value.HTMLPart = JSON.stringify(input);

//   mailArgs.value.Subject = `Run payroll for ${fullCompanyData.value.company.companyName}`;
//   mailArgs.value.To[0].Email = "eazieazipay@gmail.com";
//   loading.value = true;

//   try {
//     let res = await mutate({
//       endpoint: "SendEmail",
//       data: { input: mailArgs.value },
//       service: "AUTH",
//     });
//     if (res.success) {
//       loading.value = false;
//       shareLink.value = false;
//     } else {
//       loading.value = false;
//       toast.error(res.message);
//     }
//   } catch (err) {
//     loading.value = false;
//     console.log(err);
//   } finally {
//     loading.value = false;
//   }
// }

const fetchCompanyAdminDetails = async () => {
  await query({
    endpoint: "FetchCompanyAdmin",
    service: "SETTINGS",
    storeKey: "companyAdmin",
  });

  console.log("Company Admin Details");
  // log(adminDetails);
};

fetchCompanyAdminDetails();

onMounted(() => {
  paystackArgs.value.email = companyData.value.companyAdmin.email;
  // console.log(fullCompanyData.value, "company ID");
});

// onMounted(() => {
//   const report = convertResData(datums);
//   console.log(report, "repo>>");
// }); script to generate receipt for Abdul

function log(arg) {
  console.log(JSON.parse(JSON.stringify(arg)));
}

queryCompanyAccountDetails();
queryWalletBalance();
queryBonus();
queryDeduction();
const bvnNumber = ref("");

async function addBvn(endpoint) {
  loading.value = true;

  try {
    let res = await mutate({
      endpoint,
      data: { input: { bvn: bvnNumber.value } },
      service: "SETTINGS",
    });
    if (res.success) {
      // await queryCompanyAccountDetails();
      await queryCompany();
    } else {
      toast.error(res.message);
    }
  } catch (err) {
    console.log(err);
    toast.error(err.message);
  } finally {
    loading.value = false;
  }
}

async function submitBvn() {
  if (bvnNumber.value.length !== 11) {
    toast.error("BVN length must be 11 characters");
    return false;
  }
  try {
    if (fullCompanyData.value) {
      if (fullCompanyData.value.registrationType === "COMPANY") {
        await addBvn("CreateAccountInformation");
        return;
      }
      await addBvn("CreateAccountInformation");
    }
  } catch (e) {
    console.log(e);
  }
}

function numberToWords(number) {
  const units = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
  ];
  const teens = [
    "",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];
  const tens = [
    "",
    "ten",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];

  if (number === 0) {
    return "zero";
  }

  function convertLessThanOneThousand(num) {
    if (num < 10) {
      return units[num];
    } else if (num < 20) {
      return teens[num - 10];
    } else if (num < 100) {
      return (
        tens[Math.floor(num / 10)] +
        (num % 10 !== 0 ? "-" + units[num % 10] : "")
      );
    } else {
      return (
        units[Math.floor(num / 100)] +
        " hundred" +
        (num % 100 !== 0 ? " and " + convertLessThanOneThousand(num % 100) : "")
      );
    }
  }

  if (number < 0 || number > 999) {
    return "Number out of range (0-999)";
  }

  return convertLessThanOneThousand(number);
}
</script>

<style scoped>
.wallet {
  background: linear-gradient(89.6deg, #0a244f 0.35%, #c92f02 268.44%);
}
table {
  border-collapse: collapse;
  width: 100%;
}
</style>
