<template>
  <div class="card relative w-full">
    <DataTable
      v-model:selection="selectedData"
      :value="visibleData"
      dataKey="id"
      tableStyle="min-width: 100%"
      class="p-datatable-md text-size hidden md:block"
    >
      <!-- <Column
        selectionMode="multiple"
        :headerStyle="headerStyle"
        :style="style"
        class="text-size custom-checkbox"
      ></Column> -->
      <Column
        v-for="head of headers"
        :field="head.field"
        :header="label(head.header)"
        :key="head.field"
        :headerStyle="headerStyle"
        :style="style"
      >
        <template #body="slotProps">
          <div class="cursor-pointer" @click="getEmpHistory(slotProps.data.employeeId)">
            {{ slotProps.data[head.field] }}
          </div>
        </template>
      </Column>
    </DataTable>

    <div
      v-for="(data, i) in visibleData"
      :key="i"
      class="flex md:hidden flex-col gap-2 w-full mt-3 shadow-sm rounded-lg py-3 px-2"
      :class="showOptions === data.employeeName ? 'border-2 border-outlineGray' : undefined"
    >
      <div class="grid grid-flow-col auto-cols-auto justify-between items-center w-full gap-4 bg-white">
        <div class="flex items-center gap-3">
          <div>
            <input
              style="background-color: #aaaaaa"
              class="border border-outlineGray rounded-md opacity-80"
              type="checkbox"
            />
          </div>
          <span class="text-sm text-primary-deep">{{ String(i + 1).padStart(2, "0") }}</span>
        </div>

        <div
          @click="getEmpHistory(data.employeeId)"
          class="flex justify-start w-full text-sm col-span-1 text-dark-800 gap-3"
        >
          <p class="">{{ data.employeeName }}</p>
        </div>

        <div class="flex items-center gap-3">
          <button class="focus:outline-none bg-highlight text-sm rounded-full py-2 px-4 text-dark-800">View</button>

          <button
            @click="showAll(data.employeeName)"
            class="focus:outline-none border border-outlineGray bg-white text-lg rounded-xl py-2 px-4 text-dark-20"
          >
            <span v-show="showOptions !== data.employeeName">+</span>
            <span v-show="showOptions === data.employeeName">-</span>
          </button>
        </div>
      </div>
      <hr class="border-0.5 mt-1 mb-3 border-outlineGray" v-show="showOptions === data.employeeName" />

      <div
        v-show="showOptions === data.employeeName"
        v-for="ob in headers"
        :key="ob"
        class="flex justify-start w-full -mt-2"
        :class="[
          ob.header === 's/n' ? 'hidden' : undefined,
          ob.header !== headers[headers.length - 1].header ? 'border-b border-outlineGray' : undefined,
        ]"
        @click="getEmpHistory(data.employeeId)"
      >
        <span class="capitalize w-32 text-sm border-r py-3 border-outlineGray">{{ label(ob.header) }}</span>
        <div
          class="rounded-lg py-3 w-auto text-sm ml-3 text-left font-medium capitalize"
          :class="[statusClass(data[ob.field])]"
        >
          {{ data[ob.field] }}
        </div>
        <!-- <span class="capitalize text-left justify-start text-sm py-3">{{
          data[ob.field]
        }}</span> -->
      </div>
    </div>

    <div
      @click="showOptions = ''"
      :class="!showOptions.length ? 'pointer-events-none' : ''"
      class="bg-transparent fixed inset-0"
    ></div>

    <div class="flex w-full items-center justify-end self-end md:justify-start">
      <easiPagin
        :data="totalData"
        @page:update="updatePage"
        :currentPage="currentPage"
        :visibleData="visibleData"
        :pageSize="pageSize"
        :fromCompliance="true"
        class="w-full mt-2"
      />
    </div>

    <!-- Contributionnn History -->
    <div class="card flex justify-content-center">
      <Sidebar v-model:visible="hasData" position="right" class="md:w-96 h-full lg:w-45rem" :showCloseIcon="false">
        <div class="bg-white w-full h-screen overflow-y-auto">
          <button
            @click="hasData = false"
            class="focus:outline-none float-left bg-newAsh mb-4 p-3 rounded-xl cursor-pointer"
          >
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13.25 7L0.75 7M0.75 7L6.375 12.625M0.75 7L6.375 1.375"
                stroke="#11453B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>

          <div class="flex justify-between items-center w-full mt-12">
            <h2 class="text-primary-deep text-lg font-bold">
              {{ employeeData.employeeFirstName }}
              {{ employeeData.employeeLastName }}
            </h2>

            <easiButton size="small"
              ><svg
                class="inline-block -mt-1 mr-1"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 11L2 12.5C2 13.3284 2.67157 14 3.5 14L12.5 14C13.3284 14 14 13.3284 14 12.5V11M11 8L8 11M8 11L5 8M8 11L8 2"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <span class="text-sm"> Export</span></easiButton
            >
          </div>

          <div class="border border-outlineGray flex flex-col gap-3 rounded-xl p-4 mt-5">
            <div class="flex gap-4">
              <div class="border border-outlineGray rounded-lg p-2 flex justify-center w-10">
                <img src="@/assets/icons/structure.svg" alt="" />
              </div>
              <div class="flex flex-col gap-2">
                <span class="text-xs text-newGray">Payment partner</span>
                <h3 class="text-dark-800 font-medium text-sm">
                  {{ employeeData && employeeData.provider ? employeeData.provider : "N/A" }}
                </h3>
              </div>
            </div>

            <hr class="border-0.5 border-outlineGray my-1" />
            <div class="flex items-center gap-3 justify-between">
              <div class="flex gap-4">
                <div class="border border-outlineGray rounded-lg p-2 flex justify-center">
                  <img src="@/assets/icons/coin-stack.svg" alt="" />
                </div>
                <div class="flex flex-col gap-2">
                  <span class="text-xs text-newGray capitalize"
                    >Total <span class="uppercase">{{ route.params.id }}</span></span
                  >
                  <h3 class="text-dark-800 font-medium text-sm">
                    {{ formatAmount(employeeData.totalComplianceAmount || 0) }}
                  </h3>
                </div>
              </div>

              <div class="flex gap-4">
                <div class="border border-outlineGray rounded-lg p-2 flex justify-center">
                  <img src="@/assets/icons/bar-code.svg" alt="" />
                </div>
                <div class="flex flex-col gap-2">
                  <span class="text-xs text-newGray capitalize"
                    ><span class="uppercase">{{ route.params.id }}</span> ID</span
                  >
                  <h3 class="text-dark-800 font-medium text-sm">
                    {{ (employeeData && employeeData.complianceId) || "N/A" }}
                  </h3>
                </div>
              </div>
            </div>
          </div>

          <div class="flex my-5 items-center justify-between">
            <h2 class="text-sm font-bold text-newGray">
              <span class="uppercase">{{ route.params.id }}</span> Contributions
            </h2>

            <div
              @click.self="toggleYear = !toggleYear"
              class="relative border border-dark-300 rounded-xl md:rounded-2xl p-2 md:px-4 md:py-2 cursor-pointer flex items-center gap-2 bg-white w-full md:w-auto"
            >
              <span class="font-medium flex text-xs text-left">
                {{ selectedYear }}
              </span>

              <div class="pointer-events-none hidden md:flex items-center px-2 text-dark-500">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
              <div class="z-50 absolute right-0 md:top-10 mt-5 md:mt-0 md:-mr-3">
                <MonthAndYear v-show="toggleYear" @update="handleYear($event)" type="year" />
              </div>
            </div>
          </div>

          <div class="grid grid-cols-3 gap-4 my-3">
            <h3
              v-for="(head, i) in ['amount paid', 'month', 'status', ' ']"
              :key="i"
              class="uppercase text-xs text-dark-950 font-bold"
            >
              {{ head }}
            </h3>
          </div>

          <div
            v-for="(history, i) in employeeData.history"
            :key="i"
            class="grid grid-cols-3 pt-3 mb-3 gap-4"
            style="border-bottom: 1px solid #e3e5e5"
          >
            <p class="text-dark-800 text-xs capitalize">
              {{ formatAmount(history.amount) }}
            </p>
            <p class="text-dark-800 text-xs capitalize">
              {{ months[history.month] }}
            </p>

            <div class="relative flex items-center w-full gap-3">
              <div
                class="rounded-2xl -ml-1 -mt-1 mb-3 px-2 py-2 w-20 text-center text-xs font-medium capitalize"
                :class="[newStatusClass(history.status)]"
              >
                {{ history.status }}
              </div>

              <div @click="showButtons('1')" class="-mt-4 cursor-pointer">
                <img src="@/assets/icons/more-icon.svg" alt="" />
              </div>

              <div
                v-show="showAction == '1'"
                class="w-36 absolute group-hover:text-primary flex -right-20 flex-col bg-white rounded-xl top-5 shadow-md"
                style="z-index: 1"
              >
                <div class="flex flex-col z-50 text-left items-start text-dark-800 text-xs cursor-pointer pt-2">
                  <span
                    v-show="!['paid', 'processing', 'unpaid'].includes('unpaid')"
                    class="hover:bg-newLimeGreen py-2 px-4 w-full"
                    @click="toRunPayroll(slotProps.data)"
                  >
                    Retry payment
                  </span>
                  <span class="hover:bg-newLimeGreen py-2 px-4 w-full"> Download Receipt </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Sidebar>
    </div>

    <!-- Contributionnn History -->

    <div
      @click="showAction = ''"
      :class="!showAction.length ? 'pointer-events-none' : ''"
      class="bg-transparent fixed inset-0"
    ></div>

    <easiLoading v-if="loading" />
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch } from "vue";
import { helperFunctions } from "@/composable/helperFunctions";
import { useRoute, useRouter } from "vue-router";
import MonthAndYear from "@/components/global/MonthAndYear.vue";
import { useDataStore } from "@/stores/data.js";
import { useToast } from "vue-toastification";

const store = useDataStore();

const { query } = store;

const { formatAmount, getDateText } = helperFunctions;
const toast = useToast();
const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
});
const route = useRoute();
const router = useRouter();
const watchProps = computed(() => props.data);
const totalData = ref([]);
//array of months
const months = ref([
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]);

const statusClass = (stat) => {
  let dta = {
    success: "bg-lighterGreen text-successBg",
    failed: "bg-lighterRed text-error",
  };
  return dta[stat];
};
const newStatusClass = (stat) => {
  let dta = {
    paid: "bg-lighterGreen text-successBg",
    failed: "bg-lighterRed text-error",
    unpaid: "bg-info-lightest text-info-light",
    processing: "bg-info-lightest text-info-light",
  };
  return dta[stat];
};
const dataTable = (arr) => {
  if (arr && arr.length) {
    return {
      others: arr.map((item, i) => {
        return {
          [`s/n`]: String(i + 1),
          employeeName: item.fullName,
          contributions: formatAmount(item.amountPaid),
          lastPayment: getDateText(item.lastPayment),
          employeeId: item.employeeId,
        };
      }),
      hmo: arr.map((item, i) => {
        return {
          [`s/n`]: String(i + 1),
          employeeName: item.fullName,
          contributions: formatAmount(item.amountPaid),
          hmoProvider: item.paymentPartners || "N/A",
          lastPayment: getDateText(item.lastPayment),
          employeeId: item.employeeId,
        };
      }),
      paye: arr.map((item, i) => {
        return {
          [`s/n`]: String(i + 1),
          employeeName: item.fullName,
          contributions: formatAmount(item.amountPaid),
          payeId: item.paymentId,
          paymentPartner: item.paymentPartners || "N/A",
          lastPayment: getDateText(item.lastPayment),
          employeeId: item.employeeId,
        };
      }),
      pension: arr.map((item, i) => {
        return {
          [`s/n`]: String(i + 1),
          employeeName: item.fullName,
          contributions: formatAmount(item.amountPaid),
          pensionId: item.paymentId,
          paymentPartner: item.paymentPartners || "N/A",
          lastPayment: getDateText(item.lastPayment),
          employeeId: item.employeeId,
        };
      }),
    };
  } else {
    return null;
  }
};
const watchData = () => {
  if (["hmo", "paye", "pension"].includes(route.params.id)) {
    return {
      value: dataTable(watchProps.value) ? dataTable(watchProps.value)[route.params.id] : [],
    };
  } else {
    return {
      value: dataTable(watchProps.value) ? dataTable(watchProps.value)["others"] : [],
    };
  }
};

const headers = computed(() => {
  return Object.keys(watchData().value && watchData().value.length && watchData().value[0])
    .map((hd) => {
      if (
        (watchData().value &&
          watchData().value.length &&
          watchData().value[0][hd] &&
          hd !== "employeeId" &&
          watchData().value[0][hd].length) ||
        hd === "lastPayment"
      ) {
        return {
          header: hd,
          field: hd,
        };
      }
      return null;
    })
    .filter((item) => item !== null);
});
const currentPage = ref(0);
const pageSize = ref(15);
let visibleData = ref([]);

const updateVisibleData = () => {
  let datas = totalData.value;
  visibleData.value = datas.slice(
    currentPage.value * pageSize.value,
    currentPage.value * pageSize.value + pageSize.value
  );

  // if we have 0 visible todos, go back a page
  if (visibleData.value.length == 0 && currentPage.value > 0) {
    updatePage(currentPage.value - 1);
  }
  // for (let d of datas) {
  //   showDetails.value[d.dateAdded] = false;
  // }
};
const updatePage = (pageNumber) => {
  currentPage.value = pageNumber;
  updateVisibleData();
};
const headerStyle = computed(() => {
  return "width: 3rem; background-color: #ffffff; font-size: 12px; text-transform: uppercase";
});

const style = computed(() => {
  return "width: 3rem; background-color: #ffffff; font-size: 12px;text-transform: capitalize";
});

const selectedData = ref();
const metaKey = ref(true);

function label(str) {
  return str
    .split(".")
    .pop()
    .replace(/([A-Z])/g, " $1");
}
const showOptions = ref("");
function showAll(arg) {
  if (showOptions.value === arg) {
    showOptions.value = "";
  } else {
    showOptions.value = arg;
  }
}

const showAction = ref("");
function showButtons(arg) {
  if (showAction.value === arg) {
    showAction.value = "";
  } else {
    showAction.value = arg;
  }
}
watch(
  watchProps,
  (val) => {
    totalData.value = watchData().value;
    updateVisibleData();
  },
  { immediate: true, deep: true }
);
const currentYear = new Date().getFullYear();

const selectedYear = ref("");
selectedYear.value = currentYear;
const toggleYear = ref(false);
const loading = ref(false);
const hasData = ref(false);
const selectedEmp = ref("");

const employeeData = ref({});
async function getEmpHistory(employeeId) {
  if (employeeId) {
    selectedEmp.value = employeeId;
  }
  loading.value = true;
  try {
    const res = await query({
      endpoint: "GetEmployeeComplianceHistory",
      payload: {
        input: {
          complianceName: route.params.id,
          employeeId: selectedEmp.value,
          year: selectedYear.value,
        },
      },
      service: "PAYROLL",

      // storeKey: "listComplianceRegistration",
    });
    if (res.success) {
      hasData.value = true;
      employeeData.value = res.data;
      console.log(res.data);
    } else {
      toast.error(res.message);
    }
  } catch (err) {
    console.log(err);
  } finally {
    loading.value = false;
  }
}

const handleYear = async (e) => {
  selectedYear.value = e;
  toggleYear.value = false;
  await getEmpHistory();
};
function toRunPayroll(data) {
  const payrollDate = route.query.createdAt.split("T")[0].split("-");
  const month = parseInt(payrollDate[1]) - 1;
  const year = parseInt(payrollDate[0]);
  const date = `${month}-${year}`;
  store.$patch({
    employeeArrayForPayroll: [data.employeeId],
  });
  router.push({ name: "PaySalary", params: { id: date } });
}
onMounted(() => {
  updateVisibleData();
});
</script>

<style scoped>
.text-size {
  font-size: 16px !important;
}

.custom-checkbox .p-checkbox-icon {
  color: red !important;
}
</style>
