<template>
  <main class="md:p-4 md:px-10">
    <h4
      class="md:mt-3 md:block hidden md:text-sm md:text-left text-xs text-headerText"
    >
      Compliance/ Edit Compliance/{{
        $route.params.id.replace(/([A-Z])/g, " $1").toUpperCase()
      }}
    </h4>
    <div class="text-left mt-6 mb-3 md:block flex items-center justify-between">
      <easiBackButton></easiBackButton>
      <h4
        class="md:mt-3 md:hidden block md:text-sm md:text-left text-xs text-grey md:text-headerText"
      >
        Compliance/ Edit Compliance/{{
          $route.params.id.replace(/([A-Z])/g, " $1").toUpperCase()
        }}
      </h4>
    </div>
    <div class="flex flex-row md:flex-col w-full md:w-52 md:items-start"></div>

    <div class="relative mx-auto w-full text-sm h-auto lg:w-8/12">
      <!-- <span @click="reloadPage" class="absolute top-4 right-4 cursor-pointer">
        <img src="@/assets/icons/close.svg" alt="" />
      </span> -->
      <span></span>
      <EditLifeInsurance
        type="edit"
        v-if="$route.params.id === 'lifeInsurance'"
      />
      <EditLifeAssurance
        type="edit"
        v-if="$route.params.id === 'lifeAssurance'"
      />
      <EditHmo type="edit" v-if="$route.params.id === 'hmo'" />
      <EditWht type="edit" v-if="$route.params.id === 'wht'" />
      <EditBusinessExpense
        type="edit"
        v-if="$route.params.id === 'businessExpense'"
      />
      <EditPaye type="edit" v-if="$route.params.id === 'paye'" />
      <EditPension type="edit" v-if="$route.params.id === 'pension'" />
      <EditNhf type="edit" v-if="$route.params.id === 'nhf'" />
      <EditNsitf type="edit" v-if="$route.params.id === 'nsitf'" />
      <EditItf type="edit" v-if="$route.params.id === 'itf'" />
      <EditGratuity type="edit" v-if="$route.params.id === 'gratuity'" />
    </div>
  </main>
</template>

<script setup>
import {
  ref,
  reactive,
  computed,
  onMounted,
  onBeforeMount,
  shallowRef,
} from "vue";
import { storeToRefs } from "pinia";
import { useDataStore } from "@/stores/data.js";
import { useRoute } from "vue-router";

import EditLifeInsurance from "@/components/Compliance/Edit/EditLifeInsurance.vue";
import EditLifeAssurance from "@/components/Compliance/Edit/EditLifeAssurance.vue";
import EditHmo from "@/components/Compliance/Edit/EditHmo.vue";
import EditWht from "@/components/Compliance/Edit/EditWht.vue";
import EditBusinessExpense from "@/components/Compliance/Edit/EditBusinessExpense.vue";
import EditPaye from "@/components/Compliance/Edit/EditPaye.vue";
import EditPension from "@/components/Compliance/Edit/EditPension.vue";
import EditNhf from "@/components/Compliance/Edit/EditNhf.vue";
import EditNsitf from "@/components/Compliance/Edit/EditNsitf.vue";
import EditItf from "@/components/Compliance/Edit/EditItf.vue";
import EditGratuity from "@/components/Compliance/Edit/EditGratuity.vue";

const display = shallowRef(null); //Make dynamic when endpoints are ready

const store = useDataStore();
const { getAllEmployees, getBonusorDed, getBonusAndDeduction } =
  storeToRefs(store);
const { query, mutate } = store;
const route = useRoute();
const bonusorDed = computed(() => store.getBonusorDed);

const loading = ref(false);
const dataForBonusOrDed = computed(() => store.getBonusAndDeduction);

const queryCompliance = async () => {
  loading.value = true;
  try {
    await query({
      endpoint: "ListCompliance",
      // payload: payload.value,
      service: "PAYROLL",
      storeKey: "listCompliance",
    });

    loading.value = false;
  } catch (err) {
    loading.value = false;
    console.log(err);
  }
};
queryCompliance();

const bonusOrDedPayload = ref({
  paging: {
    limit: 1000,
    skip: 0,
    search: "",
  },
  type: bonusorDed.value,
});

// const employee = computed(() => store.getEmployeeById(route.params.id));
function getDataById(id) {
  const data = dataForBonusOrDed.value.filter((dat) => {
    return dat._id === id;
  });
  console.log(data);
  return data;
}
// const bData = getDataById(route.params.id);
//// View Components ////
const empName = () => {
  const bOrD = getDataById(route.params.id);
  return bOrD.length ? bOrD[0].name : "";
};

const queryBonusOrDed = async () => {
  loading.value = true;
  try {
    await query({
      endpoint: "ListBonusAndDeduction",
      payload: {
        input: bonusOrDedPayload.value,
      },
      service: "PAYROLL",
      storeKey: "listBonusAndDeduction",
    });
    // console.log(getBonusAndDeduction.value, "After query");
    loading.value = false;
  } catch (err) {
    loading.value = false;
    console.log(err);
  }
};
const switchView = (index) => {
  display.value = pages[index].component;
  pages.forEach((el) => {
    el.active = false;
  });
  pages[index].active = true;
};
onMounted(() => {
  //   queryBonusOrDed();
});
</script>

<style></style>
