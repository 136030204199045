<template>
  <main class="w-full text-roboto">
    <div
      class="md:w-5/6 w-full py-10 md:mx-auto bg-white rounded-lg md:rounded-2xl mt-3 mb-12 text-left border border-boxBorder md:px-6 px-2 md:shadow-lg"
    >
      <div class="mb-8 flex items-center justify-between">
        <h2
          class="text-left ml-2 md:ml-6 capitalize text-headerText font-medium text-xl sm:text-lg md:text-2xl"
        >
          {{ bonusOrDed && bonusOrDed.name }}
        </h2>
        <!-- <span
          class="cursor-pointer text-sm font-medium ml-10"
          :class="[
            bonusOrDed.status === 'COMPLETED' ? 'text-successStatus' : '',
            bonusOrDed.status === 'ACTIVE' ? 'text-info' : '',
            bonusOrDed.status === 'PAUSED' ? 'text-secondary' : '',
          ]"
          ><i class="fas fa-circle text-md mr-1"></i>
          {{ bonusOrDed && bonusOrDed.status }}
        </span> -->
        <easiButton
          @click="switchView"
          size="small"
          variant="outlined"
          color="primary"
          class="py-1 rounded-xl"
        >
          <div class="">
            <span class="flex items-center text-base px-3 gap-3">
              <img class="w-4" src="@/assets/icons/Edit3.svg" alt="" />

              Edit
            </span>
          </div>
        </easiButton>
      </div>

      <div
        class="w-full md:flex flex-wrap grid grid-cols-2 gap-4 items-center mx-2 md:mx-6 md:gap-x-8 xl:gap-x-12"
      >
        <!-- <div class="flex flex-col">
          <span class="text-sm text-dark-500 capitalize"
            >{{ bonusOrDed && bonusOrDed.type }}
            {{
              bonusOrDed && bonusOrDed.amount ? "Amount" : "Percentage"
            }}</span
          >
          <span class="text-base text-dark-800 font-medium">{{
            bonusOrDed.amount
              ? formatAmount(bonusOrDed.amount)
              : bonusOrDed.percentage
          }}</span>
        </div> -->
        <div class="flex flex-col">
          <span class="text-sm text-dark-500">Date Created</span>
          <span class="text-base text-dark-800 font-medium">{{
            formatDateString(bonusOrDed && bonusOrDed.createdAt)
          }}</span>
        </div>
        <!-- <div class="flex flex-col">
          <span class="text-sm text-dark-500">Duration</span>
          <span class="text-base text-dark-800 font-medium">{{
            returnDuration(bonusOrDed && bonusOrDed.duration)
          }}</span>
        </div>
        <div class="flex flex-col">
          <span class="text-sm text-dark-500">End Date</span>
          <span class="text-base text-dark-800 font-medium">{{
            formatDateString(bonusOrDed && bonusOrDed.endDate)
          }}</span>
        </div> -->
      </div>
      <hr class="my-4 text-dividerColor" />
      <h3 class="text-sm text-dark-500 md:ml-6">Number of Employees</h3>
      <span class="text-base md:ml-6 text-dark-800 font-medium">{{
        bonusOrDed && bonusOrDed.employees && bonusOrDed.employees.length
      }}</span>

      <div
        v-if="bonusOrDed && bonusOrDed.employees && bonusOrDed.employees.length"
        class="flex flex-wrap md:mx-6 gap-2 mt-7 mb-7 justify-start items-center"
      >
        <div
          v-for="emp in bonusOrDed.employees"
          :key="emp._id"
          class="flex items-center justify-around gap-x-3 rounded-lg px-2 py-1 text-center bg-newLimeGreen border border-newGray text-xs w-auto"
        >
          <span
            class="w-8 h-8 inline-flex flex-none items-center justify-center rounded-full"
            :style="`background-color:${
              initials(`${emp.firstName} ${emp.lastName}`).color
            }`"
          >
            <span class="shrink-0 text-white text-xs font-medium">{{
              initials(`${emp.firstName} ${emp.lastName}`).str
            }}</span></span
          >

          <span class="grow cursor-pointer text-sm">
            {{ `${emp.firstName} ${emp.lastName}` }}</span
          >
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import { ref, reactive, nextTick, watch, computed, onMounted } from "vue";
import { useDataStore } from "@/stores/data.js";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import { helperFunctions } from "@/composable/helperFunctions";

const { formatAmount, formatDateString } = helperFunctions;
const store = useDataStore();
const route = useRoute();
const { query, set } = store;
const { getAllEmployees, checkLoading, getBonusAndDeduction } =
  storeToRefs(store);
const allEmployees = computed(() => getAllEmployees.value.data);
const bonusOrDed = computed(() => store.getBonusOrDedById(route.params.id));
const queryOption = computed(() => store.getBonusorDed);
console.log("B>D", bonusOrDed.value);

const emit = defineEmits(["updateView", "args"]);
const loading = ref(false);

const switchView = () => {
  emit("updateView", true);
};

const props = defineProps({
  data: {
    type: Array,
    default: [],
  },
});
const bonusOrDedPayload = ref({
  paging: {
    limit: 1000,
    skip: 0,
    search: "",
  },
  type: queryOption.value,
});
const payload = ref({
  paging: {
    limit: 1000,
    skip: 0,
    search: "",
    sort: { firstName: "ASC" },
  },
  filter: [],
});

const optionData = ref([]);
const createEmpList = () => {
  const list = allEmployees.value.map((emp) => {
    return {
      label: `${emp.profile.firstName} ${emp.profile.lastName}`,
      value: emp._id,
    };
  });
  return list;
};

const totalEmp = createEmpList();
optionData.value = totalEmp;

function getLabelFromValue(value) {
  if (value) {
    const label = optionData.value.filter((item) => {
      return item.value === value;
    });
    if (label) {
      return label[0].label;
    }
    return "Mike Snow";
  }
}
const returnDuration = (text) => {
  switch (text) {
    case "WEEKLY":
      return "Weekly";

    case "MONTHLY":
      return "Monthly";

    case "ANNUALLY":
      return "Annually";

    case "ONE_TIME":
      return "One-Time";

    default:
      return "N/A";
  }
};

function getColor(index) {
  let n = (index * 0xfffff * 1000000).toString(16);
  return "#" + n.slice(0, 6);
}

const initials = computed(() => {
  return (name) => {
    let color;
    // Get color

    const arr = name.split(" ");
    let alphabet = "abcdefghijklmnopqrstuvwxyz".split("");
    const initialOne = String(arr[0]).charAt(0);
    const initialTwo = String(arr[1]).charAt(0);
    const num =
      Number(alphabet.indexOf(initialOne.toLowerCase())) +
      Number(alphabet.indexOf(initialTwo.toLowerCase()));
    color = getColor(num);
    const str = `${arr[0].charAt(0).toUpperCase()}${arr[1]
      .charAt(0)
      .toUpperCase()}`;
    return { str, color };
  };
});

const queryEmployees = async () => {
  //   loading.value = true;
  try {
    await query({
      endpoint: "ListEmployees",
      payload: payload.value,
      service: "EMP",
      storeKey: "listEmployees",
    });

    // loading.value = false;
    console.log("Employees");
  } catch (err) {
    // loading.value = false;
    console.log(err);
  }
};
const queryBonusOrDed = async () => {
  loading.value = true;
  try {
    await query({
      endpoint: "ListBonusAndDeduction",
      payload: {
        input: bonusOrDedPayload.value,
      },
      service: "PAYROLL",
      storeKey: "listBonusAndDeduction",
    });
    // console.log(getBonusAndDeduction.value, "After query");
    loading.value = false;
  } catch (err) {
    loading.value = false;
    console.log(err);
  }
};
onMounted(() => {
  queryBonusOrDed();
  queryEmployees();
});
</script>

<style></style>
