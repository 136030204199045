<template>
  <main>
    <!-- ///// Desktop Frame ///// -->

    <div class="overflow-y-auto hidden md:block rounded-t-xl text-primary">
      <table class="w-full">
        <thead class="">
          <tr class="text-header font-bold shadow-md bg-dark-100 relative">
            <th
              v-for="(title, index) in headers"
              :key="index"
              class="bg-newdirtyGreen border border-outlineGray font-bold uppercase text-sm tracking-wide text-header text-center whitespace-nowrap p-0"
            >
              <div class="px-2 py-3 border">
                {{ title }}
              </div>
            </th>
          </tr>
        </thead>

        <tbody class="relative">
          <!-- <div class="absolute left-0 -top-1 w-full">
              <easiLoader />
            </div> -->
          <tr
            v-for="(item, i) in data"
            :key="i"
            class="hover:bg-easigrey group text-tableBpdy cursor-pointer relative"
            @click="viewSingleRecord(item)"
          >
            <td
              class="border border-outlineGray text-left text-sm px-2 md:pl-8 py-3 bg-highlight group-hover:bg-outlineGray group-hover:border-outlineGray capitalize font-medium"
            >
              {{ months[item.month] }}
            </td>
            <td
              class="border border-outlineGray text-sm px-2 py-3 bg-white group-hover:bg-highlight group-hover:border-highlight font-medium text-center"
            >
              {{ totalStaff(item.employeeDetails) }}
            </td>
            <td
              class="border border-outlineGray text-sm px-2 py-3 bg-white group-hover:bg-highlight group-hover:border-highlight font-medium text-center"
            >
              {{ formatAmount(item.totalGross) }}
            </td>
            <td
              class="border border-outlineGray text-sm px-2 py-3 bg-white group-hover:bg-highlight group-hover:border-highlight font-medium text-center"
            >
              {{ formatAmount(item.totalNet) }}
            </td>
            <td
              class="border border-outlineGray text-sm px-2 py-3 bg-white group-hover:bg-highlight group-hover:border-highlight font-medium text-center"
            >
              {{ formatAmount(item.totalDeductions) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- ///// Mobile Frame ///// -->
    <div v-for="(item, i) in data" :key="i" class="w-full mb-8 block md:hidden">
      <table class="table-auto w-full overflow-y-auto">
        <tbody class="font-medium text-sm">
          <tr @click="viewSingleRecord(item)">
            <td
              class="w-6/12 border border-outlineGray text-left p-2 px-3 bg-newerDirtyBrown uppercase"
            >
              <div class="flex items-center gap-2">Months</div>
            </td>
            <td
              class="w-6/12 border border-newLimeGreen text-left p-2 px-3 bg-newLimeGreen"
            >
              <div class="flex items-center gap-2">
                {{ months[item.month] }}
              </div>
            </td>
          </tr>
          <tr @click="viewSingleRecord(item)">
            <td
              class="w-6/12 border border-outlineGray text-left p-2 px-3 bg-newerDirtyBrown uppercase"
            >
              <div class="flex items-center gap-2">Total Staff</div>
            </td>
            <td
              class="w-6/12 border border-outlineGray text-left p-2 px-3 bg-white"
            >
              <div class="flex items-center gap-2">
                {{ totalStaff(item.employeeDetails) }}
              </div>
            </td>
          </tr>
          <tr @click="viewSingleRecord(item)">
            <td
              class="w-6/12 border border-outlineGray text-left p-2 px-3 bg-newerDirtyBrown uppercase"
            >
              <div class="flex items-center gap-2">Total Gross Salary</div>
            </td>
            <td
              class="w-6/12 border border-outlineGray text-left p-2 px-3 bg-white"
            >
              <div class="flex items-center gap-2">
                {{ formatAmount(item.totalGross) }}
              </div>
            </td>
          </tr>
          <tr @click="viewSingleRecord(item)">
            <td
              class="w-6/12 border border-outlineGray text-left p-2 px-3 bg-newerDirtyBrown uppercase"
            >
              <div class="flex items-center gap-2">Total Net Salary</div>
            </td>
            <td
              class="w-6/12 border border-outlineGray text-left p-2 px-3 bg-white"
            >
              <div class="flex items-center gap-2">
                {{ formatAmount(item.totalNet) }}
              </div>
            </td>
          </tr>
          <tr @click="viewSingleRecord(item)">
            <td
              class="w-6/12 border border-outlineGray text-left p-2 px-3 bg-newerDirtyBrown uppercase"
            >
              <div class="flex items-center gap-2">Total Deduction</div>
            </td>
            <td
              class="w-6/12 border border-outlineGray text-left p-2 px-3 bg-white"
            >
              <div class="flex items-center gap-2">
                {{ formatAmount(item.totalDeductions) }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </main>
</template>

<script setup>
import { reactive, computed } from "vue";
import { useRouter } from "vue-router";
import { helperFunctions } from "@/composable/helperFunctions";

const router = useRouter();

const { formatAmount } = helperFunctions;

const props = defineProps({
  data: {
    type: Array,
    default: () => ({}),
  },
});

const totalStaff = (arg) => {
  return arg ? Object.keys(arg).length : "N/A";
};

const headers = reactive([
  "Month",
  "Total Staff",
  "Total Gross Salary",
  "Total Net Salary",
  "Total Deduction",
]);

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const viewSingleRecord = (payload) => {
  // console.log("view single");
  console.log("PAYLOAD", payload);

  const biweekly =
    payload.timeline == "BIWEEKLY" ? `,${payload.biweekly}` : null;

  let payloadDate = "";
  if (biweekly) {
    payloadDate = `${payload.month},${payload.year},${payload.timeline}${biweekly}`;
  } else {
    payloadDate =
      payloadDate = `${payload.month},${payload.year},${payload.timeline}`;
  }
  console.log(payloadDate, "pdate");

  router.push({
    name: "PayrollHistoryDetails",
    params: {
      date: payloadDate,
    },
  });
};
</script>

<style></style>
