<template>
  <main>
    <h4 class="mt-3 md:text-sm md:text-left text-xs text-headerText">
      Payroll / Edit Salary
    </h4>
    <div class="text-left mt-6">
      <easiBackButton></easiBackButton>
    </div>
    <h2
      class="cursor-pointer text-center mb-3 text-2xl font-medium text-dark-800"
    >
      Bulk Edit
    </h2>
    <div v-if="!allEmployees.length" class="mt-16 mb-4">
      <h3 class="my-6 text-2xl md:text-3xl text-dark-800 font-bold">
        You have not added any employee yet.
      </h3>
      <div
        class="container mx-auto flex items-center justify-center gap-4 flex-wrap"
      >
        <easiButton
          @click="$router.push({ name: 'AddEmployee' })"
          variant="outline-secondary"
        >
          Add new employee
        </easiButton>
        <img src="@/assets/icons/no_employee.svg" alt="" />
      </div>
    </div>

    <div v-else class="mb-6 md:mb-10 w-full">
      <div
        class="flex items-center w-full text-center md:w-1/2 mx-auto border border-outlineGray rounded-xl tracking-wide justify-center md:mt-8 md:mb-4 mt-4 mb-4 gap-x-2 p-2 text-sm font-medium cursor-pointer bg-white"
      >
        <span
          @click="toggleAdd"
          class="w-full text-sm rounded-lg md:px-5 md:py-2 px-3 py-2"
          :class="
            sectional ? 'bg-primary shadow-2xl text-white ' : 'bg-newGrey'
          "
          >Sectional Edit</span
        >
        <span
          @click="toggleAdd"
          class="w-full text-sm rounded-lg md:px-5 md:py-2 px-3 py-2"
          :class="
            !sectional ? 'bg-primary shadow-2xl text-white' : 'bg-newGrey'
          "
          >Upload Spreadsheet</span
        >
      </div>
      <p
        v-show="sectional"
        class="text-dark-800 md:font-bold text-center text-lg leading-6"
      >
        Please select section of employee information to edit and
        <span class="text-secondary"> Update Section.</span> <br />
        Edit employee with same salary scale by clicking
        <span class="text-primary font-bold"> Edit Multiple.</span>
      </p>
      <div
        v-show="sectional"
        class="flex md:justify-between gap-x-8 md:flex-row flex-col gap-y-6 md:gap-y-0 w-full md:items-center"
      >
        <div
          class="flex md:items-center gap-x-4 md:gap-x-8 mt-4 w-full md:w-4/5 grow-0 gap-y-4 md:gap-y-0"
        >
          <!-- /////// Search Component ///// -->
          <div class="w-11/12">
            <easiSearch
              useNewclass
              placeholder="Search employee"
              v-model="searchText"
              @search="searchFN"
            />
          </div>

          <!-- /////// Filter Component Start /////// -->
          <div class="">
            <easiFilter @update="filterFN" />
          </div>
          <!-- /////// Filter Component Ends /////// -->
        </div>

        <div class="md:self-end flex justify-center gap-x-5 items-center">
          <!-- <easiButton
            @click="$router.push({ name: '' })"
            variant="secondary"
            class="flex items-center gap-3 py-4"
          >
            <img src="@/assets/icons/account-plus.svg" alt="" />
            Bulk Edit
          </easiButton> -->

          <easiButton
            :loading="loading"
            @click="editSection"
            variant="primary"
            class="flex items-center gap-3 py-2 text-xs md:text-base"
          >
            Update Section
          </easiButton>

          <easiButton
            :loading="loadingMultiple"
            @click="openMultipleEditModal"
            variant="primary"
            class="flex items-center gap-3 py-2 text-xs md:text-base"
          >
            Edit Multiple
          </easiButton>
        </div>
      </div>
    </div>
    <p v-show="errorMultiple" class="text-base font-medium text-secondary">
      Please select employees with same salary scale to proceed.
    </p>
    <div v-show="sectional" class="md:-ml-4 md:mt-8">
      <easiPayrollTable
        :data="transformedData"
        :namesForAllowances="allowanceNames"
        :amountValues="amountValues"
        :frozenFields="['firstName', 'lastName']"
        :highlight="['firstName', 'lastName']"
        :useSelect="['department', 'employmentType', 'branch']"
        :mapData="[empType]"
        :readonly="false"
        :allowMultiple="allowMultiple"
        @updated="createUploadArray($event)"
        @selected="updateSelectedEmployees($event)"
        @setModalToFalse="allowMultiple = false"
      />
    </div>
    <!-- <div v-show="sectional" class="md:hidden block -ml-4 md:mt-8">
      <MobileSectionalEdit
        :data="transformedData"
        :frozenFields="['firstName', 'lastName']"
        :highlight="['firstName', 'lastName']"
        :header="headers"
        :mapData="[empType]"
      />
    </div> -->
    <!-- 
    <easiPagin
      v-if="transformedData.length && sectional"
      :data="transformedData"
      @page:update="updatePage"
      :currentPage="currentPage"
      :pageSize="pageSize"
      class=""
    /> -->

    <div v-show="!sectional">
      <ExcelEdit template="payroll" type="editSalary" />
    </div>
    <easiLoading v-if="loadingProgress" @close="loadingProgress = false">
      <template v-slot:heading>
        <h1>Analyzing Data</h1>
      </template>

      <template v-slot:message>
        <div class="w-full rounded-xl">
          <vue3-progress-bar></vue3-progress-bar>
        </div>
      </template>
    </easiLoading>
    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span>Updated successfully</span>
      </template>
    </easiSuccess>

    <easiModal
      :isBorder="false"
      v-if="allowMultiple"
      @close="allowMultiple = false"
    >
      <EditGroup
        @closeEdit="allowMultiple = false"
        :multipleEdit="true"
        :hideClose="true"
        type="edit"
        :edit="true"
        @uploadToServer="createPayloadAndSend($event)"
        :multipleData="selectedEmployees"
      />
    </easiModal>
  </main>
</template>

<script setup>
import ExcelEdit from "@/components/Payroll/Edit/ExcelEdit.vue";
import SectionalEdit from "@/components/Employee/Edit/SectionalEdit.vue";
import MobileSectionalEdit from "@/components/Employee/Edit/MobileSectionalEdit.vue";
import EditGroup from "@/components/Payroll/Edit/Benefits/Benefits.vue";
import {
  ref,
  reactive,
  onMounted,
  computed,
  watch,
  onBeforeUnmount,
} from "vue";
import { storeToRefs } from "pinia";
import { useToast } from "vue-toastification";

import { useDataStore } from "@/stores/data.js";
import { onBeforeRouteLeave } from "vue-router";
const store = useDataStore();
const { query, mutate } = store;
const { listEmployees, getAllEmployees } = storeToRefs(store);
const toast = useToast();

// const payrollData = computed(() => store.getPayrollData);
// const allowanceNames = computed(() => store.getAllowanceNames);
const allEmployees = computed(() => getAllEmployees.value.data);
let selectedEmployees = ref([]);

const loadingMultiple = ref(false);
const loading = ref(false);
const searchText = ref("");
let isSearch = ref(false);
const filterOptions = ref({
  sort: { firstName: "ASC" },
  filter: [],
});
const currentPage = ref(0);
const pageSize = ref(15);
let visibleData = ref([]);
const payrollData = ref([]);
const allowance = ref([]);
const uploadData = ref([]);
const allowMultiple = ref(false);

const payload = ref({
  paging: {
    limit: 1000,
    skip: 0,
    search: "",
    sort: { firstName: "ASC" },
  },
  filter: [],
});
watch(searchText, async (val) => {
  if (val.trim() === "") {
    payload.value.paging.search = "";
    await queryEmployees();
    isSearch.value = false;
  }
});
const allAllowanceData = computed(() => store.listAllowance);

function transform(arr) {
  const newArr =
    arr &&
    arr.map((obj) => {
      return {
        // id: obj.job.userName,
        employeeId: obj._id,
        firstName: obj.profile.firstName,
        lastName: obj.profile.lastName,
        picture: obj.profile.pfp,

        grossPay: obj.staffLevel ? Number(obj.staffLevel.grossAmount) : 0,
        netPay:
          obj.staffLevel &&
          obj.prorate &&
          obj.prorate.staffLevel &&
          Number(obj.prorate.staffLevel.netAmount) > 0
            ? Number(obj.prorate.staffLevel.netAmount)
            : obj.staffLevel
            ? Number(obj.staffLevel.netAmount)
            : 0,

        usePercentage:
          obj.staffLevel && obj.staffLevel.usePercentageForAllowance,
        staffLevelId: obj.staffLevel && obj.staffLevel._id,
        deductions: obj.staffLevel && obj.staffLevel.benefits,
        allowanceDetails: obj.staffLevel && obj.staffLevel.allowanceDetails,
        prorate: obj.prorate && obj.prorate.proratedSalary ? obj.prorate : {},
      };
    });
  // console.log(newArr, "EY");

  return newArr;
}

const allowanceNames = ref([]);
function getAllowanceNameById(id) {
  for (let data of allAllowanceData.value.data) {
    let allName;
    if (data._id === id) {
      allName = data.name;

      return allName;
    }
  }
}
function allAllowance() {
  const names =
    allAllowanceData.value &&
    allAllowanceData.value.data.map((n) => {
      return n.name;
    });
  return names;
}
allowanceNames.value = allAllowance();

const transformedData = ref([]);
transformedData.value = transform(allEmployees.value);
// console.log("TDATA", transformedData.value);

function extractAllowance(arr) {
  const allowance =
    arr &&
    arr.map((obj) => {
      console.log(obj, "HEE");
      const details =
        obj.allowanceDetails &&
        obj.allowanceDetails.map((ad) => {
          return {
            amount: ad.amount,
            percentage: ad.percentage,
            // usePercentage: obj.staffLevel.usePercentageForAllowance,
            name: getAllowanceNameById(ad.allowanceId),
            allowanceId: ad.allowanceId,
            _id: ad._id,
          };
        });

      return details;
    });
  // console.log("IMPOO", allowance);
  return allowance;
}
allowance.value = extractAllowance(transformedData.value);
console.log(allowance.value, "ALLO VALI");

function finalVal() {
  const fna = allowance.value.map((item, index) => {
    const info =
      item &&
      item.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.name]: cur.percentage || cur.amount,
          [`${cur.name}allowanceId`]: cur.allowanceId,
          [`${cur.name}_id`]: cur._id,
        }),
        {}
      );
    return info;
  });
  console.log("FNA", fna);
  return fna;
}

const datum = ref([]);
datum.value = finalVal();

const queryEmployees = async () => {
  loading.value = true;
  try {
    await query({
      endpoint: "ListEmployees",
      payload: payload.value,
      service: "EMP",
      storeKey: "listEmployees",
    });

    loading.value = false;
    console.log("Employees");
    log(getAllEmployees.value.data);
  } catch (err) {
    loading.value = false;
    console.log(err);
  }
};

queryEmployees();
const amountValues = [
  "paye",
  "pension",
  "hmo",
  "lifeInsurance",
  "lifeAssurance",
  "wht",
  "nsitf",
  "itf",
  "gratuity",
  "nhf",
  "businessExpense",
  "grossPay",
  "netPay",
];

const empType = reactive([
  {
    label: "Fulltime",
    value: "FULLTIME",
  },
  {
    label: "Contract",
    value: "CONTRACT",
  },
  {
    label: "Part Time",
    value: "PARTTIME",
  },
  {
    label: "Volunteer",
    value: "VOLUNTEER",
  },

  {
    label: "NYSC",
    value: "NYSC",
  },
  {
    label: "Internship",
    value: "INTERNSHIP",
  },

  {
    label: "Apprentice",
    value: "APPRENTICE",
  },
]);

const loadingProgress = ref(false);
const updateSuccess = ref(false);
const employees = getAllEmployees.value.data;

function createUploadArray(event) {
  console.log(event, "EVE");
  const data = event;
  const uploadArr = data.map((item) => {
    return {
      usePercentageForAllowance: item.usePercentage,
      staffLevelId: item.staffLevelId,
      salary: {
        grossAmount: Number(item.grossPay),
        netAmount: Number(item.netPay),
      },
      allowanceDetails: item.allowanceDetails,
    };
  });
  uploadData.value = uploadArr;
  console.log(uploadData.value);
  // const names = allowanceNames.value.map((na) => {
  //   const profile = payrollData.value.map((item, index) => {
  //     return {
  //       usePercentageForAllowance: item.usePercentage,
  //       staffLevelId: item.staffLevelId,
  //       salary: {
  //         grossAmount: item.grossPay,
  //         netAmount: item.netPay,
  //       },

  //       allowanceDetails: [
  //         {
  //           allowanceId: item[`${na}allowanceId`],

  //           _id: item[`${na}_id`],

  //           // _id: item.basic_id,
  //           percentage: item.usePercentage ? item[na] : null,

  //           amount: !item.usePercentage ? item[na] : null,
  //         },
  //       ],
  //     };
  //   });
  //   return profile;
  // });

  // return uploadArr;
}

function createUploadArrayForMultiple(event) {
  const data = event;
  const uploadArr = data.map((item) => {
    return {
      grossAmount: Number(item.grossPay),
      staffLevelIds: [item.staffLevelId],
    };
  });
  console.log(uploadArr, "EVE");

  return uploadArr;
}

// const allEmployee = reactive([""]);
let sectional = ref(true);
function toggleAdd() {
  sectional.value = !sectional.value;
}

async function editSection() {
  loading.value = true;
  let data = uploadData.value.map((d) => {
    d.allowanceDetails = d.allowanceDetails.filter(
      (obj) => obj["amount"] || obj["percentage"]
    );
    return d;
  });
  console.log(data, "REMOVED");

  try {
    let res = await mutate({
      endpoint: "updateAllowanceDetailsMultiple",
      data: {
        input: data,
      },
      service: "EMP",
    });

    console.log(res);
    if (res.success.length > 0) {
      loading.value = false;
      updateSuccess.value = true;
      await queryEmployees();
      // Object.assign(args.value, resetObj(args.value, "args"));
      setTimeout(() => {
        updateSuccess.value = false;

        uploadData.value = [];
        data = [];
        window.location.reload();
      }, 1000);
      // toast.success("Allowance Details Updated Successfully");
    } else if (res.failed.length > 0) {
      const msgs = res.failed;
      for (let msg of msgs) {
        toast.error(msg.error);
      }
      loading.value = false;
    }
  } catch (e) {
    console.log(e);
    toast.error(e);
    loading.value = false;
  }
}
const errorMultiple = ref(false);

function createPayloadAndSend(obj) {
  // const dataForUpload = createUploadArrayForMultiple(arr);
  const dataForUpload = obj;
  console.log(dataForUpload, "ADAM");

  try {
    mutate({
      endpoint: "multipleSalaryUpdate",
      data: {
        input: dataForUpload,
      },
      service: "EMP",
    })
      .then((res) => {
        console.log(res);
        if (res.success.length > 0) {
          loadingMultiple.value = false;
          updateSuccess.value = true;
          queryEmployees();
          // Object.assign(args.value, resetObj(args.value, "args"));
          setTimeout(() => {
            updateSuccess.value = false;

            uploadData.value = [];
            selectedEmployees.value = [];
            window.location.reload();
          }, 1000);
          // toast.success("Allowance Details Updated Successfully");
        } else if (res.failed.length > 0) {
          const msgs = res.failed;
          for (let msg of msgs) {
            toast.error(msg.error);
          }
          loadingMultiple.value = false;
        }
      })
      .catch((e) => {
        console.log(e);
        toast.error(e);
        loadingMultiple.value = false;
      });
  } catch (e) {
    console.log(e);
  }
}

const searchFN = async (suggested) => {
  // console.log(suggested, searchText.value);
  searchText.value = suggested ? suggested : searchText.value;
  isSearch.value = true;
  payload.value.paging.search = searchText.value;
  await queryEmployees();
};

const filterFN = (arg) => {
  isSearch.value = true;
  filterOptions.value = arg;

  payload.value.paging.sort = filterOptions.value.sort;
  payload.value.filter = filterOptions.value.filter;

  console.log("Payload");
  queryEmployees();
};
function updateSelectedEmployees(arg) {
  selectedEmployees.value = [];
  selectedEmployees.value = arg;
}

function openMultipleEditModal() {
  console.log("RAN", selectedEmployees.value);
  try {
    if (selectedEmployees.value.length < 2) {
      toast.error("You have to select at least two (2) employees to edit.");
      return false;
    }
    //  else {
    //   allowMultiple.value = true;
    // }

    for (let i = 0; i < selectedEmployees.value.length; i++) {
      for (let k = i + 1; k < selectedEmployees.value.length; k++) {
        if (
          selectedEmployees.value[i].grossPay !=
          selectedEmployees.value[k].grossPay
        ) {
          errorMultiple.value = true;
          // break;
        }
      }
    }

    if (errorMultiple.value === false) {
      console.log(allowMultiple.value, "OPEN SESAME");
      allowMultiple.value = true;
    }
  } catch (e) {
    console.log(e);
  }
}
function empty() {
  getAllEmployees.value.data = [];
  console.log(getAllEmployees.value.data);
}
// Get rid of the proxy when console.log
function log(arg) {
  console.log(JSON.parse(JSON.stringify(arg)));
}
// function cross() {
//   const employee = transformedData.value;
//   let newTransformed = [];
//   const fresh = employee.map((emp, index) => {
//     const fin = datum.value.map((du, i) => {
//       if (index === i) {
//         const merged = Object.assign(emp, du);
//         newTransformed.push(merged);
//         return merged;
//       }
//     });
//   });
//   for (let all of newTransformed) {
//     for (let k of allowanceNames.value) {
//       if (!all[k]) {
//         all[k] = 0;
//       }
//     }
//   }
//   console.log(newTransformed, "NEW");
//   return newTransformed;
// }
// payrollData.value = cross();
function goToBulkEdit() {
  store.$patch({
    payrollData: payrollData.value,
    allowanceNames: allowanceNames.value,
  });
  router.push({ name: "SalaryBulkEdit" });
}
const updatePage = (pageNumber) => {
  currentPage.value = pageNumber;
  updateVisibleData();
};
const updateVisibleData = () => {
  let datas = transformedData.value;
  visibleData.value = datas.slice(
    currentPage.value * pageSize.value,
    currentPage.value * pageSize.value + pageSize.value
  );

  if (visibleData.value.length == 0 && currentPage.value > 0) {
    updatePage(currentPage.value - 1);
  }
};
watch(allEmployees, (val) => {
  transformedData.value = transform(val);
  // allowance.value = extractAllowance(allEmployees.value);
  // datum.value = finalVal();
  // payrollData.value = cross();

  updateVisibleData();
});
onMounted(() => {
  queryEmployees();

  // updateVisibleData();
  // visibleData.value = employees;
});

onBeforeRouteLeave((to, from, next) => {
  if (
    confirm(
      "You are about to leave this page. Are you sure you want to continue?"
    )
  ) {
    // User confirmed, continue with navigation
    next();
  } else {
    // User canceled, stay on the current page
    next(false);
  }
});
const handleBeforeUnload = (event) => {
  const confirmationMessage =
    "You have unsaved changes. Are you sure you want to leave?";
  event.returnValue = confirmationMessage;
  return confirmationMessage;
};
onBeforeUnmount(() => {
  window.removeEventListener("beforeunload", handleBeforeUnload);
});

window.addEventListener("beforeunload", handleBeforeUnload);
</script>

<style></style>
