<template>
  <div class="flex items-center relative border border-newGray rounded-2xl">
    <input
      autocomplete
      class="bg-white border-none w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border border-r-0 p-4 block appearance-none rounded-tl-2xl rounded-bl-2xl"
      v-model="input"
      v-bind="$attrs"
      @keyup="querySuggestedBonus"
      @keypress.enter="searchFN"
      @input="(e) => $emit('update:modelValue', e.target.value)"
    />
    <!-- :value="modelValue" -->

    <button
      @click="searchFN"
      style="border: 3px solid transparent"
      class="rounded-tr-2xl rounded-br-2xl px-6 sm:px-10 py-4 bg-newLightGreen focus:bg-success-hover focus:outline-none"
    >
      <img src="../../assets/icons/newSearchIcon.svg" alt="" />
    </button>

    <div
      style="max-height: 500px"
      v-if="input.trim()"
      class="overflow-y-auto w-full h-auto bg-white shadow-md z-2 mt-1 rounded-md absolute top-14 left-0 overflow-hidden"
    >
      <easiLoader v-if="loading" />

      <div
        v-for="(item, i) in suggestedBonus"
        :key="i"
        @click="selectSuggestion(item)"
        class="w-full last:border-b-0 border-b border-dark-50 p-2 sm:p-4 cursor-pointer hover:bg-background-50 flex gap-3 items-center"
      >
        <div
          class="text-dark-800 text-md font-medium flex flex-col items-start capitalize"
        >
          <span>{{ item.name }}</span>
          <span class="text-sm text-dark-700 font-thin">{{
            formatAmount(item.amount)
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import { ref } from "vue";
import { storeToRefs } from "pinia";
import { useDataStore } from "@/stores/data.js";
import { helperFunctions } from "@/composable/helperFunctions";

const store = useDataStore();
const { query } = store;
const { suggestedBonus, checkLoading } = storeToRefs(store);

const { formatAmount } = helperFunctions;

const emit = defineEmits(["search", "update:modelValue"]);

const props = defineProps({
  modelValue: {
    type: String,
    default: "",
  },
  type: {
    type: String,
    default: "",
  },

  error: {
    type: [String, Boolean],
    default: null,
  },

  type: {
    type: String,
    default: "Bonus",
  },
});

const loading = ref(false);
const input = ref("");

const selectSuggestion = async (arg) => {
  input.value = arg ? arg.name : null;
  await searchFN();

  input.value = "";
};

const searchFN = () => {
  emit("search", input.value);
};

const querySuggestedBonus = async () => {
  if (props.modelValue.trim() !== "") {
    const payload = {
      paging: {
        limit: 1000,
        skip: 0,
        search: props.modelValue,
      },
      sort: {},
      filter: [],
      type: props.type,
    };

    try {
      loading.value = true;
      await query({
        endpoint: "ListBonusAndDeduction",
        payload: { input: payload },
        service: "PAYROLL",
        storeKey: "suggestedBonus",
      })
        .then(() => {
          console.log(suggestedBonus.value);
          loading.value = false;
        })
        .catch((err) => {
          loading.value = false;
        });
    } catch (err) {
      loading.value = false;
      console.log(err);
    }
  } else {
    store.$patch({
      suggestedBonus: [],
    });
  }
};
</script>

<style></style>
