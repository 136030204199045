<template>
  <main>
    <!-- <div
      class="md:w-1/2 w-full py-7 mx-auto bg-white rounded-2xl mt-10 mb-12 relative text-left border border-boxBorder shadow-lg"
    >
      <div class="mb-8">
        <h2
          class="text-center text-headerText font-bold md:text-xl text-lg md:text-2xl"
        >
          Upload Salary Sheet
        </h2>
        <hr class="my-3 border-0.5 border-dividerColor text-dividerColor" />
      </div>

      <div class="flex flex-col items-center gap-2 mb-10">
        <span class="text-dark-800 text-md font-medium mb-1"
          >Download your salary sheet and populate same.</span
        >

        <div
          @click="download('payroll')"
          class="w-56 text-center rounded-full border border-secondary py-2 px-4 cursor-pointer"
        >
          <span
            class="text-secondary flex items-center space-x-3 text-sm text-center font-medium"
          >
            <img src="@/assets/icons/newDownloadIcon.svg" />

            <span className="text-md pt-1"> Download Template </span>
          </span>
        </div>
      </div>

      <div class="flex flex-col items-center gap-2 mb-7">
        <span class="text-dark-800 text-md font-medium mb-1"
          >Upload populated salary sheet into the system.</span
        >

        <div
          @click="$refs.input.click()"
          class="w-56 mt-2 text-center rounded-full border border-primary py-2 px-4 cursor-pointer"
        >
          <span
            class="w-full flex items-center gap-3 justify-center text-primary text-sm text-center font-medium"
          >
            <img class="w-6" src="@/assets/icons/upload-icon.svg" alt="" />

            <span>Choose File</span>
          </span>
        </div>

     
        <input
          v-show="hide === false"
          ref="input"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          @change="previewFiles($event)"
          type="file"
          class="custom-file-input"
          required
        />

        <div
          v-show="fileName.length > 0"
          class="flex justify-center gap-4 items-center mt-4"
        >
          <div class="w-6 justify-center self-center">
            <img :src="xlsicon" alt="" />
          </div>

          <p class="text-xs text-dark-900">{{ fileName }}</p>
          <span class="text-xs text-gray">{{ fileSize }}</span>
        </div>

        <div
          v-show="message.length > 0 && fileName.length === 0"
          class="flex justify-center gap-4 items-center"
        >
          <p class="text-xs font-medium text-error">{{ message }}</p>
        </div>
      </div>

      <div class="w-6/12 mx-auto my-3">
        <easiButton
          @click="doUpload(false, 'payroll')"
          :loading="loadingProgress"
          class="w-full"
          ><span class="font-normal">Preview</span></easiButton
        >
      </div>

      <form
        @submit.prevent="openPreviewModal"
        autocomplete="on"
        class="flex flex-col items-center px-3 md:px-12 lg:px-8 xl:px-10"
      ></form>
    </div> -->

    <div
      class="md:w-1/2 w-full py-7 mx-auto bg-white rounded-2xl mt-10 mb-12 relative text-left border border-boxBorder shadow-lg"
    >
      <div class="mb-8">
        <h2
          class="text-center text-headerText font-bold md:text-xl text-lg md:text-xl"
        >
          Upload Employee Salary Sheet
        </h2>
        <hr class="my-3 border-0.5 border-dividerColor text-dividerColor" />
      </div>

      <div class="flex flex-col items-center gap-2 mb-10">
        <form
          class="w-11/12 md:w-10/12 md:gap-x-0 gap-x-2 flex items-center justify-between bg-greyBg px-2 md:px-3 py-3"
          :class="
            uploadErr && message && message.length
              ? 'border border-secondary'
              : undefined
          "
          style="border-radius: 16px"
        >
          <div class="relative flex items-center gap-2">
            <input
              class="border-2 border-outlineGray"
              name="radio"
              id="radio"
              type="radio"
              :value="true"
              @change="auto = true"
            />
            <label
              :class="auto ? 'text-secondary' : 'text-dark-800'"
              class="text-xs md:text-sm font-medium"
              for="radio"
              >Eazipay Auto-calculate sheet
              <span
                @click="setHoveredType('auto')"
                @mouseenter="setHoveredType('auto')"
                @mouseleave="clearHoveredType"
                class="inline-block"
                ><img
                  class="w-3 ml-1"
                  src="@/assets/icons/payroll-info.svg"
                  alt=""
              /></span>
            </label>

            <div
              v-if="isHovered('auto')"
              class="absolute w-72 z-50 top-7 mt-2 left-4 bg-white shadow-2xl rounded-xl flex gap-2 py-2 px-3 flex-col mb-2 text-left"
            >
              <p class="font-bold text-xs text-dark-800">
                Eazipay Auto-calculate sheet
              </p>

              <p class="text-xs leading-5">
                This sheet automatically calculates your PAYE, Pension, Net
                salary and all other details of your payroll. You may input only
                Gross salary and allowances on this template sheet.
              </p>
            </div>
          </div>
          <div class="relative flex items-center gap-2">
            <input
              class="border-2 border-outlineGray"
              name="radio"
              id="radio1"
              type="radio"
              :value="false"
              @change="auto = false"
            />
            <label
              :class="auto === false ? 'text-secondary' : 'text-dark-800'"
              class="text-xs md:text-sm font-medium"
              for="radio1"
              >Manual input of salary
              <span
                @click="setHoveredType('manual')"
                @mouseenter="setHoveredType('manual')"
                @mouseleave="clearHoveredType"
                class="inline-block"
                ><img
                  class="w-3 ml-1"
                  src="@/assets/icons/payroll-info.svg"
                  alt=""
              /></span>
            </label>

            <div
              v-if="isHovered('manual')"
              class="absolute w-72 z-50 top-7 mt-2 left-4 bg-white shadow-2xl rounded-xl flex gap-2 py-2 px-3 flex-col mb-2 text-left"
            >
              <p class="font-bold text-xs text-dark-800">
                Manual inout of Salary
              </p>

              <p class="text-xs leading-5">
                This sheet allows you to input all details of your payroll.
                Eazipay does not automatically calculate any part of your
                salaries here. You may be required to input
                <strong>ALL</strong> part of your salaries including Gross
                salary, Net salary, Allowances, and Statutory Deductions.
              </p>
            </div>
          </div>
        </form>
        <p
          v-show="uploadErr && message && message.length"
          class="text-xs text-secondary"
        >
          Please select the sheet you want downloaded
        </p>

        <div
          @click="download('payroll')"
          class="w-44 mb-1 bg-newAsh flex items-center justify-center mt-9 text-center py-2 px-3 cursor-pointer"
          style="border-radius: 12px"
        >
          <img src="@/assets/icons/new-download-icon.svg" class="w-4 mr-5" />
          <span class="text-dark-800 text-sm mt-1 text-center font-bold">
            Download file</span
          >
        </div>

        <p class="text-xs text-dark-200">
          Download this sample to fill in all your employee’s salary
        </p>
      </div>

      <div class="flex flex-col items-center gap-2 mb-7">
        <div
          @click="$refs.input.click()"
          style="border-color: #d4d4d4; border-radius: 16px"
          class="w-10/12 mt-2 text-center flex flex-col gap-4 py-5 rounded-xl bg-newGrey border border-dashed px-4 cursor-pointer"
        >
          <span style="color: #444444" class="mt-4 font-bold"
            >Upload your salary sheet</span
          >
          <span
            class="w-full flex items-center gap-2 justify-center text-dark-800 text-base text-center"
          >
            <img class="w-6" src="@/assets/icons/new-upload-icon.svg" alt="" />

            <span>Choose File</span>
          </span>
        </div>
        <FileLoader
          v-show="fileLoading"
          :loadNum="loadNumber"
          class="w-9/12 mt-3"
        />
        <input
          v-show="hide === false"
          ref="input"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          @change="previewFiles($event)"
          type="file"
          class="custom-file-input"
          required
        />

        <div v-show="file" class="flex justify-center gap-4 items-center mt-4">
          <div class="w-6 justify-center self-center">
            <img :src="xlsicon" alt="" />
          </div>

          <p class="text-xs text-dark-900">{{ fileName }}</p>
          <span class="text-xs text-gray">{{ fileSize }}</span>
        </div>
      </div>

      <div class="md:w-7/12 w-11/12 mx-auto my-8">
        <easiButton
          :disabled="fileLoading"
          :loading="buttonLoading"
          block
          @click="doUpload(false, 'payroll')"
          ><span class="font-normal">Submit</span></easiButton
        >
      </div>

      <!-- <form
        @submit.prevent="openPreviewModal"
        autocomplete="on"
        class="flex flex-col items-center px-3 md:px-12 lg:px-8 xl:px-10"
      ></form> -->
    </div>

    <easiModal v-if="pickStats" :isBorder="false">
      <template v-slot:header>
        <h1 class="text-center text-sm font-semibold text-headerText">
          Please select the statutory deductions you want included in your
          sheet.
        </h1>
      </template>

      <div
        class="mt-4 mb-6 grid grid-cols-2 gap-3 justify-around md:grid-cols-3"
      >
        <div
          v-for="ded in allStatutoryDeductions"
          :key="ded.value"
          class="flex items-center"
        >
          <input
            class="mx-4 my-2"
            type="checkbox"
            :name="ded.label"
            :id="ded.label"
            @change="update(ded.value)"
          />
          <label :for="ded.label" class="uppercase font-medium text-xs">{{
            ded.label
          }}</label>
        </div>
      </div>
      <div class="flex justify-center">
        <easiButton class="md:w-72 text-sm mt-4 w-96" @click="download"
          >Download</easiButton
        >
      </div>
    </easiModal>

    <easiSuccess v-if="addSalarySuccess" @close="addSalarySuccess = false">
      <template v-slot:message>
        <span>Employees added successfully</span>
      </template>
    </easiSuccess>

    <easiPreview v-if="previewModal" @close="previewModal = false">
      <template v-slot:header>
        <h1>{{ uploaded ? "Confirm" : "Preview" }} Upload</h1>
        <p
          v-if="bankInvalid"
          class="text-error-table font-bold text-base mt-7 mb-2"
        >
          Invalid bank details found
        </p>
        <p
          v-if="errorFound"
          class="text-error-table font-bold text-base mt-7 mb-2"
        >
          The highlighted section(s) need to be corrected. Please correct and
          submit
        </p>
      </template>

      <template v-slot:body>
        <!-- <div v-if="jsonData.length && uploaded === false" class="w-full">
          <easiTable
            :data="jsonData"
            :header="configureHeaders"
            :mapData="bankCodes"
            :useSelect="['Bank Code']"
            :frozenFields="['Employee Id', 'First Name', 'Last Name']"
            :highlight="['First Name', 'Last Name']"
            :mandatory="['First Name', 'Last Name', 'Phone Number', 'Email']"
            :readonly="true"
          ></easiTable>
        </div> -->

        <div class="w-full">
          <easiTable
            :data="uploadJsonData"
            :header="uploadHeaders"
            :mapData="bankCodes"
            :useSelect="['bankCode']"
            :frozenFields="['sr', 'firstName', 'lastName']"
            :highlight="['firstName', 'lastName']"
            :mandatory="['firstName', 'lastName', 'phone', 'email']"
            :errorArray="errorArr"
          ></easiTable>
        </div>

        <div class="flex flex-col items-center gap-y-2 mt-10 mb-3">
          <!-- <div class="justify-center items-center gap-2 flex">
            <input
              class="my-2"
              v-model="sendOnboardLink"
              type="checkbox"
              name=""
              id=""
            />
            <span class="text-xs text-dark-800"
              >Send employee (s) onboarding form</span
            >
          </div> -->

          <div class="self-center flex justify-center w-full text-base">
            <!-- <easiButton
              v-if="uploaded === false"
              @click="doUpload(false, 'payroll')"
              class="md:w-72 w-96"
              >Submit</easiButton
            > -->
            <easiButton class="md:w-72 w-96" @click="chooseEndpoint"
              >Submit</easiButton
            >
          </div>
        </div>
      </template>
    </easiPreview>

    <easiLoading v-if="loadingProgress" @close="loadingProgress = false">
      <template v-slot:heading>
        <h1>Analyzing Data</h1>
      </template>

      <template v-slot:message>
        <div class="w-full rounded-xl">
          <vue3-progress-bar></vue3-progress-bar>
        </div>
      </template>
    </easiLoading>
    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span>Updated successfully</span>
      </template>
    </easiSuccess>

    <easiRoundLoader v-if="loading" />
  </main>
</template>

<script>
import * as XLSX from "xlsx/xlsx.mjs";
import axios from "axios";
import { AUTH_CONTEXT } from "@/api/api";
import { useProgress } from "@marcoschulte/vue3-progress";

import { ref, computed } from "vue";
import { mapWritableState, storeToRefs } from "pinia";
import { useDataStore } from "@/stores/data.js";
import { mapActions } from "pinia";
import { useToast } from "vue-toastification";

import banks from "@/utils/banks";
import xlsicon from "@/assets/icons/xls.svg";
export default {
  setup() {
    const store = useDataStore();
    const { query } = store;
    const { allowance, listEmployees, uploadTemplate } = storeToRefs(store);
    const toast = useToast();
    const allStatutoryDeductions = computed(() => store.getStatutoryDeductions);
    const optionArray = ref([]);
    const pickStats = ref(false);
    function update(param) {
      if (param) {
        if (optionArray.value.includes(param)) {
          // const newArr = [...optionArray.value.filter((opt) => param !== opt)];
          let index = optionArray.value.indexOf(param);
          if (index > -1) {
            optionArray.value.splice(index, 1);
          }
        } else {
          optionArray.value.push(param);
        }
      }
    }

    const hoveredType = ref("");
    function setHoveredType(type) {
      hoveredType.value = type;
    }
    function clearHoveredType() {
      hoveredType.value = null;
    }
    function isHovered(type) {
      return hoveredType.value === type;
    }

    // store.$subscribe((mutation, state) => {
    //   console.log(mutation.type, mutation.storeId, mutation.payload, state);
    //   // store.allowance = mutation.payload;
    // });

    return {
      store,
      query,
      allowance,
      toast,
      listEmployees,
      uploadTemplate,
      allStatutoryDeductions,
      update,
      optionArray,
      pickStats,
      isHovered,
      setHoveredType,
      hoveredType,
      clearHoveredType,
    };
  },

  data() {
    return {
      auto: null,
      uploadErr: false,
      loadingProgress: false,
      loading: false,
      updateSuccess: false,
      progressValue: 0,
      options: {
        text: {
          color: "#FFFFFF",
          shadowEnable: true,
          shadowColor: "#000000",
          fontSize: 14,
          fontFamily: "Helvetica",
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: "#2dbd2d",
          backgroundColor: "#333333",
          inverted: false,
        },
        layout: {
          height: 35,
          width: 140,
          verticalTextAlign: 61,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 30,
          progressPadding: 0,
          type: "line",
        },
      },
      sheetStyle: {
        border: "1px solid #B5BDE1",
        fontSizee: "14px",
      },
      accountWarning: "",
      previewModal: false,
      hide: true,
      message: "",
      fileName: "",
      fileSize: "",
      addSalarySuccess: false,

      xlsicon,
      nigerianBanks: banks,
      payload: {
        paging: {
          limit: 1000,
          skip: 0,
          search: "",
          sort: { firstName: "ASC" },
        },
        filter: [],
      },
      payload2: {
        paging: {
          limit: 1000,
          skip: 0,
          search: null,
        },
      },

      sendOnboardLink: false,

      bankName: "",
      bankDetails: [],

      inviteArg: {
        employeeId: "",
      },
      bankArgs: {
        bankName: "",
        bankCode: "",
        accountNumber: "",
        accountName: "",
      },

      configureUploadHeaders: [],
      uploadJsonData: [],
      jsonData: [],

      btnState: "primary",
      uploaded: false,
      file: null,
      employeeSheet: [],
      argsArray: [],
      bankInvalid: false,
      errorFound: false,
      arrOfIds: [],
      errorArr: [],
    };
  },
  computed: {
    ...mapWritableState(useDataStore, [
      "listAllowance",
      "allowance",
      "count",
      "name",
      "doubleCount",
      "getAllEmployees",
      "salaryArray",
      "uploadTemplate",
    ]),
    allAllowanceData: (store) => store.listAllowance,
    allEmployees: (store) => store.getAllEmployees,
    accNum() {
      return this.args.accountNumber;
    },
    bankCodes() {
      // const bankCodeObj = this.nigerianBanks.reduce(
      //   (acc, cur) => ({ ...acc, [cur.code]: cur.name }),
      //   {}
      // );
      const bankArray = [];
      for (let bank of this.nigerianBanks) {
        bankArray.push({
          label: bank.name,
          value: bank.code,
        });
      }
      return bankArray;
    },
    label() {
      return (str) =>
        str
          .split(".")
          .pop()
          .replace(/([A-Z])/g, " $1")
          .toUpperCase();
    },
    configureHeaders() {
      let headArr = [];
      const head = Object.keys(this.jsonData[0]);
      for (let i of head) {
        headArr.push({
          field: i,
          header: i,
        });
      }
      return headArr;
    },

    uploadHeaders() {
      let headArr = [];

      const head = this.getObjectWithMostKeys(this.uploadJsonData);
      for (const i in head) {
        headArr.push({
          field: i,
          header: i,
        });
      }
      return headArr;
    },
  },

  methods: {
    ...mapActions(useDataStore, ["query", "mutate"]),
    getObjectWithMostKeys(arrayOfObjects) {
      return arrayOfObjects.reduce(
        (maxKeysObj, obj) =>
          Object.keys(obj).length > Object.keys(maxKeysObj).length
            ? obj
            : maxKeysObj,
        {}
      );
    },
    async download(template) {
      if (template == "employee") {
        this.btnState = "disabled";

        const token = window.localStorage.getItem("token");

        await axios({
          url: `https://eaziscript.herokuapp.com/download_template`,
          method: "POST",
          data: { employee: true },
          headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
          var contentType = "application/vnd.ms-excel";
          var blob1 = this.b64toBlob(response.data, contentType);
          var blobUrl1 = window.URL.createObjectURL(blob1);
          var fileLink = document.createElement("a");
          fileLink.href = blobUrl1;
          fileLink.setAttribute("download", "Employee.xlsx");
          document.body.appendChild(fileLink);
          fileLink.click();
          this.btnState = "outline";
        });
      } else if (template == "payroll") {
        if (this.auto === null) {
          this.uploadErr = true;
          this.message = "Please select an upload type";
          return;
        }
        try {
          this.btnState = "disabled";
          this.loading = true;

          let res = await this.query({
            endpoint: "DownloadXLTemplate",
            payload: {
              input: { type: this.auto ? "editSalary" : "editSalaryManual" },
            },
            service: "PAYROLL",
            storeKey: "uploadTemplate",
          });
          // this.arrOfIds = this.uploadTemplate.employeeIds.map((el)=> el);
          if (res) {
            var contentType = "application/vnd.ms-excel";
            var blob1 = this.b64toBlob(res, contentType);
            var blobUrl1 = window.URL.createObjectURL(blob1);
            var fileLink = document.createElement("a");
            fileLink.href = blobUrl1;
            fileLink.setAttribute(
              "download",
              `${this.auto ? "Payroll.xlsx" : "Manual Payroll.xlsx"}`
            );
            document.body.appendChild(fileLink);
            fileLink.click();
            this.btnState = "outline";
            this.btnState = "outline";
          } else {
            this.toast.error("Download failed");
          }
        } catch (err) {
          this.btnState = "outline";
          this.toast.error(err.message);
          console.log(err);
        } finally {
          this.loading = false;
        }
      }
    },
    b64toBlob(b64Data, contentType, sliceSize) {
      contentType = contentType || "";
      sliceSize = sliceSize || 512;
      var byteCharacters = atob(b64Data);
      var byteArrays = [];
      for (
        var offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);
        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      var blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },

    removePercentage(s) {
      s = s.replace(/%/g, "");
      return Number(s);
    },

    extractAllowanceObject() {
      const info = this.allAllowanceData.data.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.name.toLowerCase().replace(/\s+/g, "")]: cur._id,
        }),
        {}
      );
      return info;

      // const obj = this.allAllowanceData.data.map((alw) => {
      //   return {
      //     [alw.name]: alw._id,
      //   };
      // });
      // return obj
    },

    createUploadArray(event) {
      let data = [];
      data = event.map((emp) => {
        emp.allowanceDetails = [];
        return emp;
      });
      let allowanceObj = {};
      allowanceObj = this.extractAllowanceObject();

      const arr = Object.keys(allowanceObj).map((ao) => {
        return data.map((emp) => {
          if (emp[ao]) {
            emp["allowanceDetails"].push({
              ["allowanceId"]: allowanceObj[ao],
              amount: !String(emp[ao]).includes("%") ? Number(emp[ao]) : null, //Temporary
              percentage: String(emp[ao]).includes("%")
                ? this.removePercentage(emp[ao])
                : null,
            });
          }
          if (emp[ao] && !String(emp[ao]).includes("%")) {
            emp.usepercentageforallowance = false;
          }
          // console.log(emp, "NEWEMP");
          return emp;
        });
      });
      return arr[0];

      // this.uploadJsonData = uploadArr;
      // console.log(this.uploadJsonData);
    },
    createManualUploadArray(event) {
      let data = [];
      data = event.map((emp) => {
        emp.allowanceDetails = [];
        emp = Object.entries(emp).reduce((acc, [key, value]) => {
          const newKey = key.replace("_allowance", "");
          return { ...acc, [newKey]: value };
        }, {});
        return emp;
      });
      let allowanceObj = {};
      allowanceObj = this.extractAllowanceObject();

      const arr = Object.keys(allowanceObj).map((ao) => {
        return data.map((emp) => {
          if (emp[ao]) {
            emp["allowanceDetails"].push({
              ["allowanceId"]: allowanceObj[ao],
              amount: !String(emp[ao]).includes("%") ? Number(emp[ao]) : null, //Temporary
              percentage: String(emp[ao]).includes("%")
                ? this.removePercentage(emp[ao])
                : null,
            });
          }
          if (emp[ao] && !String(emp[ao]).includes("%")) {
            emp.usepercentageforallowance = false;
          }
          // console.log(emp, "NEWEMP");
          return emp;
        });
      });
      return arr[0];

      // this.uploadJsonData = uploadArr;
      // console.log(this.uploadJsonData);
    },
    getUniqueListBy(arr, key) {
      return [...new Map(arr.map((item) => [item[key], item])).values()];
    },

    async editManually() {
      this.loadingProgress = true;
      const data = this.createManualUploadArray(this.uploadJsonData);
      // return true;
      const uploadArrManual = data.map((item) => {
        return {
          employeeId: item.uid,
          sl: {
            usePercentageForAllowance: item.usepercentageforallowance,
            allowanceDetails: item.allowanceDetails,
            grossAmount: Number(item.grosspay),
            benefits: [
              item.gratuity && Number(item.gratuity) ? "Gratuity" : null,
              item.hmo && Number(item.hmo) ? "HMO" : null,
              item.itf && Number(item.itf) ? "ITF" : null,
              item.lifeinsurance && Number(item.lifeinsurance)
                ? "Group_Life_Insurance"
                : null,
              item.nhf && Number(item.nhf) ? "NHF" : null,
              item.nsitf && Number(item.nsitf) ? "NSITF" : null,
              item.paye && Number(item.paye) ? "PAYE" : null,
              item.wht && Number(item.wht) ? "WHT" : null,
              (item.employeepensioncontribution &&
                Number(item.employeepensioncontribution)) ||
              (item.employerpensioncontribution &&
                Number(item.employerpensioncontribution))
                ? "Pension"
                : null,
            ].filter((el) => el != null || el != undefined),
            // netAmount: Number(item.netpay),
            pension: {
              employeePensionContribution: item.employeepensioncontribution,
              employerPensionContribution: item.employerpensioncontribution,
            },
            gratuity: {
              amount: item.gratuity,
            },
            hmo: {
              amount: item.hmo,
            },
            itf: {
              amount: item.itf,
            },
            lifeInsurance: {
              amount: item.lifeinsurance,
            },
            nhf: {
              amount: item.nhf,
            },
            nsitf: {
              amount: item.nsitf,
            },
            paye: {
              amount: item.paye,
            },
            wht: {
              amount: item.wht,
            },
            usePercentageForAllowance: item.usepercentageforallowance,
            withholdingTax: item.withholdingtax,

            // staffLevelId: item.stafflevelid,
          },
        };
      });

      try {
        let res = await this.mutate({
          endpoint: "ManualUpdateStaffLevel",
          data: {
            input: uploadArrManual,
          },
          service: "PAYROLL",
        });

        if (res.success) {
          this.loadingProgress = false;
          this.updateSuccess = true;
          await this.queryEmployees();
          // Object.assign(args.value, resetObj(args.value, "args"));
          setTimeout(() => {
            this.updateSuccess = false;
            this.previewModal = false;
            this.loadingProgress = false;

            // window.location.reload();
          }, 1000);
          this.$router.push({ name: "ViewSalary" });
          // toast.success("Allowance Details Updated Successfully");
        } else {
          this.toast.error(res.message);
        }
      } catch (e) {
        this.loadingProgress = false;
        console.log(e);
      } finally {
        this.loadingProgress = false;
      }
    },

    async editSection() {
      this.loadingProgress = true;
      const data = this.createUploadArray(this.uploadJsonData);
      // return true;
      const uploadArr = data.map((item) => {
        return {
          usePercentageForAllowance: item.usepercentageforallowance,
          staffLevelId: item.stafflevelid,
          salary: {
            grossAmount: Number(item.grosspay),
            netAmount: Number(item.netpay),
          },
          allowanceDetails: item.allowanceDetails,
        };
      });

      // loading.value = true;
      // let data = uploadData.value.map((d) => {
      //   d.allowanceDetails = d.allowanceDetails.filter(
      //     (obj) => obj["amount"] || obj["percentage"]
      //   );
      //   return d;
      // });
      // console.log(data, "REMOVED");

      try {
        let res = await this.mutate({
          endpoint: "updateAllowanceDetailsMultiple",
          data: {
            input: uploadArr,
          },
          service: "EMP",
        });

        console.log(res);
        if (res.success.length > 0 && !res.failed.length) {
          this.loadingProgress = false;
          this.updateSuccess = true;
          await this.queryEmployees();
          // Object.assign(args.value, resetObj(args.value, "args"));
          setTimeout(() => {
            this.updateSuccess = false;
            this.previewModal = false;
            this.loadingProgress = false;

            // window.location.reload();
          }, 1000);
          this.$router.push({ name: "ViewSalary" });
          // toast.success("Allowance Details Updated Successfully");
        } else if (res.failed.length > 0) {
          this.previewModal = false;
          const msgs = res.failed;
          for (let msg of msgs) {
            this.toast.error(msg.error);
            this.errorArr.push(msg.staffLevelId);
          }
          this.previewModal = true;

          this.loadingProgress = false;
        }
      } catch (e) {
        this.loadingProgress = false;
        console.log(e);
      }
    },

    async chooseEndpoint() {
      if (
        this.uploadJsonData[0] &&
        String(this.uploadJsonData[0].paye) &&
        this.uploadJsonData[0].paye >= 0
      ) {
        await this.editManually();
      } else {
        await this.editSection();
      }
    },

    transformObject(arr) {
      // //remove string preceeding the "." sign
      this.uploadJsonData = [];
      const newArr = arr.map((obj) =>
        Object.fromEntries(
          Object.entries(obj).map(([k, v]) => [
            k.toLowerCase().replace(/\s+/g, ""),
            v,
          ])
        )
      );

      const nextArr = newArr.map((obj) => {
        obj["allowanceDetails"] = [];
        return obj;
      });

      if (Object.keys(arr[0]).includes("bankError")) {
        const findError = newArr.find((item) => item.bankError.length > 0);
        if (findError) {
          this.bankInvalid = true;
        }
      }

      this.uploadJsonData = nextArr.map((em) => em); //create a new instance of array

      this.$nextTick();

      // console.log(this.uploadJsonData, "JS");
    },

    async doUpload(confirmed = false, template) {
      this.bankInvalid = false;
      if (template === "payroll") {
        const endpoint = confirmed
          ? "updateAllowanceDetailsMultiple"
          : "ViewStaffLevelWithExcelLite";
        this.btnState = "disabled";
        const formData = new FormData();
        const operation = endpoint;
        formData.append(
          "operations",
          `{
          "query": "mutation ${operation}($file:Upload!) { ${operation}(file: $file){message data} }"
        }`
        );
        formData.append("map", `{"0": ["variables.file"]}`);
        formData.append("0", this.file, "Payroll.xlsx");

        try {
          this.previewModal = false;
          this.loadingProgress = true;
          const progress1 = useProgress().start();
          let progressValue = 0;
          const response = await axios.post(
            "https://payrollservice-d5ffb79ba43a9141.onporter.run/graphql",
            // "http://localhost:4001/graphql",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                authorization: AUTH_CONTEXT(),
                "Apollo-Require-Preflight": "true",
              },

              onUploadProgress: function (progressEvent) {
                progressValue = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );

                // console.log(percent);
              },
            }
          );

          if (progressValue === 100) {
            progress1.finish();
            this.loadingProgress = false;
            this.previewModal = true;
          }

          if (confirmed === true) {
            if (response.data.data.updateAllowanceDetailsMultiple.failedData) {
              this.transformObject(
                response.data.data.updateAllowanceDetailsMultiple.failedData
              );
              this.errorFound = true;

              this.toast.error(
                `${response.data.data.updateAllowanceDetailsMultiple.message}`,
                {
                  timeout: 6000,
                }
              );
            } else {
              this.previewModal = false;
              this.addSalarySuccess = true;
            }
          } else {
            console.log("RESPONSE View", response);

            if (
              response.data.data.ViewStaffLevelWithExcelLite.data.dataFromExcel
                .length
            ) {
              this.toast.success(
                `${response.data.data.ViewStaffLevelWithExcelLite.message}, confirm details before uploading`,
                {
                  timeout: 6000,
                }
              );
              this.employeeSheet =
                response.data.data.ViewStaffLevelWithExcelLite.data.dataFromExcel;
              console.log(
                response.data.data.ViewStaffLevelWithExcelLite,
                "RESPONSE"
              );
              this.transformObject(
                response.data.data.ViewStaffLevelWithExcelLite.data
                  .dataFromExcel
              );
              if (this.employeeSheet.length <= 0) {
                this.toast.error(
                  "0 correct record found in Sheet. Please fill correctly."
                );
                throw new Error("0 correct record found in Sheet");
              }
              this.uploaded = true;
            } else {
              this.toast.error(
                `${response.data.data.ViewStaffLevelWithExcelLite.message}`,
                {
                  timeout: 6000,
                }
              );
            }

            // this.confirmation = true;
            // this.uploadedTemplate = "employee";
          }
        } catch (err) {
          if (err) this.loadingProgress = false;
          this.btnState = "primary";
          console.log(err);
        }
        this.btnState = "primary";
      }
    },

    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },

    // openPreviewModal() {
    //   if (this.fileName.length === 0) {
    //     this.message =
    //       "You need to upload the populated template before proceeding";
    //     throw new Error(this.message);
    //   }
    //   this.previewModal = true;
    // },
    openPreviewModal() {
      try {
        this.loadingProgress = true;
        setTimeout(() => {
          if (this.fileName.length === 0) {
            this.message =
              "You need to upload the populated template before proceeding";
            throw new Error(this.message);
          }
          // this.loadingProgress = false;

          this.previewModal = true;
          this.loadingProgress = false;
        }, 1000);
      } catch (e) {
        console.log(e);
      } finally {
        this.loadingProgress = false;
      }
    },

    previewFiles(e) {
      this.jsonData = [];
      this.file = null;
      var files = this.$refs.input.files;
      this.uploadErr = false;
      var f = files[0];
      this.file = f;
      const allowedTypes = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ];
      if (!allowedTypes.includes(f.type)) {
        this.message = "File type is wrong!!";
        throw new Error(this.message);
      }
      if (f.size > 1000000) {
        this.message = "Too large, max size allowed is 1MB";
        throw new Error(this.message);
      }
      const { name, size } = f;
      this.fileName = name;
      this.fileSize = this.formatBytes(size);
      var reader = new FileReader();
      reader.onload = (e) => {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: "array" });
        let sheetName = workbook.SheetNames[0];
        /* DO SOMETHING WITH workbook HERE */
        let worksheet = workbook.Sheets[sheetName];
        let jsondata = XLSX.utils.sheet_to_json(worksheet, {
          defval: "",
          raw: false,
        });
        let root = this;
        jsondata.forEach(function (data) {
          root.jsonData.push(data);
        });
        console.log(this.jsonData);
        // this.jsonData = jsondata;
        this.$nextTick();
      };
      reader.readAsArrayBuffer(f);
    },

    queryAllowance() {
      this.query({
        endpoint: "ListAllowance",
        payload: this.payload2,
        service: "PAYROLL",
        storeKey: "allowance",
      });
    },
    queryEmployees() {
      this.query({
        endpoint: "ListEmployees",
        payload: this.payload,
        service: "EMP",
        storeKey: "listEmployees",
      });
    },

    sendLink(e) {
      console.log(e);
      this.sendOnboardLink = !this.sendOnboardLink;
    },
    // createUploadArray() {
    //   const profile = this.uploadJsonData.map((item) => {
    //     return {
    //       _id: item.sr, //visit later
    //       profile: {
    //         phone: item.phone,
    //         email: item.email,
    //         firstName: item.firstName,
    //         lastName: item.lastName,
    //       },
    //       job: {
    //         jobTitle: item.jobTitle,
    //       },
    //       payment: {
    //         accountNumber: item.accountNumber,
    //         bankName: item.bankName,
    //         // accountName: item.accountName,
    //         bankCode: item.bankCode,
    //       },
    //     };
    //   });
    //   return profile;
    // },

    addEmployeesWithExcelLite() {
      const uploadData = this.createUploadArray();
      console.log(uploadData);

      this.loadingProgress = true;
      this.previewModal = false;

      this.mutate({
        endpoint: "AddEmployeesWithExcelLite",
        service: "PAYROLL",
        data: { input: uploadData },
      })
        .then((res) => {
          this.loadingProgress = false;
          this.previewModal = true;
          console.log(res);
          if (res.success && res.data.failed === 0) {
            this.toast.success(res.message);
          } else {
            this.errorFound = true;
            this.uploadJsonData = this.transform(res.data.failedData);
            this.toast.error(res.message);
          }
        })
        .catch((e) => {
          console.log(e);
          this.loadingProgress = false;
          this.previewModal = true;
        });
    },

    uploadToServer() {
      this.addEmployeesWithExcelLite();

      // if (this.bankInvalid) {
      //   this.toast.error("Error Found in sheet");
      //   throw new Error("Correct sheet");
      // } else {
      //   this.bankInvalid = false;
      //   this.addEmployeesWithExcelLite();
      // }
    },
    transform(arr) {
      const newArr = arr.map((obj) => {
        return {
          _id: obj._id,
          firstName: obj.profile.firstName,
          lastName: obj.profile.lastName,
          email: obj.profile.email,
          phone: obj.profile.phone,
          jobTitle: obj.job.jobTitle,
          accountNumber: obj.payment.accountNumber,
          bankCode: obj.payment.bankCode,
          reason: obj.reason,
        };
      });

      return newArr;
    },
  },
  async created() {
    this.queryAllowance();
    this.queryEmployees();
  },
};
</script>

<style></style>
