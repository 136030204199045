<template>
  <easiModal v-if="showModal" @close="closeModal">
    <template v-slot:header>
      <h1 class="capitalize font-medium text-headerText">Payslip Details</h1>
    </template>

    <form
      @submit.prevent="previewPayslip"
      autocomplete="on"
      class="my-5 flex flex-col w-full"
    >
      <div class="flex flex-col px-8 w-full text-left">
        <label for="employmentType" class="text-dark-800 text-sm font-medium"
          >Select Month</label
        >

        <easiSelectInput2
          required
          class="mt-1 mb-3"
          @update="args.month = $event"
          :value="args.month"
          :options="months"
        />
        <label for="employmentType" class="text-dark-800 text-sm font-medium"
          >Select Year</label
        >

        <easiSelectInput2
          required
          class="mt-1 mb-3"
          @update="args.year = $event"
          :value="args.year"
          :options="years"
        />
        <label for="timeline" class="text-dark-800 text-sm font-medium"
          >Select Timeline</label
        >

        <easiSelectInput2
          required
          class="mt-1 mb-3"
          @update="args.timeline = $event"
          :value="args.timeline"
          :options="timelines"
        />
        <easiSelectInput2
          v-if="args.timeline == 'BIWEEKLY'"
          required
          class="mt-1 mb-3"
          @update="args.biweekly = $event"
          :value="args.biweekly"
          :options="biweekly"
        />

        <easiEmployeeBonus
          @update="args.employeeIds = $event"
          :value="args.employeeIds"
          :options="createEmpList()"
          class="mt-1 mb-2"
        />

        <easiButton :loading="loading" block color="primary"
          >Preview</easiButton
        >
      </div>
    </form>
  </easiModal>

  <easiPreview v-if="previewModal" @close="previewModal = false">
    <template v-slot:header>
      <h1 class="capitalize">{{ args.month }}, {{ args.year }} PAYSLIP</h1>
      <p
        v-if="errorFound"
        class="text-error-table font-bold text-base mt-7 mb-2"
      >
        The highlted section(s) need to be corrected. Please correct and submit
      </p>
    </template>

    <template v-slot:body>
      <div v-if="tableData.length" class="w-full">
        <easiTable
          :data="tableData"
          :header="configureHeaders"
          :useSelect="['Bank Code']"
          :frozenFields="[, 'firstName', 'lastName']"
          :highlight="['firstName', 'lastName']"
          :mandatory="['First Name', 'Last Name', 'Email']"
          :readonly="true"
        ></easiTable>
      </div>

      <div class="flex justify-center mt-10 mb-3 w-full">
        <easiButton :loading="loading" @click="sendPayslipFN"
          >Send Payslip</easiButton
        >
      </div>
    </template>
  </easiPreview>

  <easiSuccess v-if="payslipSuccess" @close="payslipSuccess = false">
    <template v-slot:message>
      <span>Payslip(s) sent successfully</span>
    </template>
  </easiSuccess>
</template>

<script setup>
import { computed, onMounted, ref, reactive, watch } from "@vue/runtime-core";
import { useToast } from "vue-toastification";
import { useDataStore } from "@/stores/data.js";
import { helperFunctions } from "@/composable/helperFunctions";
import { storeToRefs } from "pinia";

const toast = useToast();
const store = useDataStore();
const {
  getAllEmployees,
  getBonusAndDeduction,
  listAllowance,
  payrollForMonth,
} = storeToRefs(store);

const { formatAmount, calculatePercentage } = helperFunctions;

const allEmployees = computed(() => getAllEmployees.value.data);
const bonusDeduction = computed(() => store.getBonusAndDeduction);
const { query, mutate } = store;

const props = defineProps({
  closeFN: Function,
  employeeIds: {
    type: Array,
    default: () => [],
  },
});

const watchEmpIds = computed(() => props.employeeIds);
const loading = ref(false);
const errorCaught = ref(false);

const payload = reactive({
  month: null,
  year: null,
  timeline: null,
  sort: {},
  timeline: "MONTHLY",
  paging: {
    limit: 1000,
    skip: 0,
    search: "",
  },
  filter: [],
});

const args = ref({
  month: "",
  year: "",
  timeline: "MONTHLY",
  biweekly: null,
  employeeIds: [],
});

const biweekly = [
  {
    label: "FIRSTHALF",
    value: "FIRSTHALF",
  },
  {
    label: "SECONDHALF",
    value: "SECONDHALF",
  },
];

const timelines = [
  {
    label: "DAILY",
    value: "DAILY",
  },
  {
    label: "WEEKLY",
    value: "WEEKLY",
  },
  {
    label: "BIWEEKLY",
    value: "BIWEEKLY",
  },
  {
    label: "MONTHLY",
    value: "MONTHLY",
  },
];

const months = [
  {
    label: "January",
    value: "January",
  },
  {
    label: "February",
    value: "February",
  },
  {
    label: "March",
    value: "March",
  },
  {
    label: "April",
    value: "April",
  },
  {
    label: "May",
    value: "May",
  },
  {
    label: "June",
    value: "June",
  },
  {
    label: "July",
    value: "July",
  },
  {
    label: "August",
    value: "August",
  },
  {
    label: "September",
    value: "September",
  },
  {
    label: "October",
    value: "October",
  },
  {
    label: "November",
    value: "November",
  },
  {
    label: "December",
    value: "December",
  },
];

const years = computed(() => {
  const arr = [];
  const currentYear = new Date().getFullYear();
  for (var i = 2000; i <= currentYear; i++) {
    arr.unshift({ label: i, value: i });
  }

  return arr;
});

const showModal = ref(true);
const payslipSuccess = ref(false);
const previewModal = ref(false);

const tableData = ref([]);
const headerKey = ref([]);

const bonusNames = ref([]);
const deductionNames = ref([]);
const allowanceNames = ref([]);
const bonusData = ref([]);
const deductionData = ref([]);
const allAllowanceData = computed(() => store.listAllowance);
const bonusOptions = ref([]);
const deductionOptions = ref([]);

function getAllowanceNames() {
  const names = allAllowanceData.value
    ? allAllowanceData.value.data.map((n) => n.name)
    : [];

  console.log("ALLOWANCCE NAME", names);
  return names;
}

allowanceNames.value = getAllowanceNames();

const configureHeaders = computed(() => {
  let headArr = [];
  // const head = Object.keys(tableData.value[0]);
  const head = headerKey.value;
  for (let i of head) {
    headArr.push({
      field: i,
      header: i,
    });
  }
  return headArr;
});

if (props.employeeIds.length) {
  args.value.employeeIds = props.employeeIds;
}

watch(watchEmpIds, () => {
  if (props.employeeIds.length) {
    args.value.employeeIds = props.employeeIds;
  }
});

onMounted(() => {
  if (props.employeeIds.length) {
    args.value.employeeIds = props.employeeIds;
  }
  // console.log(props.employeeIds);
  // console.log(args.value.employeeIds);
});

function validate() {
  if (args.value.month == "") {
    toast.error("Please select a month");
    return false;
  } else if (args.value.year == "") {
    toast.error("Please select a year");
    return false;
  } else if (args.value.timeline == "") {
    toast.error("Please select a timeline");
    return false;
  } else if (args.value.timeline == "BIWEEKLY" && args.value.biweekly == "") {
    toast.error("Please select  biweekly");
    return false;
  } else if (args.value.employeeIds.length == 0) {
    toast.error("Please select atleast one employee");
    return false;
  } else {
    return true;
  }
}

const createEmpList = () => {
  const list = allEmployees.value.map((emp) => {
    return {
      label: `${emp.profile.firstName} ${emp.profile.lastName}`,
      value: emp._id,
    };
  });
  return list;
};

function matchStatutoryDeds(arr) {
  const data = arr.map((item) => {
    if (item === "PAYE") return "paye";
    if (item === "Pension") return "pension";
    if (item === "NHF") return "nhf";
    if (item === "NSITF") return "nsitf";
    if (item === "ITF") return "itf";
    if (item === "WHT") return "wht";
    if (item === "Group_Life_Insurance") return "lifeInsurance";
    if (item === "Employee_Life_Assurance") return "lifeAssurance";
    if (item === "HMO") return "hmo";
    if (item === "Business_Expense") return "businessExpense";
    if (item === "Gratuity") return "gratuity";
  });
  return data;
}

function transform(arr) {
  const benArr = [
    "paye",
    "pension",
    "hmo",
    "lifeInsurance",
    "lifeAssurance",
    "nsitf",
    "itf",
    "nhf",
    "wht",
    "gratuity",
    "businessExpense",
  ];
  let keys = ["firstName", "lastName"];
  const selectedDeds = matchStatutoryDeds(benArr);

  //Statutory
  for (let i of selectedDeds) {
    keys.push(i);
  }

  //Bonus
  for (let i of bonusNames.value) {
    keys.push(i);
  }

  //Deduction
  for (let i of deductionNames.value) {
    keys.push(i);
  }

  //Salary
  keys.splice(2, 0, "netAmount");
  keys.splice(2, 0, "grossAmount");
  for (let i of allowanceNames.value) {
    keys.splice(4, 0, i);
  }

  headerKey.value = keys.filter((el) => el != null);

  const newArr = arr.map((emp) => {
    const data = keys
      .filter((key) => {
        emp[key] ? emp[key] : "";
        return emp;
      })
      .reduce((obj, key) => {
        if (typeof emp[key] === "object") {
          console.log(key, "KEY");
          return Object.assign(obj, {
            [key]: emp[key]
              ? emp[key].amount
                ? formatAmount(Number(emp[key].amount))
                : formatAmount(
                    Number(
                      calculatePercentage(emp.grossAmount, emp[key].percentage)
                    )
                  )
              : "N/A",
          });
        }
        if (typeof emp[key] === "number") {
          return Object.assign(obj, {
            [key]: emp[key] ? formatAmount(Number(emp[key])) : "N/A",
          });
        }
        return Object.assign(obj, {
          [key]: emp[key] ? emp[key] : "N/A",
        });
      }, {});

    return data;
  });

  return newArr;
}

const convertResData = (data) => {
  const employees = Object.values(data && data.employeeDetails);

  const getSalary = (id) => {
    return data.salaryRecords.find((el) => el.employeeId == id);
  };

  const getStatutory = (id) => {
    return data.statutoryRecords.filter((el) => el.employeeId == id);
  };

  const getBonus = (id) => {
    return data.bonusOrDeductionRecords
      .filter((el) => el.employeeId == id)
      .filter((el) => el.type == "Bonus");
  };
  const getDeduction = (id) => {
    return data.bonusOrDeductionRecords
      .filter((el) => el.employeeId == id)
      .filter((el) => el.type == "Deduction");
  };

  console.log("DATAS", data);
  console.log("EMPLOYEE", employees);

  const newData = employees.map((d) => {
    const salary = getSalary(d.employeeId);
    const statutory = getStatutory(d.employeeId);
    const bonus = getBonus(d.employeeId);
    const deduction = getDeduction(d.employeeId);
    const allowance = salary.allowanceDetails;

    console.log(salary);

    const data = {
      id: d.employeeId,
      firstName: d.profile.firstName && d.profile.firstName,
      lastName: d.profile.lastName && d.profile.lastName,
      grossAmount: salary && salary.grossAmount ? salary.grossAmount : 0,
      netAmount: salary && salary.netAmount ? salary.netAmount : 0,
    };

    //////// Add Allowance Details ///////
    if (allowance) {
      Object.keys(allowance).forEach(function (key) {
        data[key] = allowance[key].percentage
          ? (allowance[key].percentage * data.grossAmount) / 100
          : allowance[key].amount;
      });
    }

    //////// Add Statutory Details ///////
    if (statutory) {
      statutory.forEach((el) => {
        data[el.name] = el.statutoryDetails.amount
          ? el.statutoryDetails.amount
          : 0;
      });
    }
    //////// Add Bonus ///////
    if (bonus) {
      bonus.forEach((el) => {
        data[el.name] = el.amount ? el.amount : 0;
      });
    }
    //////// Add Deduction ///////
    if (deduction) {
      deduction.forEach((el) => {
        data[el.name] = el.amount ? el.amount : 0;
      });
    }

    return data;
  });

  // let final = newData.map((dt) => {
  //   const allowance = dt.allowanceDetails;
  //   let da;
  //   if (allowance) {
  //     da = Object.entries(allowance).map(([key, value]) => {
  //       return (dt[key] = allowance[key].amount ? allowance[key].amount : 0);
  //     });
  //   }
  //   delete dt.allowanceDetails;
  //   return da;
  // });

  // final = newData.map((dt) => {
  //   const bonuses = dt.bonus;
  //   let da;
  //   if (bonuses) {
  //     da = Object.entries(bonuses).map(([key, value]) => {
  //       return (dt[key] = bonuses[key].amount ? bonuses[key].amount : 0);
  //     });
  //   }
  //   delete dt.bonus;
  //   return da;
  // });

  // final = newData.map((dt) => {
  //   const deductions = dt.deduction;
  //   let da;
  //   if (deductions) {
  //     da = Object.entries(deductions).map(([key, value]) => {
  //       return (dt[key] = deductions[key].amount ? deductions[key].amount : 0);
  //     });
  //   }
  //   delete dt.deduction;
  //   return da;
  // });

  // console.log(final, "fi");
  console.log("NEW DATA", newData);
  return newData;
};

//////// Functions ////////
const queryViewPayrollForMonthV3 = async () => {
  loading.value = true;

  payload.month = getIndexByMonth(args.value.month);
  payload.year = args.value.year;
  payload.timeline = args.value.timeline;
  if (args.value.biweekly) {
    payload.biweekly = args.value.biweekly;
  }

  console.log(args.value);

  try {
    await query({
      endpoint: "ViewPayrollForMonthV3",
      payload: {
        input: payload,
      },
      service: "PAYROLL",
      storeKey: "payrollForMonth",
    });

    if (payrollForMonth.value) {
      const datas = convertResData(payrollForMonth.value);

      const selectedData = [];
      args.value.employeeIds.forEach((el) => {
        datas.find((i) => {
          if (el === i.id) {
            selectedData.push(i);
          }
        });
      });
      tableData.value = transform(selectedData);

      console.log("payrollForMonth");
      console.log(selectedData);
      console.log(payrollForMonth.value);
    } else {
      toast.error(
        `No Payroll found for ${args.value.month}, ${args.value.year}`
      );
    }
    loading.value = false;
  } catch (err) {
    loading.value = false;
    console.log(err);
  }
};

const queryBonus = async () => {
  try {
    await query({
      endpoint: "ListBonusAndDeduction",
      payload: {
        input: {
          paging: {
            limit: 1000,
            skip: 0,
            search: "",
          },
          type: "Bonus",
        },
      },
      service: "PAYROLL",
      storeKey: "listBonusAndDeduction",
    });
    bonusData.value = bonusDeduction.value;

    bonusOptions.value = bonusData.value.map((bd) => {
      bonusNames.value.push(bd.name);
      return {
        label: bd.name,
        value: bd._id,
      };
    });

    console.log(bonusNames.value);
  } catch (err) {
    console.log(err);
  }
};

const queryDeduction = async () => {
  try {
    await query({
      endpoint: "ListBonusAndDeduction",
      payload: {
        input: {
          paging: {
            limit: 1000,
            skip: 0,
            search: "",
          },
          type: "Deduction",
        },
      },
      service: "PAYROLL",
      storeKey: "listBonusAndDeduction",
    });
    deductionData.value = bonusDeduction.value;
    deductionOptions.value = deductionData.value.map((bd) => {
      deductionNames.value.push(bd.name);
      return {
        label: bd.name,
        value: bd._id,
      };
    });

    console.log(deductionNames.value);
  } catch (err) {
    console.log(err);
  }
};

const previewPayslip = async () => {
  if (validate()) {
    await queryViewPayrollForMonthV3();
    console.log(args.value);

    if (payrollForMonth.value) {
      previewModal.value = true;
    }
  }
};

const sendPayslipFN = async () => {
  console.log("Sending Payslip");
  console.log(args.value);

  loading.value = true;

  const payload = {
    // companyId: window.localStorage.getItem("companyId"),
    employeeIds: [...args.value.employeeIds],
    month: payrollForMonth.value && payrollForMonth.value.month,
    year: payrollForMonth.value && payrollForMonth.value.year,
    timeline:
      payrollForMonth.value && payrollForMonth.value.timeline
        ? payrollForMonth.value.timeline
        : "MONTHLY",
  };

  try {
    const res = await mutate({
      endpoint: "SendPaySlipEmails",
      data: {
        input: payload,
      },
      service: "PAYROLL",
    });

    if (res.success) {
      payslipSuccess.value = true;
      showModal.value = false;
      previewModal.value = false;

      setTimeout(() => {
        payslipSuccess.value = false;
      }, 3000);
      console.log(res);
    } else {
      toast.error(res.message);
    }
  } catch (err) {
    console.log(err);
  } finally {
    loading.value = false;
  }
};

function getIndexByMonth(month) {
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  // return months[index];
  return months.indexOf(month);
}

const emit = defineEmits(["update", "close"]);
function close() {
  emit("close");
}

const closeModal = () => {
  showModal.value = false;
  args.value.employeeIds = [];
  close();
};

queryBonus();
queryDeduction();
</script>

<style></style>
