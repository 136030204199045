<template>
  <main>
    <div v-if="type === 'add'" class="flex flex-col gap-4 items-center mt-6">
      <h2 class="font-bold text-sm text-dark-800">
        Please Input Business Expense Details
      </h2>
    </div>
    <hr
      v-if="type === 'add'"
      class="mt-6 mb-1 border-0.5 border-dividerColor text-dividerColor"
    />

    <form
      @submit.prevent="
        type === 'add'
          ? addBusinessExpense('preview')
          : addBusinessExpense('add')
      "
      autocomplete="on"
      class="flex flex-col text-left"
    >
      <div class="px-3 md:px-12 lg:px-8 xl:px-10">
        <div class="flex flex-col mb-6">
          <!-- <label for="expenseName" class="text-dark-800 text-sm font-medium"
            >Expense Name
          </label> -->
          <easiTextInput
            placeholder="Expense Name"
            type="text"
            name="expenseName"
            v-model="args.businessExpense.expenseName"
            class="mt-1 mb-2"
            required
          ></easiTextInput>

          <!-- <label for="expenseAmount" class="text-dark-800 text-sm font-medium"
            >Amount
          </label> -->
          <easiTextInput
            placeholder="Amount"
            type="text"
            :format="true"
            name="expenseAmount"
            v-model="args.businessExpense.amount"
            class="mt-1 mb-2"
            required
          ></easiTextInput>
        </div>
        <label for="email" class="mt-4 text-dark-800 text-sm font-medium"
          >Please Select Employee
          <span class="text-xs text-error tracking-wide"> *</span></label
        >
        <easiEmployeeBonus
          @update="args.employeeIds = $event"
          :value="args.employeeIds"
          :options="createEmpList()"
          :compliance="true"
          @remove="removedEmployees = $event"
          class="mt-1 mb-8"
        />
      </div>

      <hr
        v-if="type === 'add'"
        class="mb-5 border-0.5 border-dividerColor text-dividerColor"
      />
      <div class="px-3 md:px-12 lg:px-8 xl:px-10">
        <div class="flex justify-around">
          <form class="flex items-center gap-4 justify-around mb-3" action="">
            <div>
              <label
                class="text-dark-800 font-normal text-xs mr-2"
                for="employer"
                >Charge To Employee</label
              >
              <input
                type="radio"
                name="employer"
                id="employer"
                v-model="args.chargeExpenseToEmployee"
                :value="false"
              />
            </div>
            <div class="h-4 border bg-none border-gray w-0"></div>
            <div>
              <label
                class="text-dark-800 font-normal text-xs mx-2"
                for="employee"
                >Charge To Employer</label
              >
              <input
                type="radio"
                name="employee"
                id="employee"
                v-model="args.chargeExpenseToEmployee"
                :value="true"
              />
            </div>
          </form>
        </div>
        <button
          v-if="type === 'add'"
          class="focus:outline-none text-sm mt-2 py-3 w-full px-4 md:mx-auto justify-center bg-primary rounded-full text-white"
        >
          Add Business Expense
        </button>

        <div
          v-if="type === 'edit' || edit === true"
          class="px-4 md:px-0 w-full my-6 flex md:flex-row flex-col md:justify-center md:justify-items-center md:place-items-center gap-5 md:items-center"
        >
          <easiButton
            @click="$router.go(-1)"
            type="button"
            color="primary"
            variant="outlined"
            class="rounded-full bg-white w-full md:w-52"
            :block="true"
          >
            <span class="text-sm text-dark-800"> Cancel</span>
          </easiButton>
          <easiButton
            :loading="loading"
            :block="true"
            color="primary"
            class="rounded-full w-full md:w-52"
          >
            <span class="text-sm"> Update </span>
          </easiButton>
        </div>

        <p
          @click="deleteWarning = true"
          v-show="type === 'edit' || edit === true"
          class="cursor-pointer text-center text-sm font-medium text-dark-800 my-4"
        >
          Delete Business Expense
        </p>
      </div>
    </form>

    <easiLoading v-if="loadingProgress" @close="loadingProgress = false">
      <template v-slot:heading>
        <h1>Calculating Business Expense</h1>
      </template>
    </easiLoading>

    <easiModal
      v-if="preview"
      @close="preview = false"
      :isHeader="false"
      :isBorder="false"
      :lite="false"
    >
      <h1 class="uppercase text-dark-800 text-2xl font-medium p-6">
        preview Business Expense
      </h1>
      <hr class="mx-16 mb-5 border-0.5 border-dividerColor text-dividerColor" />

      <div v-if="tableData.length" class="w-full mag">
        <easiTable
          :lite="false"
          :data="tableData"
          :header="configureHeaders"
          :mapData="bankCodes"
          :highlight="['firstName', 'lastName']"
          :readonly="true"
        ></easiTable>
      </div>

      <div class="px-3 md:px-12 lg:px-8 xl:px-10">
        <button
          @click="addBusinessExpense('add')"
          class="focus:outline-none text-sm mt-2 py-3 w-full px-4 md:mx-auto justify-center bg-primary rounded-full text-white"
        >
          Add Business Expense
        </button>
      </div>
    </easiModal>

    <easiWarn v-if="deleteWarning" @close="deleteWarning = false">
      <template v-slot:message>
        <span>Are you sure you want to delete Life Insurance?</span>
      </template>
      <template v-slot:action>
        <div class="flex flex-col items-center gap-4">
          <div class="flex justify-center items-center w-full gap-x-4 mt-6">
            <easiButton
              class="w-40 min-w-full"
              @click="deleteWarning = false"
              variant="outlined"
              >Cancel</easiButton
            >
            <easiButton
              class="w-40 min-w-full"
              :loading="loading"
              @click="deleteDeduction('deleteGeneral')"
              >Yes</easiButton
            >
          </div>
        </div>
      </template>
    </easiWarn>

    <easiRoundLoader v-if="loading" />

    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span>{{ successMessage }}</span>
      </template>
    </easiSuccess>
  </main>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
import { ref, reactive, nextTick, watch, computed } from "vue";
import { useToast } from "vue-toastification";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";
import IndustryList from "@/utils/Industry";
import HmoProviders from "@/utils/HmoProviders";

import UploadComponent from "@/components/global/UploadComponent";
import NigerianStates from "@/utils/states";
import lgData from "@/utils/localGovt";
import Countries from "@/utils/countries";

import payeIcon from "@/assets/icons/paye-icon.svg";
import hmoIcon from "@/assets/icons/hmo-icon.svg";
import itfIcon from "@/assets/icons/itf-icon.svg";
import nsitfIcon from "@/assets/icons/nsitf-icon.svg";
import pensionIcon from "@/assets/icons/pension-icon.svg";
import nhfIcon from "@/assets/icons/nhf-icon.svg";

const store = useDataStore();
const { query, mutate } = store;
const { listEmployees, getAllEmployees, getCompliancePreview } =
  storeToRefs(store);
const toast = useToast();
const { resetObj, processDate, formatAmount } = helperFunctions;
const props = defineProps({
  type: {
    type: String,
    default: "add",
  },
  data: {
    type: Object,
    default: {},
  },
});

const route = useRoute();
const router = useRouter();
const emit = defineEmits(["registered"]);
const successMessage = ref("");
const updateSuccess = ref("");
const loading = ref(false);
const edit = ref(false);
const employer = ref(true);
const industryData = IndustryList.map((el) => el.name);
const uploadFile = ref(null);
const localGovtData = ref([]);
const selectedState = computed(() => args.value.state);
const agreement = ref(true);
const useTaxOffice = ref(true);
const isExpatriate = ref(false);
const hasTaxPayerId = ref(false);
const loadingProgress = ref(false);
const preview = ref(false);
const deleteWarning = ref(false);
const tableData = ref([]);
const removedEmployees = ref([]);
const args = ref({
  employeeIds: [],
  chargeHmoToEmployee: false,
  industry: "",
  title: "",
  gender: "",
  maritalStatus: "",
  state: "",
  lga: "",
  country: "",
  taxOffice: "",
  usePercent: true,
  businessExpense: {
    amount: 0,
    expenseName: "",
    chargeExpenseToEmployee: false,
  },
});
const allEmployees = computed(() =>
  getAllEmployees.value ? getAllEmployees.value.data : []
);
const payload = reactive({
  paging: {
    limit: 1000,
    skip: 0,
    search: "",
    sort: { firstName: "ASC" },
  },
  filter: [],
});

async function queryEmployees() {
  await query({
    endpoint: "ListEmployees",
    payload: payload,
    service: "EMP",
    storeKey: "listEmployees",
  });
}
queryEmployees();

const configureHeaders = computed(() => {
  let headArr = [];
  const head = Object.keys(tableData.value[0]);
  // const head = headerKey.value;
  for (let i of head) {
    headArr.push({
      field: i,
      header: i,
    });
  }
  return headArr;
});

const createEmpList = () => {
  const list = allEmployees.value.map((emp) => {
    return {
      label: `${emp.profile.firstName} ${emp.profile.lastName}`,
      value: emp._id,
    };
  });
  return list;
};
const countryData = computed(() => {
  let countryArray = [];
  countryArray = Countries.map((country) => {
    return country.name;
  });
  return countryArray;
});
watch(
  selectedState,
  (value, newValue) => {
    console.log(value, "New Value");
    const lgs = lgData.find(
      (lg) => lg.state.toLowerCase() === value.toLowerCase()
    );
    localGovtData.value =
      lgs &&
      lgs.lgas.map((lg) => {
        return {
          label: lg,
          value: lg,
        };
      });
  },
  { deep: true }
);
const statesData = computed(() => {
  let stateArray = [];
  stateArray = NigerianStates.map((state) => {
    return {
      label: state,
      value: state,
    };
  });
  return stateArray;
});
const today = ref("");
today.value = processDate();

function closeFN() {
  emit("registered");
  router.push({ name: "ViewCompliance" });
}
function getStaffLevelIdFromEmployeeId(id) {
  if (allEmployees.value.length) {
    const data = allEmployees.value.find((emp) => emp._id === id);
    return data.staffLevel._id;
  }
}
async function deleteDeduction(operation = "deleteSingle") {
  let payload = {
    staffLevelIds: [],
    deduction: ["Business_Expense"],
  };
  if (operation === "deleteGeneral") {
    loading.value = true;
    payload.staffLevelIds = props.data.employees.map((emp) =>
      getStaffLevelIdFromEmployeeId(emp.employeeId)
    );
  } else {
    payload.staffLevelIds = removedEmployees.value.map((id) =>
      getStaffLevelIdFromEmployeeId(id)
    );
  }

  try {
    let res = await mutate({
      endpoint: "DeleteDeductionsMultiple",
      service: "PAYROLL",
      data: {
        input: payload,
      },
    });
    if (res.success) {
      if (operation === "deleteGeneral") {
        loading.value = false;
        deleteWarning.value = false;
        successMessage.value = "Business Expense deleted successfully";
        updateSuccess.value = true;
        closeFN();
      }
      return true;
    }
    return false;
  } catch (e) {
    console.log(e);

    return false;
  }
}

async function addBusinessExpense(operation) {
  let payload = {
    businessExpense: {},
    benefits: ["Business_Expense"],
    // employeeIds: [],
  };

  payload["businessExpense"]["amount"] = Number(
    args.value.businessExpense.amount
  );
  payload["businessExpense"]["expenseName"] =
    args.value.businessExpense.expenseName;
  payload["businessExpense"]["chargeTo"] = args.value.businessExpense
    .chargeExpenseToEmployee
    ? "EMPLOYEE"
    : "EMPLOYER";
  // payload["employeeIds"] = args.value.employeeIds;

  if (operation === "preview") {
    loadingProgress.value = true;
    await query({
      endpoint: "PreviewCompliance",
      payload: { input: payload, employeeIds: args.value.employeeIds },
      service: "PAYROLL",
      storeKey: "previewCompliance",
    });
    if (getCompliancePreview.value.success) {
      const data = getCompliancePreview.value.data.success.map((emp, index) => {
        return {
          sn: index + 1,
          firstName: emp.firstName,
          lastName: emp.lastName,
          grossPay: formatAmount(emp.staffLevel.grossAmount),
          businessExpense:
            emp.staffLevel.businessExpense &&
            emp.staffLevel.businessExpense.amount
              ? formatAmount(emp.staffLevel.businessExpense.amount)
              : formatAmount(0),
        };
      });

      tableData.value = data;
      loadingProgress.value = false;
      preview.value = true;
      // closeFN();
    } else {
      toast.error(getCompliancePreview.value.message);
      loadingProgress.value = false;
    }
    return true;
  } else {
    if (removedEmployees.value.length) {
      const deleted = await deleteDeduction();
      if (deleted === false) {
        return false;
      }
      console.log(deleted, "BOOL");
    }
  }

  // return true;

  try {
    loading.value = true;
    preview.value = false;
    let res = await mutate({
      endpoint: "AddCompliance",
      data: { input: payload, employeeIds: args.value.employeeIds },
      service: "PAYROLL",
    });
    if (res.success) {
      console.log(res);
      successMessage.value = res.message;
      // removeFiles.value = true;
      // uploadFile.value = null;
      // uploadProofOfAddress.value = null;
      loading.value = false;
      // await queryEmployees();
      updateSuccess.value = true;

      setTimeout(() => {
        updateSuccess.value = false;
        closeFN();
      }, 1000);
    } else {
      loading.value = false;
      toast.error(res.message);
    }
    console.log(res);
  } catch (e) {
    console.log(e);
    loading.value = false;
  }
}
if (props.data && Object.keys(props.data).length) {
  args.value.employeeIds = props.data.employees.map((emp) => emp.employeeId);
  args.value.businessExpense.expenseName =
    props.data.employees.length &&
    props.data.employees[0].businessExpense.expenseName;

  args.value.businessExpense.amount = props.data.employees.length
    ? props.data.employees[0].businessExpense.amount
    : 0;

  console.log(args.value.employeeIds);
}
function hitEndpoint() {
  window.location.reload();
  //   loadingProgress.value = true;
  //   const empArr = allEmployees.value.filter((emp) =>
  //     args.value.employeeIds.includes(emp._id)
  //   );

  //   const data = empArr.map((emp, index) => {
  //     return {
  //       sn: index + 1,
  //       firstName: emp.profile.firstName,
  //       lastName: emp.profile.lastName,
  //       grossPay: formatAmount(emp.staffLevel.grossAmount),
  //       nsitf: emp.staffLevel.nsitf
  //         ? formatAmount(emp.staffLevel.nsitf.amount)
  //         : formatAmount(0),
  //     };
  //   });

  //   tableData.value = data;
  //   loadingProgress.value = false;
  //   preview.value = true;
}

function updateLg(event) {
  console.log(event);
  args.value.state = event;
}
</script>

<style scoped>
.wallet {
  background: linear-gradient(89.6deg, #0a244f 0.35%, #c92f02 268.44%);
}
.mag {
  /* width: 100%; */
  zoom: 70%;
}
</style>
