<template>
  <main>
    <div v-if="type === 'add'" class="flex flex-col gap-4 items-center mt-6">
      <h2 class="font-bold text-sm text-dark-800">
        Please Select Employee To Add ITF
      </h2>
    </div>
    <hr
      v-if="type === 'add'"
      class="mt-6 mb-5 border-0.5 border-dividerColor text-dividerColor"
    />

    <form
      @submit.prevent="type === 'add' ? addItf('preview') : addItf('add')"
      autocomplete="on"
      class="flex flex-col text-left"
    >
      <div class="px-3 md:px-12 lg:px-8 xl:px-10">
        <label for="employee" class="text-dark-800 text-sm font-medium"
          >Please Select Employee
          <span class="text-xs text-error tracking-wide"> *</span></label
        >
        <easiEmployeeBonus
          @update="employeeIds = $event"
          :value="employeeIds"
          :options="createEmpList()"
          :compliance="true"
          @remove="removedEmployees = $event"
          class="mt-1 mb-5"
        />
      </div>

      <div class="px-3 md:px-12 lg:px-8 xl:px-10">
        <!-- <label for="amount" class="text-dark-800 text-sm font-medium"
          >Amount <span class="text-xs text-error tracking-wide"> </span
        ></label> -->
        <easiTextInput
          v-model="args.itf.amount"
          placeholder="Amount"
          type="text"
          name="amount"
          :format="true"
          class="mt-1 mb-8"
          required
        ></easiTextInput>
      </div>

      <hr
        v-if="type === 'add'"
        class="my-5 border-0.5 border-dividerColor text-dividerColor"
      />
      <div v-if="type === 'add'" class="px-3 md:px-12 lg:px-8 xl:px-10">
        <button
          class="focus:outline-none text-sm mt-2 py-3 w-full px-4 md:mx-auto justify-center bg-primary rounded-full text-white"
        >
          Submit
        </button>
      </div>

      <div
        v-if="type === 'edit' || edit === true"
        class="px-4 md:px-0 w-full my-6 flex md:flex-row flex-col md:justify-center md:justify-items-center md:place-items-center gap-5 md:items-center"
      >
        <easiButton
          @click="$router.go(-1)"
          type="button"
          color="primary"
          variant="outlined"
          class="rounded-full bg-white w-full md:w-52"
          :block="true"
        >
          <span class="text-sm text-dark-800"> Cancel</span>
        </easiButton>
        <easiButton
          :loading="loading"
          :block="true"
          color="primary"
          class="rounded-full w-full md:w-52"
        >
          <span class="text-sm"> Update </span>
        </easiButton>
      </div>

      <p
        @click="deleteWarning = true"
        v-show="type === 'edit' || edit === true"
        class="cursor-pointer text-center text-sm font-medium text-dark-800 my-4"
      >
        Delete ITF
      </p>
    </form>

    <easiLoading v-if="loadingProgress" @close="loadingProgress = false">
      <template v-slot:heading>
        <h1>Calculating ITF</h1>
      </template>
    </easiLoading>

    <easiModal
      v-if="preview"
      @close="preview = false"
      :isHeader="false"
      :isBorder="false"
      :lite="false"
    >
      <h1 class="uppercase text-dark-800 text-2xl font-medium p-6">
        preview ITF
      </h1>
      <hr class="mx-16 mb-5 border-0.5 border-dividerColor text-dividerColor" />

      <div v-if="tableData.length" class="w-full mag">
        <easiTable
          :lite="false"
          :data="tableData"
          :header="configureHeaders"
          :mapData="bankCodes"
          :highlight="['firstName', 'lastName']"
          :readonly="true"
        ></easiTable>
      </div>

      <div class="px-3 md:px-12 lg:px-8 xl:px-10">
        <button
          @click="addItf('add')"
          class="focus:outline-none text-sm mt-2 py-3 w-full px-4 md:mx-auto justify-center bg-primary rounded-full text-white"
        >
          Add ITF
        </button>
      </div>
    </easiModal>

    <easiWarn v-if="deleteWarning" @close="deleteWarning = false">
      <template v-slot:message>
        <span>Are you sure you want to delete ITF?</span>
      </template>
      <template v-slot:action>
        <div class="flex flex-col items-center gap-4">
          <div class="flex justify-center items-center w-full gap-x-4 mt-6">
            <easiButton
              class="w-40 min-w-full"
              @click="deleteWarning = false"
              variant="outlined"
              >Cancel</easiButton
            >
            <easiButton
              class="w-40 min-w-full"
              :loading="loading"
              @click="deleteDeduction('deleteGeneral')"
              >Yes</easiButton
            >
          </div>
        </div>
      </template>
    </easiWarn>

    <easiRoundLoader v-if="loading" />

    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span>{{ successMessage }}</span>
      </template>
    </easiSuccess>
  </main>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
import { ref, reactive, nextTick, watch, computed } from "vue";
import { useToast } from "vue-toastification";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";
import IndustryList from "@/utils/Industry";
import HmoProviders from "@/utils/HmoProviders";

import UploadComponent from "@/components/global/UploadComponent";
import NigerianStates from "@/utils/states";
import lgData from "@/utils/localGovt";
import Countries from "@/utils/countries";

import payeIcon from "@/assets/icons/paye-icon.svg";
import hmoIcon from "@/assets/icons/hmo-icon.svg";
import itfIcon from "@/assets/icons/itf-icon.svg";
import nsitfIcon from "@/assets/icons/nsitf-icon.svg";
import pensionIcon from "@/assets/icons/pension-icon.svg";
import nhfIcon from "@/assets/icons/nhf-icon.svg";

const store = useDataStore();
const { query, mutate } = store;
const { listEmployees, getAllEmployees, getCompliancePreview } =
  storeToRefs(store);
const toast = useToast();
const { resetObj, processDate, formatAmount } = helperFunctions;
const props = defineProps({
  type: {
    type: String,
    default: "add",
  },

  data: {
    type: Object,
    default: {},
  },
});
const payload = reactive({
  paging: {
    limit: 1000,
    skip: 0,
    search: "",
    sort: { firstName: "ASC" },
  },
  filter: [],
});

function queryEmployees() {
  query({
    endpoint: "ListEmployees",
    payload: payload,
    service: "EMP",
    storeKey: "listEmployees",
  });
}
queryEmployees();
const emit = defineEmits(["registered"]);
const successMessage = ref("");
const updateSuccess = ref("");
const loading = ref(false);
const route = useRoute();
const router = useRouter();
const deleteWarning = ref(false);
const employer = ref(true);
const industryData = IndustryList.map((el) => el.name);
const uploadFile = ref(null);
const localGovtData = ref([]);
const selectedState = computed(() => args.value.state);
const agreement = ref(true);
const useTaxOffice = ref(true);
const isExpatriate = ref(false);
const hasTaxPayerId = ref(false);
const loadingProgress = ref(false);
const preview = ref(false);
const tableData = ref([]);
const employeeIds = ref([]);
const removedEmployees = ref([]);

const args = ref({
  itf: {
    // provider: "",
    amount: 0,
    // duration: "MONTHLY",
    // plan: "BRONZE",
  },
});
const allEmployees = computed(() =>
  getAllEmployees.value ? getAllEmployees.value.data : []
);

const configureHeaders = computed(() => {
  let headArr = [];
  const head = Object.keys(tableData.value[0]);
  // const head = headerKey.value;
  for (let i of head) {
    headArr.push({
      field: i,
      header: i,
    });
  }
  return headArr;
});

const createEmpList = () => {
  const list = allEmployees.value.map((emp) => {
    return {
      label: `${emp.profile.firstName} ${emp.profile.lastName}`,
      value: emp._id,
    };
  });
  return list;
};
// const countryData = computed(() => {
//   let countryArray = [];
//   countryArray = Countries.map((country) => {
//     return country.name;
//   });
//   return countryArray;
// });
// watch(
//   selectedState,
//   (value, newValue) => {
//     console.log(value, "New Value");
//     const lgs = lgData.find(
//       (lg) => lg.state.toLowerCase() === value.toLowerCase()
//     );
//     localGovtData.value = lgs.lgas.map((lg) => {
//       return {
//         label: lg,
//         value: lg,
//       };
//     });
//   },
//   { deep: true }
// );
// const statesData = computed(() => {
//   let stateArray = [];
//   stateArray = NigerianStates.map((state) => {
//     return {
//       label: state,
//       value: state,
//     };
//   });
//   return stateArray;
// });

const today = ref("");
today.value = processDate();
function closeFN() {
  emit("registered");
  router.push({ name: "ViewCompliance" });
}
function getStaffLevelIdFromEmployeeId(id) {
  if (allEmployees.value.length) {
    const data = allEmployees.value.find((emp) => emp._id === id);
    return data.staffLevel._id;
  }
}
async function deleteDeduction(operation = "deleteSingle") {
  let payload = {
    staffLevelIds: [],
    deduction: ["ITF"],
  };
  if (operation === "deleteGeneral") {
    loading.value = true;
    payload.staffLevelIds = props.data.employees.map((emp) =>
      getStaffLevelIdFromEmployeeId(emp.employeeId)
    );
  } else {
    payload.staffLevelIds = removedEmployees.value.map((id) =>
      getStaffLevelIdFromEmployeeId(id)
    );
  }

  try {
    let res = await mutate({
      endpoint: "DeleteDeductionsMultiple",
      service: "PAYROLL",
      data: {
        input: payload,
      },
    });
    if (res.success) {
      if (operation === "deleteGeneral") {
        loading.value = false;
        deleteWarning.value = false;
        successMessage.value = "ITF deleted successfully";
        updateSuccess.value = true;
        closeFN();
      }
      return true;
    }
    return false;
  } catch (e) {
    loading.value = false;
    deleteWarning.value = false;
    console.log(e);

    return false;
  }
}
async function addItf(operation) {
  args.value.itf.amount = Number(args.value.itf.amount);

  // args.proofOfAddress = await uploadFileToServer(uploadProofOfAddress.value);
  if (operation === "preview") {
    let payload = Object.assign({}, args.value);
    payload["benefits"] = ["ITF"];

    loadingProgress.value = true;
    await query({
      endpoint: "PreviewCompliance",
      payload: { input: payload, employeeIds: employeeIds.value },
      service: "PAYROLL",
      storeKey: "previewCompliance",
    });
    if (getCompliancePreview.value.success) {
      const data = getCompliancePreview.value.data.success.map((emp, index) => {
        return {
          sn: index + 1,
          firstName: emp.firstName,
          lastName: emp.lastName,
          grossPay: formatAmount(emp.staffLevel.grossAmount),
          itf:
            emp.staffLevel.itf && emp.staffLevel.itf.amount
              ? formatAmount(emp.staffLevel.itf.amount)
              : formatAmount(0),
        };
      });

      tableData.value = data;
      loadingProgress.value = false;
      preview.value = true;
      // closeFN();
    } else {
      toast.error(getCompliancePreview.value.message);
      loadingProgress.value = false;
    }
    return true;
  } else {
    if (removedEmployees.value.length) {
      const deleted = await deleteDeduction();
      if (deleted === false) {
        return false;
      }
    }
  }

  try {
    args.value.itf.amount = Number(args.value.itf.amount);
    loading.value = true;
    preview.value = false;
    let res = await mutate({
      endpoint: "AddItfCompliance",
      data: { input: args.value, employeeIds: employeeIds.value },
      service: "PAYROLL",
    });
    if (res.success) {
      console.log(res);
      successMessage.value = res.message;
      // removeFiles.value = true;
      // uploadFile.value = null;
      // uploadProofOfAddress.value = null;
      loading.value = false;
      // await queryEmployees();
      updateSuccess.value = true;

      setTimeout(() => {
        updateSuccess.value = false;
        closeFN();
      }, 1000);
    } else {
      loading.value = false;
      toast.error(res.message);
    }
    console.log(res);
  } catch (e) {
    console.log(e);
    loading.value = false;
  }
}

if (props.data && Object.keys(props.data).length) {
  employeeIds.value = props.data.employees.map((emp) => emp.employeeId);

  console.log(employeeIds.value);
}
function hitEndpoint() {
  loadingProgress.value = true;
  const empArr = allEmployees.value.filter((emp) =>
    args.value.employeeIds.includes(emp._id)
  );

  const data = empArr.map((emp, index) => {
    return {
      sn: index + 1,
      firstName: emp.profile.firstName,
      lastName: emp.profile.lastName,
      grossPay: formatAmount(emp.staffLevel.grossAmount),
      itf: emp.staffLevel.itf
        ? formatAmount(emp.staffLevel.itf.amount)
        : formatAmount(0),
    };
  });

  tableData.value = data;
  loadingProgress.value = false;
  preview.value = true;
}

function updateLg(event) {
  console.log(event);
  args.value.state = event;
}
</script>

<style scoped>
.wallet {
  background: linear-gradient(89.6deg, #0a244f 0.35%, #c92f02 268.44%);
}
.mag {
  /* width: 100%; */
  zoom: 70%;
}
</style>
