<template>
  <main
    class="w-full text-left bg-white rounded-2xl shadow-lg grid grid-cols-12 overflow-hidden"
  >
    <div
      :class="showAll ? 'hidden' : 'hidden lg:flex lg:col-span-4'"
      class="average-payroll-bg items-center justify-center p-10 h-full"
    >
      <span class="text-xl md:text-2xl font-bold text-white">
        Monthly Average Payroll (Budget)
      </span>
    </div>

    <div :class="showAll ? 'col-span-12' : 'col-span-12 lg:col-span-8'">
      <div
        class="w-ful flex justify-end items-center text-xs gap-4 md:gap-8 mt-8 mb-2 px-4 md:px-8"
      >
        <span>
          <i style="color: #e0a409" class="fas fa-circle mr-2"></i>
          Budget
        </span>
        <span>
          <i style="color: #19a301" class="fas fa-circle mr-2"></i>
          Spend
        </span>
      </div>
      <div class="p-4 md:py-6 md:px-8">
        <div class="flex-auto">
          <div class="relative h-350-px">
            <canvas id="bar-chart-budget"></canvas>
          </div>
        </div>

        <div class="my-6 flex items-center flex-wrap text-xs">
          <div
            v-for="(item, i) in payrollMonthly"
            :key="i"
            class="flex items-center p-2"
          >
            <div
              class="transform -rotate-90 border-b-2 border-blue-100 text-xs uppercase"
            >
              {{ months[item.month] }}
            </div>
            <div class="flex flex-col">
              <span>B - {{ formatAmount(item.budget) }}</span>
              <span>S - {{ formatAmount(item.spend) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import { ref, onMounted, watch, computed, nextTick } from "vue";
import { helperFunctions } from "@/composable/helperFunctions";

const { formatAmount } = helperFunctions;

const props = defineProps({
  showAll: {
    type: Boolean,
    default: false,
  },
});

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const payrollMonthly = computed(() => {
  return [
    {
      month: 0,
      spend: 845987.34,
      budget: 18034987.34,
    },
    {
      month: 1,
      spend: 10450000.34,
      budget: 23456987.34,
    },
    {
      month: 2,
      spend: 12456987.34,
      budget: 20456987.34,
    },
    {
      month: 3,
      spend: 23456987.34,
      budget: 13456987.34,
    },
    {
      month: 4,
      spend: 13456987.34,
      budget: 13456987.34,
    },
    {
      month: 5,
      spend: 28456987.34,
      budget: 26456987.34,
    },
    {
      month: 6,
      spend: 13456987.34,
      budget: 13456987.34,
    },
    {
      month: 7,
      spend: 19999997.34,
      budget: 20900087.34,
    },
    {
      month: 8,
      spend: 1926987.34,
      budget: 13456987.34,
    },
    {
      month: 9,
      spend: 13455357.34,
      budget: 1666807.34,
    },
    {
      month: 10,
      spend: 11424257.34,
      budget: 13456987.34,
    },
    {
      month: 11,
      spend: 10000000.34,
      budget: 29456987.34,
    },
  ];
});
onMounted(() => {
  runChart();
});

const config = computed(() => {
  return {
    type: "bar",
    data: {
      labels: months,
      datasets: [
        {
          label: "Budget",
          backgroundColor: "#E0A409",
          data: payrollMonthly.value.map((el) => {
            return parseFloat(el.spend / 1000000);
          }),
          fill: false,
        },
        {
          label: "Spend",
          fill: false,
          backgroundColor: "#19A301",

          data: payrollMonthly.value.map((el) => {
            return parseFloat(el.budget / 1000000);
          }),
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      responsive: true,
      title: {
        display: false,
        text: "Orders Chart",
      },
      tooltips: {
        mode: "index",
        intersect: false,
      },
      hover: {
        mode: "nearest",
        intersect: true,
      },
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            display: true,

            gridLines: {
              color: "#E0A409",
              zeroLineColor: "#E0A409",
            },
          },
        ],
        yAxes: [
          {
            display: true,
            gridLines: {
              color: "#E0A409",
              zeroLineColor: "#E0A409",
            },
            ticks: {
              // Include a dollar sign in the ticks
              callback: function (value, index, ticks) {
                return `${value}M`;
              },
            },
          },
        ],
      },
    },
  };
});

const runChart = () => {
  nextTick(function () {
    var ctx = document.getElementById("bar-chart-budget").getContext("2d");
    // ctx.fillText(data[0].value + "%", width/2 - 20, width/2, 200);
    window.myDoughnut = new Chart(ctx, config.value);
  });
};
</script>

<style>
.average-payroll-bg {
  background: linear-gradient(
      0deg,
      rgba(25, 163, 1, 0.78),
      rgba(25, 163, 1, 0.78)
    ),
    url("../../../assets/img/payrollbg.png");
  background-position: center left;
  background-size: cover;
}
</style>
