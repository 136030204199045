<template>
  <div>
    <div class="">
      <div class="ml-2 h-full">
        <div class="w-full flex items-center justify-between mb-3">
          <div class="flex items-center gap-2">
            <input
              type="checkbox"
              :checked="allChecked"
              class="h-4 w-4"
              @change="toggleCheckAll()"
            />

            <span class="text-dark-600">Select All</span>
          </div>

          <div>
            <span
              >{{ currentPage == 0 ? currentPage + 1 : pageSize + currentPage }}
              -
              {{
                currentPage == 0
                  ? pageSize > data.length
                    ? data.length
                    : pageSize
                  : pageSize + visibleData.length
              }}
              of {{ data.length }}</span
            >
          </div>
        </div>
        <table
          v-for="emp in visibleData"
          :key="emp.employeeId"
          class="table-auto w-full my-8 overflow-y-auto"
        >
          <tbody class="">
            <tr>
              <td
                :class="[
                  transposed[0].id === emp.employeeId &&
                  getKey(emp, emp.firstName) === transposed[0].field
                    ? 'bg-primary text-white'
                    : '',
                  holdData.includes(emp) &&
                  getKey(emp, emp.firstName) === transposed[0].field
                    ? 'bg-primary text-white'
                    : '',
                ]"
                class="flex gap-3 px-2 py-3 bg-indigo-100 text-left font-bold capitalize text-sm text-header border-2 border-t-1 text-center"
              >
                <div class="text-center">
                  <input
                    @change="onRowSelect(emp)"
                    :checked="holdData.includes(emp)"
                    type="checkbox"
                    name=""
                    id=""
                  />
                </div>
                <span>First Name</span>
              </td>

              <td
                @click="setActive(emp.employeeId, 'firstName')"
                class="border-2 border-indigo-200 text-left text-sm font-medium text=dark-800 relative group-hover:bg-indigo-100 group-hover:border-indigo-300 w-2/3 bg-background-200"
              >
                <div class="inline-block items-center gap-2 mx-2">
                  <div
                    v-if="emp.picture"
                    class="border w-9 h-9 rounded-full overflow-hidden"
                  >
                    <img
                      class="-full"
                      :src="emp.picture"
                      :alt="emp.firstName + ' ' + emp.lastName"
                    />
                  </div>
                  <div
                    v-else
                    :style="`background-color:${getAvatar(emp).color}`"
                    class="p-2 h-9 w-9 text-sm text-white font-medium rounded-full"
                  >
                    {{ getAvatar(emp).name }}
                  </div>
                </div>
                <span
                  v-show="
                    transposed[0].id !== emp.employeeId ||
                    getKey(emp, emp.firstName) !== transposed[0].field
                  "
                >
                  {{ emp.firstName }}</span
                >
                <easiTextInput
                  type="text"
                  :class="
                    transposed[0].id === emp.employeeId &&
                    getKey(emp, emp.firstName) === transposed[0].field
                      ? 'z-20 absolute top-0 left-0'
                      : undefined
                  "
                  v-show="
                    transposed[0].id === emp.employeeId &&
                    getKey(emp, emp.firstName) === transposed[0].field
                  "
                  v-model="emp.firstName"
                  @input="checkValue($event, emp, 'firstName')"
                ></easiTextInput>
              </td>
            </tr>

            <tr>
              <td
                :class="[
                  transposed[0].id === emp.employeeId &&
                  getKey(emp, emp.lastName) === transposed[0].field
                    ? 'bg-primary text-white'
                    : '',
                  holdData.includes(emp) &&
                  getKey(emp, emp.lastName) === transposed[0].field
                    ? 'bg-primary text-white'
                    : '',
                ]"
                class="flex gap-3 px-2 py-3 bg-indigo-100 text-left font-bold capitalize text-sm text-header border-2 border-t-0 text-center"
              >
                <span>Last Name</span>
              </td>

              <td
                @click="setActive(emp.employeeId, 'lastName')"
                class="border-2 border-indigo-200 text-left text-sm p-2 font-medium text=dark-800 relative group-hover:bg-indigo-100 group-hover:border-indigo-300 w-52 bg-background-200"
              >
                <span
                  v-show="
                    transposed[0].id !== emp.employeeId ||
                    getKey(emp, emp.lastName) !== transposed[0].field
                  "
                >
                  {{ emp.lastName }}</span
                >
                <easiTextInput
                  type="text"
                  :class="
                    transposed[0].id === emp.employeeId &&
                    getKey(emp, emp.lastName) === transposed[0].field
                      ? 'z-20 absolute top-0 left-0'
                      : undefined
                  "
                  v-show="
                    transposed[0].id === emp.employeeId &&
                    getKey(emp, emp.lastName) === transposed[0].field
                  "
                  v-model="emp.lastName"
                  @input="checkValue($event, emp, 'lastName')"
                ></easiTextInput>
              </td>
            </tr>

            <tr>
              <td
                :class="[
                  transposed[0].id === emp.employeeId &&
                  getKey(emp, emp.jobTitle) === transposed[0].field
                    ? 'bg-primary text-white'
                    : '',
                  holdData.includes(emp) &&
                  getKey(emp, emp.jobTitle) === transposed[0].field
                    ? 'bg-primary text-white'
                    : '',
                ]"
                class="flex gap-3 px-2 py-3 bg-indigo-100 text-left font-bold capitalize text-sm text-header border-2 border-t-0 text-center"
              >
                <span>Job Title</span>
              </td>

              <td
                @click="setActive(emp.employeeId, 'jobTitle')"
                class="border-2 border-indigo-200 text-left text-sm p-2 font-medium text=dark-800 relative group-hover:bg-indigo-100 group-hover:border-indigo-300 w-52 bg-background"
              >
                <span
                  v-show="
                    transposed[0].id !== emp.employeeId ||
                    getKey(emp, emp.jobTitle) !== transposed[0].field
                  "
                >
                  {{ emp.jobTitle }}</span
                >
                <easiTextInput
                  type="text"
                  :class="
                    transposed[0].id === emp.employeeId &&
                    getKey(emp, emp.jobTitle) === transposed[0].field
                      ? 'z-20 absolute top-0 left-0'
                      : undefined
                  "
                  v-show="
                    transposed[0].id === emp.employeeId &&
                    getKey(emp, emp.jobTitle) === transposed[0].field
                  "
                  v-model="emp.jobTitle"
                  @input="checkValue($event, emp, 'jobTitle')"
                ></easiTextInput>
              </td>
            </tr>
            <tr>
              <td
                :class="[
                  transposed[0].id === emp.employeeId &&
                  getKey(emp, emp.grossPay) === transposed[0].field
                    ? 'bg-primary text-white'
                    : '',
                  holdData.includes(emp) &&
                  getKey(emp, emp.grossPay) === transposed[0].field
                    ? 'bg-primary text-white'
                    : '',
                ]"
                class="flex gap-3 px-2 py-3 bg-indigo-100 text-left font-bold capitalize text-sm text-header border-2 border-t-0 text-center"
              >
                <span>Gross Pay</span>
              </td>

              <td
                @click="setActive(emp.employeeId, 'grossPay')"
                class="border-2 border-indigo-200 text-left text-sm p-2 font-medium text=dark-800 relative group-hover:bg-indigo-100 group-hover:border-indigo-300 w-52 bg-background"
              >
                <span
                  v-show="
                    transposed[0].id !== emp.employeeId ||
                    getKey(emp, emp.grossPay) !== transposed[0].field
                  "
                >
                  {{ formatAmount(emp.grossPay) }}</span
                >
                <easiTextInput
                  type="number"
                  :class="
                    transposed[0].id === emp.employeeId &&
                    getKey(emp, emp.grossPay) === transposed[0].field
                      ? 'z-20 absolute top-0 left-0'
                      : undefined
                  "
                  v-show="
                    transposed[0].id === emp.employeeId &&
                    getKey(emp, emp.grossPay) === transposed[0].field
                  "
                  v-model="emp.grossPay"
                  @input="checkValue($event, emp, 'grossPay')"
                ></easiTextInput>
              </td>
            </tr>
            <tr v-if="showAllEmployee === emp.employeeId">
              <td
                :class="[
                  transposed[0].id === emp.employeeId &&
                  getKey(emp, emp.employmentType) === transposed[0].field
                    ? 'bg-primary text-white'
                    : '',
                  holdData.includes(emp) &&
                  getKey(emp, emp.employmentType) === transposed[0].field
                    ? 'bg-primary text-white'
                    : '',
                ]"
                class="flex gap-3 pl-2 py-3 bg-indigo-100 text-left font-bold capitalize text-sm text-header border-2 border-t-0 text-left"
              >
                <span>Employment Type</span>
              </td>

              <td
                @click="setActive(emp.employeeId, 'employmentType')"
                class="border-2 border-indigo-200 text-left text-sm p-2 font-medium text=dark-800 relative group-hover:bg-indigo-100 group-hover:border-indigo-300 w-52 bg-background"
              >
                <span
                  v-show="
                    transposed[0].id !== emp.employeeId ||
                    getKey(emp, emp.employmentType) !== transposed[0].field
                  "
                >
                  {{ emp.employmentType }}</span
                >
                <easiSelectInput2
                  required
                  class="mt-1 mb-3"
                  @update="updateSelect($event, 'employmentType', emp)"
                  :value="emp.employmentType"
                  :options="employmentTypes"
                  v-show="
                    transposed[0].id === emp.employeeId &&
                    getKey(emp, emp.employmentType) === transposed[0].field
                  "
                  :class="
                    transposed[0].id === emp.employeeId &&
                    getKey(emp, emp.employmentType) === transposed[0].field
                      ? 'z-20 absolute -top-1 left-0 right-0'
                      : undefined
                  "
                />
              </td>
            </tr>

            <tr v-show="showAllEmployee === emp.employeeId">
              <td
                :class="[
                  transposed[0].id === emp.employeeId &&
                  getKey(emp, emp.department) === transposed[0].field
                    ? 'bg-primary text-white'
                    : '',
                  holdData.includes(emp) &&
                  getKey(emp, emp.department) === transposed[0].field
                    ? 'bg-primary text-white'
                    : '',
                ]"
                class="flex gap-3 px-2 py-3 bg-indigo-100 text-left font-bold capitalize text-sm text-header border-2 border-t-0 text-center"
              >
                <span>department</span>
              </td>

              <td
                @click="setActive(emp.employeeId, 'department')"
                class="border-2 capitalize border-indigo-200 text-left text-sm p-2 font-medium text=dark-800 relative group-hover:bg-indigo-100 group-hover:border-indigo-300 w-52 bg-background"
              >
                <span
                  v-show="
                    transposed[0].id !== emp.employeeId ||
                    getKey(emp, emp.department) !== transposed[0].field
                  "
                >
                  {{
                    getDepartmentOrBranchName(emp.department, "department")
                  }}</span
                >
                <easiSelectInput2
                  required
                  class="mt-1 mb-3"
                  @update="updateSelect($event, 'department', emp)"
                  :value="emp.department"
                  :options="mapData[1]"
                  v-show="
                    transposed[0].id === emp.employeeId &&
                    getKey(emp, emp.department) === transposed[0].field
                  "
                  :class="
                    transposed[0].id === emp.employeeId &&
                    getKey(emp, emp.department) === transposed[0].field
                      ? 'z-20 absolute -top-1 left-0 right-0'
                      : undefined
                  "
                />
              </td>
            </tr>
            <tr v-show="showAllEmployee === emp.employeeId">
              <td
                :class="[
                  transposed[0].id === emp.employeeId &&
                  getKey(emp, emp.branch) === transposed[0].field
                    ? 'bg-primary text-white'
                    : '',
                  holdData.includes(emp) &&
                  getKey(emp, emp.branch) === transposed[0].field
                    ? 'bg-primary text-white'
                    : '',
                ]"
                class="flex gap-3 px-2 py-3 bg-indigo-100 text-left font-bold capitalize text-sm text-header border-2 border-t-0 text-center"
              >
                <span>Branch</span>
              </td>

              <td
                @click="setActive(emp.employeeId, 'branch')"
                class="border-2 border-indigo-200 capitalize text-left text-sm p-2 font-medium text=dark-800 relative group-hover:bg-indigo-100 group-hover:border-indigo-300 w-52 bg-background"
              >
                <span
                  v-show="
                    transposed[0].id !== emp.employeeId ||
                    getKey(emp, emp.branch) !== transposed[0].field
                  "
                >
                  {{ getDepartmentOrBranchName(emp.branch, "branch") }}</span
                >
                <easiSelectInput2
                  required
                  class="mt-1 mb-3"
                  @update="updateSelect($event, 'branch', emp)"
                  :value="emp.branch"
                  :options="mapData[2]"
                  v-show="
                    transposed[0].id === emp.employeeId &&
                    getKey(emp, emp.branch) === transposed[0].field
                  "
                  :class="
                    transposed[0].id === emp.employeeId &&
                    getKey(emp, emp.branch) === transposed[0].field
                      ? 'z-20 absolute -top-1 left-0 right-0'
                      : undefined
                  "
                />
              </td>
            </tr>
            <tr>
              <td class="max-w-full">
                <div
                  class="max-w-full bg-dark-200 text-dark-900 border-r-0 border border-dark-600 flex items-start justify-start text-xs py-1 px-1"
                  @click="showAll(emp.employeeId)"
                >
                  <span
                    class="text-left pl-1"
                    v-if="showAllEmployee === emp.employeeId"
                    >Less Information</span
                  >
                  <span class="text-left pl-1" v-else>More Information</span>
                  <i
                    :class="
                      showAllEmployee === emp.employeeId
                        ? 'fas fa-angle-right'
                        : 'fas fa-angle-down'
                    "
                    class="invisible cursor-pointer text-base text-dark-600"
                  />
                </div>
              </td>
              <td>
                <div
                  class="max-w-full bg-dark-200 text-dark-900 border border-l-0 border-dark-600 flex items-center justify-between text-xs py-1 px-3"
                  @click="showAll(emp.employeeId)"
                >
                  <span class="invisible">Less Information</span>
                  <i
                    :class="
                      showAllEmployee !== emp.employeeId
                        ? 'fas fa-angle-right'
                        : 'fas fa-angle-down'
                    "
                    class="cursor-pointer text-base text-dark-600"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div
      @click="reset"
      :class="!transposed[0].id.length ? 'pointer-events-none' : ''"
      class="bg-transparent fixed inset-0"
    ></div>

    <easiPagin
      v-if="data.length"
      :data="data"
      @page:update="updatePage"
      :currentPage="currentPage"
      :pageSize="pageSize"
      class="my-4"
    />
  </div>
</template>

<script lang="js">
import { FilterMatchMode } from "primevue/api";
import { helperFunctions } from "@/composable/helperFunctions";

export default {
  setup() {
    const { formatAmount } = helperFunctions;
    return { formatAmount };
  },
  emits: ["data", "holdData", "mobileData"],
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: [],
    },
    header: {
      type: Array,
      default: [],
    },
    mapData: {
      type: Array,
      default: [],
    },
    frozenFields: {
      type: Array,
      default: [],
    },
    highlight: {
      type: Array,
      default: [],
    },
    useSelect: {
      type: Array,
      default: [],
    },
    mandatory: {
      type: Array,
      default: [],
    },
  },

  data() {
    return {
      visibleData: [],
      currentPage: 0,
      pageSize: 15,
      employmentTypes: [
        {
          label: "Fulltime",
          value: "FULLTIME",
        },
        {
          label: "Contract",
          value: "CONTRACT",
        },
        {
          label: "Part Time",
          value: "PARTTIME",
        },
        {
          label: "Volunteer",
          value: "VOLUNTEER",
        },

        {
          label: "NYSC",
          value: "NYSC",
        },
        {
          label: "Internship",
          value: "INTERNSHIP",
        },

        {
          label: "Apprentice",
          value: "APPRENTICE",
        },
      ],
      showAllEmployee: "",
      showInput: false,
      transposed: [{ id: "", field: "" }],
      selectedEmployees: null,
      editingRows: [],
      columns: null,
      holdData: [],
      active: "",
      allChecked: false,
    };
  },

  computed: {
    style() {
      return " padding-top: 0.75rem; padding-bottom: 0.75rem; background-color: #E0E7FF; line-height: 16px; font-weight: 700; letter-spacing: 0.025em; text-align: left;  text-transform: capitalize; border-width: 1px; border-top-width: 1px; background: #D7DCF6;margin-bottom: 10px;color: #515251; border: 1px solid #E7E8E7; font-size: 12px;";
    },
    activeHeader() {
      return " padding-top: 0.75rem; padding-bottom: 0.75rem; background-color: #E7E8E7; line-height: 16px; font-weight: 700; letter-spacing: 0.025em; text-align: left;  text-transform: capitalize; border-width: 2px; border-top-width: 0; background: #E7E8E7;margin-bottom: 10px;color: #ffffff; font-size: 12px;";
    },
    checkStyle() {
      return " padding-top: 0.75rem; padding-bottom: 0.75rem; background-color: #E0E7FF;  border-width: 2px; border-top-width: 0; background: #E0E0E0;margin-bottom: 10px;border-top-left-radius: 0.5rem;border-bottom-left-radius: 0.5rem; border: 1px solid #0A244F";
    },
    lastHeaderStyle() {
      return " padding-top: 0.75rem; padding-bottom: 0.75rem; background-color: #E0E7FF; line-height: 16px; font-weight: 700; letter-spacing: 0.025em; text-align: left;  text-transform: capitalize; border-width: 2px; border-top-width: 0; background: #D7DCF6;margin-bottom: 10px;border-top-right-radius: 0.5rem;border-bottom-right-radius: 0.5rem;color: #515251; font-size: 14px; ";
    },
    label() {
      return (str) =>
        str
          .split(".")
          .pop()
          .replace(/([A-Z])/g, " $1")
          .toUpperCase();
    },
    freeze() {
      return (field) => {
        if (this.frozenFields.includes(field.field)) {
          return true;
        }
        return false;
      };
    },
    highlightField() {
      return (field) => {
        if (this.highlight.includes(field)) {
          return "background: rgba(240, 247, 235, 1); box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);";
        }
        return "background: #FCFCFC;";
      };
    },
    // mandatoryField() {
    //   return (field) => {
    //     if (this.mandatory.includes(field)) {
    //       return "border: 1px solid #FFF0EC";
    //     }
    //   };
    // },
  },

  methods: {
    getDepartmentOrBranchName(id, field){

      if(this.mapData&&this.mapData.length && id){
        if(field === 'department' && this.mapData[1]&& this.mapData[1].length){
          const arr = this.mapData[1].filter((el)=> el.value === id);

        return arr[0].label
        }

        if(field === 'branch' && this.mapData[2]&& this.mapData[2].length){
          const arr = this.mapData[2].filter((el)=> el.value === id);

        return arr[0].label
        }

      }else { return "N/A"}
  },
    getUniqueListBy(arr, key) {
      return [...new Map(arr.map((item) => [item[key], item])).values()];
    },
    toggleCheckAll() {
      this.allChecked = !this.allChecked;

      if (this.allChecked) {
        this.data.forEach((el) => {
          if (!this.holdData.includes(el)) {
            this.holdData.push(el);
          }
        });
      } else {
        this.holdData = [];
      }

      this.$emit("holdData", this.holdData);
    },
    updatePage(pageNumber) {
      this.currentPage = pageNumber;
      this.updateVisibleData();
    },
    updateVisibleData() {
      let datas = this.data;
      this.visibleData = datas.slice(
        this.currentPage * this.pageSize,
        this.currentPage * this.pageSize + this.pageSize
      );
      // if we have 0 visible todos, go back a page
      if (this.visibleData.length == 0 && this.currentPage > 0) {
        this.updatePage(this.currentPage - 1);
      }
    },
    showAll(arg) {
      if (this.showAllEmployee === arg) {
        this.showAllEmployee = "";
      } else {
        this.showAllEmployee = arg;
      }
    },
    onRowSelect(event) {
      if (this.holdData.includes(event)) {
        let index = this.holdData.indexOf(event);
        if (index > -1) {
          this.holdData.splice(index, 1);
        }
      } else {
        this.holdData.push(event);
      }

      console.log(this.holdData, "EMV");
    },
    onRowUnselect(event) {
      this.holdData = this.holdData.filter((emp) => {
        return emp.employeeId !== event.data.employeeId;
      });
    },
    updateMultipleCells(event, objkey) {
      //   console.log("1", event, "2", obj, "3", objkey);
      this.active = objkey;
      for (let i = 0; i < this.holdData.length; i++) {
        if (event.target) {
          this.holdData[i][objkey] = event.target.value;
          //   obj[objkey] = event.target.value;
        } else {
          this.holdData[i][objkey] = event;
          //   obj[objkey] = event.value;
        }
      }
      this.$emit("mobileData", this.holdData);
      console.log(this.holdData, "WORKS?");
    },
    getKey(data, value) {
      if (value) {
        console.log(value);
        const key = Object.keys(data).find((key) => data[key] === value);
        return key;
      }
    },
    setActive(id, field) {
      this.transposed = [];
      console.log(id, field, "LO");
      this.transposed.push({ id, field });
      console.log(this.transposed, "ARR");
    },
    checkForTrue() {
      const mapped = this.transposed.map((item) => {
        item.index === true ? true : false;
      });
    },
    reset() {
      this.transposed = [{ id: "", field: "" }];
    },

    checkValue(event, obj, objkey) {
      if (event.target.value != undefined && event.target.value != null) {
        this.holdData.push(obj);
        this.holdData = this.getUniqueListBy(this.holdData, "employeeId");
        console.log(this.holdData, "CHECKED");

        this.updateMultipleCells(event, objkey);
      }
    },
    updateSelect(event, key, emp) {
      emp[key] = event;
      console.log(emp[key]);
      this.checkValue(event, emp, key);
    },
    returnOptions(fieldType) {
      if (fieldType === "employmentType") {
        return this.mapData[0];
      } else if (fieldType === "department") {
        return this.mapData[1];
      }
      else if (fieldType === "branch") {
        return this.mapData[2];
      }
    },

    initials(name) {
      const arr = name.split(" ");
      const str = `${arr[0].charAt(0).toUpperCase()}${arr[1]
        .charAt(0)
        .toUpperCase()}`;
      return str;
    },
    getColor(index) {
      let n = (index * 0xfffff * 1000000).toString(16);
      return "#" + n.slice(0, 6);
    },
    getAvatar(emp) {
      let name = "",
        color;
      // Get color
      let alphabet = "abcdefghijklmnopqrstuvwxyz".split("");

      const initialOne = String(emp && emp.firstName&&emp.firstName.charAt(0));
      const initialTwo = String(emp && emp.lastName&&emp.lastName.charAt(0));
      const num =
        Number(alphabet.indexOf(initialOne.toLowerCase())) +
        Number(alphabet.indexOf(initialTwo.toLowerCase()));
      color = this.getColor(num);

      // Get Shortend Name
      emp
        ? (name = `${emp && emp.firstName&&emp.firstName.charAt(0)}${
            emp && emp.lastName&&emp.lastName.charAt(0)
          }`)
        : "";

      return {
        color,
        name,
      };
    },

    bankError(data, field) {
      if (data.bankError) {
        console.log(data.bankError, "RE");

        const bankError = data.bankError;
        if (
          field === "bankName" ||
          field === "bankCode" ||
          field === "accountNumber"
        ) {
          return [
            {
              "border border-error-table w-full h-full text-error-table p-2":
                bankError.length > 0,
            },
          ];
        }
      }
    },
    error(data, field) {
      if (data.reason) {
        console.log(data.reason, "RE");

        const reason = data.reason;

        return [
          {
            "border border-error-table w-full h-full text-error-table p-2":
              reason.includes(field),
          },
        ];
      }
    },
    madatoryClass(data, field) {
      console.log("DA", data);
      return [
        {
          "border border-secondary w-full h-full text-secondary":
            data[field].length === 0,
        },
      ];
    },
    onCellEditComplete(event) {
      let { data, newValue, field } = event;
      console.log(newValue, "NEWS");

      switch (field) {
        case "quantity":
        case "price":
          if (this.isPositiveInteger(newValue)) data[field] = newValue;
          else event.preventDefault();
          break;

        default:
          if (newValue.trim().length > 0) data[field] = newValue;
          else event.preventDefault();
          break;
      }
    },
    isPositiveInteger(val) {
      let str = String(val);
      str = str.trim();
      if (!str) {
        return false;
      }
      str = str.replace(/^0+/, "") || "0";
      var n = Math.floor(Number(str));
      return n !== Infinity && String(n) === str && n >= 0;
    },
    onRowEditSave(event) {
      let { newData, index } = event;

      this.products2[index] = newData;
    },
    getStatusLabel(status) {
      switch (status) {
        case "INSTOCK":
          return "In Stock";

        case "LOWSTOCK":
          return "Low Stock";

        case "OUTOFSTOCK":
          return "Out of Stock";

        default:
          return "NA";
      }
    },
  },
  created() {
    this.updateVisibleData();
    console.log(this.visibleData);
    //   this.visibleData = this.data;
  },
};
</script>

<style scoped></style>
