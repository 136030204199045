<template>
  <main class="flex flex-col w-full gap-8">
    <div class="text-left sm:mt-5">
      <easiBackButton></easiBackButton>
    </div>
    <div class="no-printable flex flex-col gap-6 w-full">
      <div class="flex justify-between">
        <h3 class="font-bold text-2xl text-dark-800 whitespace-nowrap">
          Payroll Analysis
        </h3>

        <!-- <easiButton :loading="loadingProgress" @click="queryPayrollAnalysis"
          >Query</easiButton
        > -->
        <div class="no-printable flex items-center justify-end gap-2 md:gap-7">
          <easiButton
            @click="generate"
            :loading="isDownloading"
            variant="outlined"
            class="rounded-md md:justify-center flex items-center md:gap-2 w-full"
            size="small"
          >
            <span class="hidden md:block"> Download</span>
            <i :class="'pi pi-download'" />
          </easiButton>
          <easiButton
            @click="shareLink = true"
            variant="outlined"
            class="rounded-md md:justify-center flex items-center gap-2 w-full"
            size="small"
          >
            <span class="hidden md:block"> Share</span>

            <i :class="'pi pi-share-alt'" />
          </easiButton>
          <easiButton
            @click="printPage"
            :loading="isPrinting"
            variant="outlined"
            class="rounded-md md:justify-center flex items-center gap-2 w-full"
            size="small"
          >
            <span class="hidden md:block"> Print</span>
            <i :class="'pi pi-print'" />
          </easiButton>
        </div>
      </div>
    </div>

    <vue3-simple-html2pdf
      ref="vue3SimpleHtml2pdf"
      :options="pdfOptions"
      :filename="payslipName"
    >
      <div :class="!showAll ? 'md:mx-auto ' : ''" class="w-full">
        <div
          :class="
            !showAll
              ? 'shadow-md md:shadow-lg  rounded-2xl border border-boxBorder'
              : ''
          "
          class="bg-white w-full"
        >
          <div
            :class="!showAll ? 'flex-col md:flex-row' : 'flex-row'"
            class="flex gap-y-8 justify-between items-start px-14 py-5"
          >
            <div class="flex gap-12">
              <div>
                <img
                  v-if="
                    companyData &&
                    companyData.company &&
                    companyData.company.logo
                  "
                  :src="
                    companyData &&
                    companyData.company &&
                    companyData.company.logo
                  "
                  class="w-28"
                  alt=""
                />
                <img
                  v-else
                  src="@/assets/img/comp-logo.png"
                  class="w-28"
                  alt=""
                />
              </div>

              <div class="flex flex-col gap-y-2">
                <div class="flex items-center gap-x-4">
                  <img src="@/assets/img/newCompany.svg" alt="" />
                  <span class="text-dark-800 font-normal text-sm capitalize">
                    {{
                      companyData &&
                      companyData.company &&
                      companyData.company.companyName
                    }}
                  </span>
                </div>

                <div class="flex items-center gap-x-4">
                  <img src="@/assets/img/newEmail.svg" alt="" />
                  <span class="text-dark-800 font-normal text-sm lowercase">
                    {{
                      companyData &&
                      companyData.companyAdmin &&
                      companyData.companyAdmin.email
                        ? companyData.companyAdmin.email
                        : adminDetails && adminDetails.email
                    }}
                  </span>
                </div>

                <div class="flex items-center gap-x-4">
                  <img src="@/assets/img/newLocation.svg" alt="" />
                  <span
                    class="text-dark-800 font-normal text-sm capitalize break-all"
                  >
                    {{
                      companyData &&
                      companyData.company &&
                      companyData.company.address
                    }}
                  </span>
                </div>
              </div>
            </div>

            <div
              :class="!showAll ? 'w-full md:w-auto' : 'w-64'"
              class="flex flex-col bg-newLimeGreen border border-primary rounded-tr-5xl px-5 py-2 text-sm"
            >
              <div class="flex gap-x-2 items-center md:items-start">
                <span class="text-dark-800">Date Generated:</span>
                <span class="text-dark-800 font-bold"
                  >{{ months[new Date().getMonth()] }}
                  {{ new Date().getDate().toString().padStart(2, "0") }},
                  {{ new Date().getFullYear() }}</span
                >
              </div>
              <hr class="border border-dark-200 mt-1" />
              <div class="flex gap-1 flex-row mt-1">
                <span class="text-dark-800">Wallet:</span>
                <span class="text-dark-800 font-bold">myeazipaywallet</span>
              </div>
              <hr class="border border-dark-200 my-1" />
              <div class="flex items-center md:items-start gap-x-2">
                <span class="text-dark-800 my-auto">Requested By:</span>

                <div class="flex items-center gap-x-2">
                  <div class="flex gap-x-2 items-center">
                    <div
                      :class="
                        !showAll
                          ? 'pt-1 pl-1 pr-1 md:p-2  md:h-9 md:w-9 w-7 h-7'
                          : 'p-2  h-9 w-9 '
                      "
                      class="bg-primary text-xs md:text-sm text-white text-center font-medium rounded-full uppercase"
                    >
                      {{
                        getAvatar({
                          profile: {
                            firstName:
                              companyData &&
                              companyData.companyAdmin &&
                              companyData.companyAdmin.firstName
                                ? companyData.companyAdmin.firstName
                                : adminDetails && adminDetails.firstName,
                            lastName:
                              companyData &&
                              companyData.companyAdmin &&
                              companyData.companyAdmin.lastName
                                ? companyData.companyAdmin.lastName
                                : adminDetails && adminDetails.lastName,
                          },
                        })
                      }}
                    </div>
                  </div>
                  <span class="text-dark-800 font-bold capitalize"
                    >{{
                      companyData &&
                      companyData.companyAdmin &&
                      companyData.companyAdmin.firstName
                        ? companyData.companyAdmin.firstName
                        : adminDetails && adminDetails.firstName
                    }}
                    {{
                      companyData &&
                      companyData.companyAdmin &&
                      companyData.companyAdmin.lastName
                        ? companyData.companyAdmin.lastName
                        : adminDetails && adminDetails.lastName
                    }}</span
                  >
                </div>
              </div>
            </div>
          </div>

          <div
            class="w-full bg-newLimeGreen md:p-3 px-12 py-3 text-sm text-center font-medium text-dark-800"
          >
            {{
              companyData &&
              companyData.companyAdmin &&
              companyData.company.companyName
            }}
            Payroll Analysis From
            {{ $route.query.date && getDate($route.query.date.split("/")[0]) }}
            -
            {{ $route.query.date && getDate($route.query.date.split("/")[1]) }}
          </div>
        </div>

        <div class="w-full grid grid-cols-12 gap-6 py-6">
          <div
            :class="showAll ? 'col-span-4' : 'col-span-12 lg:col-span-5'"
            v-if="payrollAnalysis && payrollAnalysis.byGender.length"
          >
            <!-- <NumberOfEmployees /> -->
          </div>
          <div
            :class="showAll ? 'col-span-8' : 'col-span-12 lg:col-span-7'"
            class=""
            v-if="payrollAnalysis && payrollAnalysis.bySalary"
          >
            <MinMaxSalary />
          </div>
          <div
            :class="showAll ? 'col-span-12' : 'col-span-12 lg:col-span-8'"
            class="flex flex-col gap-6"
          >
            <PayrollByDepartment
              :show-all="showAll"
              v-if="payrollAnalysis && payrollAnalysis.bydepartment.length"
            />
            <div
              class="w-full"
              v-if="
                payrollAnalysis && payrollAnalysis.monthlyPayrollByGender.length
              "
            >
              <!-- <AveragePayrollGender /> -->
            </div>
          </div>
          <div
            :class="showAll ? 'col-span-8' : 'col-span-12 lg:col-span-4'"
            class="flex flex-col gap-6"
          >
            <easiCard
              v-if="payrollAnalysis && payrollAnalysis.totalPayroll"
              title="Total Payroll"
              :amount="
                payrollAnalysis &&
                payrollAnalysis.totalPayroll &&
                payrollAnalysis.totalPayroll.count
              "
            />
            <!-- <div
              class="totalPayrollGradient relative overflow-hidden w-full bg-white border border-white text-white font-bold text-xl text-left px-4 py-6 md:p-6 xl:px-8 rounded-2xl shadow-lg flex flex-col"
              
            >
              <h2 class="z-10">Total Payroll</h2>
              <h2 class="z-10">
                {{
                  formatAmount(
                    payrollAnalysis &&
                      payrollAnalysis.totalPayroll &&
                      payrollAnalysis.totalPayroll.count
                  )
                }}
              </h2>
     

              <div
                :class="
                  showAll
                    ? 'w-20 h-20 -bottom-20 left-24'
                    : 'w-36 h-36 -bottom-24 left-24'
                "
                style="background-color: #3f3a95"
                class="rounded-full absolute"
              ></div>
              <div
                :class="
                  showAll
                    ? 'w-36 h-36 -bottom-24 -right-10'
                    : 'w-72 h-72 -bottom-36 -right-10'
                "
                style="background-color: #68e77a"
                class="rounded-full absolute -z-0"
              ></div>
            </div> -->

            <div
              class="w-full bg-white rounded-2xl text-left p-4 md:px-6"
              v-if="payrollAnalysis && payrollAnalysis.byEmploymentType.length"
            >
              <h2 class="text-xl font-bold">
                Payroll Summary <br />
                By Job Type
              </h2>

              <div class="flex flex-col gap-4 my-4 w-full">
                <div
                  v-for="(summary, i) in payrollAnalysis &&
                  payrollAnalysis.byEmploymentType"
                  :key="i"
                  :class="showAll ? 'text-base' : 'text-lg'"
                  class="rounded-2xl max-w-fit px-4 py-3 md:px-6 font-bold border"
                  :style="getSummaryColor(i)"
                >
                  {{ summary._id }} {{ formatAmount(summary.count) }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="w-full col-span-12 flex flex-col gap-6"
            v-if="
              payrollAnalysis &&
              payrollAnalysis.monthlyPayrollByDepartment.length
            "
          >
            <AveragePayrollDepartment :show-all="showAll" />
          </div>

          <!-- <div class="w-full col-span-12 flex flex-col gap-6">
            <AveragePayrollBudget :show-all="showAll" />
          </div>
          <div class="w-full col-span-12 flex flex-col gap-6">
            <YearlyBudgetVue />
          </div> -->
          <!-- <div class="w-full col-span-12 flex flex-col gap-6 grid grid-cols-12">
            <div
              :class="
                showAll
                  ? 'col-span-9'
                  : 'col-span-12 lg:col-span-9 xl:col-span-8'
              "
              class="bg-white rounded-2xl px-4 py-6 shadow-lg"
            >
              <h2 class="text-info font-bold text-xl md:text-2xl">
                Analyse Payroll Health
              </h2>
              <div
                class="w-full flex items-center justify-center gap-4 mg:gap-6 my-6 md:my-8"
              >
                
                <div
                  v-for="(item, i) in payrollHealth"
                  :key="i"
                  :class="
                    isActive === item.name
                      ? `shadow-md border-2 ${item.style}`
                      : ''
                  "
                  class="p-4 md:py-6 md:px-8 rounded-lg"
                >
                  <img
                    v-if="isActive === item.name"
                    class="w-14 md:w-20"
                    :src="item.active"
                    alt=""
                  />
                  <img v-else class="w-14 md:w-20" :src="item.icon" alt="" />
                </div>
              </div>
            </div>

            <div
              :class="
                showAll
                  ? 'col-span-3'
                  : 'hidden lg:flex lg:items-center lg:col-span-3 xl:col-span-4'
              "
            >
              <img :src="avatar" class="w-60 object-contain" alt="" />
            </div>
          </div> -->
        </div>
        <div
          class="no-printable flex items-center justify-center gap-2 md:gap-7"
        >
          <easiButton
            @click="generate"
            :loading="isDownloading"
            variant="outlined"
            class="rounded-md md:justify-center flex items-center md:gap-2 w-full"
            size="small"
          >
            <span class="hidden md:block text-grey"> Download</span>
            <img src="@/assets/icons/newDownload2.svg" />
          </easiButton>
          <easiButton
            @click="shareLink = true"
            variant="outlined"
            class="rounded-md md:justify-center flex items-center gap-2 w-full"
            size="small"
          >
            <span class="hidden md:block text-grey"> Share</span>
            <img class="w-4" src="@/assets/icons/newShare2.svg" />
          </easiButton>
          <easiButton
            @click="printPage"
            :loading="isPrinting"
            variant="outlined"
            class="rounded-md md:justify-center flex items-center gap-2 w-full"
            size="small"
          >
            <span class="hidden md:block text-grey"> Print</span>
            <img src="@/assets/icons/newPrint.svg" />
          </easiButton>
        </div>
      </div>
    </vue3-simple-html2pdf>

    <easiModal v-if="shareLink" @close="shareLink = false">
      <template v-slot:header>
        <div class="flex items-center gap-x-4 justify-center">
          <img src="@/assets/icons/share-black.svg" alt="" />
          <h1 class="capitalize text-3xl font-medium text-headerText">Share</h1>
        </div>
      </template>

      <form
        @submit.prevent="shareAnalysis"
        class="flex flex-col"
        autocomplete="on"
      >
        <div class="flex flex-col px-6 sm:px-12 lg:px-8 xl:px-10">
          <easiTextInput
            v-model="args.email"
            placeholder="Input Email Address"
            type="email"
            name="email"
            class="mt-1 mb-5"
            required
            :error="errorRules.email"
          />
        </div>
        <hr
          class="mt-3 md:mb-8 mb-4 border-0.5 border-dividerColor text-dividerColor"
        />
        <div class="px-10 w-full">
          <easiButton
            :loading="loadingShare"
            :block="true"
            class="w-full mb-4 md:mb-5"
            >Send Mail</easiButton
          >
        </div>
      </form>
    </easiModal>

    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span>Payroll Analysis has been shared successfully</span>
      </template>
    </easiSuccess>
  </main>
</template>

<script>
import { ref, reactive, onMounted, watch, computed } from "vue";
import { storeToRefs } from "pinia";
import { useDataStore } from "@/stores/data.js";
import { helperFunctions } from "@/composable/helperFunctions";
import { useToast } from "vue-toastification";

import MinMaxSalary from "./MinMaxSalary.vue";
import NumberOfEmployees from "./NumberOfEmployees.vue";
import PayrollByDepartment from "./PayrollByDepartment.vue";
import YearlyBudgetVue from "./YearlyBudget.vue";
import AveragePayrollBudget from "./AveragePayrollBudget.vue";
import AveragePayrollDepartment from "./AveragePayrollDepartment.vue";
import AveragePayrollGender from "./AveragePayrollGender.vue";

import avatar from "@/assets/img/analyst.png";

import low from "@/assets/img/low.png";
import lowActive from "@/assets/img/low-active.png";

import normal from "@/assets/img/normal.png";
import normalActive from "@/assets/img/normal-active.png";

import good from "@/assets/img/good.png";
import goodActive from "@/assets/img/good-active.png";

import great from "@/assets/img/great.png";
import greatActive from "@/assets/img/great-active.png";
import { useRoute, useRouter } from "vue-router";

export default {
  components: {
    MinMaxSalary,
    NumberOfEmployees,
    PayrollByDepartment,
    YearlyBudgetVue,
    AveragePayrollBudget,
    AveragePayrollDepartment,
    AveragePayrollGender,
  },
  data: () => ({ avatar }),
  setup() {
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();

    const {
      formatAmount,
      calculatePercentage,
      formatDateString,
      downloadBase64,
    } = helperFunctions;
    const store = useDataStore();
    const { mutate, query } = useDataStore();

    const payrollAnalysis = computed(() => store.getPayrollAnalysis);
    console.log("ANALYSIS", payrollAnalysis.value);

    // cosnt totalPayroll = computed(() => payrollAnalysis.value && )
    const companyData = computed(() => store.getCompanyDetails);
    const adminDetails = computed(() => store.getCompanyAdmin.companyAdmin);

    console.log("COMPANY DATA", companyData.value);

    const showAll = ref(false);
    const isActive = ref("normal");
    const isDownloading = ref(false);

    const loadingShare = ref(false);
    const shareLink = ref(false);
    const updateSuccess = ref(false);
    const loadingProgress = ref(false);
    const isPrinting = ref(false);

    const args = reactive({
      email: "",
    });

    const errorRules = reactive({
      email: false,
    });

    let months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const downloadThenPrint = async () => {
      const payload = {
        input: {
          from:
            route.query && route.query.date && route.query.date.split("/")[0],
          to: route.query && route.query.date && route.query.date.split("/")[1],
        },
        download: true,
        email: "",
      };
      const res = await query({
        endpoint: "SendPayrollAnalysis",
        service: "PAYROLL",
        payload,
      });
      const newData = Buffer.from(res.data);
      const base64 = newData.toString("base64");
      openPdfInNewTab(base64);
    };
    function openPdfInNewTab(base64Pdf) {
      // Create a Blob from the base64 PDF string
      const pdfData = atob(base64Pdf);
      const arrayBuffer = new ArrayBuffer(pdfData.length);
      const uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < pdfData.length; i++) {
        uint8Array[i] = pdfData.charCodeAt(i);
      }
      const pdfBlob = new Blob([arrayBuffer], { type: "application/pdf" });

      // Generate a URL for the Blob
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Open the PDF in a new browser tab
      const newTab = window.open(pdfUrl, "_blank");

      // Trigger print dialog when new tab is loaded
      if (newTab) {
        newTab.onload = function () {
          newTab.print();
        };
      }
    }
    const printPage = async () => {
      try {
        isPrinting.value = true;
        await downloadThenPrint();
      } catch (e) {
        console.error(e);
      } finally {
        isPrinting.value = false;
      }
      // old logic

      // showAll.value = true;
      // setTimeout(() => {
      //   window.print();
      // }, 1000);
    };
    onafterprint = (event) => {
      showAll.value = false;
    };

    const pdfOptions = reactive({
      margin: 10,
      image: {
        type: "jpeg",
        quality: 1,
      },
      html2canvas: { scale: 3 },
      jsPDF: {
        unit: "mm",
        format: "letter",
        orientation: "p",
      },
    });

    const payslipName = computed(() => {
      return `analysis.pdf`;
    });

    onMounted(() => {
      // router.replace({ query: { date: "2022-01-09/2023-02-07" } });
      queryPayrollAnalysis();
    });

    const getDate = (arg) => {
      const d = new Date(arg);
      return `${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
    };

    const payrollSummary = computed(() => {
      return [
        {
          title: "Full time",
          _id: "FULLTIME",
          amount: 14568983.22,
        },
        {
          title: "Part time",
          _id: "PARTTIME",
          amount: 8568983.22,
        },
        {
          title: "Contract",
          _id: "CONTRACT",
          amount: 11568983.22,
        },
        {
          title: "Volunteer",
          _id: "VOLUNTEER",
          amount: 8983.22,
        },
        {
          title: "NYSC",
          _id: "NYSC",
          amount: 268983.22,
        },
        {
          _id: "INTERNSHIP",
          title: "Internship",
          amount: 268983.22,
        },
        {
          title: "Apprenticeship",
          _id: "APPRENTICESHIP",
          amount: 168983.22,
        },
      ];
    });

    const getSummaryColor = (index) => {
      const colors = {
        0: {
          bg: "#A2F5E6",
          border: "#01AA8C",
        },
        1: {
          bg: "#FBE4FF",
          border: "#5B0169",
        },
        2: {
          bg: "#E8FFE4",
          border: "#19A301",
        },
        3: {
          bg: "#FFEBB8",
          border: "#E0A409",
        },
        4: {
          bg: "#FFDCD2",
          border: "#E4572E",
        },
        5: {
          bg: "#DFE5FF",
          border: "#023C98",
        },
        6: {
          bg: "#FFC7F3",
          border: "#DE1C10",
        },
      };

      return `border-color:${colors[index].border}; background-color:${colors[index].bg}`;
    };

    const payrollHealth = [
      {
        icon: low,
        active: lowActive,
        name: "low",
        style: "border-red-400 bg-red-100",
      },
      {
        icon: normal,
        active: normalActive,
        name: "normal",
        style: "border-yellow-400 bg-yellow-100",
      },
      {
        icon: good,
        active: goodActive,
        name: "good",
        style: "border-blue-400 bg-blue-100",
      },
      {
        icon: great,
        active: greatActive,
        name: "great",
        style: "border-green-400 bg-green-100",
      },
    ];

    async function queryPayrollAnalysis() {
      const selectDate = route.query.date.split("/");
      const payload = {
        input: {
          from: selectDate[0],
          to: selectDate[1],
        },
        // otpRef: window.localStorage.getItem("otpReference"),
      };

      loadingProgress.value = true;

      try {
        await query({
          endpoint: "GetPayrollAnalysis",
          service: "PAYROLL",
          storeKey: "payrollAnalysis",
          payload,
        });

        enterOtp.value = false;
        loadingProgress.value = false;
        isViewAnalysis.value = true;
      } catch (e) {
        loadingProgress.value = false;
        console.log(e);
      }
    }
    async function generate() {
      try {
        isDownloading.value = true;
        const payload = {
          input: {
            from:
              route.query && route.query.date && route.query.date.split("/")[0],
            to:
              route.query && route.query.date && route.query.date.split("/")[1],
          },
          download: true,
          email: "",
        };
        const res = await query({
          endpoint: "SendPayrollAnalysis",
          service: "PAYROLL",
          payload,
        });
        const newData = Buffer.from(res.data);
        const base64 = newData.toString("base64");
        downloadBase64(
          base64,
          `Payroll Analysis-${companyData.value.company.companyName},${
            (months[new Date().getMonth()],
            new Date().getDate().toString().padStart(2, "0"),
            new Date().getFullYear())
          }`
        );
      } catch (e) {
        console.error("Failed to download", e);
      } finally {
        isDownloading.value = false;
      }
    }
    async function shareAnalysis() {
      if (args.email.trim() !== "") {
        errorRules.email = false;

        console.log("DATE", route.query);

        const payload = {
          input: {
            from:
              route.query && route.query.date && route.query.date.split("/")[0],
            to:
              route.query && route.query.date && route.query.date.split("/")[1],
          },
          download: null,
          email: args.email,
        };

        console.log(payload);
        loadingShare.value = true;

        try {
          await query({
            endpoint: "SendPayrollAnalysis",
            service: "PAYROLL",
            payload,
          });

          shareLink.value = false;
          loadingShare.value = false;
          updateSuccess.value = true;

          setTimeout(() => {
            updateSuccess.value = false;
          }, 1000);
        } catch (e) {
          loadingShare.value = false;
          console.log(e);
        }
      } else {
        errorRules.email = "Please enter a valid email address";
      }
    }

    const getAvatar = (employee) => {
      let name = "";

      if (employee) {
        console.log(employee);
        employee
          ? (name = `${
              employee && employee.profile && employee.profile.firstName
                ? employee.profile.firstName.charAt(0)
                : "N"
            }${
              employee && employee.profile && employee.profile.lastName
                ? employee.profile.lastName.charAt(0)
                : "/A"
            }`)
          : "";
      }

      return name ? name : "N/A";
    };

    return {
      pdfOptions,
      payslipName,
      isActive,
      args,
      errorRules,
      getAvatar,

      showAll,
      companyData,
      getDate,
      printPage,
      onafterprint,
      getSummaryColor,
      payrollSummary,
      payrollHealth,
      payrollAnalysis,
      queryPayrollAnalysis,
      isPrinting,
      formatDateString,
      months,
      shareLink,
      loadingShare,
      shareAnalysis,
      updateSuccess,
      loadingProgress,
      isDownloading,
      generate,
      store,
      mutate,
      query,
      formatAmount,
      getAvatar,
    };
  },
};
</script>

<style scoped>
.totalPayrollGradient {
  background: linear-gradient(93.11deg, #004da6 14.05%, #059ee8 101.64%);
}
</style>
