<template>
  <main class="mx-2 md:mx-0">
    <h4
      class="md:mt-3 md:text-sm md:text-left text-xs font-medium text-headerText"
    >
      Employee Management / Edit Employee
    </h4>
    <div class="text-left my-6">
      <easiBackButton></easiBackButton>
    </div>
    <h2
      class="cursor-pointer text-center my-3 text-2xl font-medium text-dark-800"
    >
      Bulk Edit
    </h2>
    <div v-if="allEmployees && !allEmployees.length" class="mt-16 mb-4">
      <h3 class="my-6 text-2xl text-center md:text-3xl text-dark-800 font-bold">
        You have not added any employee yet.
      </h3>
      <div
        class="container mx-auto flex items-center justify-center gap-4 flex-wrap"
      >
        <easiButton
          @click="$router.push({ name: 'AddEmployee' })"
          variant="outline-secondary"
        >
          Add new employee
        </easiButton>
        <img src="@/assets/icons/no_employee.svg" alt="" />
      </div>
    </div>

    <div v-else class="my-6 md:my-10 w-full">
      <div
        class="flex bg-white border w-full md:w-1/2 text-center mx-auto mx-auto border-outlineGray items-center tracking-wide justify-center md:mt-8 md:mb-7 mt-4 mb-2 gap-x-2 rounded-xl p-1 text-sm font-medium cursor-pointer"
      >
        <span
          @click="toggleAdd"
          :class="sectional ? 'bg-primary shadow-2xl text-white' : 'bg-newGrey'"
          class="w-full text-sm rounded-lg md:px-5 md:py-2 px-3 py-2"
          >Sectional Edit</span
        >
        <span
          @click="toggleAdd"
          :class="
            !sectional ? 'bg-primary shadow-2xl text-white' : 'bg-newGrey'
          "
          class="w-full text-sm rounded-lg md:px-5 md:py-2 px-3 py-2"
          >Upload Spreadsheet</span
        >
      </div>
      <p
        v-show="sectional"
        class="mb-7 text-dark-800 font-medium text-center text-sm"
      >
        Please choose section of employee information to edit
      </p>
      <div
        v-show="sectional"
        class="flex md:justify-between md:flex-row flex-col gap-y-6 md:gap-y-0 w-full md:items-center"
      >
        <div
          class="flex md:items-center gap-x-4 md:gap-x-0 mt-4 w-full md:w-4/5 grow-0 gap-y-4 md:gap-y-0"
        >
          <!-- /////// Search Component ///// -->
          <div class="w-full md:w-10/12">
            <easiSearch
              placeholder="Search employee"
              v-model="searchText"
              @search="searchFN"
            />
          </div>

          <!-- /////// Filter Component Start /////// -->
          <div class="md:ml-4">
            <easiFilter @update="filterFN" />
          </div>
          <!-- /////// Filter Component Ends /////// -->
        </div>
        <div class="self-center">
          <!-- <easiButton
            @click="$router.push({ name: '' })"
            variant="secondary"
            class="flex items-center gap-3 py-4"
          >
            <img src="@/assets/icons/account-plus.svg" alt="" />
            Bulk Edit
          </easiButton> -->
          <easiButton
            @click="editSection"
            variant="primary"
            class="flex items-center gap-3 py-2 text-xs md:text-base"
          >
            Update Edit
          </easiButton>
        </div>
      </div>
    </div>

    <div
      v-if="allEmployees && allEmployees.length"
      v-show="sectional"
      class="md:block hidden -ml-4 md:mt-8"
    >
      <div class="text-right mr-4 mb-3">
        <span
          >{{ currentPage == 0 ? currentPage + 1 : pageSize + currentPage }} -
          {{
            currentPage == 0
              ? pageSize > allEmployees.length
                ? allEmployees.length
                : pageSize
              : pageSize + visibleData.length
          }}
          of {{ allEmployees.length }}</span
        >
      </div>
      <SectionalEdit
        :data="visibleData"
        :frozenFields="['firstName', 'lastName']"
        :highlight="['firstName', 'lastName']"
        :header="headers"
        :useSelect="['department', 'employmentType', 'branch']"
        :mapData="[getDepartment, getBranch, empType]"
      />
    </div>
    <div
      v-if="allEmployees && allEmployees.length"
      v-show="sectional"
      class="md:hidden block -ml-2 md:mt-8"
    >
      <!-- <easiEmployeeTable :search="searchText" /> -->
      <MobileSectionalEdit
        :data="transformedData"
        :frozenFields="['firstName', 'lastName']"
        :highlight="['firstName', 'lastName']"
        :header="headers"
        :mapData="[empType, getDepartment, getBranch]"
        @mobileData="createUploadArrayForMobile($event)"
      />
    </div>
    <easiPagin
      v-if="allEmployees && transformedData.length && sectional"
      :data="transformedData"
      @page:update="updatePage"
      :currentPage="currentPage"
      :pageSize="pageSize"
      class="md:block hidden"
    />

    <div v-if="allEmployees && allEmployees.length" v-show="!sectional">
      <ExcelEdit />
    </div>
    <easiLoading v-if="loadingProgress" @close="loadingProgress = false">
      <template v-slot:heading>
        <h1>Analyzing Data</h1>
      </template>

      <template v-slot:message>
        <div class="w-full rounded-xl">
          <vue3-progress-bar></vue3-progress-bar>
        </div>
      </template>
    </easiLoading>
    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span>Updated successfully</span>
      </template>
    </easiSuccess>
  </main>
</template>

<script setup>
import ExcelEdit from "@/components/Employee/Edit/ExcelEdit.vue";
import SectionalEdit from "@/components/Employee/Edit/SectionalEdit.vue";
import MobileSectionalEdit from "@/components/Employee/Edit/MobileSectionalEdit.vue";
import {
  ref,
  reactive,
  onMounted,
  computed,
  watch,
  onBeforeUnmount,
} from "vue";
import { storeToRefs } from "pinia";
import { useToast } from "vue-toastification";

import { useDataStore } from "@/stores/data.js";
import { onBeforeRouteLeave } from "vue-router";
const store = useDataStore();
const { query, mutate } = store;
const { listEmployees, getAllEmployees, getAllBranches, getAllDepartments } =
  storeToRefs(store);
const toast = useToast();

const loading = ref(false);
const searchText = ref("");
let isSearch = ref(false);
const filterOptions = ref({
  sort: { firstName: "ASC" },
  filter: [],
});
const currentPage = ref(0);
const pageSize = ref(15);
let visibleData = ref([]);
const uploadData = ref([]);
const payload = ref({
  paging: {
    limit: 1000,
    skip: 0,
    search: "",
    sort: { firstName: "ASC" },
  },
  filter: [],
});

watch(searchText, async (val) => {
  if (val.trim() === "") {
    payload.value.paging.search = "";
    await queryEmployees();
    isSearch.value = false;
  }
});

const queryDepartment = async () => {
  try {
    await query({
      endpoint: "ListDepartments",
      service: "SETTINGS",
      storeKey: "listDepartments",
    });
    console.log("Departments");
  } catch (err) {
    console.log(err);
  }
};

const queryBranch = async () => {
  try {
    await query({
      endpoint: "ListBranches",
      service: "SETTINGS",
      storeKey: "listBranches",
    });
  } catch (err) {
    console.log(err);
  }
};

queryDepartment();
queryBranch();

const getDepartment = computed(() => {
  let department = [];
  if (getAllDepartments.value) {
    department =
      getAllDepartments.value &&
      getAllDepartments.value.data &&
      Array.isArray(getAllDepartments.value.data) &&
      getAllDepartments.value.data.length &&
      getAllDepartments.value.data.map((dept) => {
        return {
          label: dept.name,
          value: dept._id,
        };
      });
  }
  return department;
});

const getBranch = computed(() => {
  let branch = [];
  if (
    getAllBranches.value &&
    getAllBranches.value.data &&
    Array.isArray(getAllBranches.value.data) &&
    getAllBranches.value.data.length
  ) {
    branch =
      getAllBranches.value &&
      getAllBranches.value.data &&
      getAllBranches.value.data.map((branch) => {
        return {
          label: branch.name,
          value: branch._id,
        };
      });
  }
  return branch;
});

async function queryEmployees() {
  await query({
    endpoint: "ListEmployees",
    payload: payload.value,
    service: "EMP",
    storeKey: "listEmployees",
  });
}
queryEmployees();
const allEmployees = computed(() =>
  getAllEmployees.value ? getAllEmployees.value.data : []
);

const empType = reactive([
  {
    label: "Fulltime",
    value: "FULLTIME",
  },
  {
    label: "Contract",
    value: "CONTRACT",
  },
  {
    label: "Part Time",
    value: "PARTTIME",
  },
  {
    label: "Volunteer",
    value: "VOLUNTEER",
  },

  {
    label: "NYSC",
    value: "NYSC",
  },
  {
    label: "Internship",
    value: "INTERNSHIP",
  },

  {
    label: "Apprentice",
    value: "APPRENTICE",
  },
]);

const loadingProgress = ref(false);
const updateSuccess = ref(false);
const employees =
  getAllEmployees && getAllEmployees.value ? getAllEmployees.value.data : [];
const headers = computed(() => {
  let headArr = [];
  const head = Object.keys(transformedData.value[0]);
  for (let i of head) {
    headArr.push({
      field: i,
      header: i,
    });
  }
  return headArr;
});

function transform(arr) {
  const newArr =
    arr &&
    arr.map((obj) => {
      return {
        id: obj.job.userName || "N/A",
        employeeId: obj._id,
        firstName: obj.profile.firstName,
        lastName: obj.profile.lastName,
        picture: obj.profile.pfp,

        jobTitle: obj.job && obj.job.jobTitle,
        grossPay: obj.staffLevel ? Number(obj.staffLevel.grossAmount) : 0,
        department: obj.job.departmentId,
        employmentType: obj.job.employmentType,
        branch: obj.job.branchId,

        staffLevelId: obj.staffLevel && obj.staffLevel._id,
        prorate: obj.prorate && obj.prorate.proratedSalary ? obj.prorate : {},
      };
    });

  return newArr;
}

function createUploadArray(arr) {
  const profile = arr.map((item) => {
    return {
      employeeId: item.employeeId,
      staffLevelId: item.staffLevelId,
      grossAmount: item.grossPay,
      profile: {
        firstName: item.firstName,
        lastName: item.lastName,
      },
      job: {
        jobTitle: item.jobTitle,
        employmentType: item.employmentType,
        branchId: item.branch,
        departmentId: item.department,
        staffLevelId: item.staffLevelId,
      },
    };
  });
  return profile;
}
function createUploadArrayForMobile(event) {
  uploadData.value = createUploadArray(event);
}
const transformedData = ref([]);
transformedData.value = transform(allEmployees.value);

// const allEmployee = reactive([""]);

let sectional = ref(true);
function toggleAdd() {
  sectional.value = !sectional.value;
}
const searchFN = async (suggested) => {
  // console.log(suggested, searchText.value);
  searchText.value = suggested ? suggested : searchText.value;
  isSearch.value = true;
  payload.value.paging.search = searchText.value;
  await queryEmployees();
};

const filterFN = (arg) => {
  isSearch.value = true;
  filterOptions.value = arg;

  payload.value.paging.sort = filterOptions.value.sort;
  payload.value.filter = filterOptions.value.filter;

  console.log("Payload");
  queryEmployees();
};

async function editSection() {
  try {
    if (uploadData.value.length <= 0) {
      uploadData.value = createUploadArray(transformedData.value);
    }
    uploadData.value = uploadData.value.map((item) => {
      item.grossAmount = Number(item.grossAmount);
      return item;
    });
    // console.log(uploadData.value, "DTUTA");

    loadingProgress.value = true;

    let res = await mutate({
      endpoint: "UpdateEmployeeSection",
      service: "EMP",
      data: { input: uploadData.value },
    });

    // loadingProgress.value = false;
    if (res.success > 0) {
      // toast.success("Update Successful");
      await queryEmployees();
      updateSuccess.value = true;

      setTimeout(() => {
        // updateSuccess.value = false;
        window.location.reload();
        uploadData = [];
      }, 1000);
    } else {
      toast.error("Update unsuccessful");
    }
  } catch (e) {
    console.log(e);
  } finally {
    loadingProgress.value = false;
  }
}
const updatePage = (pageNumber) => {
  currentPage.value = pageNumber;
  updateVisibleData();
};
const updateVisibleData = () => {
  let datas = transformedData.value;
  visibleData.value = datas.slice(
    currentPage.value * pageSize.value,
    currentPage.value * pageSize.value + pageSize.value
  );

  // if we have 0 visible todos, go back a page
  if (visibleData.value.length == 0 && currentPage.value > 0) {
    updatePage(currentPage.value - 1);
  }
};
watch(allEmployees, (val) => {
  transformedData.value = transform(val);

  updateVisibleData();
});
onMounted(() => {
  queryEmployees();
  queryDepartment();
  queryBranch();

  updateVisibleData();
  // visibleData.value = employees;
});

onBeforeRouteLeave((to, from, next) => {
  if (
    confirm(
      "You are about to leave this page. Are you sure you want to continue?"
    )
  ) {
    // User confirmed, continue with navigation
    next();
  } else {
    // User canceled, stay on the current page
    next(false);
  }
});
const handleBeforeUnload = (event) => {
  const confirmationMessage =
    "You have unsaved changes. Are you sure you want to leave?";
  event.returnValue = confirmationMessage;
  return confirmationMessage;
};
onBeforeUnmount(() => {
  window.removeEventListener("beforeunload", handleBeforeUnload);
});

window.addEventListener("beforeunload", handleBeforeUnload);
</script>

<style></style>
