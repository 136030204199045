<template>
  <main>
    <div
      class="md:w-1/2 w-full py-7 mx-auto bg-white rounded-2xl mt-10 mb-12 relative text-left border border-boxBorder shadow-lg"
    >
      <div class="mb-8">
        <h2
          class="text-center text-headerText font-bold md:text-xl text-lg md:text-2xl"
        >
          Upload Employee
        </h2>
        <hr class="my-3 border-0.5 border-dividerColor text-dividerColor" />
      </div>

      <div class="flex flex-col items-center gap-2 mb-10">
        <span class="text-dark-800 text-xs font-medium mb-1"
          >Download our employee list template and populate it.</span
        >

        <div
          @click="download('employee')"
          class="w-56 text-center rounded-full border border-secondary py-2 px-4 cursor-pointer"
        >
          <span class="text-secondary text-sm text-center font-medium">
            <i class="fas fa-download mr-3"></i>Download Template</span
          >
        </div>
      </div>

      <div class="flex flex-col items-center gap-2 mb-7">
        <span class="text-dark-800 text-xs font-medium mb-1"
          >Upload populated employee list into the system.</span
        >
        <!-- 
        <div
          @click="$refs.input.click()"
          class="w-56 text-center rounded-lg border border-hoverColor py-2 px-4 cursor-pointer"
        >
          <span
            class="w-full justify-center text-hoverColor text-sm text-center font-medium"
          >
            <i class="fas fa-upload mr-3 text-center"></i>Choose File</span
          >
        </div> -->

        <div
          @click="$refs.input.click()"
          class="w-56 mt-2 text-center rounded-full border border-primary py-2 px-4 cursor-pointer"
        >
          <span
            class="w-full flex items-center gap-3 justify-center text-primary text-sm text-center font-medium"
          >
            <img class="w-6" src="@/assets/icons/upload-icon.svg" alt="" />

            <span>Choose File</span>
          </span>
        </div>

        <input
          v-show="hide === false"
          ref="input"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          @change="previewFiles($event)"
          type="file"
          class="custom-file-input"
          required
        />

        <div
          v-show="fileName.length > 0"
          class="flex justify-center gap-4 items-center mt-4"
        >
          <div class="w-6 justify-center self-center">
            <img :src="xlsicon" alt="" />
          </div>

          <p class="text-xs text-dark-900">{{ fileName }}</p>
          <span class="text-xs text-gray">{{ fileSize }}</span>
        </div>

        <div
          v-show="message.length > 0 && fileName.length === 0"
          class="flex justify-center gap-4 items-center"
        >
          <p class="text-xs font-medium text-error">{{ message }}</p>
        </div>
      </div>

      <div class="w-full flex justify-center mx-auto my-10">
        <easiButton class="w-96" @click="openPreviewModal"
          ><span class="font-normal">Preview</span></easiButton
        >
      </div>

      <form
        @submit.prevent="openPreviewModal"
        autocomplete="on"
        class="flex flex-col items-center px-3 md:px-12 lg:px-8 xl:px-10"
      ></form>
    </div>

    <easiSuccess v-if="addSalarySuccess" @close="addSalarySuccess = false">
      <template v-slot:message>
        <span>Employees Updated successfully</span>
      </template>
    </easiSuccess>

    <easiPreview v-if="previewModal" @close="previewModal = false">
      <template v-slot:header>
        <h1>{{ uploaded ? "Confirm" : "Preview" }} Upload</h1>
        <p
          v-if="bankInvalid"
          class="text-error-table font-bold text-base mt-7 mb-2"
        >
          Invalid bank details found
        </p>
        <p
          v-if="errorFound"
          class="text-error-table font-bold text-base mt-7 mb-2"
        >
          The highlighted section(s) need to be corrected. Please correct and
          submit
        </p>
      </template>

      <template v-slot:body>
        <div v-if="jsonData.length && uploaded === false" class="w-full">
          <easiTable
            :data="jsonData"
            :header="configureHeaders"
            :mapData="bankCodes"
            :useSelect="['Bank Code']"
            :frozenFields="['Employee Id', 'First Name', 'Last Name']"
            :highlight="['First Name', 'Last Name']"
            :mandatory="['First Name', 'Last Name', 'Phone Number', 'Email']"
            :readonly="true"
          ></easiTable>
        </div>

        <div v-if="uploadJsonData.length && uploaded === true" class="w-full">
          <easiTable
            :data="uploadJsonData"
            :header="uploadHeaders"
            :mapData="bankCodes"
            :useSelect="['bankCode']"
            :frozenFields="[
              'sr',
              'firstName',
              'lastName',
              'firstname',
              'lastname',
              'reason',
              'FIRSTNAME',
            ]"
            :highlight="['firstName', 'lastName', 'firstname', 'lastname']"
            :mandatory="[
              'firstName',
              'lastName',
              'firstname',
              'lastname',
              'phone',
              'email',
            ]"
          ></easiTable>
        </div>

        <div class="flex flex-col items-center gap-y-2 mt-10 mb-3">
          <!-- <div class="justify-center items-center gap-2 flex">
            <input
              class="my-2"
              v-model="sendOnboardLink"
              type="checkbox"
              name=""
              id=""
            />
            <span class="text-xs text-dark-800"
              >Send employee (s) onboarding form</span
            >
          </div> -->

          <div class="self-center flex justify-center w-full text-base">
            <easiButton
              class="md:w-72 w-96"
              v-if="uploaded === false"
              @click="doUpload(false, 'employee')"
              >Submit</easiButton
            >
            <easiButton
              class="md:w-72 w-96"
              v-if="uploaded === true"
              @click="uploadToServer"
              >Submit</easiButton
            >
          </div>
        </div>
      </template>
    </easiPreview>

    <easiLoading v-if="loadingProgress" @close="loadingProgress = false">
      <template v-slot:heading>
        <h1>Analyzing Data</h1>
      </template>

      <template v-slot:message>
        <div class="w-full rounded-xl">
          <vue3-progress-bar></vue3-progress-bar>
        </div>
      </template>
    </easiLoading>
    <easiRoundLoader v-if="loading" />
  </main>
</template>

<script>
import * as XLSX from "xlsx/xlsx.mjs";
import axios from "axios";
import { AUTH_CONTEXT } from "@/api/api";
import { useProgress } from "@marcoschulte/vue3-progress";

import { ref, watch, reactive, computed } from "vue";
import { mapWritableState, storeToRefs } from "pinia";
import { useDataStore } from "@/stores/data.js";
import { mapActions, mapState } from "pinia";
import { useToast } from "vue-toastification";

import banks from "@/utils/banks";
import xlsicon from "@/assets/icons/xls.svg";
export default {
  setup() {
    const store = useDataStore();
    const { query } = store;
    const { allowance, listEmployees, uploadTemplate } = storeToRefs(store);
    const toast = useToast();

    // store.$subscribe((mutation, state) => {
    //   console.log(mutation.type, mutation.storeId, mutation.payload, state);
    //   // store.allowance = mutation.payload;
    // });

    return { store, query, allowance, toast, listEmployees, uploadTemplate };
  },

  data() {
    return {
      loadingProgress: false,
      loading: false,
      progressValue: 0,
      options: {
        text: {
          color: "#FFFFFF",
          shadowEnable: true,
          shadowColor: "#000000",
          fontSize: 14,
          fontFamily: "Helvetica",
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: "#2dbd2d",
          backgroundColor: "#333333",
          inverted: false,
        },
        layout: {
          height: 35,
          width: 140,
          verticalTextAlign: 61,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 30,
          progressPadding: 0,
          type: "line",
        },
      },
      sheetStyle: {
        border: "1px solid #B5BDE1",
        fontSizee: "14px",
      },
      accountWarning: "",
      previewModal: false,
      hide: true,
      message: "",
      fileName: "",
      fileSize: "",
      addSalarySuccess: false,

      xlsicon,
      nigerianBanks: banks,
      payload: {
        paging: {
          limit: 1000,
          skip: 0,
          search: "",
          sort: { firstName: "ASC" },
        },
        filter: [],
      },
      payload2: {
        paging: {
          limit: 1000,
          skip: 0,
          search: null,
        },
      },

      sendOnboardLink: false,

      bankName: "",
      bankDetails: [],

      inviteArg: {
        employeeId: "",
      },
      bankArgs: {
        bankName: "",
        bankCode: "",
        accountNumber: "",
        accountName: "",
      },

      configureUploadHeaders: [],
      uploadJsonData: [],
      jsonData: [],

      btnState: "primary",
      uploaded: false,
      file: null,
      employeeSheet: [],
      argsArray: [],
      bankInvalid: false,
      errorFound: false,
    };
  },
  computed: {
    ...mapWritableState(useDataStore, [
      "listAllowance",
      "allowance",
      "count",
      "name",
      "doubleCount",
      "getAllEmployees",
      "salaryArray",
    ]),
    allAllowanceData: (store) => store.listAllowance,
    allEmployees: (store) => store.getAllEmployees,
    accNum() {
      return this.args.accountNumber;
    },
    bankCodes() {
      // const bankCodeObj = this.nigerianBanks.reduce(
      //   (acc, cur) => ({ ...acc, [cur.code]: cur.name }),
      //   {}
      // );
      const bankArray = [];
      for (let bank of this.nigerianBanks) {
        bankArray.push({
          label: bank.name,
          value: bank.code,
        });
      }
      return bankArray;
    },
    label() {
      return (str) =>
        str
          .split(".")
          .pop()
          .replace(/([A-Z])/g, " $1")
          .toUpperCase();
    },
    configureHeaders() {
      let headArr = [];
      const head = Object.keys(this.jsonData[0]);
      for (let i of head) {
        headArr.push({
          field: i,
          header: i,
        });
      }
      return headArr;
    },

    uploadHeaders() {
      let headArr = [];
      const head = Object.keys(this.uploadJsonData[0]);
      for (let i of head) {
        headArr.push({
          field: i,
          header: i,
        });
      }
      return headArr;
    },
  },

  methods: {
    ...mapActions(useDataStore, ["query", "mutate"]),
    async download(template) {
      try {
        this.btnState = "disabled";
        this.loading = true;

        let res = await this.query({
          endpoint: "DownloadXLTemplate",
          payload: { input: { type: "editEmployeeProfile" } },
          service: "PAYROLL",
          storeKey: "uploadTemplate",
        });
        if (res) {
          var contentType = "application/vnd.ms-excel";
          var blob1 = this.b64toBlob(res, contentType);
          var blobUrl1 = window.URL.createObjectURL(blob1);
          var fileLink = document.createElement("a");
          fileLink.href = blobUrl1;
          fileLink.setAttribute("download", "Edit_Employee.xlsx");
          document.body.appendChild(fileLink);
          fileLink.click();
          this.btnState = "outline";
          this.btnState = "outline";
        } else {
          this.toast.error("Error downloading file.");
        }
      } catch (err) {
        this.btnState = "outline";
        this.toast.error(err.message);
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    b64toBlob(b64Data, contentType, sliceSize) {
      contentType = contentType || "";
      sliceSize = sliceSize || 512;
      var byteCharacters = atob(b64Data);
      var byteArrays = [];
      for (
        var offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);
        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      var blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },

    transformObject(arr) {
      // //remove string preceeding the "." sign
      console.log(arr, "HEY ARR");
      const newArr = arr.map((obj) =>
        Object.fromEntries(
          Object.entries(obj).map(([k, v]) => [
            k.replace(/ +/g, "").toLowerCase(),
            v,
          ])
        )
      );

      if (Object.keys(arr[0]).includes("bankError")) {
        const findError = newArr.find((item) => item.bankError.length > 0);
        if (findError) {
          this.bankInvalid = true;
        }
      }
      // for (let k of Object.keys(arr.errors)) {
      //   for (let data of newArr) {
      //     if (Number(k) === data.sr) {
      //       data["errors"] = arr.errors[k];
      //       console.log(data);
      //     }
      //   }
      // }

      console.log("NEWARR", newArr);
      this.uploadJsonData = newArr.map((d) => {
        d["reason"] = "";
        return d;
      });

      this.$nextTick();

      console.log(this.uploadJsonData, "JS");
    },

    // transformObject(arr) {
    //   // //remove string preceeding the "." sign
    //   const newArr = arr.map((obj) =>
    //     Object.fromEntries(
    //       Object.entries(obj).map(([k, v]) => [k.split(".").pop(), v])
    //     )
    //   );

    //   if (Object.keys(arr[0]).includes("bankError")) {
    //     const findError = newArr.find((item) => item.bankError.length > 0);
    //     if (findError) {
    //       this.bankInvalid = true;
    //     }
    //   }

    //   this.uploadJsonData = newArr;

    //   this.$nextTick();

    //   console.log(this.uploadJsonData, "JS");
    // },
    processNumber(phoneNumber) {
      if (phoneNumber) {
        let phone = phoneNumber;
        let firstDigit = phone.charAt(0);
        if (firstDigit == "0") {
          let formatted = phone.replace("0", "234").replace(/\s+/g, "");
          return formatted;
        } else if (firstDigit == 2) {
          let formatted = phone.replace(/\s+/g, "");
          return formatted;
        }
      } else {
        return "";
      }
    },

    async doUpload(confirmed = false, template = "employee") {
      this.bankInvalid = false;
      if (template == "employee") {
        this.btnState = "disabled";
        const formData = new FormData();
        const operation = `ViewEmployeeProfileExcel`;
        formData.append(
          "operations",
          `{
          "query": "mutation ${operation}($file:Upload!) { ${operation}(file: $file){message data} }"
        }`
        );
        formData.append("map", `{"0": ["variables.file"]}`);
        formData.append("0", this.file, "Employees.xlsx");
        try {
          this.previewModal = false;
          this.loadingProgress = true;
          const progress1 = useProgress().start();
          let progressValue = 0;
          const response = await axios.post(
            "https://payrollservice-d5ffb79ba43a9141.onporter.run/graphql",
            // "http://localhost:4001/graphql",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                authorization: AUTH_CONTEXT(),
                "Apollo-Require-Preflight": "true",
              },
              onUploadProgress: function (progressEvent) {
                progressValue = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );

                // console.log(percent);
              },
            }
          );

          if (progressValue === 100) {
            progress1.finish();
            this.loadingProgress = false;
            this.previewModal = true;
          }
          if (confirmed === true) {
            if (response.data.data.UpdateBulkEmployeeExcel.data.failedData) {
              this.transformObject(
                response.data.data.UpdateBulkEmployeeExcel.data.failedData
              );
              this.errorFound = true;

              this.toast.error(
                `${response.data.data.UpdateBulkEmployeeExcel.message}`,
                {
                  timeout: 6000,
                }
              );
            } else {
              this.previewModal = false;
              this.addSalarySuccess = true;
            }
          } else {
            console.log("RESr", response);
            if (response.data.data.ViewEmployeeProfileExcel.data.length) {
              this.toast.success(
                `${response.data.data.ViewEmployeeProfileExcel.message}, confirm details before uploading`,
                {
                  timeout: 6000,
                }
              );
              this.employeeSheet =
                response.data.data.ViewEmployeeProfileExcel.data;
              console.log(
                response.data.data.ViewEmployeeProfileExcel,
                "RESPONSE"
              );
              this.transformObject(
                response.data.data.ViewEmployeeProfileExcel.data
              );
              if (this.employeeSheet.length <= 0) {
                this.toast.error(
                  "0 correct record found in Sheet. Please fill correctly."
                );
                throw new Error("0 correct record found in Sheet");
              }
              this.uploaded = true;
            } else {
              this.toast.error(
                `${response.data.data.ViewEmployeeProfileExcel.message}`,
                {
                  timeout: 6000,
                }
              );
            }

            // this.confirmation = true;
            // this.uploadedTemplate = "employee";
          }
        } catch (err) {
          if (err) this.loadingProgress = false;
          this.btnState = "primary";
          console.log(err);
        }
        this.btnState = "primary";
      }
    },

    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },

    // openPreviewModal() {
    //   if (this.fileName.length === 0) {
    //     this.message =
    //       "You need to upload the populated template before proceeding";
    //     throw new Error(this.message);
    //   }
    //   this.previewModal = true;
    // },

    openPreviewModal() {
      try {
        this.loadingProgress = true;
        setTimeout(() => {
          if (this.fileName.length === 0) {
            this.message =
              "You need to upload the populated template before proceeding";
            throw new Error(this.message);
          }
          // this.loadingProgress = false;

          this.previewModal = true;
          this.loadingProgress = false;
        }, 3000);
      } catch (e) {
        console.log(e);
      } finally {
        this.loadingProgress = false;
      }
    },

    previewFiles(e) {
      this.jsonData = [];
      this.uploadJsonData = [];
      this.file = null;
      var files = e.target.files;
      console.log(files);
      let f = files[files.length - 1];
      this.file = f;
      const allowedTypes = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ];
      if (!allowedTypes.includes(f.type)) {
        this.message = "File type is wrong!!";
        throw new Error(this.message);
      }
      if (f.size > 1000000) {
        this.message = "Too large, max size allowed is 1MB";
        throw new Error(this.message);
      }
      const { name, size } = f;
      this.fileName = name;
      this.fileSize = this.formatBytes(size);
      console.log(f, "FIle ARR");
      var reader = new FileReader();
      reader.onload = (e) => {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: "array" });
        let sheetName = workbook.SheetNames[0];
        /* DO SOMETHING WITH workbook HERE */
        console.log(workbook);
        let worksheet = workbook.Sheets[sheetName];
        // let jsondata = XLSX.utils.sheet_to_json(worksheet, {
        //   defval: "",
        //   raw: false, // Parse dates as JavaScript Date objects
        //   dateNF: "yyyy-mm-dd",
        // });
        let jsondata = XLSX.utils.sheet_to_json(worksheet, { defval: "" });
        let root = this;
        jsondata.forEach(function (data) {
          root.jsonData.push(data);
        });
        console.log(this.jsonData);
        // this.jsonData = jsondata;
        this.$nextTick();
      };
      reader.readAsArrayBuffer(f);
    },

    queryAllowance() {
      this.query({
        endpoint: "ListAllowance",
        payload: this.payload2,
        service: "PAYROLL",
        storeKey: "allowance",
      });
    },
    queryEmployees() {
      this.query({
        endpoint: "ListEmployees",
        payload: this.payload,
        service: "EMP",
        storeKey: "listEmployees",
      });
    },

    sendLink(e) {
      console.log(e);
      this.sendOnboardLink = !this.sendOnboardLink;
    },
    createUploadArray() {
      const profile = this.uploadJsonData.map((item) => {
        return {
          id: item.uid,
          profile: {
            firstName: item.firstname,
            lastName: item.lastname,
            middleName: item.middlename,
            email: item.email,
            phone: item.phonenumber,
            address: item.address,
            country: item.country,
            // dob: item.dateofbirth,
            state: item.state,
            gender: item.gender,
            religion: item.religion,
            title: item.title,

            relationshipStatus: item.relationshipstatus,
            nin: String(item.nationalidnumber),
            taxId: String(item.taxid),
          },
        };
      });
      return profile;
    },
    removeEmptyValues(array) {
      console.log(array);
      return array.map((obj) => {
        Object.keys(obj).forEach((key) => {
          if (obj[key] && typeof obj[key] === "object") {
            Object.keys(obj[key]).forEach((k) => {
              if (obj[key][k] === null || obj[key][k] === "") {
                delete obj[key][k];
              }
            });
          } else if (obj[key] === null || obj[key] === "") {
            delete obj[key];
          }
        });
        return obj;
      });
    },

    addEmployeesWithExcelLite() {
      const uploadData = this.createUploadArray();
      // const keys = Object.keys(uploadData[0].profile)
      const filteredArray = this.removeEmptyValues(uploadData);

      // console.log(filteredArray, "FILTEred");

      this.loadingProgress = true;
      this.previewModal = false;

      this.mutate({
        endpoint: "UpdateEmployeeProfileBulk",
        service: "EMP",
        data: { input: filteredArray },
      })
        .then((res) => {
          this.loadingProgress = false;
          this.previewModal = true;
          console.log(res, "RES_ERR");
          if (res.success && res.data.failed.length === 0) {
            this.addSalarySuccess = true;
            // this.toast.success(res.message);
            setTimeout(() => {
              this.$router.push({ name: "ViewEmployee" });
            }, 1000);
          } else {
            this.previewModal = false;
            this.errorFound = true;
            const msgs = res.data.failed;
            const errorData = msgs.map((m) => {
              return {
                emp: m.split(":").shift(),
                reason: m.split(":").pop(),
              };
            });
            this.uploadJsonData.forEach((obj1) => {
              errorData.some((obj2) => {
                if (
                  obj2.emp
                    .toLowerCase()
                    .includes(obj1.firstname.toLowerCase()) &&
                  obj2.emp.toLowerCase().includes(obj1.lastname.toLowerCase())
                ) {
                  obj1["reason"] = obj2.reason;
                  console.log(`Match found: `, obj1);
                }
              });
            });
            this.previewModal = true;
            console.log(errorData, "EDED", this.uploadJsonData);
            // for (let msg of msgs) {
            //   this.toast.error(msg);
            //   this.errorArr.push(msg)
            // }

            // this.uploadJsonData = this.transform(res.data.failedData);
            // this.toast.error(res.message);
          }
        })
        .catch((e) => {
          console.log(e);
          this.loadingProgress = false;
          this.previewModal = true;
        });
    },

    uploadToServer() {
      this.addEmployeesWithExcelLite();

      // if (this.bankInvalid) {
      //   this.toast.error("Error Found in sheet");
      //   throw new Error("Correct sheet");
      // } else {
      //   this.bankInvalid = false;
      //   this.addEmployeesWithExcelLite();
      // }
    },
    transform(arr) {
      const newArr = arr.map((obj) => {
        return {
          _id: obj._id,
          firstName: obj.profile.firstName,
          lastName: obj.profile.lastName,
          email: obj.profile.email,
          phone: obj.profile.phone,
          jobTitle: obj.job.jobTitle,
          accountNumber: obj.payment.accountNumber,
          bankCode: obj.payment.bankCode,
          reason: obj.reason,
        };
      });

      return newArr;
    },
  },
  async created() {
    this.queryAllowance();
    this.queryEmployees();
  },
};
</script>

<style></style>
