<template>
  <div v-if="visibleData && visibleData.length" class="card w-full">
    <DataTable
      v-model:selection="selectedData"
      :value="visibleData"
      dataKey="sn"
      tableStyle="min-width: 100%"
      class="p-datatable-md text-size hidden md:block"
      @rowSelect="onRowSelect"
      @rowUnselect="onRowUnselect"
    >
      <Column
        selectionMode="multiple"
        :headerStyle="checkStyle"
        :style="style"
        class="text-size"
        id="custom-style"
      ></Column>
      <Column
        v-for="head of headers"
        :field="head.field"
        :header="label(head.header)"
        :key="head.field"
        :headerStyle="headerStyle"
        :style="style"
      >
        <template v-if="head.field === `status`" #body="slotProps">
          <div class="relative w-full flex justify-end gap-3 items-center">
            <div
              class="rounded-lg p-2 w-auto text-xs text-left font-medium capitalize"
              :style="
                slotProps.data[head.field].toLowerCase() === 'processing'
                  ? 'background-color: rgba(239, 68, 68, 0.08)'
                  : undefined
              "
              :class="[statusClass(slotProps.data[head.field])]"
            >
              {{ slotProps.data[head.field] }}
            </div>

            <div
              @click="showAll(slotProps.data.fullName)"
              class="cursor-pointer w-full"
            >
              <img src="@/assets/icons/more-icon.svg" alt="" />
            </div>

            <div
              class="w-36 absolute group-hover:text-primary flex right-10 flex-col bg-white rounded-lg top-5 shadow-md"
              style="z-index: 1"
              v-show="showOptions === slotProps.data.fullName"
            >
              <div
                class="flex flex-col z-50 text-left items-start text-dark-800 text-xs cursor-pointer pt-2"
              >
                <span
                  v-show="
                    !['paid', 'processing'].includes(slotProps.data.status)
                  "
                  class="hover:bg-newLimeGreen py-2 px-4 w-full"
                  @click="toRunPayroll(slotProps.data)"
                >
                  Retry payment
                </span>
                <span
                  class="hover:bg-newLimeGreen py-2 px-4 w-full"
                  @click="onRowClick(slotProps.data, 'edit')"
                >
                  Download Receipt
                </span>
              </div>
            </div>
          </div>
        </template>
      </Column>
    </DataTable>

    <div
      v-for="(data, i) in visibleData"
      :key="i"
      class="flex md:hidden flex-col gap-2 w-full mt-3 shadow-sm rounded-lg py-3 px-2"
      :class="
        showOptions === data.fullName
          ? 'border-2 border-outlineGray'
          : undefined
      "
    >
      <div
        class="grid grid-flow-col auto-cols-auto justify-between items-center w-full gap-4 bg-white"
      >
        <div class="flex items-center gap-3">
          <div>
            <input
              style="background-color: #aaaaaa"
              class="border border-outlineGray rounded-md opacity-80"
              type="checkbox"
            />
          </div>
          <span class="text-sm text-primary-deep">{{
            String(i + 1).padStart(2, "0")
          }}</span>
        </div>

        <div
          class="flex justify-start w-full text-sm col-span-1 text-dark-800 gap-3"
        >
          <p class="capitalize">{{ data.fullName }}</p>
        </div>

        <div class="flex items-center gap-3">
          <button
            v-if="['paid', 'processing'].includes(data.status)"
            class="focus:outline-none bg-highlight text-sm rounded-full py-2 px-4 text-dark-800"
          >
            Save
          </button>

          <button
            v-else
            @click="toRunPayroll(data)"
            class="focus:outline-none bg-highlight text-sm rounded-full py-2 px-4 text-dark-800"
          >
            Retry
          </button>

          <button
            @click="showAll(data.fullName)"
            class="focus:outline-none border border-outlineGray bg-white text-lg rounded-xl py-2 px-4 text-dark-20"
          >
            <span v-show="showOptions !== data.fullName">+</span>
            <span v-show="showOptions === data.fullName">-</span>
          </button>
        </div>
      </div>
      <hr
        class="border-0.5 mt-1 mb-3 border-outlineGray"
        v-show="showOptions === data.fullName"
      />

      <div
        v-show="showOptions === data.fullName"
        v-for="ob in headers"
        :key="ob"
        class="flex justify-start w-full -mt-2"
        :class="[
          ob.header === 'sn' ? 'hidden' : undefined,
          ob.header !== headers[headers.length - 1].header
            ? 'border-b border-outlineGray'
            : undefined,
        ]"
      >
        <span
          class="capitalize w-32 text-sm border-r py-3 border-outlineGray"
          >{{ label(ob.header) }}</span
        >
        <div
          class="rounded-lg py-3 w-auto text-sm ml-3 text-left font-medium capitalize"
          :class="[statusClass(data[ob.field])]"
        >
          {{ data[ob.field] }}
        </div>
        <!-- <span class="capitalize text-left justify-start text-sm py-3">{{
          data[ob.field]
        }}</span> -->
      </div>
    </div>
    <div
      @click="showOptions = ''"
      :class="!showOptions.length ? 'pointer-events-none' : ''"
      class="bg-transparent fixed inset-0"
    ></div>

    <div class="flex w-full items-center justify-end self-end md:justify-start">
      <easiPagin
        :data="watchData"
        @page:update="updatePage"
        :currentPage="currentPage"
        :visibleData="visibleData"
        :pageSize="pageSize"
        :fromCompliance="true"
        class="w-full mt-2"
      />
    </div>
  </div>
  <div v-else class="text-center mt-12 flex flex-col justify-center">
    <div class=" ">
      <div
        class="container mx-auto flex items-center justify-center gap-4 flex-wrap"
      >
        <img src="@/assets/img/Validation.png" class="h-32" alt="" />
      </div>
      <h3 class="text-sm md:text-xl text-dark-800 font-bold">
        No records match your search!
      </h3>
      <!-- <p class="text-dark-500 text-base">You have not made any registration</p> -->
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch } from "vue";
import { helperFunctions } from "@/composable/helperFunctions";
import { useRoute, useRouter } from "vue-router";
import { useDataStore } from "@/stores/data.js";

const store = useDataStore();
const route = useRoute();
const router = useRouter();

const { formatAmount } = helperFunctions;

const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
});
const emit = defineEmits(["selected"]);
const currentPage = ref(0);
const pageSize = ref(15);
let visibleData = ref([]);

const checkStyle = computed(() => {
  return "width: 1%; background-color: #ffffff; font-size: 12px; text-transform: uppercase";
});
const headerStyle = computed(() => {
  return " background-color: #ffffff; font-size: 12px; text-transform: uppercase";
});

const style = computed(() => {
  return " background-color: #ffffff; font-size: 12px; ";
});

const statusClass = (stat) => {
  let dta = {
    success: "md:bg-lighterGreen bg-white text-successBg",
    failed: "md:bg-lighterRed bg-white text-error",
    processing: "text-secondary",
  };
  return dta[stat];
};

const watchData = computed(() =>
  props.data.map((d, i) => {
    return {
      sn: i + 1,
      paymentId: d.paymentId,
      employeeId: d.employeeId,
      fullName: d.fullName,
      amountPaid: formatAmount(d.amountPaid),
      paymentPartner: d.paymentPartners,
      status: d.status,

      // ...d,
    };
  })
);
watch(watchData, () => {
  updateVisibleData();
});
const headers = computed(() => {
  return watchData.value && watchData.value[0]
    ? Object.keys(watchData.value[0])
        .map((hd) => {
          return {
            header: hd,
            field: hd,
          };
        })
        .filter(
          (k) =>
            watchData.value[0][k.field] &&
            String(watchData.value[0][k.field]).length &&
            k.field !== "employeeId"
        )
    : [];
});
const selectedData = ref([]);
const metaKey = ref(true);

function label(str) {
  return str
    .split(".")
    .pop()
    .replace(/([A-Z])/g, " $1");
  // .toUpperCase();
}
const showOptions = ref("");
function showAll(arg) {
  if (showOptions.value === arg) {
    showOptions.value = "";
  } else {
    showOptions.value = arg;
  }
}

const updateVisibleData = () => {
  let datas = watchData.value;
  visibleData.value = datas.slice(
    currentPage.value * pageSize.value,
    currentPage.value * pageSize.value + pageSize.value
  );

  // if we have 0 visible todos, go back a page
  if (visibleData.value.length == 0 && currentPage.value > 0) {
    updatePage(currentPage.value - 1);
  }
};
const updatePage = (pageNumber) => {
  currentPage.value = pageNumber;
  updateVisibleData();
};

function onRowSelect(event) {
  const data = selectedData.value.map((d) => d.employeeId);

  emit("selected", data);
}
function onRowUnselect(event) {
  const data = selectedData.value
    .filter((emp) => {
      return emp !== event.data.employeeId;
    })
    .map((d) => d.employeeId);

  emit("selected", data);
}

function toRunPayroll(data) {
  const payrollDate = route.query.createdAt.split("T")[0].split("-");
  const month = parseInt(payrollDate[1]) - 1;
  const year = parseInt(payrollDate[0]);
  const date = `${month}-${year}`;
  store.$patch({
    employeeArrayForPayroll: [data.employeeId],
  });
  router.push({ name: "PaySalary", params: { id: date } });
}
onMounted(() => {
  updateVisibleData();
});
</script>

<style scoped>
.text-size {
  font-size: 16px !important;
}

#custom-style .p-checkbox .p-checkbox-box {
  color: #e7e8e7 !important;
}
</style>
