<template>
  <main>
    <div class="relative">
      <div
        @click="toggleFilter = true"
        class="border border-newGray rounded-2xl p-4 cursor-pointer flex items-center gap-2 bg-white w-fit"
        :class="bonus ? 'rounded-l-none' : ''"
      >
        <img
          v-show="!bonus"
          class="w-6 md:w-4"
          src="@/assets/icons/filter.svg"
          alt=""
        />
        <span class="text-dark-600 hidden md:flex items-center gap-3"
          >Filter <i v-show="bonus" class="fas fa-angle-right"></i
        ></span>

        <span
          v-if="activeFilter"
          class="truncate text-sm font-medium hidden md:flex"
          >{{ activeFilter }}
        </span>
      </div>
      <div
        v-if="toggleFilter"
        class="absolute h-auto w-52 right-0 top-16 shadow-lg bg-white flex flex-col text-sm py-2"
        style="z-index: 2"
      >
        <div
          v-for="option in sortOptions"
          :key="option.name"
          class="flex items-center gap-3 cursor-pointer px-4 py-2 hover:bg-newLimeGreen"
          @click="sortFunc(option)"
        >
          <span>
            <img
              v-if="option.active"
              class="w-4"
              src="../../assets/icons/check3-active.svg"
              alt=""
            />
            <img
              v-else
              class="w-4"
              src="../../assets/icons/check3-null.svg"
              alt=""
            />
          </span>
          <span>{{ option.name }}</span>
        </div>

        <div
          v-for="item in filterOptions"
          :key="item.name"
          @mouseover="item.active = true"
          @mouseout="item.active = false"
          @mousedown="item.active = true"
          class="relative group flex px-4 py-2 cursor-pointer hover:bg-newLimeGreen"
        >
          {{ item.name }}
          <div
            v-show="item.active"
            class="w-44 group-hover:text-dark-800 absolute top-0 -left-44 flex flex-col bg-white shadow-lg"
          >
            <div
              v-for="option in item.options"
              :key="option.name"
              class="flex items-center gap-3 cursor-pointer px-4 py-2 hover:bg-newLimeGreen"
              @click="filterFunc(item.value, option.value, option.name)"
            >
              <span>
                <img
                  v-if="option.active"
                  class="w-4"
                  src="../../assets/icons/check3-active.svg"
                  alt=""
                />
                <img
                  v-else
                  class="w-4"
                  src="../../assets/icons/check3-null.svg"
                  alt=""
                />
              </span>
              <span>{{ option.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      @click="toggleFilter = false"
      :class="!toggleFilter ? 'pointer-events-none' : ''"
      class="bg-transparent fixed inset-0"
    ></div>
  </main>
</template>

<script>
import { ref, reactive, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useDataStore } from "@/stores/data.js";

export default {
  props: {
    bonus: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const store = useDataStore();
    const { query } = store;
    const { checkLoading } = storeToRefs(store);

    const selected = ref({
      sort: { name: "ASC" },
      filter: [],
    });

    const payload = ref({
      paging: {
        limit: 1000,
        skip: 0,
        search: "",
      },
    });

    const activeFilter = ref("");
    const activeFilterArray = ref([]);
    const activeSortArray = ref([]);
    const toggleFilter = ref(false);
    const sortOptions = reactive([
      {
        name: "All",
        value: "all",
        active: true,
      },
      {
        name: "A - Z",
        value: "a-z",
        sort: "ASC",
        active: false,
      },
      {
        name: "Z - A",
        value: "z-a",
        sort: "DESC",
        active: false,
      },
      {
        name: "Date Created",
        value: "date",
        sort: "DATE",
        active: false,
      },
    ]);

    const filterOptions = reactive([
      {
        name: "Duration",
        active: false,
        value: "duration",
        options: [
          {
            name: "One-Time",
            active: false,
            value: "ONE_TIME",
          },
          {
            name: "Weekly",
            active: false,
            value: "WEEKLY",
          },
          {
            name: "Monthly",
            active: false,
            value: "MONTHLY",
          },
          {
            name: "Annually",
            active: false,
            value: "ANNUALLY",
          },
        ],
      },

      {
        name: "Status",
        active: false,
        value: "status",
        options: [
          {
            name: "Active",
            active: false,
            value: "ACTIVE",
          },
          {
            name: "Completed",
            active: false,
            value: "COMPLETED",
          },
          {
            name: "Paused",
            active: false,
            value: "PAUSED",
          },
        ],
      },
    ]);

    const sortFunc = (arg) => {
      //reset the sort and filter option
      toggleActiveState("all", false); //remove all active state
      if (arg.name == "All") {
        selected.value.sort = { name: "ASC" };
        selected.value.filter = [];
        activeSortArray.value = [];
        activeFilterArray.value = [];

        // unselect Other options
        sortOptions.forEach((el) => {
          el.active = false;
        });

        filterOptions.forEach((el) => {
          el.options.forEach((el) => {
            el.active = false;
          });
        });
      }

      // unselect All options
      addRemoveOption();

      // Toggle the active state of the option
      function toggleActiveState(payload, state = null) {
        const index = sortOptions.map((el) => el.value).indexOf(payload);
        sortOptions[index].active =
          state !== null ? state : !sortOptions[index].active;
        return sortOptions[index].active;
      }

      //Add/Remove options to/from the sort
      function addRemoveOption() {
        const active = toggleActiveState(arg.value);
        // console.log(active);

        const opposite = {
          "a-z": "z-a",
          "z-a": "a-z",
        };

        // Check if sort is inside the selected object before adding or removing
        if (typeof selected.value.sort == "undefined") {
          addRemove();
          selected.value.sort = {};
        } else {
          addRemove();
        }

        function addRemove() {
          if (active) {
            // Add sort to selected
            console.log(arg, "AS");
            if (arg.value === "a-z" || arg.value === "z-a") {
              selected.value.sort.name = arg.sort;
              console.log(arg.sort, "AS");
              toggleActiveState(opposite[arg.value], false);
            } else if (arg.value === "date") {
              console.log("date");
              //Date not set yet
            }
          } else {
            // Remove sort to selected
            if (arg.value === "a-z" || arg.value === "z-a") {
              delete selected.value.sort.name;
            } else if (arg.value === "date") {
              console.log("date");
              //Date not set yet
            }
          }
        }
      }

      function getAllActiveFilters() {
        // check if active exist then remove it
        activeSortArray.value = activeSortArray.value.filter(
          (el) => el !== arg.name
        );

        const arr = sortOptions
          .filter((el) => el.active)
          .map((el) => {
            return el.name;
          });

        activeSortArray.value = arg.name == "All" ? [] : [...arr];
      }

      getAllActiveFilters();

      const activeArrays = [
        ...activeSortArray.value,
        ...activeFilterArray.value,
      ];

      activeFilter.value = arg.name;
      emit("update", {
        filter: selected.value,
        active: activeArrays,
      });
      // console.log("Sort");
      // log(selected.value);
    };

    const filterFunc = (parent, child, name) => {
      const options = {
        duration: "duration",
        status: "status",
      };

      //check if options is available then remove if it is
      if (!checkIfOptionExist()) {
        removeOtherRelatedFilter(); // Remove every filter option from that section
        const active = toggleActiveState().active; // Toggle the active state and return the active

        if (active) {
          selected.value.filter.push({
            field: options[parent],
            value: child,
          });
        } else {
          const index = selected.value.filter
            .map((el) => el.value)
            .indexOf(child);
          console.log(index);
          selected.value.filter.pop(index);
        }
      } else {
        removeOtherRelatedFilter(); // Remove every filter option from that section
      }

      // Toggle the active state of the option
      function toggleActiveState() {
        // unselect All options
        sortOptions[0].active = false;

        //Get parent
        const parentIndex = filterOptions.map((el) => el.value).indexOf(parent);
        const parentObj = filterOptions[parentIndex];
        // log(parentObj);

        //Loop parent to find selected child
        const childIndex = parentObj.options
          .map((el) => el.value)
          .indexOf(child);
        const childObj = parentObj.options[childIndex];
        // log(childObj);

        childObj.active = !childObj.active;

        return {
          active: childObj.active,
          index: childIndex,
        };
      }

      function checkIfOptionExist() {
        //Get parent
        const parentIndex = filterOptions.map((el) => el.value).indexOf(parent);
        const parentObj = filterOptions[parentIndex];
        // log(parentObj);

        //Loop parent to find selected child
        const childIndex = parentObj.options
          .map((el) => el.value)
          .indexOf(child);
        const childObj = parentObj.options[childIndex];

        return childObj.active;
      }

      function removeOtherRelatedFilter() {
        //Get parent
        const parentIndex = filterOptions.map((el) => el.value).indexOf(parent);
        const parentObj = filterOptions[parentIndex];

        // set all the active state to fallse
        parentObj.options.forEach((el) => {
          el.active = false;
        });

        const newFilter = [...selected.value.filter];
        newFilter.forEach((el, index) => {
          if (el.field === options[parent]) {
            newFilter.pop(index);
          }
        });

        console.log("New Filter");
        log(newFilter);
        selected.value.filter = newFilter;
      }

      function getAllActiveFilters() {
        // check if active exist then remove it
        activeFilterArray.value = activeFilterArray.value.filter(
          (el) => el !== name
        );

        const arr = filterOptions
          .flatMap((option) => option.options.filter((child) => child.active))
          .map((el) => {
            return el.name;
          });

        activeFilterArray.value = [...arr];
      }

      getAllActiveFilters();

      const activeArrays = [
        ...activeSortArray.value,
        ...activeFilterArray.value,
      ];

      activeFilter.value = name;
      emit("update", {
        filter: selected.value,
        active: activeArrays,
      });
    };

    // Get rid of the proxy when console.log
    function log(arg) {
      console.log(JSON.parse(JSON.stringify(arg)));
    }

    const removeFilter = (arg) => {
      console.log(arg);
      let selectedFilter = null;

      //Get the Filter or Sort Object using the name
      let sort = sortOptions.find((el) => el.name == arg);
      if (sort) {
        selectedFilter = sort;
      } else {
        filterOptions.forEach((parent) => {
          let child = parent.options.find((el) => el.name == arg);
          if (child) {
            selectedFilter = { parent, child };
          }
          if (selectedFilter) return;
        });
      }

      //Remove the Filter and query employee
      if (selectedFilter) {
        if (selectedFilter.sort) {
          sortFunc(selectedFilter);
        } else {
          const { parent, child } = selectedFilter;
          filterFunc(parent.value, child.value, child.name);
        }
      }

      const arr = [...activeSortArray.value, ...activeFilterArray.value];
      activeFilter.value = arr[arr.length - 1];
      //
    };

    return {
      toggleFilter,
      sortOptions,
      activeFilter,
      filterOptions,
      filterFunc,
      sortFunc,
      removeFilter,
    };
  },
};
</script>

<style></style>
