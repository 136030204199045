<template>
  <main class="w-full">
    <h4 class="uppercase mt-3 md:text-sm md:text-left text-xs text-headerText">
      Compliance/ all history
    </h4>
    <div class="text-left my-6">
      <easiBackButton></easiBackButton>
    </div>
    <div
      class="flex text-center w-full mt-6 border border-outlineGray rounded-xl tracking-wide justify-start gap-x-2 p-2 text-xs font-medium cursor-pointer mb-3 md:mb-0 bg-white"
    >
      <span
        v-for="opt in filterOpts"
        :key="opt.value"
        @click="filterHistory(opt.value)"
        class="w-full text-xs md:text-sm uppercase rounded-lg md:px-5 md:py-2 px-3 py-2"
        :class="
          activeFilter === opt.value
            ? 'bg-primary shadow-2xl text-white'
            : 'bg-newGrey'
        "
        >{{ opt.label }}
      </span>
    </div>

    <div
      class="md:my-8 w-full bg-white rounded-2xl border border-outlineGray px-4 py-3"
    >
      <div v-if="loading" class="w-full">
        <easiLoader />
      </div>
      <div
        v-show="activeFilter === 'registration'"
        class="hidden md:flex text-center w-full mb-3 rounded-xl tracking-wide justify-start gap-x-2 p-1 text-xs font-medium cursor-pointer bg-newGrey"
      >
        <span
          v-for="opt in registrationFilterOpts"
          :key="opt.value"
          @click="filterRegHistory(opt.value)"
          class="w-full text-xs uppercase rounded-lg md:px-5 md:py-2 px-3 py-2"
          :class="
            activeRegFilter === opt.value ? 'bg-white shadow-2xl text-grey' : ''
          "
          >{{ opt.label }}
        </span>
      </div>

      <hr class="border-1 border-outlineGray my-3" />

      <ComplianceTransactionHistory v-if="activeFilter == 'transaction'" />
      <ComplianceRegistrationHistory
        v-if="activeFilter == 'registration'"
        :data="regDetails"
      />
    </div>
  </main>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { useDataStore } from "@/stores/data.js";

import ComplianceTransactionHistory from "@/components/Compliance/ComplianceTransactionHistory.vue";
import ComplianceRegistrationHistory from "@/components/Compliance/ComplianceRegistrationHistory.vue";
import Filter from "@/components/Filters/ComplianceFilter.vue";
import ComplianceSearch from "@/components/Search/ComplianceSearch.vue";
import ComplianceActions from "@/components/Compliance/ComplianceActions.vue";

const store = useDataStore();
const loading = ref(false);
const { query, mutate } = store;
const employeeCompliance = computed(() =>
  store.getEmployeeCompliance ? store.getEmployeeCompliance : []
);
const complianceData = computed(() => {
  const data =
    employeeCompliance.value &&
    employeeCompliance.value.length &&
    employeeCompliance.value.map((comp) => {
      return {
        dateOfRegistration: comp.createdAt,
        typeOfRegistration: comp.registrationType,
        employee: comp.employee,
        employer: comp.employer,
        status: "pending",
      };
    });
  return data;
});

const visibleData = ref([]);
const filterData = ref({
  to: null,
  names: null,
  from: null,
});

const regDetails = computed(() => store.getSingleComplianceReg);
const queryEmployeeCompliance = async () => {
  loading.value = true;
  try {
    await query({
      endpoint: "GetComplianceRegistration",
      payload: {
        input: filterData.value,
      },
      service: "PAYROLL",
      storeKey: "listComplianceRegistration",
    });

    loading.value = false;
  } catch (err) {
    loading.value = false;
    console.log(err);
  }
};
queryEmployeeCompliance();
watch(complianceData, (val) => {
  visibleData.value = val;
});

function searchArrayObjects(arr, searchString) {
  return arr.filter((item) => {
    return item.employee.toLowerCase().includes(searchString);
  });
}

const activeFilter = ref("transaction");
const activeRegFilter = ref("all");
const filterOpts = ref([
  {
    label: "All transaction history",
    value: "transaction",
  },
  {
    label: "All registration history",
    value: "registration",
  },
]);

const registrationFilterOpts = ref([
  {
    label: "All (1)",
    value: "all",
  },
  {
    label: "success (0)",
    value: "success",
  },

  {
    label: "processing (0)",
    value: "processing",
  },
  {
    label: "failed (0)",
    value: "failed",
  },
]);

async function filterHistory(stat) {
  activeFilter.value = stat;
}

async function filterRegHistory(stat) {
  activeRegFilter.value = stat;
}

const viewEmployeeFilterRef = ref(null);

const activeFilters = ref([]);
const filterFN = async ({ filter, active }) => {
  // console.log(filter, active);
  if (active.length) {
    const isSortActive =
      Object.keys(filter.sort) && Object.keys(filter.sort).length
        ? true
        : false;
    activeFilters.value = active;
    if (isSortActive) {
      visibleData.value = sortArray(visibleData.value, filter.sort.firstName);
    }

    if (filter && filter.filter && filter.filter.length) {
      visibleData.value = complianceData.value;
      visibleData.value = filterByType(visibleData.value, filter.filter);
    }
  } else {
    visibleData.value = complianceData.value;
  }
};

const removeFilter = (arg) => {
  console.log(viewEmployeeFilterRef.value);
  if (viewEmployeeFilterRef.value && viewEmployeeFilterRef.value.removeFilter) {
    viewEmployeeFilterRef.value.removeFilter(arg);
  }
};

const searchText = ref("");
function searchEmployees(e) {
  console.log(e, "SS");
  searchText.value = e.toLowerCase();
  if (
    searchText.value &&
    searchText.value !== undefined &&
    searchText.value.length
  ) {
    visibleData.value = complianceData.value;
    visibleData.value = searchArrayObjects(visibleData.value, searchText.value);
  } else {
    visibleData.value = complianceData.value;
  }
}
</script>

<style></style>
