<template>
  <main class="p-4 md:px-10">
    <div class="flex md:block items-center justify-between mx-4">
      <easiBackButton class="md:hidden"></easiBackButton>
      <h4
        class="md:mt-3 md:text-sm md:text-left text-right text-xs font-medium text-dark-600 md:text-dark-800"
      >
        Compliance / View Compliance
      </h4>
      <div class="md:block hidden text-left my-6">
        <easiBackButton></easiBackButton>
      </div>
    </div>
    <div
      class="text-right md:text-left md:mt-9 font-bold text-sm hidden md:flex gap-x-9"
    >
      <p class="text-dark-800 text-2xl">View Compliance</p>
    </div>

    <div
      v-if="
        (!loading && !allCompliance) ||
        (allCompliance && !Object.keys(allCompliance).length) ||
        emptyArr
      "
      class="mx-20 flex flex-col flex-col-reverse md:flex-row items-center justify-center"
    >
      <easiButton
        variant="outline-secondary"
        class="mt-4 md:mt-44"
        @click="$router.push({ name: 'AddCompliance' })"
        >Add Compliance</easiButton
      >
      <easiEmptyState text="You have not added any compliance yet." />
    </div>

    <!-- <EmptyTable v-if="!isSearch && allEmployees.length" /> -->

    <div
      v-if="!emptyArr"
      class="flex md:justify-between md:flex-row flex-col gap-y-6 md:gap-y-0 w-full md:items-start"
    >
      <div
        class="flex md:items-start gap-x-4 md:gap-x-0 mt-4 w-full md:w-4/5 grow-0 gap-y-4 md:gap-y-0"
      >
        <!-- /////// Search Component ///// -->
        <div class="w-full md:w-8/12">
          <!-- <easiSearch
              placeholder="Search employee"
              v-model="searchText"
              @search="searchFN"
            /> -->
          <ComplianceSearch
            placeholder="Search Compliance"
            v-model="searchText"
            @keyup="searchFN($event)"
          />
        </div>

        <!-- /////// Filter Component Start /////// -->
        <div class="hidden md:ml-4">
          <BonusFilter @update="filterFN" />
        </div>
        <!-- /////// Filter Component Ends /////// -->
      </div>
      <div class="self-center">
        <easiButton
          @click="$router.push({ name: 'EditCompliance' })"
          color="primary"
          class="flex items-center gap-3 py-2 text-white text-xs md:text-base"
        >
          <img class="w-4 md:w-5" src="@/assets/icons/white-pen.svg" alt="" />
          Edit Compliance
        </easiButton>
      </div>
    </div>

    <div class="md:mx-20 md:mt-8">
      <div v-if="loading" class="w-full">
        <easiLoader />
      </div>
      <ComplianceTable
        :data="complianceData"
        :highlight="['compliance']"
        :amountValues="[]"
        :useForOption="'bonusName'"
        :header="headers()"
        :readonly="true"
        :routable="true"
        :empty="emptyArr"
        :view="true"
        :is-search="isSearch"
        @deleted="queryCompliance"
      />
    </div>
  </main>
</template>

<script setup>
import { ref, reactive, onMounted, computed, watch } from "vue";
import { storeToRefs } from "pinia";
import { useToast } from "vue-toastification";

import { useDataStore } from "@/stores/data.js";
import EditBonus from "@/components/Payroll/Edit/BonusAndDed/BonusAndDed.vue";
import BonusFilter from "@/components/Filters/bonusFilter.vue";
import ComplianceSearch from "@/components/Search/ComplianceSearch.vue";
import EmptyTable from "@/components/global/emptyTable.vue";
import ComplianceTable from "@/components/global/ComplianceTable.vue";

import { helperFunctions } from "@/composable/helperFunctions";

const store = useDataStore();
const { query, mutate } = store;
const { listEmployees, getAllEmployees, getCompliance, getBonusorDed } =
  storeToRefs(store);
const toast = useToast();

// import BonusAndDed from "@/components/Payroll/View/BonusAndDed/BonusAndDed";

const { formatDateString } = helperFunctions;
const loading = ref(false);

const emptyArr = ref(false);
function empty() {
  const deleteLength = complianceData.value.length - 3;
  complianceData.value.splice(0, deleteLength);
  console.log(complianceData.value);
  emptyArr.value = true;
}

const allCompliance = computed(() =>
  getCompliance.value ? getCompliance.value.data : {}
);
const allEmployees = computed(() =>
  getAllEmployees.value ? getAllEmployees.value.data : []
);
function matchStatutoryDeds(arr) {
  const data = arr.map((item) => {
    if (item === "paye") return "PAYE";
    if (item === "pension") return "Pension";
    if (item === "nhf") return "NHF";
    if (item === "nsitf") return "NSITF";
    if (item === "itf") return "ITF";
    if (item === "wht") return "WHT";
    if (item === "lifeInsurance") return "Group_Life_Insurance";
    if (item === "lifeAssurance") return "Employee_Life_Assurance";
    if (item === "hmo") return "HMO";
    if (item === "businessExpense") return "Business_Expense";
    if (item === "gratuity") return "Gratuity";
  });
  return data;
}

function getStaffLevelIdFromEmployeeId(id) {
  if (allEmployees.value.length) {
    const data = allEmployees.value.find((emp) => emp._id === id);
    const sl_id = (data && data.staffLevel._id) || "";
    return sl_id;
  }
}
const complianceData = ref([]);
const staticData = ref([]);
const queryCompliance = async () => {
  loading.value = true;
  try {
    await query({
      endpoint: "ListCompliance",
      // payload: payload.value,
      service: "PAYROLL",
      storeKey: "listCompliance",
    });
    const keys = Object.keys(allCompliance.value);
    const data = keys.map((k) => {
      return {
        id: k,
        compliance: k,
        employees: allCompliance.value[k].employeeCount,
        totalAmount: allCompliance.value[k].totalAmount,
        staffLevelId: allCompliance.value[k].employees.map((emp) =>
          getStaffLevelIdFromEmployeeId(emp.employeeId)
        ),
        benefitEnum: matchStatutoryDeds([k]),
      };
    });
    complianceData.value = data.filter((comp) => Number(comp.totalAmount) >= 0);
    staticData.value = data.map((comp) => comp);
    console.log(complianceData.value, "comp");

    loading.value = false;
  } catch (err) {
    loading.value = false;
    console.log(err);
  }
};
queryCompliance();
const searchText = ref("");
let isSearch = ref(false);
const filterOptions = ref({
  sort: { firstName: "ASC" },
  filter: [],
});
const currentPage = ref(0);
const pageSize = ref(15);
let visibleData = ref([]);

const payload = ref({
  paging: {
    limit: 1000,
    skip: 0,
    search: "",
    sort: {},
  },
  filter: [],
});

const queryEmployees = async () => {
  loading.value = true;
  try {
    await query({
      endpoint: "ListEmployees",
      payload: payload.value,
      service: "EMP",
      storeKey: "listEmployees",
    });

    loading.value = false;
    console.log("Employees");
    log(getAllEmployees.value.data);
  } catch (err) {
    loading.value = false;
    console.log(err);
  }
};
// queryBonusOrDed();
queryEmployees();

const headers = () => {
  if (complianceData.value.length > 0) {
    let headArr = [];
    const head = Object.keys(complianceData.value[0]);
    for (let i of head) {
      headArr.push({
        field: i,
        header: i,
      });
    }

    return headArr;
  }
};

const loadingProgress = ref(false);
const updateSuccess = ref(false);

const bonus = ref(true);
function searchByCompliance(data, compliance) {
  return data.filter((obj) => obj.compliance.includes(compliance));
}
const searchFN = (e) => {
  console.log(searchText.value, "model", e.target.value);
  if (e.key === "Backspace") {
    complianceData.value = staticData.value;
  }
  if (searchText.value.length) {
    complianceData.value = searchByCompliance(
      complianceData.value,
      e.target.value
    );
  } else {
    complianceData.value = staticData.value;
  }
};

const filterFN = (arg) => {
  isSearch.value = true;
  filterOptions.value = arg;

  bonusOrDedPayload.value.sort = filterOptions.value.sort;
  bonusOrDedPayload.value.filter = filterOptions.value.filter;

  console.log("Payload");

  queryBonusOrDed();
};

function updateSelectedEmployees(arg) {
  selectedEmployees.value = [];
  selectedEmployees.value = arg;
}

// Get rid of the proxy when console.log
function log(arg) {
  console.log(JSON.parse(JSON.stringify(arg)));
}

const updatePage = (pageNumber) => {
  currentPage.value = pageNumber;
  updateVisibleData();
};
const updateVisibleData = () => {
  let datas = complianceData.value && complianceData.value;
  visibleData.value = datas.slice(
    currentPage.value * pageSize.value,
    currentPage.value * pageSize.value + pageSize.value
  );

  // if we have 0 visible todos, go back a page
  if (visibleData.value.length == 0 && currentPage.value > 0) {
    updatePage(currentPage.value - 1);
  }
};

onMounted(async () => {
  // empty(); //remove later when endpoint is ready
  await queryEmployees();
  // updateVisibleData();

  // visibleData.value = employees;
});
</script>

<style></style>
