<template>
  <main class="w-full">
    <div
      class="md:w-5/6 w-full py-10 md:mx-auto bg-white rounded-2xl md:rounded-2xl mt-10 mb-12 text-left border border-boxBorder md:px-10 px-3 md:shadow-lg"
    >
      <div class="mb-8 flex items-center justify-between">
        <h2
          class="text-left ml-2 md:ml-0 capitalize text-headerText font-bold text-lg sm:text-lg md:text-2xl"
        >
          {{ "PAYE" }}
        </h2>

        <easiButton
          size="small"
          @click="switchView"
          color="primary"
          class="py-1"
          variant="outlined"
        >
          <span class="flex items-center text-base gap-3">
            <img class="w-4" src="@/assets/icons/edit2.svg" alt="" />
            Edit
          </span>
        </easiButton>
      </div>

      <div
        class="w-full md:flex flex-wrap grid grid-cols-2 gap-4 items-center md:justify-start mx-2 md:mx-0 md:gap-x-8 xl:gap-x-12"
      >
        <!-- <div class="flex flex-col">
          <span class="text-xs text-dark-500 capitalize">Partner</span>
          <span class="text-xs md:text-sm text-dark-800 font-medium"
            >Ikeja Tax Office</span
          >
        </div> -->
        <!-- <div class="flex flex-col">
          <span class="text-xs text-dark-500">Percentage</span>
          <span class="text-xs md:text-sm text-dark-800 font-medium">{{ 10 }}</span>
        </div> -->
        <!-- <div class="flex flex-col">
          <span class="text-xs text-dark-500">Plan</span>
          <span class="text-xs md:text-sm text-dark-800 font-medium">{{ "Gold" }}</span>
        </div> -->
        <!-- <div class="flex flex-col">
          <span class="text-xs text-dark-500">Duration</span>
          <span class="text-xs md:text-sm text-dark-800 font-medium">{{
            returnDuration("MONTHLY")
          }}</span>
        </div> -->
        <!-- <div class="flex flex-col">
          <span class="text-xs text-dark-500">Date Created</span>
          <span class="text-xs md:text-sm text-dark-800 font-medium">{{
            formatDateString("2022-12-09")
          }}</span>
        </div> -->
      </div>
      <hr class="my-4 text-dividerColor" />
      <h3 class="text-xs text-dark-500 md:ml-0 ml-3">Number of Employee</h3>
      <span class="text-sm md:ml-0 ml-3 text-dark-800 font-bold">{{
        data.employeeCount
      }}</span>

      <div
        class="flex flex-wrap md:mx-6 mr-3 md:mr-0 md:mx-0 md:gap-2 gap-4 mt-7 mb-7 justify-start items-center"
      >
        <div
          v-for="emp in data.employees"
          :key="emp.employeeId"
          class="flex flex-col gap-3 rounded-lg px-2 py-0.5 text-center bg-highlight border border-primary text-xs w-full md:w-auto"
        >
          <div class="flex items-center flex-nowrap justify-start mt-2 gap-x-3">
            <span
              class="w-6 h-6 inline-flex flex-none items-center justify-center rounded-full"
              :style="`background-color:${
                initials(`${emp.firstName} ${emp.lastName}`).color
              }`"
            >
              <span class="shrink-0 text-white text-xs font-medium">{{
                initials(`${emp.firstName} ${emp.lastName}`).str
              }}</span></span
            >

            <span class="grow cursor-pointer text-xs">
              {{ `${emp.firstName} ${emp.lastName}` }}</span
            >
          </div>

          <easiTextInput
            placeholder="Amount"
            type="text"
            :format="true"
            name="amount"
            :value="formatAmount(emp.paye.amount)"
            class="mt-1 mb-1"
            readonly
            v-model="payeAmount"
            :emp="true"
            required
          ></easiTextInput>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import { ref, reactive, nextTick, watch, computed, onMounted } from "vue";
import { useDataStore } from "@/stores/data.js";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import { helperFunctions } from "@/composable/helperFunctions";

const { formatAmount, formatDateString } = helperFunctions;
const store = useDataStore();
const route = useRoute();
const { query, set } = store;
const { getAllEmployees, checkLoading, getCompliance } = storeToRefs(store);

const allCompliance = computed(() =>
  getCompliance.value ? getCompliance.value.data : {}
);
const data = computed(() => allCompliance.value["paye"]);

const allEmployees = computed(() => getAllEmployees.value.data);
const bonusOrDed = computed(() => store.getBonusOrDedById(route.params.id));
const queryOption = computed(() => store.getBonusorDed);
console.log("B>D", bonusOrDed.value);

const emit = defineEmits(["updateView", "args"]);
const loading = ref(false);
const employeeIds = computed(() => allEmployees.value.map((emp) => emp._id));

const switchView = () => {
  emit("updateView", true);
};

const props = defineProps({
  data: {
    type: Array,
    default: [],
  },
});
const bonusOrDedPayload = ref({
  paging: {
    limit: 1000,
    skip: 0,
    search: "",
  },
  type: queryOption.value,
});
const payload = ref({
  paging: {
    limit: 1000,
    skip: 0,
    search: "",
    sort: { firstName: "ASC" },
  },
  filter: [],
});
const payeAmount = ref(formatAmount(12800000));

const optionData = ref([]);
const createEmpList = () => {
  const list = allEmployees.value.map((emp) => {
    return {
      label: `${emp.profile.firstName} ${emp.profile.lastName}`,
      value: emp._id,
    };
  });
  return list;
};

const totalEmp = createEmpList();
optionData.value = totalEmp;

function getLabelFromValue(value) {
  if (value) {
    const label = optionData.value.filter((item) => {
      return item.value === value;
    });
    if (label) {
      return label[0].label;
    }
    return "Mike Snow";
  }
}
const returnDuration = (text) => {
  switch (text) {
    case "WEEKLY":
      return "Weekly";

    case "MONTHLY":
      return "Monthly";

    case "ANNUALLY":
      return "Annually";

    case "ONE_TIME":
      return "One-Time";

    default:
      return "N/A";
  }
};

function getColor(index) {
  let n = (index * 0xfffff * 1000000).toString(16);
  return "#" + n.slice(0, 6);
}

const initials = computed(() => {
  return (name) => {
    let color;
    // Get color

    const arr = name.split(" ");
    let alphabet = "abcdefghijklmnopqrstuvwxyz".split("");
    const initialOne = String(arr[0]).charAt(0);
    const initialTwo = String(arr[1]).charAt(0);
    const num =
      Number(alphabet.indexOf(initialOne.toLowerCase())) +
      Number(alphabet.indexOf(initialTwo.toLowerCase()));
    color = getColor(num);
    const str = `${arr[0].charAt(0).toUpperCase()}${arr[1]
      .charAt(0)
      .toUpperCase()}`;
    return { str, color };
  };
});

const queryEmployees = async () => {
  //   loading.value = true;
  try {
    await query({
      endpoint: "ListEmployees",
      payload: payload.value,
      service: "EMP",
      storeKey: "listEmployees",
    });

    // loading.value = false;
    console.log("Employees");
  } catch (err) {
    // loading.value = false;
    console.log(err);
  }
};
const queryBonusOrDed = async () => {
  loading.value = true;
  try {
    await query({
      endpoint: "ListBonusAndDeduction",
      payload: {
        input: bonusOrDedPayload.value,
      },
      service: "PAYROLL",
      storeKey: "listBonusAndDeduction",
    });
    // console.log(getBonusAndDeduction.value, "After query");
    loading.value = false;
  } catch (err) {
    loading.value = false;
    console.log(err);
  }
};
onMounted(() => {
  queryBonusOrDed();
  queryEmployees();
});
</script>

<style></style>
