<template>
  <main class="w-full bg-white rounded-2xl p-4 px-8 shadow-lg">
    <div class="md:grid grid-cols-2 my-16">
      <div
        class="relative border-b md:border-r md:border-b-0 border-outlineGray h-36 flex flex-col items-end justify-end"
      >
        <div class="absolute left-28 md:left-9 -top-1 md:-top-4">
          <p>Minimum salary</p>
          <div class="text-secondary text-xl font-bold">
            {{
              payrollAnalysis &&
              payrollAnalysis.bySalary &&
              formatAmount(payrollAnalysis.bySalary.min)
            }}
          </div>
          <div class="text-dark-800 font-medium text-sm">
            {{
                payrollAnalysis.bySalary &&
                payrollAnalysis.bySalary.minEmp.title
                  ? payrollAnalysis.bySalary.minEmp.title
                  : "N/A"
              }}
              {{
                payrollAnalysis.bySalary &&
                payrollAnalysis.bySalary.minEmp.firstName
                  ? payrollAnalysis.bySalary.minEmp.firstName
                  : "N/A"
              }}
              {{
                payrollAnalysis.bySalary &&
                payrollAnalysis.bySalary.minEmp.lastName
                  ? payrollAnalysis.bySalary.minEmp.lastName
                  : "N/A"
              }}
          </div>
          <div class="text-center text-dark-800 text-xs">
            {{
              payrollAnalysis.bySalary &&
              payrollAnalysis.bySalary.minEmp.lastName
                ? payrollAnalysis.bySalary.minEmp.jobTitle
                : "N/A"
            }}
          </div>
        </div>
        <div class="leastGradient rounded-l-full h-8 w-40 mb-5 md:mb-0 mx-auto md:mx-0 md:w-52 flex items-center">
          <img src="@/assets/icons/star.svg" class="ml-4" />
        </div>
      </div>

      <div class="relative h-32 md:h-24 flex flex-col items-start justify-end md:justify-start">
        <div class="absolute right-20 md:right-9 bottom-11 md:-bottom-6">
          <p class="">Maximum salary</p>
         
          <div class="text-primary text-xl font-bold">
            {{
              payrollAnalysis &&
              payrollAnalysis.bySalary &&
              formatAmount(payrollAnalysis.bySalary.max)
            }}
          </div>
          <div class="text-dark-800 font-medium text-sm">
            {{
                payrollAnalysis.bySalary &&
                payrollAnalysis.bySalary.maxEmp.title
                  ? payrollAnalysis.bySalary.maxEmp.title
                  : "N/A"
              }}
              {{
                payrollAnalysis.bySalary &&
                payrollAnalysis.bySalary.maxEmp.firstName
                  ? payrollAnalysis.bySalary.maxEmp.firstName
                  : "N/A"
              }}
              {{
                payrollAnalysis.bySalary &&
                payrollAnalysis.bySalary.maxEmp.lastName
                  ? payrollAnalysis.bySalary.maxEmp.lastName
                  : "N/A"
              }}
          </div>
          <div class="text-center text-dark-800 text-xs">
            {{
              payrollAnalysis.bySalary &&
              payrollAnalysis.bySalary.maxEmp.lastName
                ? payrollAnalysis.bySalary.maxEmp.jobTitle
                : "N/A"
            }}
          </div>
        </div>
        <div
          class="maxGradient rounded-r-full h-8 w-40 md:w-52 mx-auto  md:mx-0 flex items-center justify-end"
        >
          <img src="@/assets/icons/star.svg" class="mr-4" />
        </div>
      </div>
    </div>
    <!-- <div v-for="(item, i) in minmax" :key="i" class="text-left my-2">
      <h4 class="text-lg capitalize">{{ item.title }}</h4>
      <div
        :style="item.color2"
        class="relative flex items-center rounded-2xl my-6 px-4 py-2 pl-24 ml-2"
      >
        <div
          :style="item.color1"
          class="absolute -left-6 top-1/12 rounded-full w-28 h-28 md:w-24 md:h-24 flex flex-wrap items-center justify-center"
        >
          <span
            style="color: rgba(255, 255, 255, 0.58)"
            class="text-xl uppercase md:text-2xl font-bold"
          >
            {{ item.slug }}</span
          >
        </div>

        <div
          class="w-full flex flex-col sm:flex-row md:flex-col lg:flex-row items-center justify-between"
        >
          <span class="text-white font-bold text-lg md:text-xl">
            {{ formatAmount(item.amount) }}
          </span>

          <div class="rounded-xl border border-dark-400 bg-red-100 py-2 px-4">
            <div class="flex items-center gap-x-2">
              <div class="flex gap-x-2 items-center">
                <img
                  v-if="item.employee && item.employee.pfp"
                  alt="..."
                  class="w-full h-full object-cover rounded-full align-middle border-none shadow-lg"
                  :src="item.employee && item.employee.pfp"
                />
                <div
                  v-else
                  :style="`background-color:${getAvatar(item.employee).color}`"
                  :class="
                    !showAll
                      ? 'pt-1 pl-1 pr-1 md:p-2  md:h-9 md:w-9 w-7 h-7'
                      : 'p-2  md:h-9 md:w-9 w-7 h-7'
                  "
                  class="text-xs md:text-sm text-white text-center font-medium rounded-full uppercase"
                >
                  {{ getAvatar(item.employee).name }}
                </div>
              </div>
              <div class="flex flex-col">
                <span class="text-dark-800 font-bold capitalize"
                  >{{
                    item.employee && item.employee.profile.title
                      ? item.employee.profile.title
                      : "N/A"
                  }}
                  {{
                    item.employee && item.employee.profile.firstName
                      ? item.employee.profile.firstName
                      : "N/A"
                  }}
                  {{
                    item.employee && item.employee.profile.lastName
                      ? item.employee.profile.lastName
                      : "N/A"
                  }}</span
                >
                <span class="capitalize text-xs -mt-1">{{
                  item.employee && item.employee.job.jobTitle
                    ? item.employee.job.jobTitle
                    : "N/A"
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </main>
</template>

<script setup>
import { ref, reactive, onMounted, watch, computed } from "vue";
import { storeToRefs } from "pinia";
import { useDataStore } from "@/stores/data.js";
import { helperFunctions } from "@/composable/helperFunctions";
import { useToast } from "vue-toastification";

const { formatAmount, calculatePercentage, getAvatar } = helperFunctions;
const toast = useToast();
const store = useDataStore();
const { mutate, query } = useDataStore();

const payrollAnalysis = computed(() => store.getPayrollAnalysis);

const transformEmp = (emp) => {
  if (emp) {
    return {
      department: emp.department,
      profile: {
        firstName: emp.firstName,
        lastName: emp.lastName,
        email: emp.email,
        pfp: emp.pfp,
        title: emp.title,
      },
      job: {
        userName: emp.userName,
        jobTitle: emp.jobTitle,
        employmentType: emp.employmentType,
      },
      _id: emp.employeeId,
    };
  }
};

const minmax = computed(() => {
  return [
    {
      color1: `background: radial-gradient(50% 50% at 50% 50%, #F1028E 0%, #AD013B 100%);`,
      color2: `background: linear-gradient(90deg, #A2002D 0.7%, #F1028E 95.61%);`,
      title: "Minimum Salary",
      slug: "Min",
      amount:
        payrollAnalysis.value &&
        payrollAnalysis.value.bySalary &&
        payrollAnalysis.value.bySalary.min,
      employee: transformEmp(
        payrollAnalysis.value &&
          payrollAnalysis.value.bySalary &&
          payrollAnalysis.value.bySalary.minEmp
      ),
    },
    {
      color1: `background: radial-gradient(50% 50% at 50% 50%, #059EE8 0%, #014F9F 100%);`,
      color2: `background: linear-gradient(90deg, #004DA6 1.29%, #059EE8 92.25%);`,
      title: "Maximum Salary",
      slug: "Max",
      amount:
        payrollAnalysis.value &&
        payrollAnalysis.value.bySalary &&
        payrollAnalysis.value.bySalary.max,
      employee: transformEmp(
        payrollAnalysis.value &&
          payrollAnalysis.value.bySalary &&
          payrollAnalysis.value.bySalary.maxEmp
      ),
    },
  ];
});
</script>

<style scoped>
.leastGradient {
  background: linear-gradient(90deg, #ea4e4b 0%, #f5d5d2 100%);
}
.maxGradient {
  background: linear-gradient(240deg, #11453b -3.01%, #d9ebcd 100%);
}
</style>
