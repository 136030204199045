<template>
  <main>
    <div v-if="type === 'add'" class="flex flex-col gap-4 items-center mt-6">
      <h2 class="font-bold text-sm text-dark-800">
        Please Select Employee(s) To Add Gratuity
      </h2>
    </div>
    <hr
      v-if="type === 'add'"
      class="mt-6 mb-5 border-0.5 border-dividerColor text-dividerColor"
    />

    <form
      @submit.prevent="addGratuity"
      autocomplete="on"
      class="flex flex-col text-left"
    >
      <div class="px-3 sm:px-8 lg:px-5 xl:px-7">
        <label for="email" class="text-dark-800 text-sm font-medium"
          >Please Select Employee
          <span class="text-xs text-error tracking-wide"> *</span></label
        >
        <easiEmployeeBonus
          required
          :pension="true"
          @update="employeeIds = $event"
          :value="employeeIds"
          :options="createEmpList()"
          :compliance="true"
          @remove="removedEmployees = $event"
          class="mt-1 mb-8"
        />

        <div v-if="employeeIds.length">
          <div
            v-show="employeeIds.length"
            class="max-w-100 pt-5 md:pt-0 md:-mt-0 bg-none -mt-6"
          >
            <div
              class="flex flex-wrap gap-4 mt-1 mb-5 justify-start items-center"
            >
              <div
                v-for="opt in employeeIds"
                :key="opt"
                class="flex flex-col gap-3 border-primary w-full md:w-auto rounded-lg px-2 pb-4 pt-0.5 text-center bg-highlight border text-xs"
              >
                <div
                  class="flex items-center justify-center mt-2 flex-nowrap gap-x-3"
                >
                  <span
                    @click="update(opt)"
                    class="flex-none w-6 h-6 inline-flex items-center justify-center rounded-full"
                    :style="`background-color:${
                      initials(getLabelFromValue(opt)).color
                    }`"
                  >
                    <span class="shrink-0 text-white text-xs font-medium">{{
                      initials(getLabelFromValue(opt)).str
                    }}</span></span
                  >

                  <span class="grow cursor-pointer text-xs">
                    {{ getLabelFromValue(opt) }}</span
                  >
                  <img
                    class="flex-none cursor-pointer"
                    @click="update(opt)"
                    src="@/assets/icons/remove.svg"
                    alt=""
                  />
                </div>

                <div class="text-left">
                  <!-- <label
                    for="lastDrawn"
                    class="text-left text-dark-800 text-xs font-medium"
                    >Last Drawn Amount
                  </label> -->
                  <easiTextInput
                    v-model="gratObj[opt].salaryAmount"
                    type="text"
                    :format="true"
                    name="lastamount"
                    class="mt-1 mb-3 md:mb-3"
                    placeholder="Last Drawn Amount"
                    required
                    :emp="true"
                  ></easiTextInput>

                  <!-- <label
                    for="years"
                    class="text-left text-dark-800 text-xs font-medium"
                    >Years worked
                  </label> -->
                  <easiTextInput
                    v-model="gratObj[opt].yearsWorked"
                    type="text"
                    :format="false"
                    name="amount"
                    class="mt-1 mb-3 md:mb-3"
                    placeholder="Years worked"
                    required
                    :emp="true"
                  ></easiTextInput>

                  <!-- <label
                    for="amount"
                    class="text-left text-dark-800 text-xs font-medium"
                    >Amount
                  </label> -->
                  <easiTextInput
                    v-model="gratObj[opt].amount"
                    placeholder="Amount"
                    type="text"
                    :format="true"
                    name="amount"
                    class="mt-1 mb-3 md:mb-3"
                    required
                    :emp="true"
                  ></easiTextInput>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>

      <hr
        v-if="type === 'add'"
        class="mb-5 border-0.5 border-dividerColor text-dividerColor"
      />
      <div v-if="type === 'add'" class="px-3 md:px-12 lg:px-8 xl:px-10">
        <button
          class="focus:outline-none text-sm mt-2 py-3 w-full px-4 md:mx-auto justify-center bg-primary rounded-full text-white"
        >
          Add Gratuity
        </button>
      </div>
      <div
        v-if="type === 'edit' || edit === true"
        class="px-4 md:px-0 w-full my-6 flex md:flex-row flex-col md:justify-center md:justify-items-center md:place-items-center gap-5 md:items-center"
      >
        <easiButton
          @click="$router.go(-1)"
          type="button"
          color="primary"
          variant="outlined"
          class="rounded-full bg-white w-full md:w-52"
          :block="true"
        >
          <span class="text-sm text-dark-800"> Cancel</span>
        </easiButton>
        <easiButton
          :loading="loading"
          :block="true"
          color="primary"
          class="rounded-full w-full md:w-52"
        >
          <span class="text-sm"> Update </span>
        </easiButton>
      </div>

      <p
        @click="deleteWarning = true"
        v-show="type === 'edit' || edit === true"
        class="cursor-pointer text-center text-sm font-medium text-dark-800 my-4"
      >
        Delete Gratuity
      </p>
    </form>

    <easiLoading v-if="loadingProgress" @close="loadingProgress = false">
      <template v-slot:heading>
        <h1>Calculating Pension</h1>
      </template>
    </easiLoading>

    <easiModal
      v-if="preview"
      @close="preview = false"
      :isHeader="false"
      :isBorder="false"
      :lite="false"
    >
      <h1 class="uppercase text-dark-800 text-2xl font-medium p-6">
        preview Pension
      </h1>
      <hr class="mx-16 mb-5 border-0.5 border-dividerColor text-dividerColor" />

      <div v-if="tableData.length" class="w-full mag">
        <easiTable
          :lite="false"
          :data="tableData"
          :header="configureHeaders"
          :mapData="bankCodes"
          :highlight="['firstName', 'lastName']"
          :readonly="true"
        ></easiTable>
      </div>

      <div class="px-3 md:px-12 lg:px-8 xl:px-10">
        <button
          @click="preview = false"
          class="focus:outline-none text-sm mt-2 py-3 w-full px-4 md:mx-auto justify-center bg-primary rounded-full text-white"
        >
          Add Pension
        </button>
      </div>
    </easiModal>

    <easiWarn v-if="deleteWarning" @close="deleteWarning = false">
      <template v-slot:message>
        <span>Are you sure you want to delete Gratuity?</span>
      </template>
      <template v-slot:action>
        <div class="flex flex-col items-center gap-4">
          <div class="flex justify-center items-center w-full gap-x-4 mt-6">
            <easiButton
              class="w-40 min-w-full"
              @click="deleteWarning = false"
              variant="outlined"
              >Cancel</easiButton
            >
            <easiButton
              class="w-40 min-w-full"
              :loading="loading"
              @click="deleteDeduction('deleteGeneral')"
              >Yes</easiButton
            >
          </div>
        </div>
      </template>
    </easiWarn>

    <easiRoundLoader v-if="loading" />

    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span>{{ successMessage }}</span>
      </template>
    </easiSuccess>
  </main>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
import { ref, reactive, nextTick, watch, computed, onMounted } from "vue";
import { useToast } from "vue-toastification";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";
import IndustryList from "@/utils/Industry";
import pensionProviders from "@/utils/pensionProviders";

import UploadComponent from "@/components/global/UploadComponent";
import NigerianStates from "@/utils/states";
import lgData from "@/utils/localGovt";
import Countries from "@/utils/countries";

import payeIcon from "@/assets/icons/paye-icon.svg";
import hmoIcon from "@/assets/icons/hmo-icon.svg";
import itfIcon from "@/assets/icons/itf-icon.svg";
import nsitfIcon from "@/assets/icons/nsitf-icon.svg";
import pensionIcon from "@/assets/icons/pension-icon.svg";
import nhfIcon from "@/assets/icons/nhf-icon.svg";

const store = useDataStore();
const { query, mutate } = store;
const { listEmployees, getAllEmployees } = storeToRefs(store);
const toast = useToast();
const { resetObj, processDate, formatAmount } = helperFunctions;

const props = defineProps({
  type: {
    type: String,
    default: "add",
  },
  data: {
    type: Object,
    default: {},
  },
});
const emit = defineEmits(["registered"]);
const successMessage = ref("");
const updateSuccess = ref("");
const loading = ref(false);
const edit = ref(false);
const removedEmployees = ref([]);

const gratObj = ref({});

const deleteWarning = ref(false);
const route = useRoute();
const router = useRouter();
const employer = ref(true);
const industryData = IndustryList.map((el) => el.name);
const uploadFile = ref(null);
const localGovtData = ref([]);
const selectedState = computed(() => args.value.state);
const agreement = ref(true);
const useTaxOffice = ref(true);
const isExpatriate = ref(false);
const hasTaxPayerId = ref(false);
const loadingProgress = ref(false);
const preview = ref(false);
const tableData = ref([]);
const employeeIds = ref([]);
const args = ref({
  employeeIds: [],
  chargeHmoToEmployee: false,
  contribution: false,
  industry: "",
  title: "",
  gender: "",
  maritalStatus: "",
  state: "",
  lga: "",
  country: "",
  taxOffice: "",
  pension: {
    provider: "",
    amount: 0,
    duration: "MONTHLY",
    plan: "BRONZE",
  },
});
const allEmployees = computed(() => getAllEmployees.value.data);

const payload = reactive({
  paging: {
    limit: 1000,
    skip: 0,
    search: "",
    sort: { firstName: "ASC" },
  },
  filter: [],
});

async function queryEmployees() {
  loading.value = true;
  try {
    await query({
      endpoint: "ListEmployees",
      payload: payload,
      service: "EMP",
      storeKey: "listEmployees",
    });
    let result = {};
    if (!Object.keys(props.data).length) {
      result = allEmployees.value.reduce(function (r, a) {
        r[a._id] = r[a._id] || {};
        r[a._id]["salaryAmount"] =
          a.staffLevel &&
          a.staffLevel.gratuity &&
          a.staffLevel.gratuity.salaryAmount
            ? a.staffLevel.gratuity.salaryAmount
            : "0";
        r[a._id]["yearsWorked"] =
          a.staffLevel &&
          a.staffLevel.gratuity &&
          a.staffLevel.gratuity.yearsWorked
            ? a.staffLevel.gratuity.yearsWorked
            : "";
        r[a._id]["amount"] =
          a.staffLevel && a.staffLevel.gratuity && a.staffLevel.gratuity.amount
            ? a.staffLevel.gratuity.amount
            : "";

        return r;
      }, {});
    } else {
      result = allEmployees.value.reduce(function (r, a) {
        r[a._id] = r[a._id] || {};
        r[a._id]["salaryAmount"] = 0;
        r[a._id]["yearsWorked"] = 0;
        r[a._id]["amount"] = 0;

        return r;
      }, {});
    }

    gratObj.value = result;

    if (props.data && Object.keys(props.data).length) {
      employeeIds.value = props.data.employees.map((emp) => emp.employeeId);

      for (let employee of props.data.employees) {
        gratObj.value[employee.employeeId].amount =
          employee.gratuity.amount || 0;
        gratObj.value[employee.employeeId].salaryAmount =
          employee.gratuity.salaryAmount || 0;
        gratObj.value[employee.employeeId].yearsWorked =
          employee.gratuity.yearsWorked;
      }
    }
    loading.value = false;
  } catch (e) {
    loading.value = false;
  }
}

onMounted(async () => {
  await queryEmployees();
});

function getColor(index) {
  let n = (index * 0xfffff * 1000000).toString(16);
  return "#" + n.slice(0, 6);
}
const initials = computed(() => {
  return (name) => {
    let color;
    // Get color

    const arr = name.split(" ");
    let alphabet = "abcdefghijklmnopqrstuvwxyz".split("");
    const initialOne = String(arr[0]).charAt(0);
    const initialTwo = String(arr[1]).charAt(0);
    const num =
      Number(alphabet.indexOf(initialOne.toLowerCase())) +
      Number(alphabet.indexOf(initialTwo.toLowerCase()));
    color = getColor(num);
    const str = `${arr[0].charAt(0).toUpperCase()}${arr[1]
      .charAt(0)
      .toUpperCase()}`;
    return { str, color };
  };
});
function getLabelFromValue(value) {
  if (value) {
    const label = createEmpList().filter((item) => {
      return item.value === value;
    });

    return label[0].label;
  }
  return "";
}
function formatValue(val) {
  const newVal = val.map((a) => {
    return getLabelFromValue(a);
  });
  return newVal;
}

function update(param) {
  if (param) {
    if (employeeIds.value.includes(param)) {
      let index = employeeIds.value.indexOf(param);
      if (index > -1) {
        employeeIds.value.splice(index, 1);
      }
      removedEmployees.value.push(param);
    } else {
      employeeIds.value.push(param);
      let index = removedEmployees.value.indexOf(param);
      if (index > -1) {
        removedEmployees.value.splice(index, 1);
      }
      nextTick();
    }

    // emit("update", optionArray.value);
  }
}

const configureHeaders = computed(() => {
  let headArr = [];
  const head = Object.keys(tableData.value[0]);
  // const head = headerKey.value;
  for (let i of head) {
    headArr.push({
      field: i,
      header: i,
    });
  }
  return headArr;
});

const createEmpList = () => {
  const list = allEmployees.value.map((emp) => {
    return {
      label: `${emp.profile.firstName} ${emp.profile.lastName}`,
      value: emp._id,
    };
  });
  return list;
};
const countryData = computed(() => {
  let countryArray = [];
  countryArray = Countries.map((country) => {
    return country.name;
  });
  return countryArray;
});
watch(
  selectedState,
  (value, newValue) => {
    console.log(value, "New Value");
    const lgs = lgData.find(
      (lg) => lg.state.toLowerCase() === value.toLowerCase()
    );
    localGovtData.value =
      lgs &&
      lgs.lgas.map((lg) => {
        return {
          label: lg,
          value: lg,
        };
      });
  },
  { deep: true }
);
const statesData = computed(() => {
  let stateArray = [];
  stateArray = NigerianStates.map((state) => {
    return {
      label: state,
      value: state,
    };
  });
  return stateArray;
});
const today = ref("");
today.value = processDate();

function closeFN() {
  emit("registered");
  router.push({ name: "ViewCompliance" });
}
function getStaffLevelIdFromEmployeeId(id) {
  if (allEmployees.value.length) {
    const data = allEmployees.value.find((emp) => emp._id === id);
    return data.staffLevel._id;
  }
}
async function deleteDeduction(operation = "deleteSingle") {
  let payload = {
    staffLevelIds: [],
    deduction: ["Gratuity"],
  };
  if (operation === "deleteGeneral") {
    loading.value = true;
    payload.staffLevelIds = props.data.employees.map((emp) =>
      getStaffLevelIdFromEmployeeId(emp.employeeId)
    );
  } else {
    payload.staffLevelIds = removedEmployees.value.map((id) =>
      getStaffLevelIdFromEmployeeId(id)
    );
  }

  try {
    let res = await mutate({
      endpoint: "DeleteDeductionsMultiple",
      service: "PAYROLL",
      data: {
        input: payload,
      },
    });
    if (res.success) {
      if (operation === "deleteGeneral") {
        loading.value = false;
        deleteWarning.value = false;
        successMessage.value = "Gratuity deleted successfully";
        updateSuccess.value = true;
        closeFN();
      }
      return true;
    }
    return false;
  } catch (e) {
    loading.value = false;
    deleteWarning.value = false;
    console.log(e);

    return false;
  }
}

async function addGratuity() {
  loading.value = true;
  let payload = [];
  if (removedEmployees.value.length) {
    const deleted = await deleteDeduction();
    if (deleted === false) {
      return false;
    }
  }

  for (let emp of employeeIds.value) {
    if (gratObj.value[emp] && gratObj.value[emp].amount) {
      payload.push({
        gratuity: {
          //  providerPin: null,
          salaryAmount: Number(gratObj.value[emp].salaryAmount),
          yearsWorked: Number(gratObj.value[emp].yearsWorked),
          amount: Number(gratObj.value[emp].amount),
        },
        employeeId: emp,
      });
    }
  }
  console.log(payload, "FINAL PLOAD");
  // return true;

  try {
    let res = await mutate({
      endpoint: "AddGratuityCompliance",
      data: { input: payload },
      service: "PAYROLL",
    });
    if (res.success) {
      console.log(res);
      successMessage.value = res.message;
      // removeFiles.value = true;
      // uploadFile.value = null;
      // uploadProofOfAddress.value = null;
      loading.value = false;
      // await queryEmployees();
      updateSuccess.value = true;

      setTimeout(() => {
        updateSuccess.value = false;
        closeFN();
      }, 1000);
    } else {
      loading.value = false;
      toast.error(res.message);
    }
    console.log(res);
  } catch (e) {
    console.log(e);
    loading.value = false;
  }
}

function hitEndpoint() {
  window.location.reload();
  //   loadingProgress.value = true;
  //   const empArr = allEmployees.value.filter((emp) =>
  //     args.value.employeeIds.includes(emp._id)
  //   );

  //   const data = empArr.map((emp, index) => {
  //     return {
  //       sn: index + 1,
  //       firstName: emp.profile.firstName,
  //       lastName: emp.profile.lastName,
  //       grossPay: formatAmount(emp.staffLevel.grossAmount),
  //       pension: emp.staffLevel.pension
  //         ? formatAmount(emp.staffLevel.pension.amount)
  //         : formatAmount(0),
  //     };
  //   });

  //   tableData.value = data;
  //   loadingProgress.value = false;
  //   preview.value = true;
}
console.log(props.data);

function updateLg(event) {
  console.log(event);
  args.value.state = event;
}
</script>

<style scoped>
.wallet {
  background: linear-gradient(89.6deg, #0a244f 0.35%, #c92f02 268.44%);
}
.mag {
  /* width: 100%; */
  zoom: 70%;
}
</style>
