<template>
  <main class="w-full text-left bg-white rounded-2xl shadow-lg">
    <div class="">
      <h2
        class="text-xl font-bold bg-dark-900 text-white p-4 rounded-t-2xl text-center"
      >
        Payroll Yearly Budget Versus Spend (2020 - 2022)
      </h2>
    </div>

    <div
      class="w-ful flex justify-end items-center text-xs gap-4 md:gap-8 mt-8 mb-2 px-4 md:px-8"
    >
      <span>
        <i style="color: #01aa8c" class="fas fa-circle mr-2"></i>
        Budget
      </span>
      <span>
        <i style="color: #231f1f" class="fas fa-circle mr-2"></i>
        Spend
      </span>
    </div>
    <div class="p-4 md:py-6 md:px-8">
      <div class="flex-auto">
        <div class="relative h-350-px">
          <canvas id="bar-chart"></canvas>
        </div>
      </div>

      <div class="my-6 flex items-center flex-wrap text-xs">
        <div v-for="(item, i) in 3" :key="i" class="flex items-center p-2">
          <div class="transform -rotate-90 border-b-2 border-blue-100 text-xs">
            {{ 2020 + i }}
          </div>
          <div class="flex flex-col">
            <span>B - {{ formatAmount(130456987.34) }}</span>
            <span>S - {{ formatAmount(130456987.34) }}</span>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import { ref, onMounted, watch, computed, nextTick } from "vue";

import { helperFunctions } from "@/composable/helperFunctions";

const { formatAmount } = helperFunctions;

onMounted(() => {
  runChart();
});

const config = computed(() => {
  return {
    type: "bar",
    data: {
      labels: ["2020", "2021", "2022"],
      datasets: [
        {
          label: "Budget",
          backgroundColor: "#01AA8C",
          data: [100, 78, 56, 34, 100, 45, 13],
          fill: false,
          // barThickness: 30,
        },
        {
          label: "Spend",
          fill: false,
          backgroundColor: "#231F1F",

          data: [27, 68, 86, 74, 10, 4, 82],
          // barThickness: 30,
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      responsive: true,
      title: {
        display: false,
        text: "Orders Chart",
      },
      tooltips: {
        mode: "index",
        intersect: false,
      },
      hover: {
        mode: "nearest",
        intersect: true,
      },
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            display: true,

            gridLines: {
              color: "#ED947A",
              zeroLineColor: "#ED947A",
            },
          },
        ],
        yAxes: [
          {
            display: true,
            gridLines: {
              color: "#ED947A",
              zeroLineColor: "#ED947A",
            },
            ticks: {
              // Include a dollar sign in the ticks
              callback: function (value, index, ticks) {
                return `${value}M`;
              },
            },
          },
        ],
      },
    },
  };
});

const runChart = () => {
  nextTick(function () {
    var ctx = document.getElementById("bar-chart").getContext("2d");
    // ctx.fillText(data[0].value + "%", width/2 - 20, width/2, 200);
    window.myDoughnut = new Chart(ctx, config.value);
  });
};
</script>

<style></style>
