<template>
  <main v-if="false" class="px-2 sm:px-4 py-1 md:min-h-screen">
    <coming-soon :details="launchDetails"></coming-soon>
  </main>
  <main v-else class="px-2 sm:px-4 py-1 min-h-screen">
    <div
      class="flex flex-row-reverse items-center justify-between sm:flex-col sm:items-start mb-6 sm:mb-0"
    >
      <h4
        class="md:mt-3 md:text-sm md:text-left text-xs font-medium text-dark-600 sm:text-headerText capitalize sm:uppercase"
      >
        Payroll / Payroll Analysis
      </h4>
      <!-- <div class="text-left sm:mt-4 sm:mb-6">
        <easiBackButton></easiBackButton>
      </div> -->
    </div>

    <div
      v-if="
        listPayrollHistory.dataByYear && !listPayrollHistory.dataByYear.length
      "
      class="no-printable mt-16 mb-4 w-full"
    >
      <div v-if="loading" class="pt-4 w-full">
        <easiLoader />
      </div>
      <div v-else>
        <img
          src="@/assets/img/payroll-empty.png"
          class="w-8/12 md:w-96 mx-auto"
          alt=""
        />
        <h2 class="text-xl text-center -mt-2 md:text-2xl font-medium">
          Still empty! You have not run any payroll yet
        </h2>
        <div class="flex justify-center">
          <easiButton
            @click="$router.push({ name: 'PaySalary' })"
            class="w-8/12 sm:w-60 mt-5"
          >
            Run Payroll
          </easiButton>
        </div>
      </div>
    </div>

    <div v-else>
      <div v-if="!isViewAnalysis">
        <div class="flex flex-wrap my-10">
          <div class="text-left font-bold text-dark-800 px-4">
            <h2 class="text-3xl capitalize">Generate Payroll Analysis</h2>
            <p class="text-base font-medium mt-2">
              For data security reasons, you will be required to input an OTP or
              set up 2FA to access your payroll analysis.
            </p>
          </div>
        </div>

        <div class="block md:hidden w-90 mx-auto">
          <img src="@/assets/img/newEmptyAnalysis.svg" alt="" />
        </div>
        <div
          class="reverse-gradient w-90/100 relative rounded-2xl md:rounded-l-2xl md:-mr-16 p-4 md:p-6 px-8 flex justify-end items-center mt-10 md:mt-20"
        >
          <div class="w-full md:w-min">
            <easiButton
              @click="selectDuration = true"
              variant="outlined"
              color="white"
              block
              class="font-bold text-lg md:text-xl"
              >Generate Analysis</easiButton
            >

            <div class="hidden md:block absolute w-90 md:left-20 -top-2">
              <img src="@/assets/img/newEmptyAnalysis.svg" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div class="w-full" v-else>
        <ViewPayrollAnalysis></ViewPayrollAnalysis>
      </div>
    </div>
    <easiModal v-if="selectDuration" @close="selectDuration = false">
      <template v-slot:header>
        <h1 class="text-dark-800 font-bold">
          Please select duration within the
          <br />

          same year
        </h1>
      </template>
      <form
        @submit.prevent="sendOTP"
        class="flex flex-col items-center gap-9 w-full mt-3 px-5"
      >
        <span
          class="bg-newLimeGreen px-3 py-2 text-sm rounded-md w-44 text-center text-dark-800 font-bold"
          >From</span
        >

        <input
          class="focus:border-primary border-dark-200 p-16 bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border rounded-sm py-3 px-4 block appearance-none rounded-2xl mt-1"
          type="date"
          name="start"
          id="start"
          required
          v-model="args.from"
        />

        <span
          class="bg-newLimeGreen px-3 py-2 text-sm rounded-md w-44 text-center text-dark-800 font-bold"
          >To</span
        >

        <input
          class="focus:border-primary border-dark-200 p-16 bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border rounded-sm py-3 px-4 block appearance-none rounded-2xl mt-1"
          type="date"
          name="start"
          id="start"
          required
          v-model="args.to"
          :max="maxDate"
          :min="minDate"
        />

        <div class="w-full">
          <hr
            class="mt-2 mb-8 border-0.5 border-dividerColor text-dividerColor"
          />

          <easiButton :loading="otpLoading" block>Generate Analysis</easiButton>
        </div>
      </form>
    </easiModal>

    <easiModal v-if="enterOtp" @close="enterOtp = false" :isBorder="false">
      <easiContainer :hideCancel="true" class="w-full py-8 mx-auto text-center">
        <div class="mb-4">
          <h2 class="text-secondary font-bold text-2xl sm:text-3xl md:text-4xl">
            OTP Verification
          </h2>
          <span class="text-base md:text-md mt-1 font-medium block">
            Please input OTP sent to your email and phone number
          </span>
        </div>
        <form class="w-full" @submit.prevent="confirmOtp">
          <span class="mb-4 flex justify-around items-center">
            <easiOtp
              size="10"
              :token_length="6"
              :max-length="1"
              v-model="otpCode"
              @input="checkOTP"
              :error="errorRules.otpCode"
            />
          </span>
          <div
            class="text-dark-800 mb-4 text-base font-medium text-center flex items-center justify-center"
          >
            Did not receive OTP?
            <easiButton
              type="button"
              @click="sendOTP"
              variant="text"
              color="secondary"
              class="text-base px-0 -ml-3 font-bold"
              :loading="otpLoading"
              >Resend</easiButton
            >
          </div>
          <div class="w-full">
            <easiButton
              block
              color="primary w-full"
              :loading="confirmOtpLoading"
            >
              Submit
            </easiButton>
          </div>
        </form>
      </easiContainer>
    </easiModal>

    <easiLoading v-if="loadingProgress" @close="loadingProgress = false">
      <template v-slot:heading>
        <h1>Generating Analysis</h1>
      </template>

      <!-- <template v-slot:message>
      <div class="w-full rounded-xl">
        <vue3-progress-bar></vue3-progress-bar>
      </div>
    </template> -->
    </easiLoading>

    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span>Payroll Report has been shared successfully</span>
      </template>
    </easiSuccess>
  </main>
</template>

<script setup>
import { computed, ref, onMounted, reactive, watch } from "vue";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs, mapWritableState } from "pinia";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { helperFunctions } from "@/composable/helperFunctions";

import ComingSoon from "@/components/Payroll/ComingSoon.vue";
import ViewPayrollAnalysis from "@/components/Payroll/Analysis/ViewPayrollAnalysis.vue";

const store = useDataStore();
const { query, mutate } = store;
const route = useRoute();
const router = useRouter();
const { listPayrollHistory, getPayrollHistory } = storeToRefs(store);
const { calculatePercentage, formatAmount, processDate, formatDateString } =
  helperFunctions;

const pendingLaunch = ref(true);
const launchDetails = ref({
  header: "Payroll analysis coming soon!",
  list: [
    "Payroll summary by job type",
    "Analysis of company’s payroll health",
    "Minimum and maximum payroll analysis",
    "Available for print and download.",
  ],
});

const adminDetails = computed(() => store.getCompanyAdmin.companyAdmin);

const selectDuration = ref(false);
const isViewAnalysis = ref(false);
const loading = ref(false);
const otpLoading = ref(false);
const resendOtpLoading = ref(false);
const confirmOtpLoading = ref(false);
const loadingProgress = ref(false);
const updateSuccess = ref(false);
const enterOtp = ref(false);

const today = ref("");
today.value = processDate();

const otpReference = ref("");
const otpCode = ref("");
const errorRules = reactive({
  from: false,
  to: false,
  email: false,
  download: false,
  otpCode: false,
});

const args = reactive({
  from: "",
  to: "",
});

const minDate = ref("");
const maxDate = ref("");

// minDate.value = processDate(`${}-${}-${new Date().getFullYear()}`);
// maxDate.value = processDate();

watch(args, (val) => {
  const d = new Date(val.from);
  const year = d.getFullYear();

  let lastDay = new Date(year, 11 + 1, 0).getDate();

  minDate.value = `${year}-01-01`;
  maxDate.value = `${year}-11-30`;
});

onMounted(async () => {
  // const isQuery = route.query;
  // if (isQuery.date) {
  //   const analysisDate = isQuery.date.split(",");
  //   payload.startMonth = parseInt(analysisDate[0]);
  //   payload.endYear = parseInt(analysisDate[1]);
  //   payload.year = parseInt(analysisDate[1]);
  //   isViewAnalysis.value = true;
  //   log(analysisDate);
  //   //Show Analysis Data
  // } else {
  //   isViewAnalysis.value = false;
  //   console.log("e no dey ooo");
  // }
  // await queryPayroll();
  // await updateVisibleData();

  if (route.query.date) {
    isViewAnalysis.value = true;
  }
});

const updateisViewAnalysis = (val) => {
  console.log(val);
  if (val) {
    isViewAnalysis.value = val;
  } else {
    router.replace({ query: null });
    isViewAnalysis.value = val;
  }
};

function checkOTP(code) {
  otpCode.value = code;
}

async function queryPayrollAnalysis() {
  const payload = {
    input: {
      from: args.from,
      to: args.to,
    },
    // otpRef: window.localStorage.getItem("otpReference"),
  };

  loadingProgress.value = true;
  try {
    await query({
      endpoint: "GetPayrollAnalysis",
      service: "PAYROLL",
      storeKey: "payrollAnalysis",
      payload,
    });

    enterOtp.value = false;
    loadingProgress.value = false;
    isViewAnalysis.value = true;
    console.log("ARGS DATE", args.from, args.to);
    router.replace({ query: { date: `${args.from}/${args.to}` } });

    //Clear fields
    args.from = "";
    args.to = "";
  } catch (e) {
    loadingProgress.value = false;
    console.log(e);
  }
}

async function sendOTP() {
  //validate form field
  const payload = {
    channel: "EMAIL",
    type: "PAYROLL_REPORT",
    value: adminDetails.value.email,
  };

  otpLoading.value = true;

  try {
    let res = await mutate({
      endpoint: "SendOTP",
      data: { input: payload },
      service: "AUTH",
    });

    if (res.success) {
      selectDuration.value = false;
      enterOtp.value = true;
      otpLoading.value = false;
      otpReference.value = res.data.reference;
      toast.success(res.message);
      // window.localStorage.setItem("otpReference", res.data.reference);
    } else {
      otpLoading.value = false;
    }
  } catch (e) {
    otpLoading.value = false;
  }
}

async function confirmOtp() {
  if (otpCode.value.trim() === "") {
    errorRules.otpCode = "Please enter the otp code sent to your email";
  } else {
    errorRules.otpCode = false;
    confirmOtpLoading.value = true;

    const payload = {
      reference: otpReference.value,
      code: otpCode.value,
      value: adminDetails.value.email,
    };

    try {
      let res = await mutate({
        endpoint: "ConfirmOTP",
        data: { input: payload },
        service: "AUTH",
      });

      console.log("Confirm OTP successful");
      log(res);
      if (res.success) {
        //Clear error state
        errorRules.otpCode = false;

        selectDuration.value = false;
        enterOtp.value = false;
        loadingProgress.value = true;
        confirmOtpLoading.value = false;

        await queryPayrollAnalysis();
      } else {
        errorRules.otpCode = res.message;
        confirmOtpLoading.value = false;
      }
    } catch (e) {
      loadingProgress.value = false;
      confirmOtpLoading.value = false;
      e.message.toLowerCase() == "record not found"
        ? (errorRules.otpCode = "OTP doesn't match. Please try again")
        : null;
    }
  }
}

const queryPayroll = async () => {
  // loading.value = true;
  try {
    const res = await query({
      endpoint: "ListPayrollHistory",
      payload: {
        input: payload,
      },
      service: "PAYROLL",
      storeKey: "listPayrollHistory",
    });

    // loading.value = false;

    console.log("ListPayrollHistory");
    log(getPayrollHistory.value);

    updateVisibleData();
  } catch (err) {
    // loading.value = false;
    console.log(err);
  }
};

function log(arg) {
  console.log(JSON.parse(JSON.stringify(arg)));
}
</script>

<style scoped>
.w-90\/100 {
  width: 96%;
}
</style>
