<template>
  <main class="px-2 sm:px-4 py-4 min-h-screen">
    <div
      class="flex flex-row-reverse items-center justify-between sm:flex-col sm:items-start mb-6 sm:mb-0"
    >
      <h4
        class="md:mt-3 md:text-sm md:text-left text-xs font-medium text-dark-600 sm:text-headerText capitalize sm:uppercase"
      >
        Payroll / Payroll History
      </h4>
    </div>
    <div
      v-if="
        !isSearch &&
        !(listPayrollHistory.dataByYear && listPayrollHistory.dataByYear.length)
      "
      class="no-printable mt-16 mb-4 w-full"
    >
      <div v-if="!loading">
        <img
          src="@/assets/img/payroll-empty.png"
          class="w-8/12 md:w-96 mx-auto"
          alt=""
        />
        <h2 class="text-xl -mt-2 text-center md:text-2xl font-medium">
          Still empty! You have not run any payroll yet
        </h2>
        <div class="flex justify-center">
          <easiButton
            @click="$router.push({ name: 'PaySalary' })"
            class="w-8/12 sm:w-60 mt-5"
          >
            Run Payroll
          </easiButton>
        </div>
      </div>
    </div>

    <div v-else class="w-full md:w-11/12 mx-auto">
      <!-- /////////// Search Or Filter Not found ////// -->

      <div>
        <div class="w-full">
          <h4
            v-if="isSearch"
            class="text-center text-base sm:text-xl md:text-2xl"
          >
            {{ searchDataResult }}
          </h4>

          <PayrollCard
            :loading="loading"
            :data="payrollCardData"
            :on-print="onPrint"
          />

          <div class="no-printable w-full mx-auto my-6 md:my-10">
            <div
              class="w-full hidden md:flex flex-wrap gap-2 md:justify-between items-center mx-auto"
            >
              <div
                class="no-printable flex items-center justify-end gap-2 md:gap-4"
              >
                <div
                  class="bg-white border-2 border-outlineGray p-2 rounded-2xl flex space-x-3"
                >
                  <easiButton
                    variant="outlined"
                    size="small"
                    rounded="lg"
                    border="2"
                    v-for="action in actions"
                    :key="action.title"
                    :loading="action.loading"
                    @click="action.action"
                  >
                    <span class="flex items-center gap-2">
                      {{ action.title }}

                      <i :class="action.icon" />
                    </span>
                  </easiButton>
                </div>
              </div>

              <div class="w-fit flex mx-auto sm:mx-0 items-center gap-4">
                <YearSelect v-model:year="selectedYear" />

                <PayslipFilter ref="payrollFilterRef" @update="filterFN" />
                <easiButton
                  color="primary"
                  @click="$router.push({ name: 'Payslip' })"
                  class="py-4"
                >
                  <span class=""> Send Payslip(s) </span>
                </easiButton>
              </div>
            </div>

            <div class="md:hidden flex justify-between">
              <div class="flex gap-x-3">
                <YearSelect v-model:year="selectedYear" />
                <PayslipFilter @update="filterFN" />
                <easiButton
                  color="primary"
                  @click="$router.push({ name: 'Payslip' })"
                  class="py-4"
                >
                  <span> Payslip </span>
                </easiButton>
              </div>
              <div class="flex gap-x-3">
                <easiButton
                  v-for="action in actions"
                  :key="action.title"
                  variant="outlined"
                  rounded="lg"
                  size="small"
                  border="2"
                  :loading="action.loading"
                  @click="action.action"
                >
                  <span class="py-1 px-2 flex items-center justify-center">
                    <i :class="[action.icon, '']" />
                  </span>
                </easiButton>
              </div>
            </div>
          </div>
          <easiActiveFilter @close="removeFilter" :filters="activeFilters" />
          <!-- <div v-if="loading" class="pt-4 w-full">
            <easiLoader />
          </div> -->
          <div v-if="!loading">
            <div
              v-if="
                isSearch &&
                !(
                  listPayrollHistory.dataByYear &&
                  listPayrollHistory.dataByYear.length
                )
              "
              class="no-printable container mx-auto items-center justify-center w-full sm:w-11/12 md:w-10/12 my-6 grid grid-cols-2 gap-2 mt-12"
            >
              <div class="flex flex-col text-left col-span-2 md:col-span-1">
                <h1
                  class="text-secondary text-5xl md:text-7xl font-semibold mb-4"
                >
                  Oops!
                </h1>
                <div class="text-sm md:text-lg">
                  Sorry we couldn’t find a result for your request.
                </div>
                <p class="text-xs md:text-sm mt-2">
                  Please check your search and filter, then try again.
                </p>
              </div>
              <div class="col-span-2 md:col-span-1">
                <img src="@/assets/icons/search_null.svg" alt="" />
              </div>
            </div>

            <div class="w-full" v-if="visibleData.length">
              <div
                class="w-full my-6'"
                v-for="(item, i) in visibleData"
                :key="i"
              >
                <div
                  class="hidden md:block text-left w-full text-dark-900 md:text-2xl font-bold"
                >
                  Year {{ item.year }}
                </div>

                <div
                  class="mb-7 md:hidden text-center border-b-4 border-newLightgrey w-full text-dark-800 text-3xl font-medium"
                >
                  {{ item.year }}
                </div>
                <div class="w-full mt-2 mb-4"></div>
                <payroll-table :data="item.data" />
              </div>
            </div>
          </div>

          <!-- //////// Pagination //////// -->
          <div>
            <easiPagin
              v-if="visibleData.length"
              :data="listPayrollHistory.dataByYear"
              @page:update="updatePage"
              :currentPage="currentPage"
              :pageSize="pageSize"
              class="my-4"
            />
          </div>
        </div>
      </div>
    </div>
    <easiRoundLoader v-if="loading" />

    <easiSuccess v-if="payslipSuccess" @close="payslipSuccess = false">
      <template v-slot:message>
        <span>Payroll information shared successfully</span>
      </template>
    </easiSuccess>

    <ShareToAdmin
      :input="payload"
      :isOpen="shareModal"
      @close="shareModal = false"
    />
  </main>
</template>

<script setup>
import { computed, ref, onMounted, reactive, watch } from "vue";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs, mapWritableState } from "pinia";
import { helperFunctions } from "@/composable/helperFunctions";

import YearSelect from "@/components/global/YearSelect.vue";
import PayslipFilter from "@/components/Filters/PayslipFilter.vue";
import PayrollTable from "@/components/Payroll/History/PayrollTable.vue";
import PayrollCard from "@/components/Payroll/History/PayrollCard.vue";
import ShareToAdmin from "./ShareToAdmin.vue";

// import payrollHistory from "@/utils/payrollHistory";

const store = useDataStore();
const { query, mutate } = store;
const shareModal = ref(false);

// const { listPayrollHistory, getPayrollHistory } = storeToRefs(store);
const { getPayrollHistory } = storeToRefs(store);
const { lastDateOfMonth, downloadBase64, printPDF } = helperFunctions;

const listPayrollHistory = computed(() => store.getPayrollHistory);

// console.log("listPayrollHistory", listPayrollHistory.value);

const loading = ref(false);
const printLoading = ref(false);
const downloadLoading = ref(false);
const onPrint = ref(false);
const isSearch = ref(false);

const payrollFilterRef = ref(null);
const activeFilters = ref([]);

let payload = ref({
  endMonth: 11,
  startMonth: 0,
  endYear: new Date().getFullYear(),
  year: new Date().getFullYear() - 1,
});

const selectedYear = ref(new Date().getFullYear());

watch(selectedYear, () => {
  payload.value.endYear = selectedYear.value;
  payload.value.year = selectedYear.value;
  isSearch.value = true;
  queryPayroll();
});

const actions = computed(() => {
  return [
    {
      title: "Print",
      icon: "pi pi-print",
      loading: printLoading.value,
      action() {
        handlePrint();
      },
    },
    {
      title: "Share",
      icon: "pi pi-share-alt",
      action() {
        handleShare();
      },
    },
    {
      title: "Download",
      icon: "pi pi-download",
      loading: downloadLoading.value,
      action() {
        handleDownload();
      },
    },
  ];
});

const searchDataResult = ref("");

const setSearchDataResult = (arg) => {
  const { startMonth, year, endMonth, endYear } = arg;
  // console.log(startMonth, year, endMonth, endYear);
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  if (arg) {
    const start = months[startMonth];
    const end = months[endMonth];

    searchDataResult.value = `Total Run Payroll From ${start} 1, ${year} to ${end} ${lastDateOfMonth(
      endMonth,
      endYear
    )}, ${endYear}`;
  }
};

const currentPage = ref(0);
const pageSize = ref(15);
let visibleData = ref([]);

const payrollCardData = computed(() => {
  return {
    employeesTotal: listPayrollHistory.value.totalEmployees,
    grossTotal: listPayrollHistory.value.allTimeGross,
    netTotal: listPayrollHistory.value.allTimeNetTotal,
    deductionTotal: listPayrollHistory.value.allTimeTotalDeductions,
  };
});

onMounted(async () => {
  await queryPayroll();
  updateVisibleData();
});

const updatePage = (pageNumber) => {
  currentPage.value = pageNumber;
  updateVisibleData();
};

const updateVisibleData = () => {
  // Sort Data by year
  listPayrollHistory.value.dataByYear =
    listPayrollHistory.value.dataByYear.sort(
      (a, b) => new Date(b.year) - new Date(a.year)
    );

  let datas = listPayrollHistory.value.dataByYear;
  visibleData.value = datas.slice(
    currentPage.value * pageSize.value,
    currentPage.value * pageSize.value + pageSize.value
  );

  // if we have 0 visible todos, go back a page
  if (visibleData.value.length == 0 && currentPage.value > 0) {
    updatePage(currentPage.value - 1);
  }
};

const handleShare = () => {
  if (
    listPayrollHistory.value &&
    listPayrollHistory.value.dataByYear &&
    listPayrollHistory.value.dataByYear.length
  ) {
    shareModal.value = true;
  }
};

const handleDownload = async () => {
  if (
    listPayrollHistory.value &&
    listPayrollHistory.value.dataByYear &&
    listPayrollHistory.value.dataByYear.length
  ) {
    try {
      downloadLoading.value = true;
      const res = await query({
        endpoint: "DownloadPayrollHistorySummary",
        payload: {
          input: payload.value,
          options: {
            sendToCompanyAdmin: null,
            sendToAuditors: null,
          },
        },
        service: "PAYROLL",
      });
      downloadLoading.value = false;
      const base64 = res.data.base64;
      const fileName = res.data.fileName;
      downloadBase64(base64, fileName);
    } catch (error) {
      downloadLoading.value = false;
      console.log(error.message);
    }
  }
};

const handlePrint = async () => {
  if (
    listPayrollHistory.value &&
    listPayrollHistory.value.dataByYear &&
    listPayrollHistory.value.dataByYear.length
  ) {
    try {
      printLoading.value = true;
      const res = await query({
        endpoint: "DownloadPayrollHistorySummary",
        payload: {
          input: payload.value,
          options: {
            sendToCompanyAdmin: null,
            sendToAuditors: null,
          },
        },
        service: "PAYROLL",
      });
      printLoading.value = false;
      const base64 = res.data.base64;

      printPDF(base64);
    } catch (error) {
      printLoading.value = false;
      console.log(error.message);
    }
  }
};

const filterFN = ({ filter, active }) => {
  isSearch.value = filter.isSearch;
  delete filter.isSearch;

  // console.log(isSearch.value);

  payload.value = filter;

  // console.log(payload.value);
  queryPayroll();

  activeFilters.value = active;
  setSearchDataResult(filter);
};

const removeFilter = (arg) => {
  // console.log(payrollFilterRef.value);
  if (payrollFilterRef.value && payrollFilterRef.value.removeFilter) {
    payrollFilterRef.value.removeFilter(arg);
  }
};

const queryPayroll = async () => {
  loading.value = true;
  try {
    const res = await query({
      endpoint: "ListPayrollHistory",
      payload: {
        input: payload.value,
      },
      service: "PAYROLL",
      storeKey: "listPayrollHistory",
    });

    loading.value = false;

    updateVisibleData();
  } catch (err) {
    loading.value = false;
    console.log(err);
  } finally {
    loading.value = false;
  }
};

// Get rid of the proxy when console.log
function log(arg) {
  // console.log(JSON.parse(JSON.stringify(arg)));
}
const shareLoading = ref(false);
const shareOptions = ref([]);
const allRoles = computed(() => store.getRoles.data);
let selectedEmployees = ref([]);
const payslipSuccess = ref(false);
</script>

<style></style>
