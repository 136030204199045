<template>
  <main
    class="w-full text-left bg-white rounded-2xl px-4 py-6 md:px-6 shadow-lg"
  >
    <h2 class="text-xl font-bold">Payroll By Department</h2>
    <div
      :class="
        showAll
          ? 'justify-around'
          : 'flex-wrap justify-center flex-col lg:justify-start'
      "
      class="flex lg:flex-row items-center"
    >
      <div class="w-fit">
        <div class="p-4 flex-auto">
          <div class="relative h-52">
            <canvas id="pie-chart"></canvas>
          </div>
        </div>
      </div>
      <div class="flex flex-col">
        <div
          v-for="(item, i) in payrollAnalysis && payrollAnalysis.bydepartment"
          :key="i"
          class="flex items-center justify-between gap-2 text-sm mt-1 first:mt-0"
        >
          <div class="flex items-center">
            <span>{{ item.percent.toFixed() }}%</span>
            <i
              :style="`color:${item.colour}`"
              class="fas fa-circle text-xs mx-1"
            ></i>
            <span>{{ item._id }} </span>
          </div>
          <span class="font-bold text-center">
            {{ formatAmount(item.count) }}
          </span>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import { ref, reactive, onMounted, watch, computed, nextTick } from "vue";
import { storeToRefs } from "pinia";
import { useDataStore } from "@/stores/data.js";
import { helperFunctions } from "@/composable/helperFunctions";
import { useToast } from "vue-toastification";

const { formatAmount, calculatePercentage, getAvatar } = helperFunctions;
const toast = useToast();
const store = useDataStore();
const { mutate, query } = useDataStore();

const props = defineProps({
  showAll: {
    type: Boolean,
    default: false,
  },
});

const payrollAnalysis = computed(() => store.getPayrollAnalysis);

onMounted(() => {
  runChart();
});

const config = computed(() => {
  return {
    type: "pie",
    data: {
      labels:
        payrollAnalysis.value &&
        payrollAnalysis.value.bydepartment.map((el) => {
          return el._id;
        }),
      datasets: [
        {
          // data: [
          //   12567876.33, 13567876.33, 9567876.33, 10567876.33, 4567876.33,
          //   29567876.33,
          // ],

          data:
            payrollAnalysis.value &&
            payrollAnalysis.value.bydepartment.map((el) => {
              return parseInt(el.count);
            }),

          backgroundColor:
            payrollAnalysis.value &&
            payrollAnalysis.value.bydepartment.map((el) => {
              return el.colour;
            }),
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      responsive: true,
      tooltips: {
        mode: "index",
        intersect: false,
      },
      legend: {
        display: false,
      },
      hover: {
        mode: "nearest",
        intersect: true,
      },
    },
  };
});

console.log("CONFIG", config.value);

const runChart = () => {
  nextTick(function () {
    var ctx = document.getElementById("pie-chart").getContext("2d");
    // ctx.fillText(data[0].value + "%", width/2 - 20, width/2, 200);
    window.myPie = new Chart(ctx, config.value);
  });
};
</script>

<style></style>

<!-- data: {
  labels: [
    "Administration",
    "Marketing",
    "Human Resource",
    "Information Tech",
    "Accounting",
    "Production",
  ],
  datasets: [
    {
      // data: [
      //   12567876.33, 13567876.33, 9567876.33, 10567876.33, 4567876.33,
      //   29567876.33,
      // ],

      data:
        payrollAnalysis.value &&
        payrollAnalysis.value.bydepartment.map((el) => {
          return el.count;
        }),

      backgroundColor: [
        "#E0A409",
        "#5B0169",
        "#E4572E",
        "#023C98",
        "#19A301",
        "#01AA8C",
      ],
    },
  ],
}, -->
