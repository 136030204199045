<template>
  <main class="w-full text-left bg-white rounded-2xl p-6/5 shadow-lg">
    <h2 class="text-xl font-bold text-dark-800 capitalize">Total Number of successful payments</h2>
    <div class="flex items-end justify-around">
      <div class="">
        <div class="py-4 flex-auto">
          <div class="relative h-48 w-56">
            <canvas id="doughnut-chart"></canvas>
            <h2
              class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-xl font-bold"
            >
              {{ getTotal }}
            </h2>
          </div>
        </div>
      </div>
      <div class="flex flex-col">
        <h4 class="text-base font-bold">Gender</h4>
        <div class="flex items-center md:text-sm">
          <i
            :style="`color: ${male.colour}`"
            class="fas fa-circle text-xs mr-1"
          ></i>
          <span class="text-xs"
            >Female -
            {{
              parseInt((female ? female.count : 0).toFixed()).toLocaleString()
            }}</span
          >
        </div>
        <div class="flex items-center text-xs md:text-sm">
          <i class="fas fa-circle text-xs mr-1"></i>
          <span class="text-xs"
            >Male -
            {{
              parseInt((male ? male.count : 0).toFixed()).toLocaleString()
            }}</span
          >
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import { ref, reactive, onMounted, watch, computed, nextTick } from "vue";
import { storeToRefs } from "pinia";
import { useDataStore } from "@/stores/data.js";
import { helperFunctions } from "@/composable/helperFunctions";
import { useToast } from "vue-toastification";

const { formatAmount, calculatePercentage, getAvatar } = helperFunctions;
const toast = useToast();
const store = useDataStore();
const { mutate, query } = useDataStore();

const payrollAnalysis = computed(() => store.getPayrollAnalysis);

const male = computed(() => {
  return (
    payrollAnalysis.value &&
    payrollAnalysis.value.byGender.find((el) => el._id === "MALE")
  );
});
const female = computed(() => {
  return (
    payrollAnalysis.value &&
    payrollAnalysis.value.byGender.find((el) => el._id === "FEMALE")
  );
});

const getTotal = computed(() => {
  let m = male.value ? male.value.count : 0;
  let f = female.value ? female.value.count : 0;

  return parseInt((m + f).toFixed()).toLocaleString();
});

console.log("MALE", male.value);
console.log("FEMALE", female.value);

onMounted(() => {
  runChart();
});

const config = computed(() => {
  return {
    type: "doughnut",
    data: {
      labels: ["Male", "Female"],
      datasets: [
        {
          data: [
            (male.value && male.value.count).toFixed(),
            (female.value && female.value.count).toFixed(),
          ],
          backgroundColor: ["#0A244F", "#E4572E"],
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      responsive: true,
      tooltips: {
        mode: "index",
        intersect: false,
      },
      legend: {
        display: false,
      },
      hover: {
        mode: "nearest",
        intersect: true,
      },
    },
  };
});

const runChart = () => {
  nextTick(function () {
    var ctx = document.getElementById("doughnut-chart").getContext("2d");
    // ctx.fillText(data[0].value + "%", width/2 - 20, width/2, 200);
    window.myDoughnut = new Chart(ctx, config.value);
  });
};
</script>

<style scoped>
.p-6\/5 {
  padding: 27px;
}
</style>
