<template>
  <main class="px-4 min-h-screen">
    <h4
      class="hidden sm:block md:mt-3 md:text-sm md:text-left text-xs text-headerText"
    >
      Payroll / View Salary
    </h4>
    <!-- <div class="md:block hidden text-left my-6">
      <easiBackButton></easiBackButton>
    </div> -->
    <div
      v-if="!isSearch && allEmployees && !allEmployees.length"
      class="mt-16 mb-4"
    >
      <easiEmptyState
        text=" You have not added any employee yet."
      ></easiEmptyState>
    </div>

    <div v-else class="my-6 flex flex-col gap-2 md:my-6 w-full mx-auto">
      <div
        class="flex md:flex-row flex-col md:justify-between justify-center mx-auto w-full md:gap-y-0 gap-y-8 md:items-center"
      >
        <div
          class="flex flex-wrap sm:flex-nowrap md:items-center mt-4 w-full md:w-full mx-auto grow-0"
        >
          <!-- /////// Search Component ///// -->
          <div class="w-9/12">
            <easiSearch
              placeholder="Search employee by name"
              useNewclass
              v-model="searchText"
              @search="searchFN"
            />
          </div>

          <!-- /////// Filter Component Start /////// -->
          <div class="ml-4">
            <SalaryFilter ref="viewSalaryFilterRef" @update="filterFN" />
          </div>
          <div
            class="sm:ml-3 mt-4 flex sm:block justify-center w-full sm:w-auto sm:mt-0"
          >
            <easiButton
              @click="showSendPayslip = true"
              size="small"
              color="primary"
              class="flex justify-center items-center py-3 px-7 md:py-4 space-x-3"
            >
              <img
                class="w-4 md:w-5"
                src="@/assets/icons/newPayslipBtn.svg"
                alt=""
              />
              <span class="text-sm md:text-base whitespace-nowrap">
                Payslips</span
              >
            </easiButton>
          </div>
          <!-- /////// Filter Component Ends /////// -->
        </div>
      </div>

      <easiActiveFilter @close="removeFilter" :filters="activeFilters" />
    </div>
    <div
      v-if="getAllEmployees && allEmployees.length"
      class="hidden sm:block flex justify-end items-center md:gap-x-6 gap-2"
    >
      <easiButton
        @click="$router.push({ name: 'EditSalary' })"
        size="small"
        color=" "
        class="flex justify-center items-center px-2 space-x-2"
      >
        <span class="text-sm text-center text-primary whitespace-nowrap">
          Edit Salary
        </span>
        <img class="w-4 md:w-5" src="@/assets/icons/newEdit.svg" alt="" />
      </easiButton>
    </div>

    <div v-if="getAllEmployees && allEmployees.length" class="mt-6">
      <easiPayrollTable
        :routable="true"
        :data="transformedData"
        :namesForAllowances="allowanceNames"
        :amountValues="amountValues"
        :frozenFields="['firstName', 'lastName']"
        :highlight="['firstName', 'lastName']"
        :header="headers"
        :useSelect="['department', 'employmentType', 'branch']"
        :hideAllowances="false"
        @selected="updateSelectedEmployees($event)"
      />
    </div>

    <div
      v-if="isSearch && !allEmployees.length"
      class="container mx-auto items-center justify-center w-full sm:w-11/12 md:w-10/12 my-6 grid grid-cols-2 gap-2 mt-12"
    >
      <div class="flex flex-col text-left col-span-2 md:col-span-1">
        <h1 class="text-secondary text-5xl md:text-7xl font-semibold mb-4">
          Oops!
        </h1>
        <div class="text-sm md:text-lg">
          Sorry we could not find a result for your search
        </div>
        <p class="text-xs md:text-sm mt-2">
          Check your spelling or try a different name. Make sure you search by
          <span class="text-secondary font-medium">Name</span>,
          <span class="text-secondary font-medium">Employee ID</span> or
          <span class="text-secondary font-medium">Department</span>
        </p>
      </div>
      <div class="col-span-2 md:col-span-1">
        <img src="@/assets/icons/search_null.svg" alt="" />
      </div>
    </div>

    <!-- <easiPagin
      v-if="payrollData.length"
      :data="payrollData"
      @page:update="updatePage"
      :currentPage="currentPage"
      :pageSize="pageSize"
      class=""
    /> -->

    <SendPayslip
      v-if="getAllEmployees && allEmployees.length && showSendPayslip"
      @close="showSendPayslip = false"
      :employeeIds="selectedEmployees"
    />
  </main>
</template>

<script setup>
import { ref, reactive, onMounted, watch, computed } from "vue";
import { storeToRefs } from "pinia";
import { useDataStore } from "@/stores/data.js";
import { helperFunctions } from "@/composable/helperFunctions";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toastification";

import SendPayslip from "@/components/Payroll/Payslip/SendPayslip.vue";
import SalaryFilter from "@/components/Filters/SalaryFilter.vue";

const store = useDataStore();
const { query, mutate } = store;
const { getAllEmployees, checkLoading, listAllowance } = storeToRefs(store);
const { calculatePercentage, formatAmount, formatDateString, log } =
  helperFunctions;
const router = useRouter();

const toast = useToast();

const loading = ref(false);
const showSendPayslip = ref(false);
const searchText = ref("");
let isSearch = ref(false);
const filterOptions = ref({
  sort: { firstName: "ASC" },
  filter: [],
});
const activeFilters = ref([]);
const viewSalaryFilterRef = ref(null);

const allEmployees = computed(() =>
  getAllEmployees.value && getAllEmployees.value.data
    ? getAllEmployees.value.data
    : []
);
let selectedEmployees = ref([]);
const amountValues = [
  "paye",
  "pension",
  "hmo",
  "lifeInsurance",
  "lifeAssurance",
  "wht",
  "nsitf",
  "itf",
  "gratuity",
  "nhf",
  "businessExpense",
  "grossPay",
  "netPay",
];
const currentPage = ref(0);
const pageSize = ref(15);
let visibleData = ref([]);

const payload = ref({
  paging: {
    limit: 1000,
    skip: 0,
    search: "",
    sort: {
      firstName: "ASC",
    },
  },
  filter: [],
});

watch(searchText, async (val) => {
  if (val.trim() === "") {
    payload.value.paging.search = "";
    await queryEmployees();
    isSearch.value = false;
  }
});
const allAllowanceData = computed(() => store.listAllowance);

function transform(arr) {
  const newArr = arr.map((obj) => {
    let data = {
      // id: obj.job.userName,
      employeeId: obj._id,
      firstName: obj.profile.firstName,
      lastName: obj.profile.lastName,
      picture: obj.profile.pfp,

      grossPay: obj.staffLevel ? Number(obj.staffLevel.grossAmount) : 0,
      netPay:
        obj.staffLevel &&
        obj.prorate &&
        obj.prorate.staffLevel &&
        Number(obj.prorate.staffLevel.netAmount) > 0
          ? Number(obj.prorate.staffLevel.netAmount)
          : obj.staffLevel
          ? Number(obj.staffLevel.netAmount)
          : 0,
      accountName:
        obj.payment && obj.payment.accountName
          ? obj.payment.accountName
          : "N/A",
      accountNumber:
        obj.payment && obj.payment.accountNumber
          ? obj.payment.accountNumber
          : "N/A",
      bankName:
        obj.payment && obj.payment.bankName ? obj.payment.bankName : "N/A",
      //   department: obj.job.departmentId,
      //   employmentType: obj.job.employmentType,
      //   branch: obj.job.branchId,
      usePercentage: obj.staffLevel && obj.staffLevel.usePercentageForAllowance,
      staffLevelId: obj.staffLevel && obj.staffLevel._id,
      // deductions: obj.staffLevel && obj.staffLevel.benefits,
      allowanceDetails: obj.staffLevel && obj.staffLevel.allowanceDetails,
      prorate: obj.prorate && obj.prorate.proratedSalary ? obj.prorate : {},

      // itf: obj.staffLevel.itf ? obj.staffLevel.itf.amount : 0,
      nhf: obj.staffLevel.nhf ? obj.staffLevel.nhf.amount : 0,
      hmo: obj.staffLevel.hmo ? obj.staffLevel.hmo.amount : 0,
      employeePension: obj.staffLevel.pension
        ? obj.staffLevel.pension.employeePensionContribution
        : 0,
      employerPension: obj.staffLevel.pension
        ? obj.staffLevel.pension.employerPensionContribution
        : 0,
      paye: obj.staffLevel.paye ? obj.staffLevel.paye.amount : 0,
      lifeAssurance: obj.staffLevel.lifeAssurance,
      //   ? obj.staffLevel.lifeAssurance.amount
      //   : 0,
      // lifeInsurance: obj.staffLevel.lifeInsurance
      //   ? obj.staffLevel.lifeInsurance.amount
      //   : 0,
      // wht: obj.staffLevel.wht ? obj.staffLevel.wht.amount : 0,
      // nsitf: obj.staffLevel.nsitf ? obj.staffLevel.nsitf.amount : 0,
      // gratuity: obj.staffLevel.gratuity ? obj.staffLevel.gratuity.amount : 0,
      // businessExpense: obj.staffLevel.businessExpense
      //   ? obj.staffLevel.businessExpense.amount
      //   : 0,
    };

    obj.bonusOrDeductions.forEach((item) => {
      data[`${item.name.toLowerCase()}${item.type}`] = item.percentage
        ? formatAmount(calculatePercentage(data.grossPay, item.percentage))
        : formatAmount(item.amount);
    });
    return data;
  });

  return newArr;
}
const allowanceNames = ref([]);
function getAllowanceNameById(id) {
  for (let data of allAllowanceData.value.data) {
    let allName;
    // allowanceNames.value.push(data.name);
    if (data._id === id) {
      allName = data.name;
      return allName;
    }
  }
}
function getAllowanceNames() {
  let names = [];
  names =
    allAllowanceData.value.data &&
    allAllowanceData.value.data.map((n) => n.name);
  return names;
}
allowanceNames.value = getAllowanceNames();

function createCustomAllowanceDetails() {
  const employees = allEmployees.value;
  for (let emp of employees) {
    for (let aw of allAllowanceData.value.data) {
      if (emp.staffLevel.allowanceDetails.allowanceId !== aw._id) {
        emp.staffLevel.allowanceDetails.push({
          _id: null,
          allowanceId: aw._id,
          amount: 0,
          percentage: 0,
        });
      }
    }
  }
  console.log(employees, "HEY");
}
// createCustomAllowanceDetails();

function extractAllowance(arr) {
  const allowance =
    arr &&
    arr.map((obj) => {
      const details =
        obj.staffLevel &&
        obj.staffLevel.allowanceDetails.map((ad) => {
          return {
            amount: ad.amount,
            percentage: ad.percentage,
            usePercentage: obj.staffLevel.usePercentageForAllowance,
            name: getAllowanceNameById(ad.allowanceId),
          };
        });

      return details;
    });

  return allowance;
}
const allowance = ref([]);
allowance.value = extractAllowance(allEmployees.value);

function finalVal() {
  const fna =
    allowance.value &&
    allowance.value.map((item, index) => {
      const info =
        item &&
        item.reduce(
          (acc, cur) => ({ ...acc, [cur.name]: cur.percentage || cur.amount }),
          {}
        );
      return info;
    });
  return fna;
}
const datum = ref([]);
datum.value = finalVal();
const transformedData = ref([]);
transformedData.value = transform(allEmployees.value);

const headers = computed(() => {
  if (payrollData.value.length > 0) {
    let headArr = [];
    const head = Object.keys(payrollData.value[0]);
    for (let i of head) {
      headArr.push({
        field: i,
        header: i,
      });
    }
    return headArr;
  }
});

const searchFN = async (suggested) => {
  // console.log(suggested, searchText.value);
  searchText.value = suggested ? suggested : searchText.value;
  isSearch.value = true;
  payload.value.paging.search = searchText.value;
  await queryEmployees();
};

const filterFN = async ({ filter, active }) => {
  isSearch.value = true;
  filterOptions.value = filter;

  payload.value.paging.sort = filterOptions.value.sort;
  payload.value.filter = filterOptions.value.filter;

  console.log("Payload");
  log(payload.value);
  await queryEmployees();

  activeFilters.value = active;
};

const removeFilter = (arg) => {
  console.log(viewSalaryFilterRef.value);
  if (viewSalaryFilterRef.value && viewSalaryFilterRef.value.removeFilter) {
    viewSalaryFilterRef.value.removeFilter(arg);
  }
};

const queryEmployees = async () => {
  loading.value = true;
  try {
    await query({
      endpoint: "ListEmployees",
      payload: payload.value,
      service: "EMP",
      storeKey: "listEmployees",
    });

    loading.value = false;
    console.log("Employees");
    log(getAllEmployees.value.data);
  } catch (err) {
    loading.value = false;
    console.log(err);
  }
};

function updateSelectedEmployees(arg) {
  selectedEmployees.value = [];
  if (arg) {
    arg.forEach((el) => {
      selectedEmployees.value.push(el.employeeId);
    });
  }

  log(selectedEmployees.value);
}

function cross() {
  const employee = transformedData.value;
  let newTransformed = [];
  if (employee) {
    const fresh = employee.map((emp, index) => {
      const fin = datum.value.map((du, i) => {
        if (index === i) {
          const merged = Object.assign({}, emp, du);
          newTransformed.push(merged);
          return merged;
        }
      });
    });
    for (let all of newTransformed) {
      for (let k of allowanceNames.value) {
        if (!all[k]) {
          all[k] = 0;
        }
      }
    }
  }
  console.log(newTransformed, "NEW");
  return newTransformed;
}

const payrollData = ref([]);
payrollData.value = cross();

const updatePage = (pageNumber) => {
  currentPage.value = pageNumber;
  if (
    getAllEmployees.value &&
    getAllEmployees.value.data &&
    getAllEmployees.value.data.length
  ) {
    updateVisibleData();
  }
};

const updateVisibleData = () => {
  let datas = transformedData.value;
  visibleData.value = datas.slice(
    currentPage.value * pageSize.value,
    currentPage.value * pageSize.value + pageSize.value
  );

  // if we have 0 visible todos, go back a page
  if (visibleData.value.length == 0 && currentPage.value > 0) {
    updatePage(currentPage.value - 1);
  }
};

watch(allEmployees, (val) => {
  transformedData.value = transform(val);
  // allowance.value = extractAllowance(allEmployees.value);
  // datum.value = finalVal();
  // payrollData.value = cross();

  if (
    getAllEmployees.value &&
    getAllEmployees.value.data &&
    getAllEmployees.value.data.length
  ) {
    updateVisibleData();
  }
});

onMounted(async () => {
  await queryEmployees();
  if (
    getAllEmployees.value &&
    getAllEmployees.value.data &&
    getAllEmployees.value.data.length
  ) {
    updateVisibleData();
  }
});
</script>

<style></style>
