<template>
  <div class="flex items-center gap-4">
    <div
      @click="emit('previous')"
      class="w-9 h-9 flex items-center justify-center rounded-full bg-greyBg border border-outlineGray"
      :class="hasPrevious ? 'cursor-pointer' : 'pointer-events-none'"
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19 12H5"
          :stroke="hasPrevious ? '#474747' : '#B5B6B5'"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11 6L5 12L11 18"
          :stroke="hasPrevious ? '#474747' : '#B5B6B5'"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>

    <div
      @click="emit('next')"
      class="w-9 h-9 flex items-center justify-center rounded-full bg-greyBg border border-outlineGray"
      :class="hasNext ? 'cursor-pointer' : 'pointer-events-none'"
    >
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.5 12H19.5"
          :stroke="hasNext ? '#474747' : '#B5B6B5'"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.5 6L19.5 12L13.5 18"
          :stroke="hasNext ? '#474747' : '#B5B6B5'"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  </div>
</template>

<script setup>
const props = defineProps(["hasPrevious", "hasNext"]);
const emit = defineEmits(["previous", "next"]);
</script>

<style></style>
